import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'
import { RootState, useAppDispatch } from '@/states'
import {
  CellInterface,
  ComponentInterface,
  ContentsInterface,
  LanguagesAvailable,
} from '@/types'
import { updateContentsItem } from '@/states/actions'
import { createCell, getContentsItem, updateCell, updateContents } from '@/api'
import { CellComponent } from '.'
import moment from 'moment-timezone'
import { dateFormatReq } from '@/configs'

interface CellItemProps {
  component: ComponentInterface
  contents: ContentsInterface
  cell: CellInterface | null | undefined
}

export const CellItem = ({ component, contents, cell }: CellItemProps) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject, currentModel, contentsList, flattenComponentList } =
    projectsState

  /**
   * 셀 정보 수정
   * @param componentId
   * @param key
   * @param value
   * @param lang
   * @returns
   */
  const onHandleCellChange = (
    componentId: number | undefined,
    key: string,
    value: boolean | number | string | object | null,
    lang?: LanguagesAvailable
  ) => {
    if (!contents || currentProject?.role === 'VIEWER') return false

    const updatedContents = JSON.parse(JSON.stringify(contentsList))
    const contentsItem = updatedContents.find((cn) => cn.uid === contents.uid)

    let req: CellInterface = {
      uid: cell?.uid,
    }

    // Update
    if (cell && cell.uid) {
      const cellItem = contentsItem.cellList?.find((cl) => cl.uid === cell.uid)

      if (lang) {
        // Language map 추가
        if (typeof cellItem[key] === 'undefined') {
          cellItem[key] = {}
        }
        currentProject?.languageList.map((lang) => {
          cellItem[key][lang] =
            cell.languageMap && cell.languageMap[lang]
              ? cell.languageMap[lang]
              : ''
        })

        cellItem[key][lang] = value
      } else {
        cellItem[key] = value
      }

      req = cellItem
    }
    // Add
    else {
      const cellAdd: CellInterface = {
        // @ts-ignore
        component: {
          // @ts-ignore
          id: component.id,
          type: component.type,
        },
        componentId: component.id,
        option: component.option,
        value: null,
        selectorIdList: [],
        mediaList: [],
        relationUidList: [],
        languageMap: {},
      }

      // Language map 추가
      currentProject?.languageList.map((lang) => {
        // @ts-ignore
        cellAdd.languageMap[lang] = ''
      })

      if (lang) {
        cellAdd[key][lang] = value
      } else {
        cellAdd[key] = value
      }

      req = cellAdd
    }

    // Sanitize
    if (
      component &&
      (component.type === 'TITLE' ||
        component.type === 'SINGLE_LINE_TEXT' ||
        component.type === 'LONG_LINE_TEXT') &&
      component.option &&
      !component.option.allowHtml
    ) {
      currentProject?.languageList.forEach((lang) => {
        if (req && req.languageMap && req.languageMap[lang]) {
          req.languageMap[lang] = (req.languageMap[lang] as string)
            .normalize('NFC')
            .replace(/(<([^>]+)>)/gi, '')
        }
      })
    }

    if (
      component &&
      (component.type === 'SINGLE_LINE_TEXT_MONO' ||
        component.type === 'LONG_LINE_TEXT_MONO' ||
        component.type === 'RICH_TEXT_MONO') &&
      component.option &&
      !component.option.allowHtml &&
      req &&
      req.value
    ) {
      req.value = (req.value + '').normalize('NFC').replace(/(<([^>]+)>)/gi, '')
    }

    if (
      component &&
      (component.type === 'PASSWORD' || component.type === 'EMAIL') &&
      req &&
      req.value
    ) {
      req.value = (req.value + '').normalize('NFC').replace(/(<([^>]+)>)/gi, '')
    }

    if (
      component &&
      component.type === 'DATE' &&
      req.value &&
      (typeof req.value === 'string' || typeof req.value === 'object')
    ) {
      if (component.option?.dateFormats === 'year') {
        req.value = moment(req.value, 'YYYY').format(dateFormatReq)
      } else if (component.option?.dateFormats === 'month') {
        req.value = moment(req.value, 'YYYY-MM').format(dateFormatReq)
      } else if (component.option?.dateFormats === 'date') {
        req.value = moment(req.value, 'YYYY-MM-DD').format(dateFormatReq)
      } else if (component.option?.dateFormats === 'time') {
        req.value = moment(req.value, 'HH:mm:ss').format(dateFormatReq)
      }
    }

    if (cell && cell.uid) {
      updateCell(
        currentProject?.uid,
        currentModel?.id,
        contentsItem.uid,
        req.uid,
        req
      )
        .then((res) => {
          getContentsItem(
            currentProject?.uid,
            currentModel?.id,
            contentsItem.uid
          ).then((res) => {
            dispatch(updateContentsItem(res.data))
          })
        })
        .catch((e) => {
          message.error(e.response.data.error)
        })
    } else {
      createCell(currentProject?.uid, currentModel?.id, contentsItem.uid, req)
        .then((res) => {
          getContentsItem(
            currentProject?.uid,
            currentModel?.id,
            contentsItem.uid
          ).then((res) => {
            dispatch(updateContentsItem(res.data))
          })
        })
        .catch((e) => {
          message.error(e.response.data.error)
        })
    }
  }

  return currentProject && currentModel && component ? (
    <CellComponent
      contents={contents}
      component={component}
      cell={cell}
      onCellChange={onHandleCellChange}
    />
  ) : (
    <></>
  )
}
