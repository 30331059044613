import React from 'react'
import type { RadioChangeEvent } from 'antd'
import { Input, Radio, Space, Checkbox, Col, Row } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '@/states'

export const HelpSurvey1 = ({
  answer,
  onSelect,
  answerEtc,
  onSelectEtc,
}: {
  answer: string
  onSelect: (value: string) => void
  answerEtc: string
  onSelectEtc: (value: string) => void
}) => {
  const { t, i18n } = useTranslation()

  // State (Redux)
  const { authState } = useSelector(
    (state: RootState) => ({
      authState: state.auth,
    }),
    shallowEqual
  )
  const { init, me } = authState

  const options = [
    'IT',
    'DESIGN',
    'MARKETING',
    'PLANNING',
    'ADMIN',
    'STUDENT',
    'ETC',
  ]

  return (
    <div className="space-y-5">
      <div className="text-center">
        <h2
          className="text-xl"
          dangerouslySetInnerHTML={{
            __html: t('survey.survey1.title', { name: me?.name }),
          }}
        />
        <p className="text-base">{t('survey.survey1.question')}</p>
      </div>
      <div className="space-y-2">
        <Radio.Group
          onChange={(e) => onSelect(e.target.value)}
          value={answer}
          size="large">
          <Space direction="vertical">
            {options.map((option, index) => {
              return (
                <Radio key={index} value={option}>
                  {t(`survey.survey1.options.${option}`)}
                </Radio>
              )
            })}
          </Space>
        </Radio.Group>
        <Input
          className="w-full block"
          disabled={answer !== 'ETC'}
          value={answerEtc}
          onChange={(e) => onSelectEtc(e.target.value)}></Input>
      </div>
    </div>
  )
}

export const HelpSurvey2 = ({
  answer,
  onSelect,
}: {
  answer: string
  onSelect: (value: string) => void
}) => {
  const { t, i18n } = useTranslation()

  const options = ['INDIVIDUAL', 'TEAM']

  return (
    <div className="space-y-5">
      <div className="text-center">
        <h2
          className="text-xl"
          dangerouslySetInnerHTML={{
            __html: t('survey.survey2.title'),
          }}
        />
        <p className="text-base">{t('survey.survey2.question')}</p>
      </div>
      <Radio.Group
        onChange={(e) => onSelect(e.target.value)}
        value={answer}
        size="large">
        <Space direction="vertical">
          {options.map((option, index) => {
            return (
              <Radio key={index} value={option}>
                {t(`survey.survey2.options.${option}`)}
              </Radio>
            )
          })}
        </Space>
      </Radio.Group>
    </div>
  )
}

export const HelpSurvey3 = ({
  answer,
  onSelect,
  answerEtc,
  onSelectEtc,
}: {
  answer: CheckboxValueType[]
  onSelect: (value: CheckboxValueType[]) => void
  answerEtc: string
  onSelectEtc: (value: string) => void
}) => {
  const { t, i18n } = useTranslation()

  const options = [
    'INHOUSE',
    'NOTION',
    'WEBBUILDER',
    'HEADLESS_CMS',
    'GOOGLE',
    'MS',
    'NEVER',
    'ETC',
  ]

  const optionsCheckbox = options.map((option) => {
    return {
      label: t(`survey.survey3.options.${option}`),
      value: option,
    }
  })

  return (
    <div className="space-y-5">
      <div className="text-center">
        <h2
          className="text-xl"
          dangerouslySetInnerHTML={{
            __html: t('survey.survey3.title'),
          }}
        />
        <p className="text-base">{t('survey.survey3.question')}</p>
      </div>
      <div className="space-y-2">
        <Checkbox.Group
          onChange={(values) => onSelect(values)}
          // options={optionsCheckbox}
        >
          <Row>
            {optionsCheckbox.map((option, index) => {
              return (
                <Col key={index} span={24} className="mb-2">
                  <Checkbox value={option.value}>{option.label}</Checkbox>
                </Col>
              )
            })}
          </Row>
        </Checkbox.Group>
        <Input
          className="w-full block"
          disabled={!answer.find((v) => v === 'ETC')}
          value={answerEtc}
          onChange={(e) => onSelectEtc(e.target.value)}></Input>
      </div>
    </div>
  )
}
