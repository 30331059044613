import { TextTransform } from '@/components/Ai'
import { EditorJs, RelationSelector } from '@/components/Contents'
import { MediaSelector } from '@/components/Media'
import { availableComponents } from '@/configs'
import { RootState, useAppDispatch } from '@/states'
import { setCurrentLanguage } from '@/states/actions'
import { CellInterface, ComponentInterface, LanguagesAvailable } from '@/types'
import { TreeDataInterface } from '@/types/component.type'
import {
  AuditOutlined,
  CaretDownFilled,
  CaretRightFilled,
  CodeOutlined,
  DiffOutlined,
  KeyOutlined,
  LoadingOutlined,
  TranslationOutlined,
} from '@ant-design/icons'
import {
  DatePicker,
  Input,
  InputNumber,
  Segmented,
  Switch,
  Tooltip,
  TreeSelect,
} from 'antd'
import locale from 'antd/es/date-picker/locale/ko_KR'
import moment from 'moment-timezone'
import 'moment/locale/ko'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { getTreeSelectors } from '../../utils/helpers'

interface FormComponentProps {
  flattenCells: CellInterface[]
  component: ComponentInterface
  onCellChange: (
    componentId: number | undefined,
    key: string,
    value: boolean | number | string | object | null,
    lang?: LanguagesAvailable
  ) => void
  cell: CellInterface | null | undefined
  parentComponent?: ComponentInterface
  customClassName?: string
  preview?: boolean
}

export const FormComponent = ({
  flattenCells,
  component,
  onCellChange,
  cell,
  parentComponent,
  customClassName,
  preview,
}: FormComponentProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState, modalsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      modalsState: state.modals,
    }),
    shallowEqual
  )
  const {
    currentProject,
    currentModel,
    reqComponents,
    categoriesList,
    currentLanguage,
  } = projectsState
  const { contentsFormModal, contentsInfo } = modalsState

  // State
  const [tmpCell, setTmpCell] = useState<CellInterface | null | undefined>(null)
  const [showChildren, setShowChildren] = useState<boolean>(true)
  const [editorRefs, setEditorRefs] = useState({})
  const [busy, setBusy] = useState<boolean>(false)

  // Effect
  useEffect(() => {
    setTmpCell(cell ? JSON.parse(JSON.stringify(cell)) : null)
  }, [cell])

  useEffect(() => {
    if (!contentsFormModal) {
      setTmpCell(null)
    }
  }, [contentsFormModal])

  // Memo
  const categorySelectors = useMemo(() => {
    return component.type === 'CATEGORY'
      ? categoriesList.find((c) => c.id === component.selectorGroupId)
      : null
  }, [categoriesList, component])

  const treeCategorySelectors: TreeDataInterface[] = useMemo(() => {
    return getTreeSelectors(categorySelectors, currentLanguage)
  }, [categorySelectors, currentLanguage])

  /**
   * 임시 Cell 정보 변경
   * @param key
   * @param value
   * @param lang
   */
  const onChangeTmpCell = (key, value, lang?: LanguagesAvailable) => {
    const updatedtmpCell = JSON.parse(JSON.stringify(tmpCell))

    if (updatedtmpCell && updatedtmpCell.languageMap) {
      if (lang) {
        updatedtmpCell[key][lang] = value
      } else {
        updatedtmpCell[key] = value
      }

      setTmpCell(updatedtmpCell)
    }
  }

  /**
   * Cell 정보 수정
   * @param key
   * @param lang
   */
  const onBlurCell = (key, lang?: LanguagesAvailable) => {
    onCellChange(
      component.id,
      key,
      tmpCell && lang ? tmpCell[key][lang] : tmpCell ? tmpCell[key] : null,
      lang
    )
  }

  /**
   * 텍스트 에디터 instance 설정
   * @param instance
   * @param lang
   */
  const onHandleInstance = async (instance, lang) => {
    const updatedRefs = editorRefs
    updatedRefs[lang] = instance
    setEditorRefs(updatedRefs)

    const targetCell = contentsInfo?.cellList.find(
      (c) => c?.component?.id === cell?.component?.id
    )

    try {
      await instance.dangerouslyLowLevelInstance.isReady.then(() => {
        const editorJsObj =
          targetCell && targetCell.languageMap && targetCell.languageMap[lang]
            ? JSON.parse(
                (targetCell.languageMap[lang] as string)
                  .replace(/(?:\r\n|\r|\n)/g, '<br/>')
                  .replace(/(?:\t)/g, ' ') as string
              )
            : null

        if (editorJsObj && editorJsObj.blocks.length > 0) {
          instance.dangerouslyLowLevelInstance.render(editorJsObj)
        } else {
          instance.dangerouslyLowLevelInstance.clear()
        }
      })
    } catch (reason) {
      console.log('Editor error: ' + reason)
    }
  }

  /**
   * 텍스트 에디터 저장
   * @param lang
   */
  const onEditorSave = async (lang: LanguagesAvailable) => {
    await setTimeout(async () => {
      // @ts-ignore
      const savedData = await editorRefs[lang]?.save()

      onCellChange(component.id, 'languageMap', JSON.stringify(savedData), lang)
    })
  }

  /**
   * 컴포넌트 아이콘
   * @param type
   * @returns
   */
  const getIcon = (type) => {
    const component = availableComponents.find((c) => c.type === type)

    return component ? <component.icon /> : <></>
  }

  return currentProject && currentModel ? (
    <div
      id={`contents-component-${component.devKey}`}
      className={`text-sm ${
        component.type === 'BLOCK' ? 'border border-gray-300 rounded-sm' : ''
      }
      ${
        component.type === 'MEDIA' || component.type === 'RELATION'
          ? 'border border-gray-300 rounded-sm p-4'
          : ''
      }
      `}>
      <div
        className={`relative flex items-center ${
          component.type === 'BLOCK'
            ? 'bg-gray-200 hover:bg-gray-300 p-3 border-b border-gray-300 cursor-pointer'
            : 'mb-2'
        }`}
        onClick={() => setShowChildren(!showChildren)}>
        {component.type === 'BLOCK' ? (
          <div
            className={`absolute right-2 flex-none w-6 h-6 flex justify-center items-center`}>
            {!showChildren ? <CaretRightFilled /> : <CaretDownFilled />}
          </div>
        ) : (
          <></>
        )}
        <div className="grow flex overflow-hidden">
          <div className="text-sm leading-5 truncate flex items-center space-x-1">
            {component.option && component.option.required ? (
              <Tooltip
                title={t('optionsType.required.desc')}
                className="cursor-help">
                <span className="text-red-500">*</span>
              </Tooltip>
            ) : (
              ''
            )}
            <span className="flex space-x-1">
              <div className="flex items-center">{getIcon(component.type)}</div>
              <span>{component.languageMap[currentProject.defaultLang]}</span>
              <span className="text-gray-600 text-sm">
                ({component.devKey})
              </span>
            </span>
            {component.type === 'TITLE' ? (
              <span className="flex items-center">
                <Tooltip
                  title={t('optionsType.title.desc')}
                  className="cursor-help">
                  <KeyOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
            {component.type === 'TITLE' ||
            component.type === 'SINGLE_LINE_TEXT' ||
            component.type === 'LONG_LINE_TEXT' ||
            component.type === 'RICH_TEXT' ? (
              <span className="flex items-center">
                <Tooltip
                  title={t('optionsType.multiLanguage.desc')}
                  className="cursor-help">
                  <TranslationOutlined />
                </Tooltip>
              </span>
            ) : (
              ''
            )}
            {component.type === 'TITLE' ||
            component.type === 'SINGLE_LINE_TEXT' ||
            component.type === 'LONG_LINE_TEXT' ||
            component.type === 'RICH_TEXT' ? (
              <span className={`flex items-center space-x-1 absolute right-0`}>
                <Segmented
                  size="small"
                  className="text-xxs"
                  options={currentProject.languageList}
                  value={currentLanguage}
                  onChange={(val) => {
                    dispatch(setCurrentLanguage(val as LanguagesAvailable))
                  }}></Segmented>
                {/* <ul className="flex items-center ml-0 mb-0">
                  {currentProject.languageList.map((lang) => (
                    <li
                      key={lang}
                      className="text-xxs border border-gray-500 rounded-sm bg-gray-200 px-0.5 leading-4">
                      {lang}
                    </li>
                  ))}
                </ul> */}
              </span>
            ) : (
              ''
            )}
            {component.option?.unique ? (
              <span className="flex items-center">
                <Tooltip
                  title={t('optionsType.unique.desc')}
                  className="cursor-help">
                  <AuditOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
            {component.option?.allowHtml ? (
              <span className="flex items-center">
                <Tooltip
                  title={t('optionsType.allowHtml.desc')}
                  className="cursor-help">
                  <CodeOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
            {component.option?.multiple ? (
              <span className="flex items-center">
                <Tooltip
                  title={t(`optionsType.multiple.${component.type}.desc`)}
                  className="cursor-help">
                  <DiffOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
            {component.option?.multipleMedia ? (
              <span className="flex items-center">
                <Tooltip
                  title={t('optionsType.multipleMedia.desc')}
                  className="cursor-help">
                  <DiffOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {/* Title or Single Line Text: 시작 */}
      {component.type === 'TITLE' || component.type === 'SINGLE_LINE_TEXT' ? (
        <div>
          {currentProject.languageList.map((lang) => (
            <div
              key={lang}
              className={lang === currentLanguage ? '' : 'hidden'}>
              <Input
                name={component.devKey}
                autoComplete={'off'}
                required={component.option?.required}
                value={
                  tmpCell && tmpCell.languageMap && tmpCell.languageMap[lang]
                    ? (tmpCell.languageMap[lang] as string)
                    : ''
                }
                maxLength={component.option?.maxText}
                showCount
                onChange={(e) =>
                  onChangeTmpCell('languageMap', e.target.value, lang)
                }
                onBlur={(e) => onBlurCell('languageMap', lang)}
                readOnly={currentProject.role === 'VIEWER'}
                suffix={busy ? <LoadingOutlined /> : <></>}
                disabled={busy}
              />
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
      {/* Title or Single Line Text: 끝 */}
      {/* Single Line Text Mono: 시작 */}
      {component.type === 'SINGLE_LINE_TEXT_MONO' ? (
        <div>
          <Input
            name={component.devKey}
            autoComplete={'off'}
            required={component.option?.required}
            value={tmpCell && tmpCell.value ? (tmpCell.value as string) : ''}
            onChange={(e) => onChangeTmpCell('value', e.target.value)}
            onBlur={(e) => onBlurCell('value')}
            maxLength={component.option?.maxText}
            showCount
            readOnly={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Title or Single Line Text: 끝 */}
      {/* Long Line Text: 시작 */}
      {component.type === 'LONG_LINE_TEXT' ? (
        <div>
          {currentProject.languageList.map((lang) => (
            <div
              key={lang}
              className={lang === currentLanguage ? '' : 'hidden'}>
              <Input.TextArea
                rows={5}
                name={component.devKey}
                required={component.option?.required}
                showCount
                value={
                  tmpCell && tmpCell.languageMap && tmpCell.languageMap[lang]
                    ? (tmpCell.languageMap[lang] as string)
                    : ''
                }
                onChange={(e) =>
                  onChangeTmpCell('languageMap', e.target.value, lang)
                }
                onBlur={(e) => onBlurCell('languageMap', lang)}
                readOnly={currentProject.role === 'VIEWER'}
              />
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
      {/* Long Line Text: 끝 */}
      {/* Long Line Text Mono: 시작 */}
      {component.type === 'LONG_LINE_TEXT_MONO' ? (
        <div>
          <Input.TextArea
            rows={5}
            name={component.devKey}
            autoComplete={'off'}
            required={component.option?.required}
            value={tmpCell && tmpCell.value ? (tmpCell.value as string) : ''}
            onChange={(e) => onChangeTmpCell('value', e.target.value)}
            onBlur={(e) => onBlurCell('value')}
            maxLength={component.option?.maxText}
            showCount
            readOnly={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Long or Single Line Text: 끝 */}
      {/* Rich Text: 시작 */}
      {component.type === 'RICH_TEXT' &&
      contentsFormModal &&
      cell &&
      reqComponents.find((rc) => rc.component?.id === component.id) ? (
        <div className="relative">
          <div className="ant-input ant-input-rich-text">
            {currentProject.languageList.map((lang) => (
              <div
                key={lang}
                className={lang === currentLanguage ? '' : 'hidden'}>
                <div
                  id={`rich-text-${component.id}-${lang}`}
                  className=" overflow-x-visible pl-0"
                  onBlur={() => onEditorSave(lang)}>
                  {EditorJs ? (
                    <EditorJs
                      editorCore={editorRefs[lang]}
                      handleInstance={(instance) =>
                        onHandleInstance(instance, lang)
                      }
                      onSave={() => {
                        onEditorSave(lang)
                      }}
                      readOnly={currentProject.role === 'VIEWER'}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* Rich Text: 끝 */}
      {/* Number: 시작 */}
      {component.type === 'NUMBER' ? (
        <div>
          <InputNumber
            name={component.devKey}
            autoComplete={'off'}
            required={component.option?.required}
            min={component.option?.min}
            max={component.option?.max}
            value={cell && cell.value ? Number(cell.value) : undefined}
            onChange={(val) => onChangeTmpCell('value', val)}
            onBlur={(e) => onBlurCell('value')}
            readOnly={currentProject.role === 'VIEWER'}
          />
          {component?.option?.min || component?.option?.max ? (
            <p className="text-xs text-gray-500 mb-0 mt-0.5">
              {component?.option?.min} ~ {component?.option?.max}
            </p>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {/* Number: 끝 */}
      {/* Category: 시작 */}
      {component.type === 'CATEGORY' && currentLanguage ? (
        <div>
          {component.option?.multiple ? (
            <TreeSelect
              showSearch
              style={{ width: '100%' }}
              value={
                cell && cell.selectorIdList && cell.selectorIdList.length
                  ? cell.selectorIdList
                  : []
              }
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder={t('selectCategory')}
              allowClear
              treeCheckable
              multiple
              treeDefaultExpandAll
              onChange={(val) =>
                onCellChange(component.id, 'selectorIdList', val)
              }
              treeData={treeCategorySelectors}
              disabled={currentProject.role === 'VIEWER'}
            />
          ) : (
            <TreeSelect
              showSearch
              style={{ width: '100%' }}
              value={
                cell && cell.selectorIdList && cell.selectorIdList.length
                  ? cell.selectorIdList
                  : null
              }
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder={t('selectCategory')}
              allowClear
              treeDefaultExpandAll
              onChange={(val) =>
                onCellChange(component.id, 'selectorIdList', [val])
              }
              treeData={treeCategorySelectors}
              disabled={currentProject.role === 'VIEWER'}
            />
          )}
        </div>
      ) : (
        <></>
      )}
      {/* Category: 끝 */}
      {/* Boolean: 시작 */}
      {component.type === 'BOOLEAN' ? (
        <div>
          <Switch
            checked={!!(cell && cell.value)}
            onChange={(checked) => onCellChange(component.id, 'value', checked)}
            disabled={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Boolean 끝 */}
      {/* Date: 시작 */}
      {component.type === 'DATE' ? (
        <div>
          <DatePicker
            locale={locale}
            picker={component?.option?.dateFormats}
            value={
              cell && cell.value
                ? moment(cell.value as string, 'YYYY-MM-DDTHH:mm:ss')
                : undefined
            }
            onChange={(val, dateString) =>
              onCellChange(component.id, 'value', dateString)
            }
            disabled={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Date 끝 */}
      {/* Email: 시작 */}
      {component.type === 'EMAIL' ? (
        <div>
          <Input
            type={'email'}
            autoComplete={'off'}
            name={component.devKey}
            required={component.option?.required}
            value={tmpCell && tmpCell.value ? (tmpCell.value as string) : ''}
            onChange={(e) => onChangeTmpCell('value', e.target.value)}
            onBlur={(e) => onBlurCell('value')}
            readOnly={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Email: 끝 */}
      {/* Password: 시작 */}
      {component.type === 'PASSWORD' ? (
        <div>
          <Input.Password
            autoComplete={'off'}
            name={component.devKey}
            required={component.option?.required}
            value={tmpCell && tmpCell.value ? (tmpCell.value as string) : ''}
            onChange={(e) => onChangeTmpCell('value', e.target.value)}
            onBlur={(e) => onBlurCell('value')}
            readOnly={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Password: 끝 */}
      {/* Component Block: 시작 */}
      {component.type === 'BLOCK' ? (
        <div className={`p-6 bg-white ${showChildren ? '' : 'hidden'}`}>
          <div className="space-y-2">
            {component.childList?.map((subComponent, index) => (
              <FormComponent
                key={subComponent.id}
                flattenCells={flattenCells}
                component={subComponent}
                onCellChange={onCellChange}
                cell={
                  flattenCells
                    ? flattenCells.find(
                        (cell) => cell?.componentId === subComponent.id
                      )
                    : null
                }
                parentComponent={component}
                customClassName="bg-gray-100 shadow-sm border border-gray-200 rounded"
              />
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* Component Block: 끝 */}
      {/* Media: 시작 */}
      {component.type === 'MEDIA' ? (
        <div>
          <MediaSelector
            component={component}
            cell={
              flattenCells
                ? flattenCells.find(
                    (cell) => cell?.componentId === component.id
                  )
                : null
            }
            mediaList={cell && cell.mediaList ? cell.mediaList : []}
            onSelect={(mediaList) => {
              onCellChange(component.id, 'mediaList', mediaList)
            }}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Media: 끝 */}
      {/* Relation: 시작 */}
      {component.type === 'RELATION' ? (
        <div>
          <RelationSelector
            component={component}
            cell={
              flattenCells
                ? flattenCells.find(
                    (cell) => cell?.componentId === component.id
                  )
                : null
            }
            relationList={cell && cell.relationList ? cell.relationList : []}
            onSelect={(contentsList) => {
              onCellChange(component.id, 'relationList', contentsList)
            }}
            preview={preview}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Relation: 끝 */}
      {/* AI Text : 시작 */}
      {component.type === 'TITLE' ||
      component.type === 'SINGLE_LINE_TEXT' ||
      component.type === 'LONG_LINE_TEXT' ? (
        <div className="pt-0">
          <TextTransform
            text={
              tmpCell &&
              tmpCell.languageMap &&
              tmpCell.languageMap[currentLanguage]
                ? (tmpCell.languageMap[currentLanguage] as string)
                : ''
            }
            component={component}
            onTextChange={(text) => {
              onChangeTmpCell(
                'languageMap',
                text,
                currentLanguage as LanguagesAvailable
              )
              onCellChange(
                component.id,
                'languageMap',
                text,
                currentLanguage as LanguagesAvailable
              )
            }}
          />
        </div>
      ) : (
        ''
      )}
      {component.type === 'SINGLE_LINE_TEXT_MONO' ||
      component.type === 'LONG_LINE_TEXT_MONO' ? (
        <div className="pt-0">
          <TextTransform
            text={tmpCell && tmpCell.value ? (tmpCell.value as string) : ''}
            component={component}
            onTextChange={(text) => {
              onChangeTmpCell('value', text)
              onCellChange(component.id, 'value', text)
            }}
          />
        </div>
      ) : (
        ''
      )}
      {/* AI Text : 끝 */}
    </div>
  ) : (
    <></>
  )
}
