import React, { useEffect, useState } from 'react'
import { Button, Input } from 'antd'
import { CheckOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { ProjectUsageProperties } from '@/configs'
import { LINK_PLAN, LINK_SURVEY } from '@/constants'
import { ProjectUsageType } from '@/types'

export const CreateStep4 = ({
  loading,
  formikProjectForm,
  onFinish,
  onNext,
  isMaxFreeProjectsMeet,
  freeProjectsCount,
}: {
  loading: boolean
  formikProjectForm: any
  onNext: (number) => void
  onFinish: () => void
  isMaxFreeProjectsMeet: boolean
  freeProjectsCount: number
}) => {
  const { t, i18n } = useTranslation()

  const PlanItem = ({ type }: { type: ProjectUsageType }) => {
    const plan = ProjectUsageProperties.find((p) => p.price === type)

    /**
     * 요금제별 플랜 선택
     */
    const onHandleSelectPlan = () => {
      if (type === 'FREE') {
        onFinish()
      } else {
        // @ts-ignore
        ChannelIO('openChat')
      }
    }

    return (
      <div
        className={`border border-gray-400 rounded-2xl p-6 hover:bg-gray-200 space-y-4`}>
        <div>
          <h2 className="text-2xl font-bold mb-2">
            {t(`planTypes.${type}.title`)}
          </h2>
          <div className="text-lg font-medium">
            {t(`planTypes.${type}.price`)}
          </div>
        </div>
        <hr />
        <div>
          <ul className="space-y-1">
            <li className="flex space-x-2 items-center">
              <div className="color-primary flex items-center">
                <CheckOutlined />
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: t(`planTypes.${type}.storage`),
                }}></div>
            </li>
            <li className="flex space-x-2 items-center">
              <div className="color-primary flex items-center">
                <CheckOutlined />
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: t(`planTypes.${type}.members`),
                }}></div>
            </li>
            <li className="flex space-x-2 items-center">
              <div className="color-primary flex items-center">
                <CheckOutlined />
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: t(`planTypes.${type}.contents`),
                }}></div>
            </li>
            <li className="flex space-x-2 items-center">
              <div className="color-primary flex items-center">
                <CheckOutlined />
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: t(`planTypes.${type}.roles`),
                }}></div>
            </li>

            <li className="flex space-x-2 items-center">
              <div className="color-primary flex items-center">
                <CheckOutlined />
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: t(`planTypes.${type}.langs`),
                }}></div>
            </li>
            <li className="flex space-x-2 items-center">
              <div className="color-primary flex items-center">
                <CheckOutlined />
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: t(`planTypes.${type}.models`),
                }}></div>
            </li>
            {(type === 'PRO' || type === 'UNLIMITED') && (
              <li className="flex space-x-2 items-center">
                <div className="color-primary flex items-center">
                  <CheckOutlined />
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t(`planTypes.${type}.ai`),
                  }}></div>
              </li>
            )}
          </ul>
        </div>
        <hr />
        <Button
          type={type === 'FREE' ? 'primary' : 'default'}
          block
          onClick={() => onHandleSelectPlan()}
          disabled={loading}
          loading={loading}>
          {t(`planTypes.${type}.start`)}
        </Button>
      </div>
    )
  }

  return (
    <div className={`h-full overflow-y-auto`}>
      <div className="w-full max-w-7xl mx-auto space-y-10">
        <div className="text-center">
          <h1 className="text-3xl font-bold mb-2">
            {t('projectCreate.step4.title')}
          </h1>
          <p
            className="text-sm mb-0"
            dangerouslySetInnerHTML={{
              __html: t('projectCreate.step4.desc'),
            }}
          />
        </div>
        <div className="space-y-10">
          <div className="grid xl:grid-cols-4 gap-5">
            <PlanItem type={'FREE'} />
            <PlanItem type={'BASIC'} />
            <PlanItem type={'PRO'} />
            <PlanItem type={'UNLIMITED'} />
          </div>
          <div className="flex justify-center">
            <a
              href={LINK_PLAN}
              target={'_blank'}
              rel="noreferrer"
              className="flex items-center space-x-0.5 font-normal">
              <QuestionCircleOutlined />
              <span>{t('morePlan')}</span>
            </a>
          </div>
        </div>
        <div className="space-y-4">
          {/* <div className="text-center">
            <p
              className="mb-0"
              dangerouslySetInnerHTML={{
                __html: t('projectCreate.step4.desc2'),
              }}
            />
          </div>
          <div className="flex justify-center">
            <a
              href={LINK_SURVEY}
              target="_blank"
              rel="noreferrer"
              className="ant-btn ant-btn-primary"
              title={t('projectCreate.step4.linkDesc')}>
              {t('projectCreate.step4.linkDesc')}
            </a>
          </div> */}
        </div>
        <div className="flex justify-between items-center">
          <div className="flex space-x-2 items-center">
            <div
              className="text-gray-500 cursor-pointer text-xs"
              onClick={() => onNext(2)}>
              {t('previous')}
            </div>
            <div className="text-gray-500 text-xs">4/4</div>
          </div>
          <div className="text-right">
            <p className="text-xs">
              <span className="color-primary">*</span> 오픈 베타에서 사용한
              데이터는 정식 오픈 이후에도 보존됩니다.
              <br />
              <span className="color-primary">*</span> 플랜별 제공 기능은 추후
              변경될 수 있습니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
