import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'
import { StarOutlined, StarFilled } from '@ant-design/icons'
import { RootState, useAppDispatch } from '@/states'
import { getFavorites, addFavoriteProject, removeFavorites } from '@/api'
import { ProjectInterface } from '@/types'

export const ProjectFavorite = ({ project }: { project: ProjectInterface }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState, authState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )

  const { projectList } = projectsState
  const { init, me, favorites } = authState

  // Memo
  const isFavorite: boolean = useMemo(() => {
    return !!(
      favorites.projects &&
      favorites.projects.find((item) => item.uid === project.uid)
    )
  }, [favorites.projects, project])

  /**
   * 즐겨찾기 제거
   * @param string
   */
  const removeFavoritesProject = (e, string) => {
    if (e.target.closest('.ico-favorite')) {
      e.preventDefault()
    }

    removeFavorites('projects', string)
      .then((res) => {
        dispatch(getFavorites())
      })
      .catch((e) => {
        message.error(e.response.data.error)
      })
  }

  /**
   * 즐겨찾기 추가
   * @param string
   */
  const addFavoritesProject = (e, string) => {
    if (e.target.closest('.ico-favorite')) {
      e.preventDefault()
    }

    addFavoriteProject('projects', string)
      .then((res) => {
        dispatch(getFavorites())
      })
      .catch((e) => {
        message.error(e.response.data.error)
      })
  }

  return process.env.REACT_APP_HIDE_FAVORITES !== '1' ? (
    isFavorite ? (
      <StarFilled
        style={{ color: '#facc15' }}
        onClick={(e) => {
          // e.stopPropagation()
          removeFavoritesProject(e, project.uid)
        }}
      />
    ) : (
      <div className="hover-target-flex flex items-center">
        <StarOutlined
          onClick={(e) => {
            // e.stopPropagation()
            addFavoritesProject(e, project.uid)
          }}
        />
      </div>
    )
  ) : (
    <></>
  )
}
