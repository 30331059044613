import {
  deleteProject,
  exitProject,
  getProject,
  getProjects,
  updateProject,
  uploadFileToS3,
} from '@/api'
import { AlertStatus } from '@/components/Common'
import { LayoutProjectsTab } from '@/components/Layout/TabProjects'
import {
  FILE_IMAGE_MAX_RES,
  FILE_MAX_SIZE_UPLOAD,
  languagesConfig,
} from '@/configs'
import { RootState, useAppDispatch } from '@/states'
import { setCurrentModel } from '@/states/actions'
import { FileResponseInterface, LanguagesAvailable } from '@/types'
import { ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Typography,
  Upload,
  message,
} from 'antd'
import ImgCrop from 'antd-img-crop'
import { UploadFile } from 'antd/es/upload/interface'
import { AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import * as Yup from 'yup'

const initialProjectFormValues: {
  name: string
  languages: LanguagesAvailable[]
  defaultLanguage: LanguagesAvailable | ''
  description: string
} = {
  name: '',
  languages: [],
  defaultLanguage: '',
  description: '',
}

const ProjectsSettings = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState, layoutState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const { currentProject, modelList } = projectsState
  const { gnb } = layoutState

  // State
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [previewUrl, setPreviewUrl] = useState<string>('')

  // Effect
  useEffect(() => {
    if (currentProject) {
      formikProjectForm.setFieldValue('name', currentProject.name)
      formikProjectForm.setFieldValue('description', currentProject.description)
      formikProjectForm.setFieldValue('languages', currentProject.languageList)
      formikProjectForm.setFieldValue(
        'defaultLanguage',
        currentProject.defaultLang
      )

      if (currentProject.image) {
        setFileList([
          {
            uid: '-1',
            name: currentProject.image.name,
            status: 'success',
            url: currentProject.image.path,
          },
        ])
      }
    }
  }, [currentProject])

  // Validation
  const validationProjectFormSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    languages: Yup.array().min(1, t('validation.minLanguage')),
    defaultLanguage: Yup.string().required(t('validation.required')),
  })

  // Formik
  const formikProjectForm = useFormik({
    initialValues: initialProjectFormValues,
    validationSchema: validationProjectFormSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        if (!currentProject) return false

        setStatus(null)
        setLoading(true)

        // Language Map
        const languageMap = {}
        values.languages.forEach((lang) => {
          languageMap[lang] = lang === values.defaultLanguage
        })

        const req = {
          type: 'ARCHIVE',
          name: values.name,
          description: values.description,
          languageMap: languageMap,
          imageId: null,
          memberAuthList: currentProject?.memberList.map((ma) => {
            return { memberId: ma.id, role: ma.role }
          }),
        }

        // 프로젝트 이미지 업로드
        if (fileList && fileList.length) {
          let imageId = currentProject?.image?.id
          const projectThumbnail = fileList[0]

          // New Image
          if (projectThumbnail.status === 'done') {
            await uploadFileToS3(fileList, undefined, true).then((res) => {
              const resData = (res as AxiosResponse)
                .data as FileResponseInterface[]
              imageId = resData[0].fileId
            })
          }

          // @ts-ignore
          req.imageId = imageId
        }

        await updateProject(currentProject.uid, req)
        await dispatch(getProject(currentProject.uid, true))
        await setLoading(false)
        await message.success(t('saveSuccess'))

        if (modelList && modelList.length) {
          await dispatch(setCurrentModel(modelList[0]))
        }
      } catch (e) {
        setLoading(false)
        setSubmitting(false)
        // @ts-ignore
        setStatus(e.response.data.error)
      }
    },
  })

  useEffect(() => {
    if (
      formikProjectForm.values.defaultLanguage &&
      !formikProjectForm.values.languages.includes(
        formikProjectForm.values.defaultLanguage
      )
    ) {
      formikProjectForm.setFieldValue('defaultLanguage', '')
    }
  }, [formikProjectForm.values.languages])

  // Upload props
  const uploadProps = {
    onRemove: (file) => {
      setFileList([])
    },
    beforeUpload: (file) => {
      const reader = new FileReader()

      reader.onload = (e) => {
        if (e.target && e.target.result) {
          // 파일 크기 체크
          if (file.size > FILE_MAX_SIZE_UPLOAD * 1024) {
            message.error(
              t('error.fileTooBig', {
                name: file.name,
                size: FILE_MAX_SIZE_UPLOAD / 1024,
              })
            )
            return false
          }

          // 이미지의 경우 해상도 확인
          if (file.type.indexOf('image') >= 0) {
            const image = new Image()

            // @ts-ignore
            image.src = e.target.result
            image.onload = function () {
              // @ts-ignore
              const height = this.height
              // @ts-ignore
              const width = this.width

              if (width > FILE_IMAGE_MAX_RES || height > FILE_IMAGE_MAX_RES) {
                message.error(
                  t('error.imageSizeTooBig', {
                    name: file.name,
                    px: FILE_IMAGE_MAX_RES,
                  })
                )
                return false
              } else {
                const fileAdded = file
                fileAdded.status = 'done'
                // @ts-ignore
                fileAdded.url = e.target.result
                setFileList([fileAdded])
              }

              return true
            }
          } else {
            const fileAdded = file
            fileAdded.status = 'done'
            fileAdded.url = e.target.result
            setFileList([fileAdded])
          }
        }
      }
      reader.readAsDataURL(file)

      return false
    },
    fileList,
  }

  /**
   * 이미지 프리뷰
   * @param file
   */
  const onHandlePreview = (file) => {
    setPreviewUrl(file.url)
    setShowPreview(true)
  }

  /**
   * 가용 언어 선택
   * @param val
   */
  const onHandleLanguages = (val) => {
    if (
      currentProject &&
      currentProject.usage &&
      val.length > currentProject?.usage?.language.limit
    )
      return false

    formikProjectForm.setFieldValue('languages', val)
  }

  /**
   * 프로젝트 삭제 confirm
   * @param project
   */
  const onProjectDelete = (project) => {
    Modal.confirm({
      centered: true,
      title: t('confirmDeleteProjectTitle'),
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>{t('confirmDeleteProjectDesc')}</p>
          <p>{t('confirmDeleteProjectDesc2')}</p>
          <div
            className="text-xs"
            dangerouslySetInnerHTML={{
              __html: t('confirmDeleteProjectList'),
            }}></div>
        </div>
      ),
      okText: t('delete'),
      cancelText: t('cancel'),
      onOk() {
        return new Promise((resolve, reject) => {
          deleteProject(project.uid)
            .then((res) => {
              dispatch(getProjects())
              navigate('/projects')
              message.success(t('deleteSuccess'))
              resolve(res)
            })
            .catch((e) => {
              message.error(e.response.data.error)
              reject(e)
            })
        }).catch((e) => console.log(e))
      },
      onCancel() {},
    })
  }

  /**
   * 프로젝트 나가기 confirm
   * @param project
   */
  const onProjectExit = (project) => {
    Modal.confirm({
      centered: true,
      title: t('confirmExitProjectTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('confirmExitProjectDesc'),
      okText: t('exit'),
      cancelText: t('cancel'),
      onOk() {
        return new Promise((resolve, reject) => {
          exitProject(project.uid)
            .then((res) => {
              dispatch(getProjects())
              navigate('/projects')
              message.success(t('exitSuccess'))
              resolve(res)
            })
            .catch((e) => {
              message.error(e.response.data.error)
              reject(e)
            })
        }).catch((e) => console.log(e))
      },
      onCancel() {},
    })
  }

  return (
    <>
      {currentProject && (
        <Helmet>
          <title>
            {t('settings')} · {currentProject.name} ·{' '}
            {process.env.REACT_APP_NAME}
          </title>
        </Helmet>
      )}
      {currentProject ? (
        <div className={'space-y-5 py-5 lg:py-7'}>
          <LayoutProjectsTab />
          <form onSubmit={formikProjectForm.handleSubmit} method="POST">
            {/* Setting body: 시작 */}
            <div className="space-y-5">
              <Card>
                <AlertStatus
                  status={formikProjectForm.status}
                  onClick={() =>
                    formikProjectForm.setStatus(null)
                  }></AlertStatus>
                <div className={'space-y-6'}>
                  <Typography.Title level={5}>
                    {currentProject.name}
                  </Typography.Title>
                  <Row>
                    <Col xs={24} md={12} lg={8}>
                      <div className="block">
                        <label htmlFor="" className="">
                          <div className={'mb-2'}>{t('photo')}</div>
                          <ImgCrop
                            modalTitle={t('editImage')}
                            modalOk={t('confirm')}
                            modalCancel={t('cancel')}>
                            <Upload
                              {...uploadProps}
                              accept="image/*"
                              listType={'picture-card'}
                              maxCount={1}
                              onPreview={onHandlePreview}
                              disabled={
                                currentProject.role !== 'OWNER' &&
                                currentProject.role !== 'ADMIN'
                              }>
                              {fileList && fileList.length
                                ? t('editPhoto')
                                : t('addPhoto')}
                            </Upload>
                          </ImgCrop>
                        </label>
                      </div>
                    </Col>
                    <Col xs={24} md={12} lg={16}>
                      <div className="space-y-4">
                        <div className="block">
                          <label htmlFor="projectFormName" className="">
                            <div className={'mb-2'}>
                              {t('projectName')}{' '}
                              <span className="text-red-500">*</span>
                            </div>
                            <Input
                              id={'projectFormName'}
                              name="name"
                              type={'text'}
                              onChange={formikProjectForm.handleChange}
                              value={formikProjectForm.values.name}
                              disabled={
                                currentProject.role !== 'OWNER' &&
                                currentProject.role !== 'ADMIN'
                              }
                            />
                          </label>
                          {formikProjectForm.touched.name &&
                          formikProjectForm.errors.name ? (
                            <p className="my-1 text-xs text-red-500">
                              {formikProjectForm.errors.name}
                            </p>
                          ) : null}
                        </div>
                        <div className="block">
                          <label htmlFor="projectFormDescription" className="">
                            <div className={'mb-2'}>{t('description')}</div>
                            <Input.TextArea
                              rows={2}
                              id={'projectFormDescription'}
                              name="description"
                              onChange={formikProjectForm.handleChange}
                              value={formikProjectForm.values.description}
                              disabled={
                                currentProject.role !== 'OWNER' &&
                                currentProject.role !== 'ADMIN'
                              }
                            />
                          </label>
                        </div>
                        <div className="block">
                          <label htmlFor="projectFormLanguages" className="">
                            <div className={'mb-2'}>
                              {t('languages')}{' '}
                              <span className="text-red-500">*</span>
                              {currentProject.price !== 'UNLIMITED'
                                ? `(${t('maxLanguages', {
                                    max: currentProject.usage?.language.limit,
                                  })})`
                                : ''}
                            </div>
                            <Select
                              id={'projectFormLanguages'}
                              className={'w-full block'}
                              mode={'multiple'}
                              showArrow
                              maxLength={currentProject.usage?.language.limit}
                              onChange={(val) => onHandleLanguages(val)}
                              value={formikProjectForm.values.languages}
                              disabled={
                                currentProject.role !== 'OWNER' &&
                                currentProject.role !== 'ADMIN'
                              }>
                              {languagesConfig.availableLanguages.map(
                                (lang) => (
                                  <Select.Option
                                    key={lang.code}
                                    value={lang.code}
                                    label={t(
                                      'lang.' + lang.code.toLocaleLowerCase()
                                    )}>
                                    <div className="demo-option-label-item">
                                      {t(
                                        'lang.' + lang.code.toLocaleLowerCase()
                                      )}
                                    </div>
                                  </Select.Option>
                                )
                              )}
                            </Select>
                          </label>
                          {formikProjectForm.touched.languages &&
                          formikProjectForm.errors.languages ? (
                            <p className="my-1 text-xs text-red-500">
                              {formikProjectForm.errors.languages}
                            </p>
                          ) : null}
                        </div>
                        <div className="block">
                          <label
                            htmlFor="projectFormDefaultLanguage"
                            className="">
                            <div className={'mb-2'}>
                              {t('defaultLanguage')}{' '}
                              <span className="text-red-500">*</span>
                            </div>
                            <Select
                              id={'projectFormDefaultLanguage'}
                              className={'w-full block'}
                              onChange={(val) =>
                                formikProjectForm.setFieldValue(
                                  'defaultLanguage',
                                  val
                                )
                              }
                              value={formikProjectForm.values.defaultLanguage}
                              disabled={
                                currentProject.role !== 'OWNER' &&
                                currentProject.role !== 'ADMIN'
                              }>
                              {formikProjectForm.values.languages.map(
                                (lang) => (
                                  <Select.Option
                                    key={lang}
                                    value={lang}
                                    label={t(
                                      'lang.' + lang.toLocaleLowerCase()
                                    )}>
                                    <div className="demo-option-label-item">
                                      {t('lang.' + lang.toLocaleLowerCase())}
                                    </div>
                                  </Select.Option>
                                )
                              )}
                            </Select>
                          </label>
                          {formikProjectForm.touched.defaultLanguage &&
                          formikProjectForm.errors.defaultLanguage ? (
                            <p className="my-1 text-xs text-red-500">
                              {formikProjectForm.errors.defaultLanguage}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Modal
                  open={showPreview}
                  maskClosable={false}
                  footer={null}
                  onCancel={() => setShowPreview(false)}>
                  <img src={previewUrl} className={'w-full'} />
                </Modal>
              </Card>
              <div className="flex justify-between items-center">
                <div>
                  {currentProject.role === 'OWNER' ? (
                    <a
                      className="mb-0 text-red-500"
                      onClick={() => onProjectDelete(currentProject)}>
                      {t('deleteProject')}
                    </a>
                  ) : (
                    <a
                      className="mb-0 text-red-500"
                      onClick={() => onProjectExit(currentProject)}>
                      {t('exitProject')}
                    </a>
                  )}
                </div>
                <div
                  className={`transition-all !fixed top-2 ${
                    gnb ? 'right-4 lg:right-10' : 'right-4'
                  }  z-50`}>
                  {currentProject.role === 'OWNER' ||
                  currentProject.role === 'ADMIN' ? (
                    <Button
                      type={'primary'}
                      className="btn-sm-ico-only"
                      icon={<SaveOutlined />}
                      onClick={() => formikProjectForm.submitForm()}
                      disabled={loading}
                      loading={loading}>
                      {t('save')}
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {/* Setting body: 끝 */}
          </form>
        </div>
      ) : (
        <div className={'flex justify-center items-center h-screen'}>
          <Spin />
        </div>
      )}
    </>
  )
}

export default ProjectsSettings
