import { getContentsItem, getContentsListSearch, getMedia } from '@/api'
import {
  CellList,
  ContentsFormModal,
  RelationSelectorModal,
} from '@/components/Contents'
import {
  MediaFormModal,
  MediaSelectorModal,
  UploadModal,
} from '@/components/Media'
import {
  DEFAULT_CONTENTS_PAGE_SIZE,
  DEFAULT_SORT,
  LAYOUT_GNB_WIDTH,
  LAYOUT_MODAL_LG_WIDTH,
  LAYOUT_MODAL_WIDTH,
  LAYOUT_SIDEBAR_WIDTH,
} from '@/configs'
import { RootState, useAppDispatch } from '@/states'
import {
  setCheckedContentsList,
  setContentsFormModal,
  setContentsList,
  setContentsListLoading,
  updateContentsItem,
} from '@/states/actions'
import { MediaInterface } from '@/types'
import { Button, Empty, Spin, message, notification } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { useLocation, useSearchParams } from 'react-router-dom'
import {
  setSearchConditions,
  setValidateContentsSortParams,
} from '../../utils/helpers'

let interval

export const ContentsList = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // Params
  let { projectUid, modelId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  // State (Redux)
  const { projectsState, modalsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      modalsState: state.modals,
    }),
    shallowEqual
  )
  const {
    currentProject,
    contentsPagination,
    currentModel,
    contentsListInit,
    contentsListReload,
    contentsList,
    contentsListLoading,
    contentsSort,
    contentsKeyword,
    flattenComponentList,
    modelListInit,
    contentsWithProcessingMedia,
    mediaListProcessing,
    currentLanguage,
    categoriesList,
  } = projectsState
  const {
    contentsFormModal,
    contentsPreviewFormModal,
    contentsRelationSelectorModal,
    contentsInfo,
  } = modalsState

  // State
  const [localLoading, setLocalLoading] = useState<boolean>(false)

  // Effect
  useEffect(() => {
    if (location && modelListInit) {
      const projectUidParsed = projectUid ? projectUid : -1
      const modelIdParsed = modelId ? Number(modelId) : -1

      const sort = searchParams.get('sort')
        ? (searchParams.get('sort') as string)
        : DEFAULT_SORT
      const pagination = {
        totalElements: contentsList.length,
        size: searchParams.get('size')
          ? Number(searchParams.get('size'))
          : DEFAULT_CONTENTS_PAGE_SIZE,
        page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
      }
      const q = searchParams.get('q')

      if (
        currentProject &&
        currentModel &&
        (contentsListReload ||
          !contentsListInit ||
          currentModel.id !== modelIdParsed)
      ) {
        // const targetModel = modelList.find((m) => m.id === modelIdParsed)
        // if (targetModel) dispatch(setCurrentModel(targetModel))

        if (contentsFormModal) dispatch(setContentsFormModal(false))

        getContentsList(projectUid, modelIdParsed, pagination, sort, q)

        if (
          !contentsFormModal &&
          searchParams.get('edit') === 'modal' &&
          searchParams.get('id') &&
          projectUidParsed &&
          modelIdParsed
        ) {
          getContentsItem(
            projectUidParsed,
            modelIdParsed,
            searchParams.get('id')
          )
            .then(async (res) => {
              await dispatch(
                setContentsFormModal(true, res.data, modelIdParsed)
              )
            })
            .catch((e) => {
              message.error(e.response.data.error)
            })
        }
      } else if (
        currentProject &&
        currentModel &&
        (pagination.size !== contentsPagination.size ||
          pagination.page !== contentsPagination.page ||
          sort !== contentsSort ||
          q !== contentsKeyword)
      ) {
        getContentsList(projectUid, modelIdParsed, pagination, sort, q)
      } else if (
        contentsFormModal &&
        (!(searchParams.get('new') === 'modal') ||
          !(
            searchParams.get('edit') &&
            searchParams.get('edit') === 'modal' &&
            searchParams.get('id') &&
            projectUidParsed &&
            modelIdParsed
          ))
      ) {
        dispatch(setContentsFormModal(false))
      } else if (!contentsFormModal && searchParams.get('new') === 'modal') {
        dispatch(setContentsFormModal(true, null, modelIdParsed))
      } else if (
        !contentsFormModal &&
        searchParams.get('edit') === 'modal' &&
        searchParams.get('id') &&
        projectUidParsed &&
        modelIdParsed
      ) {
        getContentsItem(projectUidParsed, modelIdParsed, searchParams.get('id'))
          .then(async (res) => {
            await dispatch(setContentsFormModal(true, res.data, modelIdParsed))
          })
          .catch((e) => {
            message.error(e.response.data.error)
          })
      }
    }
  }, [location, contentsListReload, modelListInit])

  /**
   * 체크하기
   */
  const onToggleCheckAll = (e) => {
    dispatch(setCheckedContentsList(e.target.checked ? contentsList : []))
  }

  /**
   * 정렬 순서 변경
   * @param comp
   */
  const onHandleDateSort = (dateField) => {
    navigate(
      `/projects/${currentProject?.uid}/${currentModel?.id}/contents?page=${
        searchParams.get('page')
          ? searchParams.get('page')
          : contentsPagination.page
      }&size=${
        searchParams.get('size')
          ? searchParams.get('size')
          : contentsPagination.size
      }&sort=${
        dateField + '.' + (contentsSort.indexOf('.DESC') >= 0 ? 'ASC' : 'DESC')
      }&q=${searchParams.get('q') ? searchParams.get('q') : ''}`
    )
  }

  /**
   * 콘텐트 목록 가져오기
   * @param paging
   * @param sorting
   * @param field
   * @param filter
   * @param delay
   * @param skipLoading
   */
  const getContentsList = (
    projectUid,
    modelId,
    pagination,
    sorting,
    q,
    delay = 0,
    skipLoading = false
  ) => {
    if (!skipLoading) dispatch(setContentsListLoading(true))
    setLocalLoading(true)

    setTimeout(
      async () => {
        try {
          getContentsListSearch(projectUid, modelId, {
            page: pagination.page - 1,
            size: pagination.size,
            orderCond: setValidateContentsSortParams(sorting, 'order'),
            direction: setValidateContentsSortParams(sorting, 'direction'),
            searchCond: setSearchConditions(
              flattenComponentList,
              q,
              currentLanguage,
              categoriesList
            ),
          }).then((res) => {
            const pageInfo = res.data.pageInfo

            dispatch(
              setContentsList(
                res.data.list,
                {
                  totalElements: pageInfo.totalElements,
                  size: pageInfo.size,
                  page: pageInfo.page + 1,
                },
                q,
                sorting
              )
            )

            dispatch(setContentsListLoading(false))
            setLocalLoading(false)
          })
        } catch (e) {
          dispatch(
            setContentsList(
              [],
              {
                totalElements: 0,
                size: DEFAULT_CONTENTS_PAGE_SIZE,
                page: 1,
              },
              '',
              DEFAULT_SORT
            )
          )
          dispatch(setContentsListLoading(false))
          setLocalLoading(false)

          notification.destroy()
        }
      },
      delay ? delay : 0
    )
  }

  useEffect(() => {
    clearInterval(interval)

    if (mediaListProcessing.length) {
      checkProcessingMedia(mediaListProcessing)
    }
  }, [mediaListProcessing])

  /**
   * 처리중인 미디어 갱신 체크
   * @param mediaListProcessing
   */
  const checkProcessingMedia = (mediaListProcessing: MediaInterface[]) => {
    interval = setInterval(() => {
      Promise.all(
        mediaListProcessing.map((mediaProcessing) =>
          getMedia(currentProject?.uid, mediaProcessing.id)
        )
      ).then(
        axios.spread((...res) => {
          const updatedMediaItems = [...res]

          contentsWithProcessingMedia.forEach((contents) => {
            const updatedCellList = contents.cellList.map((cell) => {
              if (
                cell.component?.type === 'MEDIA' &&
                cell.mediaList &&
                cell.mediaList.length
              ) {
                return {
                  ...cell,
                  mediaList: cell.mediaList.map((media) => {
                    const updatedMedia = updatedMediaItems.find(
                      (updatedMediaItem) =>
                        updatedMediaItem.data.id === media.id
                    )

                    if (updatedMedia) {
                      return updatedMedia.data
                    }

                    return media
                  }),
                }
              }

              return cell
            })

            dispatch(
              updateContentsItem({ ...contents, cellList: updatedCellList })
            )
          })
        })
      )
    }, 1000 * 30)
  }

  return currentProject && currentModel ? (
    <>
      {flattenComponentList && flattenComponentList.length ? (
        <div className="relative">
          {contentsListInit ? (
            <div id="contents-list-body">
              {contentsList.length ? (
                contentsList.map((contents, cIdx) => (
                  <div className="flex " key={contents.uid}>
                    <CellList contents={contents} />
                  </div>
                ))
              ) : !contentsListLoading ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <Empty
          description={
            <div className="space-y-6">
              <p className="text-center">{t('componentNeed')}</p>
              <div className={'flex justify-center'}>
                <Button
                  type={'primary'}
                  htmlType={'button'}
                  onClick={() =>
                    navigate(
                      `/projects/${currentProject.uid}/${currentModel.id}/components`
                    )
                  }>
                  {t('manageComponent')}
                </Button>
              </div>
            </div>
          }
        />
      )}
      <ContentsFormModal
        open={contentsFormModal}
        width={LAYOUT_MODAL_LG_WIDTH}
      />
      <ContentsFormModal
        open={contentsPreviewFormModal}
        width={LAYOUT_MODAL_WIDTH}
        preview
      />
      <MediaSelectorModal />
      <MediaFormModal />
      <UploadModal />
      <RelationSelectorModal />
      {localLoading ? (
        <div
          className={`h-screen fixed top-0 right-0 flex justify-center items-center text-center`}
          style={{
            width: `calc(100% - ${LAYOUT_GNB_WIDTH + LAYOUT_SIDEBAR_WIDTH}px)`,
            zIndex: 1000,
          }}>
          <Spin />
        </div>
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  )
}
