import { AI_API_URL, axiosInstance } from '@/configs'
import { LanguagesAvailable } from '@/types'

/**
 * 모델 컴포넌트 추천
 * @param req
 */
export const callModelRecommendations = (req: {
  name: string
  description: string | null
}) => {
  return axiosInstance.post(`${AI_API_URL}/models`, req)
}

/**
 * 언어 번역
 * @param req
 */
export const callTextTransform = (req: {
  type: string
  text: string
  lang?: LanguagesAvailable
}) => {
  return axiosInstance.post(`${AI_API_URL}/text/${req.type}`, req)
}
