import { getContentsItem } from '@/api'
import { ContentsFavorite } from '@/components/Contents'
import { DEFAULT_CONTENTS_PAGE_SIZE } from '@/configs'
import { RootState } from '@/states'
import { AuthFavoriteContentInterface } from '@/types'
import { getSavedContentsSortKey } from '@/utils/helpers'
import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

export const DashboardItem = ({
  contents,
}: {
  contents: AuthFavoriteContentInterface
}) => {
  const navigate = useNavigate()

  // State
  const [title, setTitle] = useState<string>('-')

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject, modelList, modelListInit } = projectsState

  // Effect
  useEffect(() => {
    if (
      modelListInit &&
      currentProject?.defaultLang &&
      contents.name[currentProject?.defaultLang]
    ) {
      setTitle(contents.name[currentProject?.defaultLang] as string)
    } else {
      getTitleOfContents()
    }
  }, [contents, currentProject, modelListInit])

  /**
   * 모달 수정폼 열기
   */
  const onContentsOpenModal = (e) => {
    if (e.target.closest('.ico-favorite')) return

    navigate(
      `/projects/${currentProject?.uid}/${
        contents.model.id
      }/contents?page=1&size=${DEFAULT_CONTENTS_PAGE_SIZE}&sort=${getSavedContentsSortKey(
        contents.model.id
      )}&q=&edit=modal&id=${contents.uid}`
    )
  }

  /**
   * 콘텐츠 제목 불러오기
   */
  const getTitleOfContents = () => {
    const targetModel = modelList.find(
      (model) => model.id === contents.model.id
    )

    const titleComponent = targetModel?.flattenComponentList?.find(
      (component) => component.type === 'TITLE'
    )

    if (titleComponent) {
      getContentsItem(currentProject?.uid, targetModel?.id, contents.uid)
        .then((res) => {
          const cell = res.data.cellList.find(
            (c) => c.component.id === titleComponent.id
          )

          if (cell && cell.languageMap[currentProject?.defaultLang as string]) {
            setTitle(cell.languageMap[currentProject?.defaultLang as string])
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  return currentProject ? (
    <div
      className="flex justify-between hover:bg-gray-300 rounded-lg p-1"
      onClick={(e) => onContentsOpenModal(e)}>
      <div className="flex space-x-2 items-center">
        <div className="px-2 bg-gray-200 border border-gray-300 rounded-sm text-xs">
          {contents?.model?.name[currentProject?.defaultLang]}
        </div>
        <div>{title}</div>
      </div>
      <div className="flex-none ico-favorite">
        <ContentsFavorite contents={contents} />
      </div>
    </div>
  ) : (
    <></>
  )
}
