import { updateModel } from '@/api'
import {
  DEFAULT_SORT,
  LAYOUT_CHECKBOX_CELL_WIDTH,
  LAYOUT_DEFAULT_CELL_HEIGHT,
  LAYOUT_DEFAULT_CELL_WIDTH,
  LAYOUT_MIN_CELL_WIDTH,
  availableComponents,
} from '@/configs'
import { RootState, useAppDispatch } from '@/states'
import { setCurrentModel } from '@/states/actions'
import { ComponentInterface } from '@/types'
import { setComponentsRequestBody } from '@/utils/helpers'
import {
  CaretDownFilled,
  CaretUpFilled,
  TranslationOutlined,
} from '@ant-design/icons'
import { Tooltip, message } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { ResizableBox } from 'react-resizable'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

export const HeaderCells = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // Params
  let { q } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const {
    currentProject,
    currentModel,
    currentLanguage,
    contentsSort,
    contentsPagination,
  } = projectsState

  // State
  const [loading, setLoading] = useState<boolean>(false)

  /**
   * 컴포넌트 아이콘
   * @param type
   * @returns
   */
  const getIcon = (type) => {
    const component = availableComponents.find((c) => c.type === type)

    return component ? <component.icon /> : <></>
  }

  /**
   * 부모 컴포넌트 이름
   * @param parentComponents
   * @returns
   */
  const getParentComponentsName = (parentComponents) => {
    let name = ''
    parentComponents.forEach(
      (parentComp) =>
        (name +=
          (parentComp.languageMap[currentLanguage]
            ? parentComp.languageMap[currentLanguage]
            : parentComp.languageMap[
                currentProject ? currentProject.defaultLang : 'KO'
              ]) + ' | ')
    )

    return name
  }

  /**
   * 리사이즈 핸들러
   * @returns
   */
  const resizeHandler = () => {
    return (
      <div
        className="w-1 h-full cursor-col-resize flex justify-center absolute top-0"
        style={{
          right: '-1.5px',
        }}>
        <div className="h-full bg-gray-300" style={{ width: '1px' }}></div>
      </div>
    )
  }

  /**
   * 셀 길이 변경
   * @param event
   * @param param1
   * @param componentInfo
   * @param parentComponents
   * @returns
   */
  const onResizeStop = (
    event,
    { element, size, handle },
    componentInfo,
    parentComponents
  ) => {
    const updatedModel = currentModel
    if (!updatedModel || loading) return

    if (!parentComponents.length) {
      const idx = updatedModel.componentList.findIndex(
        (c) => c.id === componentInfo.id
      )
      const updatedComponent = updatedModel.componentList[idx]
      if (!updatedComponent.option) updatedComponent.option = {}
      updatedComponent.option.width = size.width

      updatedModel.componentList[idx] = updatedComponent

      dispatch(setCurrentModel(updatedModel, true))
    } else {
      updateFromComponents(
        updatedModel.componentList,
        parentComponents[parentComponents.length - 1],
        size.width
      )
    }

    if (!currentModel || loading) return false

    setLoading(true)

    const req = {
      languageMap: updatedModel.languageMap,
      devKey: updatedModel.devKey.replace(/[^A-Z0-9]/gi, ''),
      componentList: setComponentsRequestBody(updatedModel.componentList),
      order: updatedModel.order,
    }

    updateModel(currentProject?.uid, updatedModel.id, req)
      .then((res) => {
        // dispatch(setCurrentModel(res.data.data, true))
      })
      .catch((e) => {
        message.error(e.response.data.error)
      })
      .then(() => {
        setLoading(false)
      })

    /**
     * 하위 컴포넌트 수정
     * @param components
     */
    function updateFromComponents(components, parentComponentInfo, width) {
      components.forEach((com, cIdx) => {
        if (
          componentInfo &&
          parentComponentInfo &&
          com.id === parentComponentInfo.id
        ) {
          const idx = com.childList.findIndex((c) => c.id === componentInfo.id)

          const updatedComponent = com.childList[idx]
          if (!updatedComponent.option) updatedComponent.option = {}
          updatedComponent.option.width = size.width
          com.childList[idx] = updatedComponent
          dispatch(setCurrentModel(updatedModel, true))
        } else if (com.childList && com.childList.length) {
          updateFromComponents(com.childList, parentComponentInfo, width)
        }
      })
    }
  }

  /**
   * 정렬 순서 변경
   * @param comp
   */
  const onHandleSort = (comp) => {
    if (comp.sortable) {
      const queryKeyword = searchParams.get('q')
      const curSortKey = contentsSort.split('.')[0]

      let sortToBeSet: string = ''

      if (Number(curSortKey) === comp.id) {
        sortToBeSet =
          contentsSort === comp.id + '.' + 'DESC'
            ? DEFAULT_SORT
            : comp.id + '.' + 'DESC'
      } else {
        sortToBeSet = comp.id + '.' + 'ASC'
      }

      navigate(
        `/projects/${currentProject?.uid}/${currentModel?.id}/contents?page=1&size=${contentsPagination.size}&sort=${sortToBeSet}&q=${queryKeyword}`
      )
    }
  }

  /**
   * 상단 셀 목록
   * @param componentList
   * @param parentComponents
   * @returns
   */
  const getHeaderCells = (
    componentList,
    parentComponents: ComponentInterface[] = []
  ) => {
    return componentList?.map((comp: ComponentInterface, cIdx) =>
      comp.type !== 'BLOCK' ? (
        <ResizableBox
          key={comp.id}
          className={`px-2 py-0.5 flex items-center truncate bg-gray-200 border-b border-t border-gray-300
          ${
            comp.first
              ? 'sticky top-0 z-40 header-fixed-cell header-first-cell'
              : 'relative z-30 header-cell'
          }`}
          width={
            comp.option && comp.option.width
              ? comp.option.width
              : LAYOUT_DEFAULT_CELL_WIDTH
          }
          style={{
            left: comp.first ? LAYOUT_CHECKBOX_CELL_WIDTH + 'px' : 0,
          }}
          height={LAYOUT_DEFAULT_CELL_HEIGHT}
          onResizeStop={(event, { element, size, handle }) =>
            onResizeStop(
              event,
              { element, size, handle },
              comp,
              parentComponents
            )
          }
          resizeHandles={
            currentProject?.role === 'OWNER' || currentProject?.role === 'ADMIN'
              ? ['e']
              : []
          }
          minConstraints={[LAYOUT_MIN_CELL_WIDTH, LAYOUT_DEFAULT_CELL_HEIGHT]}
          handle={resizeHandler()}
          handleSize={[8, 8]}>
          <div
            className="w-full truncate relative pr-3"
            style={{
              width:
                comp.option && comp.option.width
                  ? comp.option.width
                  : LAYOUT_DEFAULT_CELL_WIDTH,
            }}>
            <Tooltip title={comp.devKey} className="cursor-help">
              <div className="flex items-center space-x-1.5">
                {getIcon(comp.type)}
                <span className="truncate">
                  {getParentComponentsName(parentComponents)}
                  <span
                    className={`${
                      comp.type === 'TITLE' ? 'font-bold text-black' : ''
                    }`}>
                    {comp.languageMap[currentLanguage]
                      ? comp.languageMap[currentLanguage]
                      : comp.languageMap[
                          currentProject ? currentProject.defaultLang : 'KO'
                        ]}
                  </span>
                  {comp?.option?.required ? (
                    <span className="text-red-500">*</span>
                  ) : (
                    ''
                  )}
                  {comp.type === 'TITLE' ||
                  comp.type === 'SINGLE_LINE_TEXT' ||
                  comp.type === 'LONG_LINE_TEXT' ||
                  comp.type === 'RICH_TEXT' ? (
                    <TranslationOutlined className="ml-1" />
                  ) : (
                    ''
                  )}
                </span>
              </div>
            </Tooltip>
            {comp.sortable ? (
              <div
                className="flex flex-col space-y-0 cursor-pointer absolute top-0.5 -right-px hover:opacity-80"
                onClick={() => onHandleSort(comp)}>
                <CaretUpFilled
                  className={`h-2.5 cursor-pointer text-xxs hover:opacity-80`}
                  style={{
                    color:
                      contentsSort &&
                      contentsSort.split('.') &&
                      Number(contentsSort.split('.')[0]) === Number(comp.id) &&
                      contentsSort.split('.')[1] === 'ASC'
                        ? '#1890FF'
                        : '#BFBFBF',
                  }}
                  title={t('sort')}
                />
                <CaretDownFilled
                  className={`h-2.5 cursor-pointer text-xxs hover:opacity-80`}
                  style={{
                    color:
                      contentsSort &&
                      contentsSort.split('.') &&
                      Number(contentsSort.split('.')[0]) === Number(comp.id) &&
                      contentsSort.split('.')[1] === 'DESC'
                        ? '#1890FF'
                        : '#BFBFBF',
                  }}
                  title={t('sort')}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </ResizableBox>
      ) : (
        getHeaderCells(comp.childList, [...parentComponents, comp])
      )
    )
  }

  return currentProject && currentModel ? (
    getHeaderCells(currentModel.componentList)
  ) : (
    <></>
  )
}
