import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RootState, useAppDispatch } from '@/states'
import { Table, Row, Col, Card, Select, message, Spin, Collapse } from 'antd'
const { Option } = Select
import moment from 'moment'
import { FAVORITES_PROJECTS_HIDE_KEY, PROJECTS_HIDE_KEY } from '@/configs'

import Cookies from 'js-cookie'
import { ProjectFavorite } from '@/components/Projects'

export const ProjectsList = ({ q }: { q: string }) => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [viewType, setViewType] = useState('')
  const [collapseKeys, setCollapseKeys] = useState<string | string[]>([])
  const [sort, setSort] = useState<string>('editedAt')
  const [sortFavorites, setSortFavorites] = useState<string>('editedAt')

  // State (Redux)
  const { projectsState, layoutState, authState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      layoutState: state.layout,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { projectList, projectsListInit } = projectsState
  const { projectListView } = layoutState
  const { me, favorites } = authState

  // Memo
  const favoritesProjectList = useMemo(() => {
    return favorites.projects
      ? projectList.filter((item) => {
          return !!favorites.projects.find(
            (project) => project.uid === item.uid
          )
        })
      : []
  }, [projectList, favorites.projects])

  // Effect
  useEffect(() => {
    setViewType(projectListView)
  }, [projectListView])

  useEffect(() => {
    if (projectsListInit) {
      const isHideFavoritesProjects = Cookies.get(FAVORITES_PROJECTS_HIDE_KEY)
      const isHideProjects = Cookies.get(PROJECTS_HIDE_KEY)
      const collapseKeys: string[] = []

      if (!isHideFavoritesProjects) {
        collapseKeys.push('favorites')
      }

      if (!isHideProjects) {
        collapseKeys.push('list')
      }

      setCollapseKeys(collapseKeys)
    }
  }, [projectsListInit])

  useEffect(() => {
    if (projectsListInit) {
      if (!collapseKeys.includes('favorites')) {
        Cookies.set(FAVORITES_PROJECTS_HIDE_KEY, '1')
      } else {
        Cookies.remove(FAVORITES_PROJECTS_HIDE_KEY)
      }

      if (!collapseKeys.includes('list')) {
        Cookies.set(PROJECTS_HIDE_KEY, '1')
      } else {
        Cookies.remove(PROJECTS_HIDE_KEY)
      }
    }
  }, [projectsListInit, collapseKeys])

  // Table columns
  const tableCols = [
    {
      title: t('projectName'),
      key: 'name',
      width: 'auto',
      render: (project) => (
        <Link
          to={`/projects/${project.uid}`}
          // onClick={(e) => enterProject(e)}
          className={'flex items-center space-x-3 cursor-pointer'}>
          {project?.image?.path ? (
            <div
              className={'w-6 h-6 rounded-sm bg-cover bg-gray-300 flex-none'}
              style={{
                backgroundImage: `url(${project?.image?.path})`,
              }}></div>
          ) : (
            <div
              className={`w-6 h-6 rounded-sm bg-secondary flex-none transition-all`}>
              <div
                className={
                  'flex items-center justify-center h-full text-white text-xs'
                }>
                {project.name.replace(
                  /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim,
                  ''
                )
                  ? project.name.replace(
                      /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim,
                      ''
                    )[0]
                  : '-'}
              </div>
            </div>
          )}
          <div className="flex space-x-2 items-center">{project.name}</div>
          <div className="flex items-center ico-favorite">
            <ProjectFavorite project={project} />
          </div>
        </Link>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('plan'),
      key: 'plan',
      width: 140,
      render: (project) => (
        <span className="border border-gray-300 rounded-sm px-1 font-normal text-xs">
          {t(`planTypes.${project.price}.title`)}
        </span>
      ),
      sorter: (a, b) => a.price.localeCompare(b.price),
    },
    {
      title: t('role'),
      key: 'role',
      width: 120,
      render: (project) => (
        <span className="border border-gray-300 rounded-sm px-1 font-normal text-xs">
          {project.memberList.find((m) => m.id === me?.id)
            ? t(
                'roleDesc.' +
                  project.memberList
                    .find((m) => m.id === me?.id)
                    .role.toLocaleLowerCase() +
                  '.name'
              )
            : '-'}
        </span>
      ),
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: t('editedDate'),
      key: 'editedAt',
      width: 140,
      render: (project) => (
        <>
          {project.date.editedAt
            ? moment(project.date.editedAt, 'YYYYMMDDHHmmss').fromNow()
            : '-'}
        </>
      ),
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        moment(a.date.editedAt).unix() - moment(b.date.editedAt).unix(),
    },
    {
      title: t('createdDate'),
      key: 'createdAt',
      width: 140,
      render: (project) => (
        <>
          {moment(project.date.createdAt, 'YYYYMMDDHHmmss').format(
            'YYYY-MM-DD'
          )}
        </>
      ),
      sorter: (a, b) =>
        moment(a.date.createdAt).unix() - moment(b.date.createdAt).unix(),
    },
  ]

  /**
   * Table 정보 수정
   * @param pagination
   * @param filters
   * @param sorter
   * @param extra
   */
  const onTableChange = (pagination, filters, sorter, extra) => {
    setSort(sorter.field)
  }

  /**
   * 즐겨찾기 Table 정보 수정
   * @param pagination
   * @param filters
   * @param sorter
   * @param extra
   */
  const onFavoritesTableChange = (pagination, filters, sorter, extra) => {
    setSortFavorites(sorter.field)
  }

  /**
   * 프로젝트 카드
   * @param project
   * @constructor
   */
  const ProjectCardItem = (project) => {
    return (
      <Card
        cover={
          <Link
            to={`/projects/${project.uid}`}
            // onClick={(e) => enterProject(e)}
            className={
              'w-full aspect-w-10 aspect-h-8 bg-cover bg-gray-300 cursor-pointer relative'
            }
            style={{
              backgroundImage: project?.image?.path
                ? `url(${project?.image?.path ? project?.image?.path : ''})`
                : '',
            }}>
            {!project?.image?.path ? (
              <div
                className={`absolute top-0 left-0 w-full h-full bg-secondary flex-none transition-all`}>
                <div
                  className={
                    'flex items-center justify-center h-full text-white text-5xl'
                  }>
                  {project.name.replace(
                    /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim,
                    ''
                  )
                    ? project.name.replace(
                        /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim,
                        ''
                      )[0]
                    : '-'}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="w-full h-4 absolute top-3 flex justify-between items-center px-3">
              <div className="bg-white border border-gray-300 rounded-sm px-1 w-max h-max font-normal text-xs">
                {t(`planTypes.${project.price}.title`)}
              </div>
              <div className="w-5 h-5 flex justify-center items-center text-base ico-favorite">
                <ProjectFavorite project={project} />
              </div>
            </div>
          </Link>
        }
        className="hover-trigger hover:shadow-lg hover:shadow-gray-300">
        <div className={'flex justify-between items-center'}>
          <Link
            to={`/projects/${project.uid}`}
            className={'overflow-hidden cursor-pointer'}>
            <p className={'leading-6 truncate mb-0'}>{project.name}</p>
            <p className={'text-xs text-gray-500 leading-5 truncate mb-0'}>
              {project.date.editedAt
                ? moment(project.date.editedAt, 'YYYYMMDDHHmmss').fromNow()
                : '-'}
            </p>
          </Link>
        </div>
      </Card>
    )
  }

  return (
    <div className={'space-y-0 relative'}>
      <Collapse ghost activeKey={collapseKeys} onChange={setCollapseKeys}>
        {/* Card View 즐겨찾기: 시작 */}
        {process.env.REACT_APP_HIDE_FAVORITES !== '1' &&
        viewType === 'card' &&
        favoritesProjectList.length ? (
          <Collapse.Panel
            header={
              <div className="flex justify-between items-center">
                <div className="leading-6">
                  <div className="font-medium leading-6">
                    {t('favoritesProjects')}
                  </div>
                </div>
                <div className={'hidden lg:flex items-center space-x-2'}>
                  <div>
                    <Select
                      size="small"
                      bordered={false}
                      defaultValue={'editedAt'}
                      style={{
                        width: 120,
                      }}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(val) => setSortFavorites(val)}>
                      <Option value={'name'}>{t('projectName')}</Option>
                      <Option value={'editedAt'}>{t('editedDate')}</Option>
                      <Option value={'createdAt'}>{t('createdDate')}</Option>
                    </Select>
                  </div>
                </div>
              </div>
            }
            key="favorites">
            <Row gutter={24}>
              {favoritesProjectList
                .filter(
                  (project) =>
                    !q || project.name.toLowerCase().includes(q.toLowerCase())
                )
                .sort(function (a, b) {
                  return sortFavorites === 'name'
                    ? a[sortFavorites].localeCompare(b[sortFavorites])
                    : // @ts-ignore
                      moment(b.date[sortFavorites]).unix() -
                        // @ts-ignore
                        moment(a.date[sortFavorites]).unix()
                })
                .map((project) => (
                  <Col
                    key={project.uid}
                    xs={24}
                    sm={12}
                    md={6}
                    lg={4}
                    className="mb-6">
                    {ProjectCardItem(project)}
                  </Col>
                ))}
            </Row>
          </Collapse.Panel>
        ) : (
          <></>
        )}
        {/* Card View 즐겨찾기: 끝 */}
        {/* Card View: 시작 */}
        {viewType === 'card' ? (
          <Collapse.Panel
            header={
              <div className="flex justify-between items-center">
                <div className="leading-6">
                  <div className="font-medium leading-6">
                    {t('allProjects')}
                  </div>
                </div>
                <div className={'hidden lg:flex items-center space-x-2'}>
                  <div>
                    <Select
                      size="small"
                      bordered={false}
                      defaultValue={'editedAt'}
                      style={{
                        width: 120,
                      }}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(val) => setSort(val)}>
                      <Option value={'name'}>{t('projectName')}</Option>
                      <Option value={'editedAt'}>{t('editedDate')}</Option>
                      <Option value={'createdAt'}>{t('createdDate')}</Option>
                    </Select>
                  </div>
                </div>
              </div>
            }
            key="list">
            <Row gutter={24}>
              {projectList
                .filter(
                  (project) =>
                    !q || project.name.toLowerCase().includes(q.toLowerCase())
                )
                .sort(function (a, b) {
                  return sort === 'name'
                    ? a[sort].localeCompare(b[sort])
                    : // @ts-ignore
                      moment(b.date[sort]).unix() - moment(a.date[sort]).unix()
                })
                .map((project) => (
                  <Col
                    key={project.uid}
                    xs={24}
                    sm={12}
                    md={6}
                    lg={4}
                    className="mb-6">
                    {ProjectCardItem(project)}
                  </Col>
                ))}
            </Row>
          </Collapse.Panel>
        ) : (
          <></>
        )}
        {/* Card View: 끝 */}
      </Collapse>
      <Collapse ghost activeKey={collapseKeys} onChange={setCollapseKeys}>
        {/* List View 즐겨찾기: 시작 */}
        {process.env.REACT_APP_HIDE_FAVORITES !== '1' &&
        viewType === 'list' &&
        favoritesProjectList.length ? (
          <Collapse.Panel
            header={
              <div className="font-medium leading-6">
                {t('favoritesProjects')}
              </div>
            }
            key="favorites">
            <Table
              size="small"
              rowClassName="hover-trigger"
              // @ts-ignore
              columns={tableCols}
              dataSource={projectList.filter(
                (project) =>
                  favoritesProjectList
                    .map((f) => f.uid)
                    .includes(project.uid) &&
                  (!q || project.name.toLowerCase().includes(q.toLowerCase()))
              )}
              rowKey="uid"
              loading={loading}
              pagination={false}
              onChange={onFavoritesTableChange}></Table>
          </Collapse.Panel>
        ) : (
          <></>
        )}
        {/* List View 즐겨찾기: 끝 */}
        {/* List View: 시작 */}
        {viewType === 'list' ? (
          <Collapse.Panel
            header={
              <div className="font-medium leading-6">{t('allProjects')}</div>
            }
            key="list">
            <Table
              size="small"
              rowClassName="hover-trigger"
              // @ts-ignore
              columns={tableCols}
              dataSource={projectList.filter(
                (project) =>
                  !q || project.name.toLowerCase().includes(q.toLowerCase())
              )}
              rowKey="uid"
              loading={loading}
              pagination={false}
              onChange={onTableChange}></Table>
          </Collapse.Panel>
        ) : (
          <></>
        )}
        {/* List View: 끝 */}
      </Collapse>
      {viewType === 'card' && loading ? (
        <div
          className={`w-full h-full absolute top-0 right-0 flex justify-center items-center text-center`}>
          <Spin />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
