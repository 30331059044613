import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Spin, Typography, Card, Button } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { RootState, useAppDispatch } from '@/states'
import { UsageBar, UsageCircle } from '@/components/Projects'
import { LINK_PLAN } from '@/constants'
import { LayoutProjectsTab } from '@/components/Layout'
import NotFound from '../404'

const ProjectsUsage = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject, modelList } = projectsState

  if (process.env.REACT_APP_HIDE_USAGE === '1') {
    return <NotFound />
  }

  return (
    <>
      {currentProject && (
        <Helmet>
          <title>
            {t('usage')} {currentProject.name} · {process.env.REACT_APP_NAME}
          </title>
        </Helmet>
      )}
      {currentProject ? (
        <div className="space-y-5 py-5 lg:py-7">
          <LayoutProjectsTab />
          {/* Setting body: 시작 */}
          <div className={'space-y-6'}>
            <Card>
              <div className="flex justify-between items-center">
                <div>
                  <Typography.Title
                    level={5}
                    className={'mt-0 flex space-x-1 items-center'}>
                    <span>{t('projectPlan')}</span>
                    <a
                      href={LINK_PLAN}
                      target={'_blank'}
                      rel="noreferrer"
                      className="text-sm font-normal mb-0 flex items-center space-x-0.5">
                      <QuestionCircleOutlined />
                    </a>
                  </Typography.Title>
                  <div className="flex">
                    <span className="bg-white border border-gray-300 rounded-sm px-3 leading-6 block">
                      {t(`planTypes.${currentProject.usage?.type}.title`)}{' '}
                    </span>
                  </div>
                </div>
                <div>
                  {currentProject?.role === 'OWNER' &&
                    currentProject?.price === 'FREE' && (
                      <>
                        <Button
                          onClick={() => {
                            // @ts-ignore
                            ChannelIO('openChat')
                          }}>
                          {t('freeUpgradePlan')}
                        </Button>
                      </>
                    )}
                </div>
              </div>
            </Card>
            <Card>
              <div className="mb-6">
                <Typography.Title level={5} className={'mt-0'}>
                  {t('projectGlobalUsage')}
                </Typography.Title>
              </div>
              <div className="2xl:px-10">
                <div className="grid lg:grid-cols-3 xl:grid-cols-5 gap-5 lg:gap-0">
                  <div className="lg:border-r border-gray-300">
                    <UsageCircle
                      usage={currentProject?.usage?.content}
                      label={t('noGloablContents')}
                      desc={t('noGloablContentsDesc')}
                    />
                  </div>
                  <div className="lg:border-r border-gray-300">
                    <UsageCircle
                      usage={currentProject?.usage?.storage}
                      label={t('mediaStorage')}
                      desc={t('mediaStorageDesc')}
                      isFile
                    />
                  </div>
                  <div className="lg:border-r border-gray-300">
                    <UsageCircle
                      usage={currentProject?.usage?.model}
                      label={t('noModels')}
                      desc={t('noModelsDesc')}
                    />
                  </div>
                  <div className="lg:border-r border-gray-300">
                    <UsageCircle
                      usage={currentProject?.usage?.language}
                      label={t('noLanguages')}
                      desc={t('noLanguagesDesc')}
                    />
                  </div>
                  <div className="">
                    <UsageCircle
                      usage={currentProject?.usage?.member}
                      label={t('noMembers')}
                      desc={t('noMembersDesc')}
                    />
                  </div>
                </div>
              </div>
            </Card>
            {currentProject.usage?.modelList &&
            currentProject.usage?.modelList.length ? (
              <Card>
                <div className="mb-6">
                  <Typography.Title level={5} className={'mt-0'}>
                    {t('usageByModel')}
                  </Typography.Title>
                </div>
                <div className="space-y-3">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center space-x-1">
                      <span>{t('limitComponentByModel')}</span>
                      {/* <InfoCircleOutlined /> */}
                    </div>
                    <div>
                      {currentProject.usage.type !== 'UNLIMITED'
                        ? currentProject.usage?.modelList[0].component.limit.toLocaleString() +
                          t('countWord')
                        : '∞'}
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="flex items-center space-x-1">
                      <span>{t('maxContentsByModel')}</span>
                      {/* <InfoCircleOutlined /> */}
                    </div>
                    <div>
                      {currentProject.usage.type !== 'UNLIMITED'
                        ? currentProject.usage?.modelList[0].content.limit.toLocaleString() +
                          t('countWord')
                        : '∞'}
                    </div>
                  </div>
                </div>
                <hr className="mt-6 mb-6" />
                <div className="space-y-10">
                  {currentProject.usage?.modelList.map((model) => (
                    <div key={model.id}>
                      <div className="grid grid-cols-8 gap-6">
                        <div className="col-span-3">
                          {
                            modelList.find((m) => m.id === model.id)
                              ?.languageMap[currentProject.defaultLang]
                          }
                        </div>
                        <div className="col-span-5 space-y-2">
                          <UsageBar
                            usage={model.component}
                            label={t('noComponents')}
                          />
                          <UsageBar
                            usage={model.content}
                            label={t('noContents')}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Card>
            ) : (
              <></>
            )}
          </div>
          {/* Setting body: 끝 */}
        </div>
      ) : (
        <div className={'flex justify-center items-center h-screen'}>
          <Spin />
        </div>
      )}
    </>
  )
}

export default ProjectsUsage
