import React, { useEffect, useState } from 'react'
import { SelectorGroupInterface } from '@/types'
import { CategoriesFormModal, CategoriesList } from '@/components/Categories'
import { useTranslation } from 'react-i18next'
import { Card } from 'antd'
import { useAppDispatch } from '@/states'
import { setCurrentModel } from '@/states/actions'
import NotFound from '../404'

const ProjectsCategories = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State
  const [currentCategory, setCurrentCategory] =
    useState<SelectorGroupInterface | null>(null)

  // Effect
  useEffect(() => {
    dispatch(setCurrentModel(null))
  }, [])

  if (process.env.REACT_APP_HIDE_CATEGORY === '1') {
    return <NotFound />
  }

  return (
    <>
      <div className="py-10">
        <div className="mb-10">
          <h1 className={'mb-2 text-lg leading-7 flex items-center space-x-4'}>
            {t('category')}
          </h1>
          <p
            className="mb-0"
            dangerouslySetInnerHTML={{
              __html: t('categoryDesc'),
            }}
          />
        </div>
        <Card>
          <CategoriesList currentCategory={currentCategory} />
        </Card>
      </div>

      <CategoriesFormModal />
    </>
  )
}

export default ProjectsCategories
