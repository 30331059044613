import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { message, Dropdown, Switch } from 'antd'
import Cookies from 'js-cookie'
import {
  EllipsisOutlined,
  LogoutOutlined,
  SettingOutlined,
  ExpandOutlined,
  BgColorsOutlined,
} from '@ant-design/icons'
import { RootState, useAppDispatch } from '@/states'
import { toggleStretch, logout, setAccountModal } from '@/states/actions'
import { setLogout } from '@/utils/auth'
import { STRETCH_TOGGLE_KEY } from '@/configs'

export const GnbAuth = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { layoutState, authState, projectsState } = useSelector(
    (state: RootState) => ({
      layoutState: state.layout,
      authState: state.auth,
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { accountMenu, gnb, stretch } = layoutState
  const { me } = authState
  const { contentsListLoading } = projectsState

  // State

  /**
   * 로그아웃
   */
  const goLogout = () => {
    logout()
      .then((res) => {
        setLogout(true)
      })
      .catch((e) => {
        message.error(t('error.wrong'))
      })
  }

  /**
   * GNB Menu Toggle
   */
  const toggleStretchScreen = () => {
    dispatch(toggleStretch(!stretch))

    Cookies.set(STRETCH_TOGGLE_KEY, !stretch ? '1' : '0', {
      expires: 86400,
    })
  }

  /**
   * 메뉴 아이템
   */
  const items = [
    {
      label: (
        <div
          className="flex items-center space-x-2"
          onClick={() => {
            dispatch(setAccountModal(true))
          }}>
          <SettingOutlined />
          <span>{t('myAccount')}</span>
        </div>
      ),
      key: 'account',
    },
    {
      label: (
        <div
          className="flex justify-between items-center w-full"
          onClick={() => {
            toggleStretchScreen()
          }}>
          <div className="flex items-center space-x-2">
            <ExpandOutlined />
            <span>{t('stretchScreen')}</span>
          </div>
          <Switch checked={stretch} />
        </div>
      ),
      key: 'stretch',
    },
    {
      label: (
        <div className="flex justify-between items-center w-full cursor-not-allowed opacity-30">
          <div className="flex items-center space-x-2">
            <BgColorsOutlined />
            <span>{t('darkMode')}</span>
          </div>
        </div>
      ),
      key: 'darkMode',
    },
    {
      type: 'divider',
      key: 'divider',
    },
    {
      label: (
        <div
          className="flex items-center space-x-2 text-red-500"
          onClick={() => {
            goLogout()
          }}>
          <LogoutOutlined />
          <span>{t('logout')}</span>
        </div>
      ),
      key: 'logout',
    },
  ]

  return (
    <div className={''}>
      <div
        className={
          'hover:bg-gray-300 cursor-pointer flex items-center rounded-lg transition-all'
        }>
        <Dropdown menu={{ items }} trigger={['click']}>
          {me ? (
            <div
              className={`w-full flex items-center transition-all space-x-3 p-2`}>
              {me.image && me.image.path ? (
                <div
                  className={`w-7 h-7 rounded-full bg-gray-300 flex-none bg-cover bg-center transition-all`}
                  style={{ backgroundImage: `url(${me.image.path})` }}></div>
              ) : (
                <div
                  className={`w-7 h-7 rounded-full bg-secondary flex-none memex-ico-user transition-all`}></div>
              )}
              <div className={'grow overflow-hidden'}>
                <div className={'leading-5 truncate'}>{me.name}</div>
                <div className={'text-xs text-gray-500 leading-3 truncate'}>
                  {me.email}
                </div>
              </div>
              {gnb ? (
                <div className={'w-4 flex-none'}>
                  <EllipsisOutlined />
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </Dropdown>
      </div>
    </div>
  )
}
