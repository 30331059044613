import { APIs } from '@/components/Developers'
import Common from '@/components/Developers/Common'
import MediaUpload from '@/components/Developers/MediaUpload'
import { DevelopersTab } from '@/components/Layout/TabDevelopers'
import { RootState, useAppDispatch } from '@/states'
import { setCurrentModel } from '@/states/actions'
import { Anchor, Button, Card, Col, Row, Spin } from 'antd'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import NotFound from '../404'
const { Link } = Anchor

const ProjectsDevelopersAPIs = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  if (process.env.REACT_APP_HIDE_DEVELOPERS === '1') {
    return <NotFound />
  }

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject, modelList } = projectsState

  // Effect
  useEffect(() => {
    dispatch(setCurrentModel(null))
  }, [])

  return (
    <>
      {currentProject && (
        <Helmet>
          <title>
            {t('externalAPIs')} · {t('developers')} · {currentProject.name} ·{' '}
            {process.env.REACT_APP_NAME}
          </title>
        </Helmet>
      )}
      {currentProject ? (
        <div className={'space-y-10 py-5 lg:py-7'}>
          {/* 프로젝트 Tab: 시작 */}
          <DevelopersTab />
          {/* 프로젝트 Tab: 끝 */}
          <div>
            <div className="mb-4">
              <h1
                className={
                  'mb-2 text-lg leading-7 flex items-center space-x-4'
                }>
                {t('externalAPIs')}
              </h1>
              <p className="mb-0">{t('externalAPIsDescription')}</p>
              <p className="mb-0">{t('externalAPIsDescription2')}</p>
            </div>
            <Button
              type={'primary'}
              onClick={() =>
                navigate(`/projects/${currentProject.uid}/developers/access`)
              }
              title={t('manageApiKey')}>
              {t('manageApiKey')}
            </Button>
          </div>
          <Row gutter={28}>
            <Col xs={24} lg={18}>
              <Card>
                <div className="p-3 pb-20">
                  {/* 본문: 시작 */}
                  <div className="space-y-20">
                    <Common />
                    <hr />
                    <MediaUpload />
                    {modelList.map((model, mIdx) => (
                      <APIs key={model.devKey} model={model} />
                    ))}
                  </div>
                  {/* 본문: 끝 */}
                </div>
              </Card>
            </Col>
            <Col span={6} className="!hidden lg:!block z-50 bg-gray-200">
              <div className="px-3">
                {/* Anchor: 시작 */}
                <Anchor
                  // @ts-ignore
                  getContainer={() => document.getElementById('layout-main')}
                  offsetTop={0}
                  className="py-3">
                  {modelList.map((model) => (
                    <Link
                      key={model.devKey}
                      href={`#${model.devKey}`}
                      title={`${
                        model.languageMap[currentProject.defaultLang]
                      } (${model.devKey})`}
                    />
                  ))}
                </Anchor>
                {/* Anchor: 끝 */}
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div className={'flex justify-center items-center h-screen'}>
          <Spin />
        </div>
      )}
    </>
  )
}

export default ProjectsDevelopersAPIs
