import { AppDispatch } from '@/states'
import { ActionTypesProjects } from '@/states/interfaces'
import {
  LanguagesAvailable,
  ProjectSyncActionType,
  ProjectSyncModelSyncInterface,
} from '@/types'

/**
 * 현재 프로젝트 설정
 * @param data
 * @param isPreserve
 * @returns {function(*): *}
 */
export const setCurrentProject =
  (data, isPreserve?: boolean) => (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_CURRENT_PROJECT,
      payload: {
        project: data,
        isPreserve,
      },
    })
  }

/**
 * 현재 프로젝트 언어 설정
 * @param lang
 * @returns {function(*): *}
 */
export const setCurrentLanguage = (lang: LanguagesAvailable) => (dispatch) => {
  return dispatch({
    type: ActionTypesProjects.SET_CURRENT_LANGUAGE,
    payload: {
      language: lang,
    },
  })
}

/**
 * WS Client 설정
 * @param client
 * @returns {function(*): *}
 */
export const setWsClient = (client: WebSocket | null) => (dispatch) => {
  return dispatch({
    type: ActionTypesProjects.SET_WS_CLIENT,
    payload: {
      client,
    },
  })
}

/**
 * WS Client Sync 설정
 * @param sync
 * @param actionType
 * @returns
 */
export const setWsClientSync =
  (sync: ProjectSyncModelSyncInterface[], actionType?: ProjectSyncActionType) =>
  (dispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_WS_CLIENT_SYNC,
      payload: {
        sync,
        actionType,
      },
    })
  }
