import React, { useMemo } from 'react'
import { RootState, useAppDispatch } from '@/states'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Button } from 'antd'
import {
  FileImageOutlined,
  PlusSquareOutlined,
  CloudSyncOutlined,
  CodeSandboxOutlined,
  ApartmentOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { ProjectFavorite } from '@/components/Projects'
import { Link } from 'react-router-dom'

import ModelFavorite from '@/components/Models/ModelsFavorite'
import { HeaderContents, HeaderMedia } from '@/components/Layout'
import {
  setCategoryFormModal,
  setProjectAccessFormModal,
  setProjectInviteModal,
} from '@/states/actions'
import { PublishAll } from '@/components/Projects'

export const Header = () => {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const dispatch = useAppDispatch()

  // Params
  const { projectUid, modelId } = useParams()
  const navigate = useNavigate()

  // State (Redux)
  const { layoutState, projectsState, authState } = useSelector(
    (state: RootState) => ({
      layoutState: state.layout,
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { gnb, stretch } = layoutState
  const { currentProject, currentModel } = projectsState
  const { init, me } = authState

  // Memo
  const isProjectDashboard = useMemo(() => {
    return (
      (location.pathname === `/projects/${projectUid}` ||
        location.pathname === `/projects/${projectUid}/`) &&
      gnb
    )
  }, [location.pathname, gnb])

  return projectUid ? (
    <div
      className={`${
        !isProjectDashboard ? 'h-12' : 'h-12 lg:h-auto'
      } flex justify-between items-center space-x-1 lg:space-x-2 py-2 ${
        gnb ? '' : 'lg:pl-8'
      }`}>
      {/* Header Left: 시작 */}
      <div className="grow w-full overflow-x-auto flex items-center space-x-1 lg:space-x-2">
        {/* <div className="py-3">
        <Tooltip title={t('goProjectList')}>
          <div
            className="flex-none w-8 h-8 flex items-center justify-center hover:bg-gray-300 transition-all rounded-lg cursor-pointer"
            onClick={() => {
              navigate('/projects')
            }}>
            <LeftOutlined />
          </div>
        </Tooltip>
      </div> */}
        {currentProject ? (
          <Link
            to={`/projects/${currentProject.uid}`}
            className={`max-w-full lg:max-w-none ${
              isProjectDashboard
                ? 'h-8 lg:h-20 space-x-1 lg:space-x-2 px-2.5 lg:px-0'
                : 'h-8 space-x-1 px-2.5 hover:bg-gray-300'
            } flex items-center transition-all rounded-lg cursor-pointer`}>
            <div
              className={`w-full grow flex items-center truncate ${
                isProjectDashboard ? 'space-x-2 lg:space-x-4' : 'space-x-2'
              }`}>
              {currentProject?.image?.path ? (
                <div
                  className={`${
                    isProjectDashboard
                      ? 'w-5 h-5 rounded lg:w-8 lg:h-8 lg:rounded-lg'
                      : 'w-5 h-5 rounded'
                  } bg-gray-300 flex-none bg-cover bg-center transition-all`}
                  style={{
                    backgroundImage: `url(${currentProject.image.path})`,
                  }}></div>
              ) : (
                <div
                  className={`${
                    isProjectDashboard
                      ? 'w-5 h-5 rounded lg:w-8 lg:h-8 lg:rounded-lg'
                      : 'w-5 h-5 rounded text-xs'
                  } bg-secondary flex-none transition-all`}>
                  <div
                    className={`flex items-center justify-center h-full text-white ${
                      isProjectDashboard ? 'text-base' : ''
                    }`}>
                    {currentProject.name.replace(
                      /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim,
                      ''
                    )
                      ? currentProject.name.replace(
                          /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim,
                          ''
                        )[0]
                      : '-'}
                  </div>
                </div>
              )}
              <div
                className={`truncate transition-all ${
                  isProjectDashboard ? 'lg:text-2xl lg:font-medium' : ''
                }`}>
                {currentProject.name}
              </div>
            </div>
            <div
              className={`flex-none flex items-center ico-favorite  transition-all ${
                isProjectDashboard ? 'lg:text-lg' : ''
              }`}>
              <ProjectFavorite project={currentProject} />
            </div>
          </Link>
        ) : (
          <></>
        )}
        {currentProject && currentModel ? (
          <div className="h-8 flex items-center space-x-1 lg:space-x-2 px-2.5 hover:bg-gray-300 transition-all rounded-lg">
            <div className="w-full grow flex items-center space-x-1 truncate">
              <div className="truncate">
                {currentModel.languageMap[currentProject.defaultLang]}
              </div>
              <div className="flex-none flex items-center ico-favorite">
                <ModelFavorite model={currentModel} />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {location.pathname.includes(
          `/projects/${currentProject?.uid}/media`
        ) ? (
          <div className="max-w-full lg:max-w-none h-8 flex items-center px-2.5 hover:bg-gray-300 transition-all rounded-lg">
            <div className="w-full grow flex items-center space-x-1 truncate">
              <FileImageOutlined />
              <span>{t('mediaContents')}</span>
            </div>
          </div>
        ) : (
          <></>
        )}
        {process.env.REACT_APP_HIDE_CATEGORY !== '1' &&
        location.pathname.includes(
          `/projects/${currentProject?.uid}/categories`
        ) ? (
          <div className="h-8 flex items-center px-2.5 hover:bg-gray-300 transition-all rounded-lg">
            <div className="w-full grow flex items-center space-x-1 truncate">
              <ApartmentOutlined />
              <span>{t('category')}</span>
            </div>
          </div>
        ) : (
          <></>
        )}
        {process.env.REACT_APP_HIDE_PUBLISH !== '1' &&
        location.pathname.includes(
          `/projects/${currentProject?.uid}/publish`
        ) ? (
          <div className="h-8 flex items-center space-x-2 px-2.5 hover:bg-gray-300 transition-all rounded-lg">
            <div className="w-full grow flex items-center space-x-1 truncate">
              <CloudSyncOutlined />
              <span>{t('publishContents')}</span>
            </div>
          </div>
        ) : (
          <></>
        )}
        {process.env.REACT_APP_HIDE_DEVELOPERS !== '1' &&
        location.pathname.includes(
          `/projects/${currentProject?.uid}/developers`
        ) ? (
          <div className="h-8 flex items-center space-x-2 px-2.5 hover:bg-gray-300 transition-all rounded-lg">
            <div className="w-full grow flex items-center space-x-1 truncate">
              <CodeSandboxOutlined />
              <span>{t('developers')}</span>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {/* Header Left: 끝 */}
      {/* Header Right: 시작 */}
      <div className="flex-none">
        {currentProject &&
        currentModel &&
        location.pathname.includes(
          `/projects/${currentProject.uid}/${currentModel.id}/contents`
        ) ? (
          <HeaderContents />
        ) : (
          <></>
        )}
        {currentProject &&
        location.pathname.includes(`/projects/${currentProject.uid}/media`) ? (
          <HeaderMedia />
        ) : (
          <></>
        )}
        {process.env.REACT_APP_HIDE_MEMBERS !== '1' &&
        currentProject &&
        location.pathname.includes(
          `/projects/${currentProject.uid}/members`
        ) ? (
          <>
            <Button
              type={'primary'}
              className="btn-sm-ico-only"
              icon={<PlusSquareOutlined />}
              onClick={() => dispatch(setProjectInviteModal(true))}
              disabled={
                (currentProject &&
                  currentProject.usage &&
                  currentProject.memberList.length >=
                    currentProject.usage?.member.limit) ||
                !(
                  currentProject.role === 'OWNER' ||
                  currentProject.role === 'ADMIN'
                )
              }>
              {t('inviteMembers')}
            </Button>
          </>
        ) : (
          <></>
        )}
        {process.env.REACT_APP_HIDE_CATEGORY !== '1' &&
        currentProject &&
        location.pathname.includes(
          `/projects/${currentProject.uid}/categories`
        ) ? (
          <div>
            <Button
              type={'primary'}
              className="btn-sm-ico-only"
              icon={<PlusSquareOutlined />}
              disabled={
                currentProject.role !== 'OWNER' &&
                currentProject.role !== 'ADMIN'
              }
              onClick={() => dispatch(setCategoryFormModal(true))}
              title={t('addCategory')}>
              {t('addCategory')}
            </Button>
          </div>
        ) : (
          <></>
        )}
        {process.env.REACT_APP_HIDE_DEVELOPERS !== '1' &&
        currentProject &&
        location.pathname.includes(
          `/projects/${currentProject.uid}/developers/access`
        ) ? (
          <div>
            <Button
              type={'primary'}
              className="btn-sm-ico-only"
              disabled={
                currentProject.role !== 'OWNER' &&
                currentProject.role !== 'ADMIN'
              }
              icon={<PlusSquareOutlined />}
              onClick={() => dispatch(setProjectAccessFormModal(true))}
              title={t('createAccess')}>
              {t('createAccess')}
            </Button>
          </div>
        ) : (
          <></>
        )}
        {process.env.REACT_APP_HIDE_PUBLISH !== '1' &&
        currentProject &&
        location.pathname.includes(
          `/projects/${currentProject.uid}/publish`
        ) ? (
          <PublishAll />
        ) : (
          <></>
        )}
      </div>
      {/* Header Right: 끝 */}
    </div>
  ) : (
    <></>
  )
}
