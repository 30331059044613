import React, { useEffect, useMemo, useState } from 'react'
import { Button, Input, Upload, Modal, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { DEFAULT_PROJECT_PLAN, ProjectUsageProperties } from '@/configs'
import { ProjectUsagePropertiesInterface } from '@/types'
import ImgCrop from 'antd-img-crop'
import { languagesConfig } from '@/configs'
import { UploadFile } from 'antd/es/upload/interface'

export const CreateStep3 = ({
  formikProjectForm,
  onNext,
  prevFileList,
  onFileUpload,
  isMaxFreeProjectsMeet,
  freeProjectsCount,
}: {
  formikProjectForm: any
  onNext: (number) => void
  prevFileList: UploadFile[]
  onFileUpload: (fileList) => void
  isMaxFreeProjectsMeet: boolean
  freeProjectsCount: number
}) => {
  const { t, i18n } = useTranslation()

  // State
  const [fileList, setFileList] = useState<UploadFile[]>(prevFileList)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [previewUrl, setPreviewUrl] = useState<string>('')
  const [projectPlan, setProjectPlan] = useState<
    ProjectUsagePropertiesInterface | undefined
  >(ProjectUsageProperties.find((p) => p.price === DEFAULT_PROJECT_PLAN))

  // Upload props
  const uploadProps = {
    onRemove: (file) => {
      setFileList([])
    },
    beforeUpload: (file) => {
      const reader = new FileReader()

      reader.onload = (e) => {
        if (e.target && e.target.result) {
          const fileAdded = file
          fileAdded.status = 'done'
          fileAdded.url = e.target.result
          setFileList([fileAdded])
        }
      }
      reader.readAsDataURL(file)

      return false
    },
    fileList,
  }

  // Effect
  useEffect(() => {
    onFileUpload(fileList)
  }, [fileList])

  /**
   * 다음 버튼 클릭
   * @param val
   */
  const onHandleSubmit = (e) => {
    e.preventDefault()
    onNext(3)
  }

  /**
   * 이미지 프리뷰
   * @param file
   */
  const onHandlePreview = (file) => {
    setPreviewUrl(file.url)
    setShowPreview(true)
  }

  /**
   * 가용 언어 선택
   * @param val
   */
  const onHandleLanguages = (val) => {
    if (
      projectPlan &&
      projectPlan.maxLangauges &&
      val.length > projectPlan.maxLangauges
    )
      return false

    formikProjectForm.setFieldValue('languages', val)
  }

  // Memo
  const isValid = useMemo(() => {}, [formikProjectForm.values])

  return (
    <div className={`h-full overflow-y-auto`}>
      <div className="w-full max-w-3xl mx-auto">
        <div className="text-center mb-10">
          <h1 className="text-3xl font-bold mb-2">
            {t('projectCreate.step3.title')}
          </h1>
          <p
            className="text-sm mb-0"
            dangerouslySetInnerHTML={{
              __html: t('projectCreate.step3.desc'),
            }}
          />
        </div>
        <form onSubmit={onHandleSubmit}>
          <div className="space-y-4">
            <div className="grid lg:grid-cols-2 gap-5">
              <div className="space-y-4 pt-6 lg:pt-0">
                <div className="block">
                  <label htmlFor="projectFormLanguages" className="">
                    <div className={'mb-2'}>
                      {t('languages')}
                      {projectPlan?.maxLangauges
                        ? `(${t('maxLanguages', {
                            max: projectPlan?.maxLangauges,
                          })})`
                        : ''}{' '}
                      <span className="text-red-500">*</span>
                    </div>
                    <Select
                      size="large"
                      id={'projectFormLanguages'}
                      className={'w-full block'}
                      mode={'multiple'}
                      showArrow
                      onChange={(val) => onHandleLanguages(val)}
                      value={formikProjectForm.values.languages}>
                      {languagesConfig.availableLanguages.map((lang) => (
                        <Select.Option
                          key={lang.code}
                          value={lang.code}
                          label={t('lang.' + lang.code.toLocaleLowerCase())}>
                          <div className="demo-option-label-item">
                            {t('lang.' + lang.code.toLocaleLowerCase())}
                          </div>
                        </Select.Option>
                      ))}
                    </Select>
                  </label>
                  {formikProjectForm.touched.languages &&
                  formikProjectForm.errors.languages ? (
                    <p className="my-1 text-xs text-red-500">
                      {formikProjectForm.errors.languages}
                    </p>
                  ) : null}
                </div>
                <div className="block">
                  <label htmlFor="projectFormDefaultLanguage" className="">
                    <div className={'mb-2'}>
                      {t('defaultLanguage')}{' '}
                      <span className="text-red-500">*</span>
                    </div>
                    <Select
                      size="large"
                      id={'projectFormDefaultLanguage'}
                      className={'w-full block'}
                      onChange={(val) =>
                        formikProjectForm.setFieldValue('defaultLanguage', val)
                      }
                      value={formikProjectForm.values.defaultLanguage}>
                      {formikProjectForm.values.languages.map((lang) => (
                        <Select.Option
                          key={lang}
                          value={lang}
                          label={t('lang.' + lang.toLocaleLowerCase())}>
                          <div className="demo-option-label-item">
                            {t('lang.' + lang.toLocaleLowerCase())}
                          </div>
                        </Select.Option>
                      ))}
                    </Select>
                  </label>
                  {formikProjectForm.touched.defaultLanguage &&
                  formikProjectForm.errors.defaultLanguage ? (
                    <p className="my-1 text-xs text-red-500">
                      {formikProjectForm.errors.defaultLanguage}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="space-y-4 pt-6 lg:pt-0">
                <div className="block">
                  <label htmlFor="projectFormDescription" className="">
                    <div className={'mb-2'}>{t('projectDescription')}</div>
                    <Input.TextArea
                      size="large"
                      rows={2}
                      id={'projectFormDescription'}
                      name="description"
                      onChange={formikProjectForm.handleChange}
                      value={formikProjectForm.values.description}
                    />
                  </label>
                </div>
                <div className="block">
                  <label htmlFor="" className="">
                    <div className={'mb-2'}>{t('photo')}</div>
                    <ImgCrop
                      modalTitle={t('editImage')}
                      modalOk={t('confirm')}
                      modalCancel={t('cancel')}>
                      <Upload
                        {...uploadProps}
                        accept="image/*"
                        listType={'picture-card'}
                        maxCount={1}
                        onPreview={onHandlePreview}>
                        {fileList && fileList.length
                          ? t('editPhoto')
                          : t('addPhoto')}
                      </Upload>
                    </ImgCrop>
                  </label>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex space-x-2 items-center">
                <div
                  className="text-gray-500 cursor-pointer text-xs"
                  onClick={() => onNext(1)}>
                  {t('previous')}
                </div>
                <div className="text-gray-500 text-xs">3/4</div>
              </div>
              <div>
                <Button
                  type="primary"
                  disabled={
                    isMaxFreeProjectsMeet ||
                    formikProjectForm.values.languages.length === 0 ||
                    !formikProjectForm.values.defaultLanguage
                  }
                  onClick={onHandleSubmit}>
                  {t('next')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Modal
        open={showPreview}
        maskClosable={false}
        footer={null}
        onCancel={() => setShowPreview(false)}>
        <img src={previewUrl} className={'w-full'} />
      </Modal>
    </div>
  )
}
