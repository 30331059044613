import {
  CONTENTS_SORT_KEY,
  DEFAULT_CONTENTS_PAGE_SIZE,
  DEFAULT_SORT,
  LAYOUT_CHECKBOX_CELL_WIDTH,
  LAYOUT_DEFAULT_CELL_WIDTH,
  ProjectUsageProperties,
} from '@/configs'
import {
  ActionTypesProjects,
  ActionsProjects,
  ProjectsState,
} from '@/states/interfaces'
import { ComponentInterface, ProjectSyncInteraface } from '@/types'
import { getUserId } from '@/utils/auth'
import {
  getContentsWithProcessingMedia,
  getMediaProcessingList,
  setComponentsProperties,
  sortPaginateContentsList,
} from '@/utils/helpers'
import Cookies from 'js-cookie'

export const initialState: ProjectsState = {
  projectsListInit: !!process.env.REACT_APP_PROJECT_ID,
  projectList: [],
  favoritesProjectList: [],
  accessList: [],
  currentProject: null,
  modelListInit: false,
  modelList: [],
  currentModel: null,
  tmpModel: null,
  flattenComponentList: [],
  mediaListInit: true,
  mediaList: [],
  newMediaList: {},
  checkedMediaList: [],
  currentLanguage: '',
  contentsListInit: false,
  contentsListReload: false,
  contentsListLoading: false,
  contentsList: [],
  filteredContentsList: [],
  checkedContentsList: [],
  contentsPagination: {
    totalElements: 0,
    size: DEFAULT_CONTENTS_PAGE_SIZE,
    page: 0,
  },
  contentsSort: DEFAULT_SORT,
  contentsKeyword: '',
  reqComponents: [],
  reqPreviewComponents: [],
  categoriesListInit: false,
  categoriesList: [],
  ws: null,
  wsSync: [],
  contentsWithProcessingMedia: [],
  mediaListProcessing: [],
}

interface HydratePayload {
  projects: ProjectsState
}

const projectsReducer = (
  state = initialState,
  action: ActionsProjects
): ProjectsState => {
  switch (action.type) {
    // 프로젝트 목록 설정
    case ActionTypesProjects.SET_PROJECT_LIST: {
      // 내 권한
      const myId = Number(getUserId())
      const projectsSet = action.payload.projects.filter(
        (v, i, a) => a.findIndex((t) => t.uid === v.uid) === i
      )

      projectsSet.forEach((project) => {
        project.role =
          project?.memberList && project?.memberList.find((m) => m.id === myId)
            ? project?.memberList.find((m) => m.id === myId)?.role
            : 'USER'
      })

      return {
        ...state,
        projectsListInit: true,
        projectList: projectsSet,
        modelListInit: false,
        modelList: [],
        currentModel: null,
        tmpModel: null,
        flattenComponentList: [],
        mediaListInit: true,
        mediaList: [],
        newMediaList: {},
        checkedMediaList: [],
        currentLanguage: '',
        contentsListInit: false,
        contentsListReload: false,
        contentsListLoading: false,
        contentsList: [],
        checkedContentsList: [],
        contentsPagination: {
          totalElements: 0,
          size: DEFAULT_CONTENTS_PAGE_SIZE,
          page: 1,
        },
        contentsWithProcessingMedia: [],
        mediaListProcessing: [],
      }
    }
    // 프로젝트 목록 설정
    case ActionTypesProjects.SET_FAVORITE_PROJECT_LIST: {
      return {
        ...state,
        favoritesProjectList: action.payload.projects,
      }
    }
    // 현재 프로젝트 설정
    case ActionTypesProjects.SET_CURRENT_PROJECT: {
      // 내 권한
      const myId = Number(getUserId())
      const projectSet = action.payload.project

      if (myId && projectSet) {
        projectSet.role = projectSet?.memberList.find(
          (m) => m.id === myId
        )?.role

        const languageTuple = Object.keys(projectSet.languageMap).map((key) => [
          key,
          projectSet.languageMap[key],
        ])

        projectSet.defaultLang = languageTuple
          ? // @ts-ignore
            languageTuple.find((tuple) => tuple[1] === true)[0]
          : 'KO'

        projectSet.languageList = [
          projectSet.defaultLang,
          ...languageTuple
            .map((tuple) => tuple[0])
            .filter((lang) => lang !== projectSet.defaultLang),
        ]
      }

      if (projectSet) {
        const projectProperties = ProjectUsageProperties.find(
          (p) => p.price === projectSet.price
        )
        projectSet.properties = projectProperties
      }

      return {
        ...state,
        currentProject: projectSet,
        currentLanguage: projectSet ? projectSet.defaultLang : 'KO',
        currentModel: null,
        modelListInit: action.payload.isPreserve ? true : false,
        tmpModel: null,
        accessList: projectSet?.accessList ? projectSet?.accessList : [],
      }
    }
    // 현재 프로젝트 요금제 설정
    case ActionTypesProjects.SET_CURRENT_PROJECT_USAGE: {
      const projectSet = state.currentProject
      if (projectSet) {
        projectSet.usage = action.payload.usage

        const projectProperties = ProjectUsageProperties.find(
          (p) => p.price === projectSet.price
        )
        projectSet.properties = projectProperties
      }

      return {
        ...state,
        currentProject: projectSet,
      }
    }
    // 모델 목록 설정
    case ActionTypesProjects.SET_MODEL_LIST: {
      const newModels = action.payload.models.filter((m) => {
        return (
          !process.env.REACT_APP_HIDE_MODELS ||
          process.env.REACT_APP_HIDE_MODELS.split(',')
            .map((s) => Number(s))
            .indexOf(m.id as number) === -1
        )
      })

      newModels.forEach((model) => {
        if (!model.componentList) {
          model.componentList = []
        } else {
          const flattedComponentsArr: ComponentInterface[] = []

          // Component 평탄화
          const flattenComponents = () => {
            // Component 평탄화
            var flatComponents = (compList) => {
              compList.forEach((comp) => {
                if (comp.childList) {
                  flattedComponentsArr.push(comp)
                  flatComponents(comp.childList)
                } else flattedComponentsArr.push(comp)
              })
            }

            flatComponents(model.componentList)
          }

          flattenComponents()

          model.flattenComponentList =
            setComponentsProperties(flattedComponentsArr)

          // Title 여부 확인
          model.hasTitle = !!flattedComponentsArr.find(
            (fc) => fc.type === 'TITLE'
          )
        }
      })

      return {
        ...state,
        modelListInit: true,
        modelList: newModels,
      }
    }
    // 현재 모델 설정
    case ActionTypesProjects.SET_CURRENT_MODEL: {
      const flattedComponentsArr: ComponentInterface[] = []

      const newModel = action.payload.model
      if (newModel && !newModel.componentList) {
        newModel.componentList = []
      } else if (newModel && newModel.componentList) {
        // Component 평탄화
        const flattenComponents = () => {
          // Component 평탄화
          var flatComponents = (compList) => {
            compList.forEach((comp) => {
              if (comp.childList) {
                flattedComponentsArr.push(comp)
                flatComponents(comp.childList)
              } else flattedComponentsArr.push(comp)
            })
          }

          flatComponents(newModel?.componentList)
        }

        flattenComponents()

        // 첫번째 엑셀 셀
        let isFirstFound = false
        let isSecondFound = false
        flattedComponentsArr.forEach((comp, cIdx) => {
          if (comp.type !== 'BLOCK' && !isFirstFound) {
            comp.first = true
            isFirstFound = true
            newModel.cellOffsetWidth =
              (comp?.option?.width
                ? comp.option.width
                : LAYOUT_DEFAULT_CELL_WIDTH) + LAYOUT_CHECKBOX_CELL_WIDTH
          } else if (comp.type !== 'BLOCK' && !isSecondFound) {
            comp.second = true
            isSecondFound = true
          }

          // 빈 언어 components 채우기
          if (comp.type !== 'BLOCK' && state.currentProject) {
            state.currentProject.languageList.map((lang) => {
              if (!comp.languageMap[lang] && state.currentProject) {
                comp.languageMap[lang] =
                  comp.languageMap[state.currentProject.defaultLang]
              }
            })
          }
        })

        newModel.flattenComponentList =
          setComponentsProperties(flattedComponentsArr)
      }

      const updatedTmpModel = newModel
        ? JSON.parse(JSON.stringify(newModel))
        : null

      return {
        ...state,
        currentModel: newModel,
        tmpModel: updatedTmpModel,
        flattenComponentList: flattedComponentsArr,
        contentsList: action.payload.isPreserveContents
          ? state.contentsList
          : [],
        checkedContentsList: [],
        checkedMediaList: [],
        contentsWithProcessingMedia: [],
        mediaListProcessing: [],
      }
    }
    // 임시 모델 설정
    case ActionTypesProjects.SET_TMP_MODEL: {
      const flattedComponentsArr: ComponentInterface[] = []

      const newModel = action.payload.model
      if (newModel && !newModel.componentList) {
        newModel.componentList = []
      } else if (newModel && newModel.componentList) {
        // Component 평탄화
        const flattenComponents = () => {
          // Component 평탄화
          var flatComponents = (compList) => {
            compList.forEach((comp) => {
              if (comp.childList) {
                flattedComponentsArr.push(comp)
                flatComponents(comp.childList)
              } else flattedComponentsArr.push(comp)
            })
          }

          flatComponents(newModel?.componentList)
        }

        flattenComponents()

        // 검색 가능
        newModel.flattenComponentList =
          setComponentsProperties(flattedComponentsArr)
      }

      return {
        ...state,
        tmpModel: newModel,
      }
    }
    // 미디어 콘텐츠 목록 설정
    case ActionTypesProjects.SET_MEDIA_LIST: {
      return {
        ...state,
        checkedMediaList: [],
        mediaList: action.payload.mediaList,
      }
    }
    // 새 업로드 미디어 목록 설정
    case ActionTypesProjects.SET_NEW_MEDIA_LIST: {
      return {
        ...state,
        newMediaList: {
          mediaList: action.payload.mediaList,
          uid: action.payload.uid,
        },
      }
    }
    // 미디어 추가
    case ActionTypesProjects.ADD_MEDIA_TO_LIST: {
      return {
        ...state,
        mediaList: [...state.mediaList, action.payload.mediaAdded],
      }
    }
    // 체크된 미디어 목록 설정
    case ActionTypesProjects.SET_CHECKED_MEDIA_LIST: {
      return {
        ...state,
        checkedMediaList: action.payload.checkedMediaList,
      }
    }
    // 현재 콘텐츠 언어 설정
    case ActionTypesProjects.SET_CURRENT_LANGUAGE: {
      return {
        ...state,
        currentLanguage: action.payload.language,
      }
    }
    // 콘텐츠 목록 다시불러오기
    case ActionTypesProjects.SET_CONTENTS_LIST_RELOAD: {
      return {
        ...state,
        contentsListReload: action.payload.reload,
      }
    }
    // 콘텐츠 목록 로딩
    case ActionTypesProjects.SET_CONTENTS_LIST_LOADING: {
      return {
        ...state,
        contentsListLoading: action.payload.isLoading,
      }
    }
    // 콘텐츠 목록 초기화
    case ActionTypesProjects.RESET_CONTENTS_LIST: {
      return {
        ...state,
        contentsListInit: false,
        contentsListReload: false,
        contentsListLoading: false,
        contentsList: [],
        checkedContentsList: [],
        contentsPagination: {
          totalElements: 0,
          size: DEFAULT_CONTENTS_PAGE_SIZE,
          page: 0,
        },
        contentsWithProcessingMedia: [],
        mediaListProcessing: [],
      }
    }
    // 콘텐츠 목록 설정
    case ActionTypesProjects.SET_CONTENTS_LIST: {
      const newContentsList = action.payload.contentsList

      return {
        ...state,
        contentsList: newContentsList,
        contentsListReload: false,
        // contentsListLoading: false,
        contentsListInit: true,
        contentsPagination: {
          totalElements: action.payload.pagination.totalElements,
          size: action.payload.isPreserveCondition
            ? state.contentsPagination.size
            : action.payload.pagination.size,
          page: action.payload.isPreserveCondition
            ? state.contentsPagination.page
            : action.payload.pagination.page,
        },
        contentsSort: action.payload.isPreserveCondition
          ? state.contentsSort
          : action.payload.sort,
        contentsKeyword: action.payload.isPreserveCondition
          ? state.contentsKeyword
          : action.payload.keyword,
        contentsWithProcessingMedia:
          getContentsWithProcessingMedia(newContentsList),
        mediaListProcessing: getMediaProcessingList(
          getContentsWithProcessingMedia(newContentsList)
        ),
      }
    }
    // 콘텐츠 아이템 수정
    case ActionTypesProjects.UPDATE_CONTENTS_ITEM: {
      const updatedContentsList = state.contentsList
      const updatedContentsIdx = updatedContentsList.findIndex(
        (c) => c.uid === action.payload.contentsItem.uid
      )

      if (updatedContentsIdx > -1) {
        updatedContentsList[updatedContentsIdx] = action.payload.contentsItem
      }

      return {
        ...state,
        contentsList: updatedContentsList,

        contentsListReload: false,
        contentsListLoading: false,
        contentsWithProcessingMedia:
          getContentsWithProcessingMedia(updatedContentsList),
        mediaListProcessing: getMediaProcessingList(
          getContentsWithProcessingMedia(updatedContentsList)
        ),
      }
    }
    // 콘텐츠 아이템 삭제
    case ActionTypesProjects.DELETE_CONTENTS_ITEM: {
      const updatedContentsList = state.contentsList.filter(
        (c) => !action.payload.contentsItemUidList.includes(c.uid as string)
      )

      return {
        ...state,
        contentsList: updatedContentsList,
        contentsListReload: false,
        contentsListLoading: false,
        contentsPagination: {
          totalElements:
            state.contentsPagination.totalElements -
            action.payload.contentsItemUidList.length,
          size: state.contentsPagination.size,
          page: state.contentsPagination.page,
        },
        contentsWithProcessingMedia:
          getContentsWithProcessingMedia(updatedContentsList),
        mediaListProcessing: getMediaProcessingList(
          getContentsWithProcessingMedia(updatedContentsList)
        ),
      }
    }
    // 체크된 콘텐츠 목록 설정
    case ActionTypesProjects.SET_CHECKED_CONTENTS_LIST: {
      return {
        ...state,
        checkedContentsList: action.payload.checkedContentsList,
      }
    }
    // 추가
    case ActionTypesProjects.ADD_CONTENTS_TO_LIST: {
      const updatedContentsPagination = state.contentsPagination
      updatedContentsPagination.totalElements =
        updatedContentsPagination.totalElements + 1

      const updatedContents = [
        action.payload.contentsAdded,
        ...state.contentsList,
      ]

      return {
        ...state,
        contentsList: updatedContents,
        contentsWithProcessingMedia:
          getContentsWithProcessingMedia(updatedContents),
        mediaListProcessing: getMediaProcessingList(
          getContentsWithProcessingMedia(updatedContents)
        ),
      }
    }
    // 콘텐츠 정렬 설정
    case ActionTypesProjects.SET_CONTENTS_SORT: {
      if (action.payload.modelId) {
        const oldContentsSortKey =
          Cookies.get(CONTENTS_SORT_KEY) &&
          typeof JSON.parse(Cookies.get(CONTENTS_SORT_KEY) as string) ===
            'object'
            ? JSON.parse(Cookies.get(CONTENTS_SORT_KEY) as string)
            : {}

        oldContentsSortKey[action.payload.modelId] = action.payload.sort

        Cookies.set(CONTENTS_SORT_KEY, JSON.stringify(oldContentsSortKey), {
          expires: 365,
        })
      }

      const updatedContents = sortPaginateContentsList(
        state.filteredContentsList,
        action.payload.sort,
        state.flattenComponentList,
        state.currentLanguage,
        state.contentsPagination
      )

      return {
        ...state,
        contentsSort: action.payload.sort,
        contentsList: updatedContents,
        contentsWithProcessingMedia:
          getContentsWithProcessingMedia(updatedContents),
        mediaListProcessing: getMediaProcessingList(
          getContentsWithProcessingMedia(updatedContents)
        ),
      }
    }
    // 콘텐츠 Req 컴포넌트 설정
    case ActionTypesProjects.SET_CONTENTS_REQ_COMPONENTS: {
      return {
        ...state,
        reqComponents: action.payload.componentsArr,
      }
    }
    // 콘텐츠 Req 컴포넌트 설정 (Preview)
    case ActionTypesProjects.SET_CONTENTS_PREVIEW_REQ_COMPONENTS: {
      return {
        ...state,
        reqPreviewComponents: action.payload.componentsArr,
      }
    }
    // 액세스 목록 설정
    case ActionTypesProjects.SET_ACCESS_LIST: {
      return {
        ...state,
        accessList: action.payload.accessList,
      }
    }
    // 카테고리 목록 설정
    case ActionTypesProjects.SET_CATEGORIES_LIST: {
      return {
        ...state,
        categoriesListInit: true,
        categoriesList: action.payload.categoriesList,
      }
    }
    // 콘텐츠 Cell 설정
    case ActionTypesProjects.SET_CONTENTS_CELL_ITEM: {
      const updatedContentsList = JSON.parse(JSON.stringify(state.contentsList))
      const targetContents = updatedContentsList.find(
        (contents) => contents.uid === action.payload.contentsUid
      )
      const targetCell = targetContents
        ? targetContents.cellList.find(
            (cell) => cell.uid === action.payload.cellUid
          )
        : null
      const targetRelation = targetCell?.relationList.find(
        (r) => r.uid === action.payload.relationUid
      )

      targetRelation.title = action.payload?.title
      targetRelation.modelTitle = action.payload?.modelTitle

      return {
        ...state,
        contentsList: updatedContentsList,
        contentsWithProcessingMedia:
          getContentsWithProcessingMedia(updatedContentsList),
        mediaListProcessing: getMediaProcessingList(
          getContentsWithProcessingMedia(updatedContentsList)
        ),
      }
    }
    // 웹소켓 클라이언트 설정
    case ActionTypesProjects.SET_WS_CLIENT: {
      return {
        ...state,
        ws: action.payload.client,
      }
    }
    // 웹소켓 클라이언트 Sync 목록 설정
    case ActionTypesProjects.SET_WS_CLIENT_SYNC: {
      const actionType = action.payload.actionType
      let updatedSync: ProjectSyncInteraface[] = []

      if (!actionType) {
        return {
          ...state,
          wsSync:
            action.payload.sync && action.payload.sync.length
              ? state.wsSync.filter(
                  (ws) =>
                    action.payload.sync
                      .map((s) => s.memberId)
                      .indexOf(ws.memberId) === -1
                )
              : [],
        }
      }

      switch (actionType) {
        case 'SUBSCRIBE':
          updatedSync = [...state.wsSync, ...action.payload.sync]
          break
        case 'UNSUBSCRIBE':
          updatedSync = state.wsSync.filter(
            (ws) =>
              action.payload.sync
                .map((s) => s.memberId)
                .indexOf(ws.memberId) === -1
          )
          break
        case 'SELECT':
          updatedSync = state.wsSync.map((ws) => {
            const sync = action.payload.sync.find(
              (s) => s.memberId === ws.memberId
            )
            return sync ? sync : ws
          })
          break
        case 'UNSELECT':
          updatedSync = state.wsSync.map((ws) => {
            const sync = action.payload.sync.find(
              (s) => s.memberId === ws.memberId
            )
            return sync ? sync : ws
          })
          break
        default:
      }

      return {
        ...state,
        wsSync: updatedSync,
      }
    }
    default:
      return state
  }
}

export default projectsReducer
