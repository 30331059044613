import { uploadFileToS3 } from '@/api'
import { DeactivateModal, PasswordModal } from '@/components/Account'
import { AlertStatus } from '@/components/Common'
import {
  FILE_IMAGE_MAX_RES,
  FILE_MAX_SIZE_UPLOAD,
  LAYOUT_MODAL_LG_WIDTH,
} from '@/configs'
import { RootState, useAppDispatch } from '@/states'
import {
  setAccountModal,
  setAccountPasswordModal,
  updateProfile,
} from '@/states/actions'
import { FileResponseInterface } from '@/types'
import { SaveOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Col,
  Input,
  Modal,
  Row,
  Spin,
  Typography,
  Upload,
  message,
} from 'antd'
import ImgCrop from 'antd-img-crop'
import { UploadFile } from 'antd/es/upload/interface'
import { AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import * as Yup from 'yup'

const initialSettingsAccountFormValues = {
  name: '',
  email: '',
}

export const AccountModal = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { authState, modalsState } = useSelector(
    (state: RootState) => ({
      authState: state.auth,
      modalsState: state.modals,
    }),
    shallowEqual
  )

  const { init, me } = authState
  const { accountModal } = modalsState

  // State
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [previewUrl, setPreviewUrl] = useState<string>('')
  const [newsLetter, setNewsLetter] = useState<boolean>(false)

  useEffect(() => {
    if (me) {
      formikSettingsAccountForm.setFieldValue('name', me.name)
      formikSettingsAccountForm.setFieldValue('email', me.email)
      setNewsLetter(me.newsLetter)

      if (me.image) {
        setFileList([
          {
            uid: '-1',
            name: me.image.name,
            status: 'success',
            url: me.image.path,
          },
        ])
      }
    }
  }, [me])

  // Validation
  const validationsettingsAccountFormSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    email: Yup.string()
      .required(t('validation.required'))
      .email(t('validation.email')),
  })

  // Formik
  const formikSettingsAccountForm = useFormik({
    initialValues: initialSettingsAccountFormValues,
    validationSchema: validationsettingsAccountFormSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        setStatus(null)
        setLoading(true)

        const req = {
          name: values.name,
          email: values.email,
          imageId: null,
          newsLetter,
        }

        // 프로필 이미지 업로드
        if (fileList && fileList.length) {
          let imageId = me?.image?.id
          const projectThumbnail = fileList[0]

          // New Image
          if (projectThumbnail.status === 'done') {
            await uploadFileToS3(fileList, undefined, true).then((res) => {
              const resData = (res as AxiosResponse)
                .data as FileResponseInterface[]
              imageId = resData[0].fileId
            })
          }

          // @ts-ignore
          req.imageId = imageId
        }

        await dispatch(updateProfile(req))
        await dispatch(setAccountModal(false))
        await setLoading(false)
        await message.success(t('saveSuccess'))
      } catch (e) {
        setLoading(false)
        setSubmitting(false)
        // @ts-ignore
        setStatus(e.response.data.error)
      }
    },
  })

  // Upload props
  const uploadProps = {
    onRemove: (file) => {
      setFileList([])
    },
    beforeUpload: (file) => {
      const reader = new FileReader()

      reader.onload = (e) => {
        if (e.target && e.target.result) {
          // 파일 크기 체크
          if (file.size > FILE_MAX_SIZE_UPLOAD * 1024) {
            message.error(
              t('error.fileTooBig', {
                name: file.name,
                size: FILE_MAX_SIZE_UPLOAD / 1024,
              })
            )
            return false
          }

          // 이미지의 경우 해상도 확인
          if (file.type.indexOf('image') >= 0) {
            const image = new Image()

            // @ts-ignore
            image.src = e.target.result
            image.onload = function () {
              // @ts-ignore
              const height = this.height
              // @ts-ignore
              const width = this.width

              if (width > FILE_IMAGE_MAX_RES || height > FILE_IMAGE_MAX_RES) {
                message.error(
                  t('error.imageSizeTooBig', {
                    name: file.name,
                    px: FILE_IMAGE_MAX_RES,
                  })
                )
                return false
              } else {
                const fileAdded = file
                fileAdded.status = 'done'
                // @ts-ignore
                fileAdded.url = e.target.result
                setFileList([fileAdded])
              }

              return true
            }
          } else {
            const fileAdded = file
            fileAdded.status = 'done'
            fileAdded.url = e.target.result
            setFileList([fileAdded])
          }
        }
      }
      reader.readAsDataURL(file)

      return false
    },
    fileList,
  }

  /**
   * 이미지 프리뷰
   * @param file
   */
  const onHandlePreview = (file) => {
    setPreviewUrl(file.url)
    setShowPreview(true)
  }

  return (
    <Modal
      title={t('myAccount')}
      open={accountModal}
      maskClosable={false}
      centered
      width={LAYOUT_MODAL_LG_WIDTH}
      footer={
        <div className="flex justify-between">
          <Button onClick={() => dispatch(setAccountModal(false))}>
            {t('close')}
          </Button>
          <Button
            type={'primary'}
            icon={<SaveOutlined />}
            onClick={() => formikSettingsAccountForm.submitForm()}
            disabled={loading}
            loading={loading}>
            {t('save')}
          </Button>
        </div>
      }
      onCancel={() => dispatch(setAccountModal(false))}>
      {init ? (
        <div>
          <form onSubmit={formikSettingsAccountForm.handleSubmit} method="POST">
            {/* Setting body: 시작 */}
            <div className="space-y-20">
              <div>
                <div className="">
                  <AlertStatus
                    status={formikSettingsAccountForm.status}
                    onClick={() =>
                      formikSettingsAccountForm.setStatus(null)
                    }></AlertStatus>
                  <div className={'space-y-6'}>
                    <div>
                      <Typography.Title level={5} className={'mt-0 mb-3'}>
                        {t('myInformation')}
                      </Typography.Title>
                    </div>
                    <Row>
                      <Col xs={24} lg={8}>
                        <div className="block">
                          <label htmlFor="" className="">
                            <div className={'mb-2'}>{t('photo')}</div>
                            <ImgCrop
                              modalTitle={t('editImage')}
                              modalOk={t('confirm')}
                              modalCancel={t('cancel')}>
                              <Upload
                                {...uploadProps}
                                accept="image/*"
                                listType={'picture-card'}
                                maxCount={1}
                                onPreview={onHandlePreview}>
                                {fileList && fileList.length
                                  ? t('editPhoto')
                                  : t('addPhoto')}
                              </Upload>
                            </ImgCrop>
                          </label>
                        </div>
                      </Col>
                      <Col xs={24} lg={16}>
                        <div className="space-y-4">
                          <div className="block">
                            <label
                              htmlFor="settingsAccountFormEmail"
                              className="">
                              <div className={'mb-2'}>{t('email')}</div>
                              <p>{me?.email}</p>
                            </label>
                          </div>
                          <div className="block">
                            <label
                              htmlFor="settingsAccountFormName"
                              className="">
                              <div className={'mb-2'}>
                                {t('name')}{' '}
                                <span className="text-red-500">*</span>
                              </div>
                              <Input
                                id={'settingsAccountFormName'}
                                name="name"
                                type={'text'}
                                onChange={
                                  formikSettingsAccountForm.handleChange
                                }
                                value={formikSettingsAccountForm.values.name}
                              />
                            </label>
                            {formikSettingsAccountForm.touched.name &&
                            formikSettingsAccountForm.errors.name ? (
                              <p className="my-1 text-xs text-red-500">
                                {formikSettingsAccountForm.errors.name}
                              </p>
                            ) : null}
                          </div>
                          <div className="block">
                            <label>
                              <div className={'mb-2'}>{t('password')}</div>
                              <div>
                                <Button
                                  onClick={() =>
                                    dispatch(setAccountPasswordModal(true))
                                  }>
                                  {t('changePassword')}
                                </Button>
                              </div>
                            </label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Modal
                    open={showPreview}
                    maskClosable={false}
                    centered
                    footer={null}
                    onCancel={() => setShowPreview(false)}>
                    <img src={previewUrl} className={'w-full'} />
                  </Modal>
                </div>
              </div>
              {!process.env.REACT_APP_PROJECT_ID ? (
                <div>
                  <div className="">
                    <div className={'space-y-6'}>
                      <div>
                        <Typography.Title level={5} className={'mt-0 mb-3'}>
                          {t('notificationsSettings')}
                        </Typography.Title>
                      </div>
                      <div>
                        <Checkbox
                          checked={newsLetter}
                          onChange={(e) => setNewsLetter(!newsLetter)}>
                          <div>
                            <p className="mb-0">
                              {t('notificationsSettingsNewsLetter')}
                            </p>
                            <p className="mb-0 text-xs text-gray-600">
                              {t('auth.signup.emailNotificationDesc')}
                            </p>
                          </div>
                        </Checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div>
                <div className="">
                  <div className={'space-y-6'}>
                    <div>
                      <Typography.Title level={5} className={'mt-0 mb-3'}>
                        {t('accountStatus')}
                      </Typography.Title>
                    </div>
                    <DeactivateModal />
                  </div>
                </div>
              </div>
            </div>
            {/* Setting body: 끝 */}
          </form>
          <PasswordModal />
        </div>
      ) : (
        <div className={'flex justify-center items-center h-screen'}>
          <Spin />
        </div>
      )}
    </Modal>
  )
}
