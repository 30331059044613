import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Card, Spin, Tooltip, Anchor, Row, Col, Button, message } from 'antd'
const { Link } = Anchor
import { CopyOutlined } from '@ant-design/icons'
import Prism from 'prismjs'
import { RootState, useAppDispatch } from '@/states'
import { Typescript } from '@/components/Developers'
import { copyStr } from '@/utils/helpers'
import { DevelopersTab } from '@/components/Layout/TabDevelopers'
import { setCurrentModel } from '@/states/actions'
import NotFound from '../404'

const ProjectsDevelopersTypescript = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  if (process.env.REACT_APP_HIDE_DEVELOPERS === '1') {
    return <NotFound />
  }

  // State
  const [commonTypeStr, setCommonTypeStr] = useState<string>('')

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject, modelList } = projectsState

  // Effect
  useEffect(() => {
    dispatch(setCurrentModel(null))
  }, [])

  useEffect(() => {
    if (currentProject) {
      let langMap = ''
      let langMapDesc = ''

      currentProject?.languageList.forEach((lang, lIdx) => {
        langMap += `${lang}?: string${
          lIdx < currentProject.languageList.length - 1
            ? `
  `
            : ''
        }`

        langMapDesc += `${lang}?: {
    name?: string
    description?: string
  }${
    lIdx < currentProject.languageList.length - 1
      ? `
  `
      : ''
  }`
      })

      let commonTypescript = `export interface LanguageMap {
  ${langMap}
}
    
export interface LanguageMapDesc {
  ${langMapDesc}
}
    
export interface DateInterface {
  createdAt?: string | null
  deletedAt?: string | null
  editedAt?: string | null
}

export type FileType = 'IMAGE' | 'VIDEO' | 'AUDIO' | 'PDF' | 'FILE'
export type MediaType = 'FILE' | 'URL'

export interface FileMetaInterface {
  width?: number
  height?: number
  size: string
  type: string
}
  
export interface FileInterface {
  _id: number
  name: string
  path: string
  type: string
  meta: FileMetaInterface
  thumbnailList?: FileInterface[]
  date: DateInterface
}

export interface MediaInterface {
  _id: number
  languageMap: LanguageMapDesc
  fileType: FileType
  mediaType: MediaType
  file: FileInterface
  value?: string
}

export interface CategoryInterface {
  _id: number
  order: number
  languageMap: LanguageMap
}

export interface RelationInterface {
  _id: number
  uid: string
  languageMap: LanguageMap
}`

      setCommonTypeStr(commonTypescript)

      setTimeout(() => {
        Prism.highlightAll()
      })
    }
  }, [currentProject])

  /**
   * 문자 복사
   * @param str
   */
  const copyCommonType = (str) => {
    if (copyStr(str)) {
      message.success(t('copied'))
    }
  }

  return (
    <>
      {currentProject && (
        <Helmet>
          <title>
            {t('typescript')} · {t('developers')} · {currentProject.name} ·{' '}
            {process.env.REACT_APP_NAME}
          </title>
        </Helmet>
      )}
      {currentProject ? (
        <div className={'space-y-10 py-5 lg:py-7'}>
          {/* 프로젝트 Tab: 시작 */}
          <DevelopersTab />
          {/* 프로젝트 Tab: 끝 */}
          <div>
            <h1
              className={'mb-2 text-lg leading-7 flex items-center space-x-4'}>
              {t('typescript')}
            </h1>
            <p className="mb-0">{t('typescriptDescription')}</p>
          </div>
          <Row gutter={28}>
            <Col xs={24} lg={18}>
              <Card>
                <div className="p-3 pb-20">
                  {/* 본문: 시작 */}
                  <div className="space-y-20">
                    <div>
                      <h2 className="text-2xl font-medium mb-0 break-all">
                        {t('commonInterface')}
                      </h2>
                      <p>{t('commonInterfaceDesc')}</p>
                      <div className="flex justify-between items-center">
                        <h4 className="font-medium">Interface</h4>
                        <Tooltip
                          title={t('clickToCopy')}
                          className="cursor-help">
                          <Button
                            type={'text'}
                            icon={<CopyOutlined />}
                            onClick={() => copyCommonType(commonTypeStr)}
                          />
                        </Tooltip>
                      </div>
                      <div className="relative">
                        <pre>
                          <code className="language-javascript">
                            {`${commonTypeStr}`}
                          </code>
                        </pre>
                      </div>
                    </div>
                    {modelList.map((model, mIdx) => (
                      <Typescript key={model.devKey} model={model} />
                    ))}
                  </div>
                  {/* 본문: 끝 */}
                </div>
              </Card>
            </Col>
            <Col span={6} className="!hidden lg:!block z-50 bg-gray-200">
              <div className="px-3">
                {/* Anchor: 시작 */}
                <Anchor
                  // @ts-ignore
                  getContainer={() => document.getElementById('layout-main')}
                  offsetTop={0}
                  className="py-3">
                  {modelList.map((model) => (
                    <Link
                      key={model.devKey}
                      href={`#${model.devKey}`}
                      title={`${
                        model.languageMap[currentProject.defaultLang]
                      } (${model.devKey})`}
                    />
                  ))}
                </Anchor>
                {/* Anchor: 끝 */}
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div className={'flex justify-center items-center h-screen'}>
          <Spin />
        </div>
      )}
    </>
  )
}

export default ProjectsDevelopersTypescript
