import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Segmented } from 'antd'
import { AppstoreOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { RootState, useAppDispatch } from '@/states'
import { setMediaListViewType } from '@/states/actions'
import { MediaListViewType } from '@/types'

export const ListViewType = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State
  const [viewType, setViewType] = useState('')

  // State (Redux)
  const { layoutState } = useSelector(
    (state: RootState) => ({
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const { mediaListView } = layoutState

  // Effect
  useEffect(() => {
    setViewType(mediaListView)
  }, [mediaListView])

  return (
    <Segmented
      options={[
        {
          value: 'list',
          icon: <UnorderedListOutlined />,
        },
        {
          value: 'card',
          icon: <AppstoreOutlined />,
        },
      ]}
      value={viewType}
      onChange={(value) =>
        dispatch(setMediaListViewType(value as MediaListViewType))
      }
    />
  )
}
