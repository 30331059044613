import { ProjectListViewType, MediaListViewType } from '@/types'

export enum ActionTypesLayout {
  TOGGLE_BUSY = 'TOGGLE_BUSY', // BUSY 설정
  TOGGLE_GNB = 'TOGGLE_GNB', // GNB 설정
  TOGGLE_GNB2 = 'TOGGLE_GNB2', // GNB 2 설정
  TOGGLE_GNB3 = 'TOGGLE_GNB3', // GNB 3 설정
  TOGGLE_STRETCH = 'TOGGLE_STRETCH', // 늘리기/줄이기 설정
  TOGGLE_USER_PROFILE_TAB = 'TOGGLE_USER_PROFILE_TAB', // 유저 프로필 설정
  SET_ACCOUNT_MENU = 'SET_ACCOUNT_MENU', // 계정 메뉴 설정
  SET_MORE_MENU = 'SET_MORE_MENU', // 더보기 메뉴 설정
  SET_SIDEBAR = 'SET_SIDEBAR', // 사이드바 설정
  SET_SPLIT_SIZE = 'SET_SPLIT_SIZE', // Split 크기 설정
  SET_PROJECT_LIST_VIEW_TYPE = 'SET_PROJECT_LIST_VIEW_TYPE', // 프로젝트 목록 보기 타입 설정
  SET_MEDIA_LIST_VIEW_TYPE = 'SET_MEDIA_LIST_VIEW_TYPE', // 미디어 콘텐츠 보기 타입 설정
}

export type ActionsLayout =
  | LayoutToggleBusy
  | LayoutToggleGnb
  | LayoutToggleGnb2
  | LayoutToggleGnb3
  | LayoutStretch
  | LayoutToggleUserProfileTab
  | LayoutSetAccountMenu
  | LayoutSetMoreMenu
  | LayoutSetSidebar
  | LayoutSetSplitSize
  | LayoutSetProjectListViewType
  | LayoutSetMediaListViewType

export interface LayoutToggleBusy {
  type: ActionTypesLayout.TOGGLE_BUSY
  payload: {
    isBusy: boolean
  }
}

export interface LayoutToggleGnb {
  type: ActionTypesLayout.TOGGLE_GNB
  payload: {
    isCollapse: boolean
  }
}

export interface LayoutToggleGnb2 {
  type: ActionTypesLayout.TOGGLE_GNB2
  payload: {
    isCollapse: boolean
  }
}

export interface LayoutToggleGnb3 {
  type: ActionTypesLayout.TOGGLE_GNB3
  payload: {
    isCollapse: boolean
  }
}

export interface LayoutStretch {
  type: ActionTypesLayout.TOGGLE_STRETCH
  payload: {
    isStrecth: boolean
  }
}

export interface LayoutToggleUserProfileTab {
  type: ActionTypesLayout.TOGGLE_USER_PROFILE_TAB
  payload: {
    isOpen: boolean
  }
}

export interface LayoutSetAccountMenu {
  type: ActionTypesLayout.SET_ACCOUNT_MENU
  payload: {
    isOpen: boolean
  }
}

export interface LayoutSetMoreMenu {
  type: ActionTypesLayout.SET_MORE_MENU
  payload: {
    isOpen: boolean
  }
}

export interface LayoutSetSidebar {
  type: ActionTypesLayout.SET_SIDEBAR
  payload: {
    isOpen: boolean
  }
}

export interface LayoutSetSplitSize {
  type: ActionTypesLayout.SET_SPLIT_SIZE
  payload: {
    splitSize: number[]
  }
}

export interface LayoutSetProjectListViewType {
  type: ActionTypesLayout.SET_PROJECT_LIST_VIEW_TYPE
  payload: {
    viewType: ProjectListViewType
  }
}

export interface LayoutSetMediaListViewType {
  type: ActionTypesLayout.SET_MEDIA_LIST_VIEW_TYPE
  payload: {
    viewType: MediaListViewType
  }
}
