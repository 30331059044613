import { RootState } from '@/states'
import { copyStr } from '@/utils/helpers'
import { CopyOutlined } from '@ant-design/icons'
import { Button, Collapse, Tag, Tooltip, message } from 'antd'
import Prism from 'prismjs'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

function MediaUpload() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject, modelList } = projectsState

  /**
   * 코드 Prism 스타일 적용
   */
  const highlightCode = () => {
    setTimeout(() => {
      Prism.highlightAll()
    }, 0)
  }

  /**
   * API 주소 복사
   * @param str
   */
  const copyApi = (str) => {
    if (copyStr(str)) {
      message.success(t('copied'))
    }
  }

  return (
    <div className="space-y-4">
      <div>
        <h2 className="text-2xl font-medium break-all">
          미디어 콘텐츠 업로드 (Upload Media Contents)
        </h2>
        <p>
          <Link
            to={`/projects/${currentProject.uid}/media`}
            className="text-primary">
            미디어 콘텐츠
          </Link>
          에 직접 업로드를 할 수 있는 공통 기능입니다. Presigned URL을 발급받아
          S3버킷에 파일을 업로드합니다. 아래 요청을 순차적으로 실행하세요.
        </p>
      </div>
      <Collapse
        accordion
        defaultActiveKey={[]}
        onChange={() => highlightCode()}>
        <Collapse.Panel header={`1. POST - S3 presigned URL 받기`} key="media1">
          <div className="space-y-10">
            <p>
              file name으로 Presigned URL생성을 위한 요청을 전송합니다. 실제
              파일을 업로드할 <Tag>url</Tag>
              <Tag>metadata</Tag>
              <Tag>key</Tag>를 받게됩니다.
            </p>
            <div>
              <div className="flex justify-between items-center">
                <h4 className="font-medium">URL</h4>
                <Tooltip title={t('clickToCopy')} className="cursor-help">
                  <Button
                    type={'text'}
                    icon={<CopyOutlined />}
                    onClick={() =>
                      copyApi(`${process.env.REACT_APP_API_URL}api/projects/
              ${currentProject?.uid}
              /files/access`)
                    }
                  />
                </Tooltip>
              </div>
              <div className="relative">
                <pre>
                  <code className="language-js">
                    {`POST ${process.env.REACT_APP_API_URL}api/projects/${currentProject?.uid}/files/access
Content-Type: application/json
Access-Token: {AccessToken}
X-Forwarded-Host: {AllowedHost}`}
                  </code>
                </pre>
              </div>
            </div>
            <div>
              <h4 className="font-medium">Request (raw)</h4>
              <pre>
                <code className="language-js">{`94416470_p0.jpg`}</code>
              </pre>
            </div>
            <div className="space-y-10">
              <div>
                <h5 className="font-medium">Response (Example)</h5>
                <div className="relative">
                  <pre>
                    <code className="language-javascript">
                      {`HTTP 200 OK
Content-Type: application/json;charset=UTF-8
{
    "url": "https://memex-file.s3.ap-northeast-2.amazonaws.com/memex/TEMP/0ee04a2a-7c1a-498b-a5c5-f2c5f0ab8ead?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240123T103000Z&X-Amz-SignedHeaders=host%3Bx-amz-meta-create-by%3Bx-amz-meta-name&X-Amz-Credential=AKIA2EAU5PPI4MJ3TAFE%2F20240123%2Fap-northeast-2%2Fs3%2Faws4_request&X-Amz-Expires=900&X-Amz-Signature=dc290e488e54d310b5d390c21a2b4e9c107bf89e8cde88b4fbf9de66ea56e32f",
    "metadata": {
        "x-amz-meta-name": "OTQ0MTY0NzBfcDAuanBn",
        "x-amz-meta-create-by": "1"
    },
    "key": "memex/TEMP/0ee04a2a-7c1a-498b-a5c5-f2c5f0ab8ead"
}`}
                    </code>
                  </pre>
                </div>
              </div>
            </div>
          </div>
        </Collapse.Panel>
        <Collapse.Panel header={`2. PUT - S3 presigned 업로드`} key="media2">
          <div className="space-y-10">
            <p>
              <strong>1. POST - S3 presigned URL 받기</strong> 에서 받은{' '}
              <Tag>url</Tag>로 파일을 전송합니다.
              <br />
              전송시 Header에 metadata (<Tag>x-amz-meta-create-by</Tag>
              <Tag>x-amz-meta-name</Tag>)를 넣고 전송합니다.
            </p>
            <div>
              <div className="flex justify-between items-center">
                <h4 className="font-medium">URL</h4>
                <Tooltip title={t('clickToCopy')} className="cursor-help">
                  <Button
                    type={'text'}
                    icon={<CopyOutlined />}
                    onClick={() =>
                      copyApi(`${process.env.REACT_APP_API_URL}api/projects/
              ${currentProject?.uid}
              /files/access`)
                    }
                  />
                </Tooltip>
              </div>
              <div className="relative">
                <pre>
                  <code className="language-js">
                    {`PUT {URL_FROM_Presigned_URL_받기}
Content-Type: application/json
Access-Token: {AccessToken}
X-Forwarded-Host: {AllowedHost}
x-amz-meta-create-by: {X_AMZ_META_CREAT_BY}
x-amz-meta-name: {X_AMZ_META_NAME}`}
                  </code>
                </pre>
              </div>
            </div>
            <div>
              <h4 className="font-medium">Request (binary)</h4>
              <pre>
                <code className="language-js">{`File`}</code>
              </pre>
            </div>
            <div className="space-y-10">
              <div>
                <h5 className="font-medium">Response (Example)</h5>
                <div className="relative">
                  <pre>
                    <code className="language-javascript">{`HTTP 200 OK`}</code>
                  </pre>
                </div>
              </div>
            </div>
          </div>
        </Collapse.Panel>
        <Collapse.Panel header={`3. POST - 파일 정보 저장`} key="media3">
          <div className="space-y-10">
            <p>
              <strong>1. POST - S3 presigned URL 받기</strong> 에서 받은{' '}
              <Tag>key</Tag>를 이용해 파일 정보를 저장합니다.
            </p>
            <div>
              <div className="flex justify-between items-center">
                <h4 className="font-medium">URL</h4>
                <Tooltip title={t('clickToCopy')} className="cursor-help">
                  <Button
                    type={'text'}
                    icon={<CopyOutlined />}
                    onClick={() =>
                      copyApi(`${process.env.REACT_APP_API_URL}api/projects/
              ${currentProject?.uid}
              /files/upload`)
                    }
                  />
                </Tooltip>
              </div>
              <div className="relative">
                <pre>
                  <code className="language-js">
                    {`POST ${process.env.REACT_APP_API_URL}api/projects/${currentProject?.uid}/files/upload
Content-Type: application/json
Access-Token: {AccessToken}
X-Forwarded-Host: {AllowedHost}`}
                  </code>
                </pre>
              </div>
            </div>
            <div>
              <h4 className="font-medium">Request (raw)</h4>
              <pre>
                <code className="language-js">{`memex/TEMP/dcc0c4de-4c1f-4019-a998-4b94e849bc43`}</code>
              </pre>
            </div>
            <div className="space-y-10">
              <div>
                <h5 className="font-medium">Response (Example)</h5>
                <div className="relative">
                  <pre>
                    <code className="language-javascript">
                      {`HTTP 200 OK
Content-Type: application/json;charset=UTF-8
{
    "type": "FILE",
    "fileId": 57339,
    "value": "94416470_p0.jpg",
    "languageMap": null
}`}
                    </code>
                  </pre>
                </div>
              </div>
            </div>
          </div>
        </Collapse.Panel>
        <Collapse.Panel header={`4. POST - 미디어 생성`} key="media4">
          <div className="space-y-10">
            <p>
              <strong>3. POST - 파일 정보 저장</strong> 에서 받은 file
              object정보를 전송하여 Media Contents Record를 생성합니다.
            </p>
            <div>
              <div className="flex justify-between items-center">
                <h4 className="font-medium">URL</h4>
                <Tooltip title={t('clickToCopy')} className="cursor-help">
                  <Button
                    type={'text'}
                    icon={<CopyOutlined />}
                    onClick={() =>
                      copyApi(`${process.env.REACT_APP_API_URL}api/projects/
              ${currentProject?.uid}
              /media`)
                    }
                  />
                </Tooltip>
              </div>
              <div className="relative">
                <pre>
                  <code className="language-js">
                    {`POST ${process.env.REACT_APP_API_URL}api/projects/${currentProject?.uid}/media
Content-Type: application/json
Access-Token: {AccessToken}
X-Forwarded-Host: {AllowedHost}`}
                  </code>
                </pre>
              </div>
            </div>
            <div>
              <h4 className="font-medium">Request (JSON)</h4>
              <pre>
                <code className="language-js">{`{
    "type" : "FILE",
    "fileId" : 57339,
    "value" : "94416470_p0.jpg",
    "languageMap" : {
        "KO" : {
            "name": "모나 최고",
            "description" : "몬드의 미녀"
        }
    }
}`}</code>
              </pre>
              <div className="w-full overflow-x-auto">
                <table className="w-max lg:w-full table-fixed border-collapse border border-slate-400">
                  <colgroup>
                    <col width={'20%'} />
                    <col width={'30%'} />
                    <col width={'80px'} />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th className="border border-slate-300 p-3">
                        {t('name')}
                      </th>
                      <th className="border border-slate-300 p-3">
                        {t('type')}
                      </th>
                      <th className="border border-slate-300 p-3">
                        {t('optionsType.required.name')}
                      </th>
                      <th className="border border-slate-300 p-3">
                        {t('description')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border border-slate-300 p-3">type</td>
                      <td className="border border-slate-300 p-3 ">
                        <Tag>FILE</Tag>
                      </td>
                      <td className="border border-slate-300 p-3 text-center">
                        O
                      </td>
                      <td className="border border-slate-300 p-3">
                        FILE 로 고정
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-slate-300 p-3">fileId</td>
                      <td className="border border-slate-300 p-3 ">
                        <Tag>number</Tag>
                      </td>
                      <td className="border border-slate-300 p-3 text-center">
                        O
                      </td>
                      <td className="border border-slate-300 p-3">
                        <strong>3. POST - 파일 정보 저장</strong> 에서 받은
                        fileId
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-slate-300 p-3">value</td>
                      <td className="border border-slate-300 p-3 align-top">
                        <Tag>string</Tag>
                      </td>
                      <td className="border border-slate-300 p-3 text-center">
                        O
                      </td>
                      <td className="border border-slate-300 p-3">
                        파일명
                        <br />
                        <strong>1. POST - S3 presigned URL 받기</strong> 호출 시
                        사용한 파일명
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-slate-300 p-3">
                        languageMap
                      </td>
                      <td className="border border-slate-300 p-3 align-top">
                        <Tag>object</Tag>
                      </td>
                      <td className="border border-slate-300 p-3 text-center">
                        O
                      </td>
                      <td className="border border-slate-300 p-3">
                        언어별로 name, description 을 지정합니다.
                        <br />
                        name, description은 비워도 되지만 languageMap object는
                        반드시 전달해야합니다.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="space-y-10">
              <div>
                <h5 className="font-medium">Response (Example)</h5>
                <div className="relative">
                  <pre>
                    <code className="language-javascript">
                      {`HTTP 200 OK
Content-Type: application/json;charset=UTF-8
{
    "id": 55584,
    "mediaType": "FILE",
    "fileType": "IMAGE",
    "file": {
        "id": 57339,
        "type": "IMAGE",
        "name": "94416470_p0.jpg",
        "path": "https://d1s4x98zvrnxpf.cloudfront.net/memex/IMAGE/2024/01/23/2024012319310577dd71a2-aab2-472d-92f4-a45d1c6361ce.jpg?Expires=2021366207&Signature=PohFJjClOckT9NOOXOpvWkvN8y0lbgeZoRhOHWQJ1cNeQlq7RX7HNk231X7CPqLG3iR8gRJvJunUg6CYUCubvd4OASkCtW5aIYMX-RNlcd1PLt2gx4cFDJkzbdcVcITBwgUpd5nUyOY-V~YKeQ5ddgI8g7li5Gr7LzxEWiIqfVnjWWWAnhjb5F~3Ol1rAkQBy7YLhBwLEB5pvnWok-AL2cXFKO~52yqk31wzsaPFkSf4sHtiQMLIa1shiraHKjA-FxoygjeskwahD70ZOaN7v2M-8AeFNxpTJIO3AnD72hwFsrTCyN1bB90~m1VUmYqluWr7~A9WWY4jyrPX~sNRew__&Key-Pair-Id=K2ACQX3DHH72RZ",
        "meta": {
            "size": "1 MB",
            "contentLength": 1372423,
            "type": "image/jpeg",
            "height": 2997,
            "width": 1500
        },
        "thumbnailList": [
            {
                "id": 163770,
                "path": "https://d1s4x98zvrnxpf.cloudfront.net/memex/THUMBNAIL/LARGE/2024/01/23/20240123193109de942a67-e421-4663-ad2b-24e35d474f02.jpg?Expires=2021366207&Signature=mUZwaGI6OTJL1Ry5GM8Hg7tFvUptC-9GVd0rkwNdZwODAPAGzWT91IrCchkbGi0Udnr~59kyFjubWS6IJUrbrSr18c3lPEO5Q6SqpkzX9XiKBh3SriGSqJJD4XExXMMVAKEnqq4vQIyWEDpBpPTlaqmc4rKZmjppFiIpiOiW2Vljvg10fFMu6ZDn2oI0Ek4wupdjKB~PmfviFbeFMOjzzD00xu0nrT9K0EI1-~Hbe4N2CiXqmGGWDZ3fxJJwXv6SFSr94PorSxdb~n0n50FAogzTL3ETTV1HtnkAs2Em4IKVLbEmMU-qXCGkL8966y0MyNHV7aEIEpvFtERKHsMWiA__&Key-Pair-Id=K2ACQX3DHH72RZ",
                "type": "LARGE",
                "meta": {
                    "size": "128 KB",
                    "contentLength": 131626,
                    "type": "image/jpeg",
                    "height": 1920,
                    "width": 960
                }
            },
            {
                "id": 163772,
                "path": "https://d1s4x98zvrnxpf.cloudfront.net/memex/THUMBNAIL/SMALL/2024/01/23/20240123193109fa76761c-d62e-4eb0-855c-7acce8c76b05.jpg?Expires=2021366207&Signature=XmTYYyJ6RJK6OOrzjy9Lf6h0jYr~LRGtuC6ftowMyim1Mnn~MutJjOn-0EdYwTD8CxLaK4XoV~HwgmwLdWSlhUBUMs3eAucwSJYscRhc2lHGi8Tux3yc0Q1-nDFZ2jBaQH6UvpdhBd06rjM7sBySxwgzdnx79N-mnN4UXz~3J7Ae1i9RerfDUXLnjclaN~GofdI8-i2FUmxtuc8HPS8zMZywPoXvRBRmswq0aSoytrR7oRhDZJSwJmoN0PO~xxIM~-wCgitcHoUx65rNbfNpyjUAPE8o057ghlnm1-kmQ9VlfMzI8RjV85VvioIow-l~seAF2OIDEHAC8A7ii-qkjA__&Key-Pair-Id=K2ACQX3DHH72RZ",
                "type": "SMALL",
                "meta": {
                    "size": "5 KB",
                    "contentLength": 5603,
                    "type": "image/jpeg",
                    "height": 200,
                    "width": 100
                }
            },
            {
                "id": 163771,
                "path": "https://d1s4x98zvrnxpf.cloudfront.net/memex/THUMBNAIL/MEDIUM/2024/01/23/2024012319310904cc39c4-d27f-46fd-a70c-9e97a9039482.jpg?Expires=2021366207&Signature=aUXcNp5ipbv~Y3Ju~VRz~PDrs-LMvO6paI2HolJdzEFT3EPU9SXvx0TSB0ASdXZdhwc3gevKTB3d1wLaBv6wPc6iJbYU1Fkl2ds~GTrLUNXhr4kKiCAvcsm9y5Ksfs4hgJ2g~lSLHKudrHwjK~~zlTV0D9anucNE0Z-QqtvA7M-osgLfbmpDwcr5XyjnkQZCgFUf1oC8h899d8aDHBX~gJ3oXCn5wc5osjjnsrGDRSL7JqnEkoUx~sJQZvmnQUaBYJBVKtXPNQzSXWyymV2B6uXOlLuvlP6PKavDzxnjrmup-QDStMt9fNQTfa1a7zXS38TPKlXdaQKeOejjKBWZ4g__&Key-Pair-Id=K2ACQX3DHH72RZ",
                "type": "MEDIUM",
                "meta": {
                    "size": "30 KB",
                    "contentLength": 31708,
                    "type": "image/jpeg",
                    "height": 720,
                    "width": 360
                }
            }
        ]
    },
    "value": "94416470_p0.jpg",
    "languageMap": {
        "KO": {
            "name": "모나 최고",
            "description": "몬드의 미녀"
        }
    }
}`}
                    </code>
                  </pre>
                </div>
              </div>
              <div>
                <h5 className="font-medium">Response</h5>
                <p>Image의 경우 thumbnailList정보를 같이 생성하게됩니다.</p>
              </div>
            </div>
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  )
}

export default MediaUpload
