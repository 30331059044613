import {
  deleteMedia,
  deleteMultiMedia,
  getMedia,
  getMediaListSearch,
  getProjectUsage,
} from '@/api'
import {
  ListViewType,
  MediaFormModal,
  MediaThumb,
  UploadModal,
} from '@/components/Media'
import { DEFAULT_MEDIA_PAGE_SIZE, availableFileTypes } from '@/configs'
import { RootState, useAppDispatch } from '@/states'
import {
  setCheckedMediaList,
  setMediaFormModal,
  setMediaList,
} from '@/states/actions'
import {
  FileType,
  MediaInterface,
  MediaType,
  PaginationInterface,
} from '@/types'
import { checkIfMediaProcessing } from '@/utils/helpers'
import {
  DeleteFilled,
  DownloadOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import {
  Button,
  Card,
  Checkbox,
  Col,
  Dropdown,
  Empty,
  Input,
  Menu,
  Modal,
  Pagination,
  Row,
  Select,
  Spin,
  Table,
  message,
} from 'antd'
import axios from 'axios'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
const { Option } = Select

let interval

export const MediaList = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // Params
  const [searchParams, setSearchParams] = useSearchParams()

  // State
  const [loading, setLoading] = useState<boolean>(true)
  const [viewType, setViewType] = useState('')
  const [q, setQ] = useState<string>('')
  const [sort, setSort] = useState<string>('DATE_CREATE.DESC')
  const [filter, setFilter] = useState<FileType | MediaType | ''>('')
  const [pagination, setPagination] = useState<PaginationInterface>({
    totalElements: 0,
    size: searchParams.get('size')
      ? Number(searchParams.get('size'))
      : DEFAULT_MEDIA_PAGE_SIZE,
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
  })

  // State (Redux)
  const { projectsState, layoutState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const { currentProject, mediaList, checkedMediaList } = projectsState
  const { mediaListView } = layoutState

  // Effect
  useEffect(() => {
    if (currentProject) {
      getMediaList(pagination, sort, filter, q)
    }
  }, [currentProject])

  useEffect(() => {
    setViewType(mediaListView)
  }, [mediaListView])

  /**
   * 체크하기
   */
  const onToggleCheckAll = (e) => {
    dispatch(setCheckedMediaList(e.target.checked ? mediaList : []))
  }

  /**
   * 콘텐츠 체크
   * @param e
   */
  const onToggleCheck = (e, media) => {
    const isAlready = checkedMediaList.map((cc) => cc.id).includes(media.id)

    dispatch(
      setCheckedMediaList(
        !isAlready
          ? [...checkedMediaList, media]
          : checkedMediaList.filter((cc) => cc.id !== media.id)
      )
    )
  }

  // Table columns
  const tableCols = [
    {
      title: (
        <div>
          <Checkbox
            indeterminate={
              checkedMediaList.length > 0 &&
              checkedMediaList.length !== mediaList.length
            }
            checked={
              checkedMediaList.length > 0 &&
              checkedMediaList.length === mediaList.length
            }
            onChange={onToggleCheckAll}
            disabled={currentProject?.role === 'VIEWER'}
          />
        </div>
      ),
      render: (media) => (
        <div>
          <Checkbox
            checked={checkedMediaList.map((cc) => cc.id).includes(media.id)}
            onChange={(e) => onToggleCheck(e, media)}
            disabled={currentProject?.role === 'VIEWER'}
          />
        </div>
      ),
    },
    {
      title: t('fileName'),
      key: 'name',
      render: (media: MediaInterface) => (
        <div
          className={'flex items-center space-x-3 cursor-pointer'}
          onClick={(e) => onMediaEdit(media, e)}>
          <MediaThumb
            media={media}
            // width={'96'}
            outerWidth={96}
            outerHeight={96}
            textSize="3xl"
            thumbnail="SMALL"
          />
          <div
            className={`flex ${
              media.file && media.file.processType === 'PROCESS'
                ? 'text-gray-500'
                : ''
            } ${
              media.file && media.file.processType === 'FAIL'
                ? 'text-red-500'
                : ''
            }`}>
            {currentProject &&
            media.languageMap &&
            media.languageMap[currentProject?.defaultLang] ? (
              <p
                className="mb-0"
                style={{
                  overflowWrap: 'anywhere',
                }}>
                {media.languageMap[currentProject?.defaultLang]?.name}
                {media.file && media.file.processType === 'FAIL' ? (
                  <div className="text-sm pt-1">
                    * 파일을 다시 업로드 해주세요
                  </div>
                ) : (
                  <></>
                )}
              </p>
            ) : (
              ''
            )}

            {media.value && media.mediaType === 'URL' ? (
              <p className="mb-0 mt-1 text-gray-600 text-xs">{media.value}</p>
            ) : (
              <></>
            )}
          </div>
        </div>
      ),
    },
    {
      title: t('fileType'),
      key: 'fileType',
      render: (media: MediaInterface) =>
        media.file && media.file.processType === 'PROCESS' ? (
          <></>
        ) : (
          <div className={'flex items-center'}>
            {media.mediaType === 'FILE' ? (
              <div className="rounded-sm px-1 bg-gray-100 border border-gray-400 text-xs">
                {media.fileType}
              </div>
            ) : (
              <></>
            )}
            {media.mediaType === 'URL' ? (
              <div className="rounded-sm px-1 bg-gray-100 border border-gray-400 text-xs">
                {media.mediaType}
              </div>
            ) : (
              <></>
            )}
          </div>
        ),
      width: 120,
    },
    {
      title: t('extension'),
      key: 'extension',
      render: (media: MediaInterface) =>
        media.file && media.file.processType === 'PROCESS' ? (
          <></>
        ) : (
          <div className={'flex items-center'}>
            <div className="text-xs">
              {media.file
                ? media.file.path
                    .split('.')
                    [media.file.path.split('.').length - 1].toUpperCase()
                    .split('?')[0]
                : ''}
            </div>
          </div>
        ),
      width: 90,
    },
    {
      title: t('resolutions'),
      key: 'resolutions',
      render: (media: MediaInterface) => (
        <div className={'flex items-center'}>
          <div className="text-xs">
            {media.file &&
            media.file.processType === 'COMPLETE' &&
            media.file.meta &&
            media.file.meta.width &&
            media.file.meta.height
              ? `${media.file.meta.width}x${media.file.meta.height}`
              : '-'}
          </div>
        </div>
      ),
      width: 120,
    },
    {
      title: t('fileSize'),
      key: 'fileSize',
      render: (media: MediaInterface) =>
        media.file &&
        media.file.processType === 'COMPLETE' && (
          <div className={'flex items-center'}>
            <div className="text-xs">{media.file?.meta?.size}</div>
          </div>
        ),
      width: 120,
    },
    {
      title: t('createdDate'),
      key: 'date',
      render: (media) => (
        <div className="text-xs">
          {moment(media.date.createdAt, 'YYYYMMDDHHmmss').format(
            'YYYY-MM-DD HH:mm:ss'
          )}
        </div>
      ),
      width: 160,
    },
    /* {
      title: t('relatedContents'),
      key: 'relatedContents',
      render: (media) => (
        <div>{media.contentList ? media.contentList.length : 0}</div>
      ),
    }, */
    {
      title: '',
      key: 'actions',
      align: 'right',
      width: 40,
      render: (project) =>
        currentProject?.role !== 'VIEWER' ? (
          <Dropdown overlay={mediaMenus(project)} trigger={['click']}>
            <Button
              type={'text'}
              icon={<EllipsisOutlined></EllipsisOutlined>}
              title={t('more')}></Button>
          </Dropdown>
        ) : (
          <></>
        ),
    },
  ]

  /**
   * 미디어 콘텐츠 가져오기
   * @param paging
   * @param sortBy
   * @param type
   * @param keyword
   */
  const getMediaList = (paging, sortBy, type, keyword) => {
    setLoading(true)
    dispatch(setMediaList([]))

    const req = {
      page: paging.page - 1,
      size: paging.size,
      direction: sortBy.split('.')[1],
      orderCond: sortBy.split('.')[0],
      fileType: type && type !== 'URL' ? type : null,
      mediaType: type && type === 'URL' ? type : null,
      keyword: keyword
        ? {
            value: keyword.normalize('NFC').trim(),
          }
        : null,
    }

    getMediaListSearch(currentProject?.uid, req)
      .then((res) => {
        dispatch(setMediaList(res.data.list))
        setLoading(false)

        const pageInfo = res.data.pageInfo

        setPagination({
          totalElements: pageInfo.totalElements,
          size: pageInfo.size,
          page: pageInfo.page + 1,
        })
      })
      .catch((e) => {
        dispatch(setMediaList([]))
        // message.error(e.response.data.error)
        setLoading(false)

        setPagination({
          totalElements: 0,
          size: DEFAULT_MEDIA_PAGE_SIZE,
          page: 0,
        })
      })
  }

  /**
   * Table 정보 수정
   * @param pagination
   * @param filters
   * @param sorter
   * @param extra
   */
  const onTableChange = (pagination, filters, sorter, extra) => {
    getMediaList(pagination, sort, filter, q)
  }

  /**
   * 필터 변경
   * @param filter
   */
  const onFilterChange = (filter) => {
    setFilter(filter)
    setPagination({
      totalElements: pagination.totalElements,
      size: pagination.size,
      page: 1,
    })

    getMediaList(
      {
        total: pagination.totalElements,
        pageSize: pagination.size,
        page: 1,
      },
      sort,
      filter,
      q
    )
  }

  /**
   * 정렬 변경
   * @param sorter
   */
  const onSortChange = (sorter) => {
    setSort(sorter)
    setPagination({
      totalElements: pagination.totalElements,
      size: pagination.size,
      page: 1,
    })

    getMediaList(
      {
        totalElements: pagination.totalElements,
        size: pagination.size,
        page: 1,
      },
      sorter,
      filter,
      q
    )
  }

  /**
   * 미디어 콘텐츠 수정
   * @param model
   */
  const onMediaEdit = (model, e: undefined | any = null) => {
    if (e && e.target && e.target.tagName === 'INPUT') return false
    dispatch(setMediaFormModal(true, model))
  }

  /**
   * 여러 미디어 삭제
   * @param contentsToBeDeleted
   */
  const onMediaListDelete = () => {
    if (checkedMediaList.length) {
      Modal.confirm({
        centered: true,
        title: t('confirmDeleteContentsTitle'),
        icon: <ExclamationCircleOutlined />,
        content: t('confirmDeleteContentsDesc'),
        okText: t('delete'),
        cancelText: t('cancel'),
        onOk() {
          return new Promise((resolve, reject) => {
            deleteMultiMedia(
              currentProject?.uid,
              checkedMediaList.map((cc) => cc.id)
            )
              .then((res) => {
                message.success(t('deleteSuccess'))
                getMediaList(pagination, sort, filter, q)
                resolve(res)
              })
              .catch((e) => {
                message.error(e.response.data.error)
                reject(e)
              })
          }).catch((e) => console.log(e))
        },
        onCancel() {},
      })
    }
  }

  const onMediaListDownload = () => {
    if (checkedMediaList.length) {
      checkedMediaList.forEach((mediaInfo) => {
        try {
          const source = mediaInfo.file.path
          const fileName = mediaInfo.file.name.normalize('NFC')

          var xhr = new XMLHttpRequest()
          xhr.open('GET', source, true)
          xhr.responseType = 'blob'
          xhr.onload = function () {
            var urlCreator = window.URL || window.webkitURL
            var imageUrl = urlCreator.createObjectURL(this.response)
            var tag = document.createElement('a')
            tag.href = imageUrl
            tag.download = fileName
            document.body.appendChild(tag)
            tag.click()
            document.body.removeChild(tag)
          }
          xhr.send()
        } catch (e) {
          console.log(e)
        }
      })
    }
  }

  /**
   * 미디어 콘텐츠 삭제 confirm
   * @param media
   */
  const onMediaDelete = (media) => {
    Modal.confirm({
      centered: true,
      title: t('confirmDeleteFileTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('confirmDeleteFileDesc'),
      okText: t('delete'),
      cancelText: t('cancel'),
      onOk() {
        return new Promise((resolve, reject) => {
          deleteMedia(currentProject?.uid, media.id)
            .then((res) => {
              message.success(t('deleteSuccess'))
              dispatch(getProjectUsage(currentProject?.uid as string))
              getMediaList(pagination, sort, filter, q)
              resolve(res)
            })
            .catch((e) => {
              message.error(e.response.data.error)
              reject(e)
            })
        }).catch((e) => console.log(e))
      },
      onCancel() {},
    })
  }

  /**
   * 미디어 콘텐츠 액션 메뉴
   * @param media
   * @returns
   */
  const mediaMenusItem = (media) => {
    return [
      {
        key: 'edit',
        label: t('editMediaContents'),
        onClick: () => {
          onMediaEdit(media, null)
        },
      },
      {
        key: 'delete',
        label: <div className="text-red-500">{t('deleteMediaContents')}</div>,
        onClick: () => {
          onMediaDelete(media)
        },
      },
    ]
  }

  /**
   * 미디어 콘텐츠 액션 툴
   * @param media
   */
  const mediaMenus = (media) => (
    <Menu className={'w-48'} items={mediaMenusItem(media)} />
  )

  /**
   * 미디어 콘텐츠 카드
   * @param media
   * @constructor
   */
  const MediaCardItem = (media: MediaInterface) => {
    return (
      <Card
        cover={
          <div
            className="cursor-pointer relative"
            onClick={(e) => onMediaEdit(media, e)}>
            <MediaThumb
              cover
              media={media}
              // height={'157'}
              outerHeight={157}
              textSize="3xl"
              thumbnail="SMALL"
            />
            <div className="absolute top-2 left-2">
              <Checkbox
                checked={checkedMediaList.map((cc) => cc.id).includes(media.id)}
                onChange={(e) => onToggleCheck(e, media)}
                disabled={currentProject?.role === 'VIEWER'}
              />
            </div>
          </div>
        }>
        <div className={'flex justify-between items-center'}>
          <div
            className={'cursor-pointer'}
            style={{
              width: 'calc(100% - 32px)',
            }}
            onClick={(e) => onMediaEdit(media, e)}>
            <div
              className={
                'w-full leading-6 mb-0 flex justify-between items-center space-x-2'
              }>
              <div className="flex truncate">
                {currentProject &&
                media.languageMap &&
                media.languageMap[currentProject?.defaultLang] ? (
                  <p
                    className="w-full truncate mb-0"
                    style={{
                      overflowWrap: 'anywhere',
                    }}>
                    {media.languageMap[currentProject?.defaultLang]?.name}
                  </p>
                ) : (
                  ''
                )}
              </div>

              {media.file && media.file.processType === 'COMPLETE' ? (
                <>
                  {media.mediaType === 'FILE' ? (
                    <div className="absolute top-2 right-2 rounded-sm px-1 bg-gray-100 border border-gray-400 text-xs">
                      {media.fileType}
                    </div>
                  ) : (
                    <></>
                  )}
                  {media.mediaType === 'URL' ? (
                    <div className="absolute top-2 right-2 rounded-sm px-1 bg-gray-100 border border-gray-400 text-xs">
                      {media.mediaType}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            {media.file && media.file.processType === 'COMPLETE' ? (
              <p className={'text-xs text-gray-500 leading-5 truncate mb-0'}>
                {media.file
                  ? media.file.path
                      .split('.')
                      [media.file.path.split('.').length - 1].toUpperCase()
                      .split('?')[0]
                  : ''}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="flex-none w-8">
            {currentProject?.role !== 'VIEWER' ? (
              <Dropdown overlay={mediaMenus(media)} trigger={['click']}>
                <Button
                  type={'text'}
                  icon={<EllipsisOutlined></EllipsisOutlined>}
                  title={t('more')}></Button>
              </Dropdown>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Card>
    )
  }

  /**
   * 페이지네이션 변경
   * @param page
   * @param size
   */
  const onHandleChangePagination = (page, size) => {
    getMediaList(
      {
        totalElements: pagination.totalElements,
        size,
        page,
        changing: true,
      },
      sort,
      filter,
      q
    )
  }

  // Memo
  const mediaListProcessing: MediaInterface[] = useMemo(() => {
    return mediaList.filter((media) => checkIfMediaProcessing(media))
  }, [mediaList])

  useEffect(() => {
    clearInterval(interval)

    if (mediaListProcessing.length) {
      checkProcessingMedia(mediaListProcessing)
    }
  }, [mediaListProcessing])

  /**
   * 처리중인 미디어 갱신 체크
   * @param mediaListProcessing
   */
  const checkProcessingMedia = (mediaListProcessing: MediaInterface[]) => {
    // Check every 30 seconds
    interval = setInterval(() => {
      Promise.all(
        mediaListProcessing.map((mediaProcessing) =>
          getMedia(currentProject?.uid, mediaProcessing.id)
        )
      ).then(
        axios.spread((...res) => {
          const updatedMediaItems = [...res]

          dispatch(
            setMediaList(
              mediaList.map((media) => {
                const updatedMedia = updatedMediaItems.find(
                  (updatedMediaItem) => updatedMediaItem.data.id === media.id
                )

                if (updatedMedia) {
                  return updatedMedia.data
                }

                return media
              })
            )
          )
        })
      )
    }, 1000 * 30)
  }

  return (
    <div className={'space-y-7'}>
      {/* 미디어 콘텐츠 목록 보기 옵션: 시작 */}
      <div
        className={
          'lg:flex justify-between lg:space-x-4 space-y-4 lg:space-y-0 sticky top-12 z-20 bg-gray-200 py-2'
        }>
        <div>
          <Input.Search
            placeholder={t('search')}
            title={t('search')}
            allowClear
            onChange={(e) => setQ(e.target.value)}
            onSearch={(value) =>
              getMediaList(
                {
                  totalElements: 0,
                  size: searchParams.get('size')
                    ? Number(searchParams.get('size'))
                    : DEFAULT_MEDIA_PAGE_SIZE,
                  page: searchParams.get('page')
                    ? Number(searchParams.get('page'))
                    : 1,
                },
                sort,
                filter,
                value
              )
            }
            value={q}
            disabled={loading}
            enterButton
          />
        </div>
        <div className="flex space-x-1 lg:space-x-3">
          <div className={'flex items-center space-x-1 lg:space-x-3'}>
            <div>
              <Select
                bordered={false}
                className="w-auto lg:w-40"
                defaultValue={''}
                disabled={loading}
                onChange={(val) => onFilterChange(val)}>
                <Option value={''}>{t('all')}</Option>
                {availableFileTypes.map((fileType) => (
                  <Option key={fileType} value={fileType}>
                    {t(
                      'fileTypes.' +
                        (fileType ? fileType : 'all').toLowerCase() +
                        '.name'
                    )}
                  </Option>
                ))}
                <Option value={'URL'}>{t('url')}</Option>
              </Select>
            </div>
          </div>
          <div className={'flex items-center space-x-1 lg:space-x-3'}>
            <div>
              <Select
                bordered={false}
                className="w-auto lg:w-40"
                defaultValue={'DATE_CREATE.DESC'}
                disabled={loading}
                onChange={(val) => onSortChange(val)}>
                <Option value={'DATE_CREATE.DESC'}>
                  {t('recentCreatedDate')}
                </Option>
                <Option value={'DATE_CREATE.ASC'}>
                  {t('oldestCreatedDate')}
                </Option>
                <Option value={'NAME.ASC'}>{t('sortByFileAsc')}</Option>
                <Option value={'NAME.DESC'}>{t('sortByFileDesc')}</Option>
              </Select>
            </div>
          </div>
          <ListViewType />
          {checkedMediaList.length ? (
            <div className="flex items-center space-x-1 lg:space-x-3">
              <Button
                danger
                className="btn-sm-ico-only"
                icon={<DeleteFilled />}
                onClick={() => onMediaListDelete()}>
                {t('delete')}
              </Button>
              <Button
                type={'primary'}
                className="btn-sm-ico-only"
                icon={<DownloadOutlined />}
                onClick={() => onMediaListDownload()}>
                {t('downloadSelected')}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* 미디어 콘텐츠 목록 보기 옵션: 끝 */}
      {/* Card View: 시작 */}
      {viewType === 'card' ? (
        !loading ? (
          <Row gutter={24}>
            {mediaList.length ? (
              mediaList.map((media) => (
                <Col
                  key={media.id}
                  xs={24}
                  sm={12}
                  md={12}
                  lg={8}
                  xl={6}
                  xxl={4}
                  className="mb-6">
                  {MediaCardItem(media)}
                </Col>
              ))
            ) : (
              <Col span={24} className="w-full flex justify-center">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Col>
            )}
          </Row>
        ) : (
          <div className={'h-screen flex justify-center items-center'}>
            <Spin />
          </div>
        )
      ) : (
        <></>
      )}
      {/* Card View: 끝 */}
      {/* List View: 시작 */}
      {viewType === 'list' ? (
        <div>
          <Table
            // @ts-ignore
            columns={tableCols}
            rowKey="id"
            loading={loading}
            dataSource={mediaList}
            onChange={onTableChange}
            pagination={false}></Table>
        </div>
      ) : (
        <></>
      )}
      {/* List View: 끝 */}
      {/* Pagination: 시작 */}
      <div className="flex justify-between items-center py-6">
        <div className="text-gray-600">
          {t('noOfContents', { no: pagination.totalElements })}
        </div>
        <div className="flex justify-center">
          <Pagination
            defaultCurrent={1}
            current={pagination.page}
            total={pagination.totalElements}
            pageSize={pagination.size}
            onChange={onHandleChangePagination}
          />
        </div>
        <div></div>
      </div>
      {/* Pagination: 끝 */}

      <MediaFormModal
        onChangeMedia={() =>
          getMediaList(
            {
              total: pagination.totalElements,
              pageSize: pagination.size,
              page: 1,
            },
            sort,
            filter,
            q
          )
        }
      />
      <UploadModal
        onUpload={() =>
          getMediaList(
            {
              total: pagination.totalElements,
              pageSize: pagination.size,
              page: 1,
            },
            sort,
            filter,
            q
          )
        }
      />
    </div>
  )
}
