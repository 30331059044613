import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FileOutlined,
  LinkOutlined,
  PlayCircleOutlined,
  YoutubeOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons'
import { FileInterface, MediaInterface } from '@/types'
import { vimeoParser, youtubeParser } from '@/utils/helpers'
import { Spin } from 'antd'

interface MediaFileInfoProps {
  media: MediaInterface
  cover?: boolean
  width?: string
  height?: string
  outerWidth?: number
  outerHeight?: number
  textSize: string
  region?: 'full' | 'square'
  rotation?: number
  thumbnail?: 'SMALL' | 'MEDIUM' | 'LARGE'
}

export const MediaThumb = ({
  media,
  cover,
  width,
  height,
  outerWidth,
  outerHeight,
  textSize,
  region,
  rotation,
  thumbnail,
}: MediaFileInfoProps) => {
  const { t, i18n } = useTranslation()

  // 썸네일
  const thumbnailFile: FileInterface | null | undefined = useMemo(
    () =>
      media && thumbnail && media.file && media.file.thumbnailList
        ? media.file.thumbnailList.find((t) => t.type === thumbnail)
        : media.file,
    [media]
  )

  // 썸네일 생성 여부 (가로 or 세로가 200픽셀보다 높을경우)
  const isHasThumbnail = useMemo(
    () =>
      media &&
      media.file &&
      media.file.meta &&
      ((media.file.meta.width && media.file.meta.width > 200) ||
        (media.file.meta.height && media.file.meta.height > 200)),
    [media]
  )

  // 썸네일 생성 완료 여부
  const isCompleteThumbnail = useMemo(
    () =>
      isHasThumbnail &&
      media &&
      thumbnail &&
      media.file &&
      media.file.thumbnailList &&
      media.file.thumbnailList.length > 0,
    [media, isHasThumbnail]
  )

  /**
   * 이미지
   * @returns
   */
  const MediaImage = () => {
    return thumbnailFile ? (
      <>
        {cover ? (
          <div
            className={`overflow-hidden flex-none flex items-center bg-cover bg-no-repeat bg-center`}
            style={{
              width: outerWidth ? outerWidth + 'px' : '100%',
              height: outerHeight ? outerHeight + 'px' : '100%',
              backgroundImage: `url(${thumbnailFile.path})`,
            }}></div>
        ) : (
          <img
            loading="lazy"
            src={thumbnailFile.path}
            alt={media.file.meta.type.split('/')[1]}
            className="w-full"
          />
        )}
      </>
    ) : (
      <img
        loading="lazy"
        src={media.file?.path}
        alt={media.file.meta.type.split('/')[1]}
        className="w-full"
      />
    )
  }

  /**
   * 이미지 파일
   * @returns
   */
  const MediaFileImage = () => {
    return (
      <div
        className={`overflow-hidden flex-none flex items-center`}
        style={{
          width: outerWidth ? outerWidth + 'px' : 'auto',
          height: outerHeight ? outerHeight + 'px' : 'auto',
        }}>
        {!isHasThumbnail || (isHasThumbnail && isCompleteThumbnail) ? (
          <MediaImage />
        ) : (
          <div
            className={`bg-gray-300 aspect-1 overflow-hidden flex-none flex justify-center items-center`}
            style={{
              width: outerWidth ? outerWidth + 'px' : '100%',
              height: outerHeight ? outerHeight + 'px' : '100%',
            }}>
            <div>
              <Spin size={thumbnail === 'SMALL' ? 'small' : 'default'} />
            </div>
          </div>
        )}
      </div>
    )
  }

  /**
   * 비디오 파일
   * @returns
   */
  const MediaFileVideo = () => {
    return (
      <div
        className={`bg-gray-300 flex-none flex items-center justify-center relative`}
        style={{
          width: outerWidth ? outerWidth + 'px' : 'auto',
          height: outerHeight ? outerHeight + 'px' : 'auto',
        }}>
        <VideoCameraOutlined className={`text-${textSize}`} />
        {media.file.video &&
          outerWidth &&
          outerWidth > 76 &&
          outerHeight &&
          outerHeight > 76 && (
            <div className="absolute top-2 right-2">
              <PlayCircleOutlined className="text-sm !text-red-500" />
            </div>
          )}
      </div>
    )
  }

  /**
   * 이미지가 아닌 파일
   * @returns
   */
  const MediaFileStatic = () => {
    return (
      <div
        className={` bg-gray-300 flex-none flex items-center justify-center`}
        style={{
          width: outerWidth ? outerWidth + 'px' : 'auto',
          height: outerHeight ? outerHeight + 'px' : 'auto',
        }}>
        <FileOutlined className={`text-${textSize}`} />
      </div>
    )
  }

  /**
   * 유투브 URL
   * @returns
   */
  const MediaUrlYoutube = () => {
    return (
      <div
        className={`overflow-hidden flex-none flex items-center relative`}
        style={{
          width: outerWidth ? outerWidth + 'px' : 'auto',
          height: outerHeight ? outerHeight + 'px' : 'auto',
        }}>
        {
          <img
            loading="lazy"
            src={`https://img.youtube.com/vi/${youtubeParser(
              media.value
            )}/0.jpg`}
            alt={media.value}
            height={height}
            className="w-full max-h-full"
          />
        }
        <div
          className="absolute w-full h-full flex items-center justify-center"
          style={{
            backgroundColor: 'rgba(0,0,0,0.4)',
          }}>
          <YoutubeOutlined
            className={` text-${textSize}`}
            style={{
              color: 'white',
            }}
          />
        </div>
      </div>
    )
  }

  /**
   * 비메오 URL
   * @returns
   */
  const MediaUrlVimeo = () => {
    return (
      <div
        className={`overflow-hidden flex-none flex items-center relative`}
        style={{
          width: outerWidth ? outerWidth + 'px' : 'auto',
          height: outerHeight ? outerHeight + 'px' : 'auto',
        }}>
        {
          <img
            loading="lazy"
            src={`https://vumbnail.com/${vimeoParser(media.value)}.jpg`}
            alt={media.value}
            className="w-full max-h-full"
            height={outerHeight}
          />
        }
        <div
          className="absolute w-full h-full flex items-center justify-center"
          style={{
            backgroundColor: 'rgba(0,0,0,0.4)',
          }}>
          <PlayCircleOutlined
            className={`text-${textSize}`}
            style={{
              color: 'white',
            }}
          />
        </div>
      </div>
    )
  }

  /**
   * 일반 URL
   * @returns
   */
  const MediaUrlLink = () => {
    return (
      <div
        className={` bg-gray-300 flex-none flex items-center justify-center`}
        style={{
          width: outerWidth ? outerWidth + 'px' : 'auto',
          height: outerHeight ? outerHeight + 'px' : 'auto',
        }}>
        <LinkOutlined className={`text-${textSize}`} />
      </div>
    )
  }

  return media ? (
    <div>
      {media.file && media.file.processType === 'PROCESS' ? (
        <div
          className={`overflow-hidden bg-gray-300 flex-none flex justify-center items-center`}
          style={{
            width: outerWidth ? outerWidth + 'px' : 'auto',
            height: outerHeight ? outerHeight + 'px' : 'auto',
          }}>
          <Spin size={thumbnail === 'SMALL' ? 'small' : 'default'} />
        </div>
      ) : (
        <>
          {media.mediaType === 'FILE' &&
          media.file &&
          media.file.type === 'IMAGE' ? (
            <MediaFileImage />
          ) : (
            <></>
          )}
          {media.mediaType === 'FILE' &&
          media.file &&
          media.file.type === 'VIDEO' ? (
            <MediaFileVideo />
          ) : (
            <></>
          )}
          {media.mediaType === 'FILE' &&
          media.file &&
          media.file.type !== 'IMAGE' &&
          media.file.type !== 'VIDEO' ? (
            <MediaFileStatic />
          ) : (
            <></>
          )}
          {media.mediaType === 'URL' && youtubeParser(media.value) ? (
            <MediaUrlYoutube />
          ) : (
            <></>
          )}
          {media.mediaType === 'URL' && vimeoParser(media.value) ? (
            <MediaUrlVimeo />
          ) : (
            <></>
          )}
          {media.mediaType === 'URL' &&
          !youtubeParser(media.value) &&
          !vimeoParser(media.value) ? (
            <MediaUrlLink />
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  ) : (
    <></>
  )
}
