import { AppDispatch } from '@/states'
import { ActionTypesModals } from '@/states/interfaces'
import {
  ComponentInterface,
  ProjectInterface,
  ModelInterface,
  MediaInterface,
  ContentsInterface,
  CellInterface,
  ProjectAccessInterface,
  SelectorInterface,
} from '@/types'

/**
 * 계정 정보 모달 설정
 */
export const setAccountModal = (isOpen: boolean) => (dispatch: AppDispatch) => {
  return dispatch({
    type: ActionTypesModals.SET_ACCOUNT_MODAL,
    payload: {
      isOpen: isOpen,
    },
  })
}

/**
 * 비밀번호 변경 모달 설정
 */
export const setAccountPasswordModal =
  (isOpen: boolean) => (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesModals.SET_ACCOUNT_PASSWORD_MODAL,
      payload: {
        isOpen: isOpen,
      },
    })
  }

/**
 * 프로젝트 폼 모달 설정
 */
export const setProjectFormModal =
  (isOpen: boolean, projectInfo?: ProjectInterface) =>
  (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesModals.SET_PROJECT_FORM_MODAL,
      payload: {
        isOpen: isOpen,
        info: projectInfo,
      },
    })
  }

/**
 * 프로젝트 초대 모달 설정
 */
export const setProjectInviteModal =
  (isOpen: boolean) => (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesModals.SET_PROJECT_INVITE_MODAL,
      payload: {
        isOpen: isOpen,
      },
    })
  }

/**
 * 프로젝트 API Key 모달 설정
 */
export const setProjectAccessFormModal =
  (isOpen: boolean, projectAccessInfo?: ProjectAccessInterface) =>
  (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesModals.SET_PROJECT_ACCESS_FORM_MODAL,
      payload: {
        isOpen: isOpen,
        info: projectAccessInfo,
      },
    })
  }

/**
 * 모델 폼 모달 설정
 */
export const setModelFormModal =
  (isOpen: boolean, modelInfo?: ModelInterface) => (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesModals.SET_MODEL_FORM_MODAL,
      payload: {
        isOpen: isOpen,
        info: modelInfo,
      },
    })
  }

/**
 * 컴포넌트 폼 모달 설정
 */
export const setComponentFormModal =
  (
    isOpen: boolean,
    componentInfo?: ComponentInterface | null,
    parentComponentInfo?: ComponentInterface | null
  ) =>
  (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesModals.SET_COMPONENT_FORM_MODAL,
      payload: {
        isOpen: isOpen,
        info: componentInfo ? componentInfo : null,
        parentInfo: parentComponentInfo ? parentComponentInfo : null,
      },
    })
  }

/**
 * 미디어 업로드 모달 설정
 */
export const setMediaUploadModal =
  (isOpen: boolean, uid?: string) => (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesModals.SET_MEDIA_UPLOAD_MODAL,
      payload: {
        isOpen: isOpen,
        uid: uid,
      },
    })
  }

/**
 * 미디어 폼 모달 설정
 */
export const setMediaFormModal =
  (
    isOpen: boolean,
    mediaInfo?: MediaInterface | null,
    updatedMediaInfo?: MediaInterface | null,
    uid?: string
  ) =>
  (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesModals.SET_MEDIA_FORM_MODAL,
      payload: {
        isOpen: isOpen,
        info: mediaInfo,
        updatedInfo: updatedMediaInfo,
        uid: uid,
      },
    })
  }

/**
 * 미디어 선택 모달 설정
 */
export const setMediaSelectorModal =
  (
    isOpen: boolean,
    cellInfo?: CellInterface,
    componentInfo?: ComponentInterface,
    contentsInfo?: ContentsInterface
  ) =>
  (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesModals.SET_MEDIA_SELECTOR_MODAL,
      payload: {
        isOpen: isOpen,
        info: cellInfo,
        componentInfo: componentInfo,
        contentsInfo: contentsInfo,
      },
    })
  }

/**
 * 연관 콘텐츠 선택 모달 설정
 */
export const setContentsRelationSelectorModal =
  (
    isOpen: boolean,
    cellInfo?: CellInterface,
    componentInfo?: ComponentInterface,
    contentsInfo?: ContentsInterface
  ) =>
  (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesModals.SET_CONTENTS_RELATION_SELECTOR_MODAL,
      payload: {
        isOpen: isOpen,
        info: cellInfo,
        componentInfo: componentInfo,
        contentsInfo: contentsInfo,
      },
    })
  }

/**
 * 콘텐츠 폼 모달 설정
 */
export const setContentsFormModal =
  (
    isOpen: boolean,
    contentsInfo?: ContentsInterface | null,
    modelId?: number
  ) =>
  (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesModals.SET_CONTENTS_FORM_MODAL,
      payload: {
        isOpen: isOpen,
        info: contentsInfo,
        modelId,
      },
    })
  }

/**
 * 콘텐츠 프리뷰 폼 모달 설정
 */
export const setContentsPreviewFormModal =
  (isOpen: boolean, contentsInfo?: ContentsInterface, modelId?: number) =>
  (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesModals.SET_CONTENTS_PREVIEW_FORM_MODAL,
      payload: {
        isOpen: isOpen,
        info: contentsInfo,
        modelId,
      },
    })
  }

/**
 * 카테고리 폼 모달 설정
 */
export const setCategoryFormModal =
  (isOpen: boolean, categoryInfo?: SelectorInterface) =>
  (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesModals.SET_CATEGORY_FORM_MODAL,
      payload: {
        isOpen: isOpen,
        info: categoryInfo,
      },
    })
  }
