import { RootState, useAppDispatch } from '@/states'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Button } from 'antd'
import { PlusSquareOutlined, UploadOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { setMediaFormModal, setMediaUploadModal } from '@/states/actions'

export const HeaderMedia = () => {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )

  const { currentProject, currentModel } = projectsState

  return (
    <div className="flex space-x-2">
      {/* <div>
        <Button
        type={'primary'}
        htmlType={'button'}
        icon={<DownloadOutlined />}
        onClick={() => downloadAllMedia()}
        style={{
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0',
        }}>
        {t('downloadMediaContents')}
        </Button>
    </div> */}
      <div className="flex items-center">
        <Button
          type={'primary'}
          className="btn-sm-ico-only"
          htmlType={'button'}
          icon={<PlusSquareOutlined />}
          disabled={currentProject?.role === 'VIEWER'}
          onClick={() => dispatch(setMediaFormModal(true))}
          style={{
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0',
          }}>
          {t('addMediaContents')}
        </Button>
        <Button
          type={'primary'}
          className="btn-sm-ico-only"
          htmlType={'button'}
          icon={<UploadOutlined />}
          disabled={currentProject?.role === 'VIEWER'}
          onClick={() => dispatch(setMediaUploadModal(true))}
          style={{
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
            marginLeft: '-1px',
          }}>
          {t('uploadMultipleMediaContents')}
        </Button>
      </div>
    </div>
  )
}
