/**
 * Cookie Keys
 */
export const XSRF_TOKEN_KEY = 'XSRF-TOKEN' // CSRF Token
export const USER_ACCESS_TOKEN_KEY = 'mmx_acs_usr_tkn' // Auth access token
export const USER_REFRESH_TOKEN_KEY = 'mmx_rfs_usr_tkn' // Auth refresh token
export const USER_INFO_KEY = 'mmx_usr_inf' // Auth User Info
export const USER_ID_KEY = 'mmx_usr_id' // Auth User ID
export const LOGIN_EMAIL_KEY = 'mmx_lgn_eml' // Login email
export const SPLIT_KEY = 'mmx_spl_val' // Split value
export const GNB_TOGGLE_KEY = 'mmx_gnb_tgl' // GNB Toggle
export const STRETCH_TOGGLE_KEY = 'mmx_stc_tgl' // Stretch Toggle
export const COMPONENTS_QUICK_BUTTONS_KEY = 'mmx_cmp_qbt' // Component quick buttons toggle
export const DISPLAY_MODE_KEY = 'mmx_dsp_mod' // Display mode
export const PROJECT_LIST_VIEW_MODE_KEY = 'mmx_plv_mod' // Project list view mode
export const PROJECT_INFO_KEY = 'mmx_prj_inf' // Project info key
export const MEDIA_LIST_VIEW_MODE_KEY = 'mmx_mlv_mod' // Media list view mode
export const CONTENTS_LIST_VIEW_MODE_KEY = 'mmx_clv_mod' // Contents list view mode
export const CONTENTS_SORT_KEY = 'mmx_cnt_srt' // Contents Sort information
export const TIP_STEP_COMPLETE_1_KEY = 'mmx_tip_st1' // Tip Step 1 complete
export const TIP_STEP_COMPLETE_2_KEY = 'mmx_tip_st2' // Tip Step 2 complete
export const TIP_STEP_COMPLETE_3_KEY = 'mmx_tip_st3' // Tip Step 3 complete
export const TIP_STEP_COMPLETE_4_KEY = 'mmx_tip_st4' // Tip Step 4 complete

export const TIP_SKIP_ALL = 'mmx_tip_skp' // Tip Skip All
export const TIP_ACCOUNT_KEY = 'mmx_tip_acn' // Tip Account complete
export const TIP_LINKS_KEY = 'mmx_tip_lnk' // Tip Link complete
export const TIP_TOGGLE_MENU_KEY = 'mmx_tip_tgm' // Tip Toggle Menu complete
export const TIP_BUILDER_KEY = 'mmx_tip_bld' // Tip Builder complete
export const TIP_SETTINGS_KEY = 'mmx_tip_set' // Tip Settings complete
export const TIP_DEVELOPERS_KEY = 'mmx_tip_dev' // Tip Developer complete
export const TIP_DEV_KEY_KEY = 'mmx_tip_dke' // Tip Dev Key complete
export const TIP_COMPONENT_SAVE_KEY = 'mmx_tip_csv' // Component Save Key complete
export const TIP_CONTENTS_KEY = 'mmx_tip_cnt' // Contents Key complete
export const TIP_MEDIA_CONTENTS_KEY = 'mmx_tip_med' // Media Contents Key complete
export const SURVEY_OPEN_KEY = 'mmx_sur_opn' // Survey Open Key
export const FAVORITES_PROJECTS_HIDE_KEY = 'mmx_fpr_hid' // 즐겨찾기 섹션 닫기
export const PROJECTS_HIDE_KEY = 'mmx_prt_hid' // 프로젝트 목록 닫기

export const GNB_FAVORITES_PROJECTS_HIDE_KEY = 'mmx_gfp_hid' // GNB 즐겨찾기 프로젝트 섹션 닫기
export const GNB_PROJECTS_HIDE_KEY = 'mmx_gpr_hid' // GNB 프로젝트 목록 닫기
export const GNB_FAVORITES_MODELS_HIDE_KEY = 'mmx_gfm_hid' // GNB 즐겨찾기 모델 섹션 닫기
export const CHECKLIST_HIDE_KEY = 'mmx_chk_hid' // 프로젝트 체크리스트 닫기
