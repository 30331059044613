import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import axios, { AxiosResponse } from 'axios'
import { Drawer, message } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { UploadFile } from 'antd/es/upload/interface'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ProjectUsageProperties, DEFAULT_PROJECT_PLAN } from '@/configs'
import { LINK_SURVEY } from '@/constants'
import { RootState, useAppDispatch } from '@/states'
import { setProjectFormModal } from '@/states/actions'
import {
  createProject,
  getProjects,
  createModel,
  createCategoryGroup,
  createCategorySelector,
  uploadFileToS3,
} from '@/api'
import {
  FileResponseInterface,
  LanguagesAvailable,
  ModelInterface,
  ProjectUsagePropertiesInterface,
  SelectorGroupInterface,
} from '@/types'
import {
  CreateStep1,
  CreateStep2,
  CreateStep3,
  CreateStep4,
} from '@/components/Projects/Create'

const initialProjectFormValues: {
  name: string
  languages: LanguagesAvailable[]
  defaultLanguage: LanguagesAvailable | ''
  description: string
} = {
  name: '',
  languages: ['KO'],
  defaultLanguage: 'KO',
  description: '',
}
export const ProjectsFormModal = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // State (Redux)
  const { modalsState, projectsState } = useSelector(
    (state: RootState) => ({
      modalsState: state.modals,
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { projectFormModal, projectInfo } = modalsState
  const { projectsListInit, projectList } = projectsState

  // State
  const [step, setStep] = useState(0)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [projectPlan, setProjectPlan] = useState<
    ProjectUsagePropertiesInterface | undefined
  >(ProjectUsageProperties.find((p) => p.price === DEFAULT_PROJECT_PLAN))
  const [projectStarter, setProjectStarter] = useState<string>('')
  const [modelList, setModelList] = useState<ModelInterface[]>([])
  const [groupList, setGroupList] = useState<SelectorGroupInterface[]>([])

  // Effect
  useEffect(() => {
    if (projectFormModal) {
      setTimeout(() => {
        document.getElementById('projectFormName')?.focus()
      })

      if (!projectInfo) {
        formikProjectForm.validateForm()
      }
    } else {
      resetForm()
    }
  }, [projectFormModal])

  useEffect(() => {
    if (projectInfo) {
      formikProjectForm.setFieldValue('name', projectInfo.name)
      formikProjectForm.setFieldValue('description', projectInfo.description)
      formikProjectForm.setFieldValue('languages', projectInfo.languageMap)
      formikProjectForm.setFieldValue(
        'defaultLanguage',
        projectInfo.defaultLang
      )
    }
  }, [projectInfo])

  // Validation
  const validationProjectFormSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    languages: Yup.array().min(1, t('validation.minLanguage')),
    defaultLanguage: Yup.string().required(t('validation.required')),
  })

  // Formik
  const formikProjectForm = useFormik({
    initialValues: initialProjectFormValues,
    validationSchema: validationProjectFormSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      // Fake Submit
    },
  })

  useEffect(() => {
    if (
      formikProjectForm.values.defaultLanguage &&
      !formikProjectForm.values.languages.includes(
        formikProjectForm.values.defaultLanguage
      )
    ) {
      formikProjectForm.setFieldValue('defaultLanguage', '')
    }
  }, [formikProjectForm.values.languages])

  /**
   * 폼 리셋
   */
  const resetForm = () => {
    formikProjectForm.resetForm()
    setLoading(false)
    setFileList([])
    setModelList([])
    setGroupList([])

    setTimeout(() => {
      setStep(0)
    }, 1000)
  }

  /**
   * 프로젝트 생성
   */
  const handleSubmitProjectCreate = async () => {
    setLoading(true)
    const values = formikProjectForm.values

    // Language Map
    const languageMap = {}
    values.languages.forEach((lang) => {
      languageMap[lang] = lang === values.defaultLanguage
    })

    const req = {
      type: 'ARCHIVE',
      name: values.name,
      description: values.description,
      languageMap: languageMap,
      imageId: null,
    }

    // 프로젝트 이미지 업로드

    if (fileList && fileList.length) {
      await uploadFileToS3(fileList).then((res) => {
        const resData = (res as AxiosResponse).data as FileResponseInterface[]

        // @ts-ignore
        req.imageId = resData[0].fileId
      })
    }

    // 프로젝트 생성
    await createProject(req)
      .then(async (res) => {
        const projectUid = res.data

        if (modelList && modelList.length) {
          try {
            // 프로젝트 스타터 예제 데이터 생성
            const projectStarterSelectorReqs: any[] = []
            const projectStarterModelReqs: any[] = []
            const selectorGroupIds: {
              oldCategoryGroupId: number
              newCategoryGroupId: number
            }[] = []

            // 카테고리 생성
            await groupList.forEach((selectorGroupReq, sIdx) => {
              return projectStarterSelectorReqs.push(
                createCategoryGroup(projectUid, selectorGroupReq).then(
                  async (res) => {
                    const newCategoryGroupId = res.data
                    const categorySelectorReq = {
                      languageMap: selectorGroupReq.languageMap,
                      order: selectorGroupReq.order,
                      selectorList: selectorGroupReq.selectorList,
                    }

                    selectorGroupIds.push({
                      oldCategoryGroupId: selectorGroupReq.id as number,
                      newCategoryGroupId,
                    })

                    return await createCategorySelector(
                      projectUid,
                      newCategoryGroupId,
                      categorySelectorReq
                    )
                  }
                )
              )
            })

            await axios.all([...projectStarterSelectorReqs]).then(
              axios.spread(async (...responses) => {
                // 모델 생성
                await modelList.forEach((modelReq, mIdx) => {
                  // 카테고리 연결
                  modelReq.componentList.forEach((component) => {
                    const selectorGroupId = selectorGroupIds.find(
                      (s) => s.oldCategoryGroupId === component.selectorGroupId
                    )?.newCategoryGroupId

                    if (component.type === 'CATEGORY' && selectorGroupId) {
                      component.selectorGroupId = selectorGroupId
                    }
                  })
                  return projectStarterModelReqs.push(
                    createModel(projectUid, modelReq)
                  )
                })
              })
            )

            await axios.all([...projectStarterModelReqs]).then(
              axios.spread((...responses) => {
                setTimeout(() => {
                  message.success(t('projectCreated'))

                  setTimeout(() => {
                    dispatch(setProjectFormModal(false))
                    setLoading(false)
                    dispatch(getProjects())
                    enterProject(projectUid)
                  }, 500)
                })
              })
            )
          } catch (e) {
            dispatch(setProjectFormModal(false))
            setLoading(false)
            // @ts-ignore
            message.error(e.response.data.error)
          }
        } else {
          message.success(t('projectCreated'))

          setTimeout(() => {
            dispatch(setProjectFormModal(false))
            setLoading(false)
            dispatch(getProjects())
            enterProject(projectUid)
          }, 500)
        }
      })
      .catch((e) => {
        setLoading(false)
        message.error(e.response.data.error)
      })
  }

  /**
   * 프로젝트 진입
   * @param projectUid
   */
  const enterProject = (projectUid) => {
    setTimeout(() => {
      // location.href = `/projects/${projectUid}`
      navigate(`/projects/${projectUid}`)
    }, 500)
  }

  // Memo
  const freeProjectsCount = useMemo(() => {
    return projectList.filter((p) => p.role === 'OWNER' && p.price === 'FREE')
      .length
  }, [projectList])

  const isMaxFreeProjectsMeet = useMemo(() => {
    return !!(
      projectPlan &&
      projectPlan.maxProjects &&
      freeProjectsCount >= projectPlan.maxProjects
    )
  }, [projectPlan, freeProjectsCount])

  // Steps
  const steps = [
    {
      title: t('projectCreate.step1.title'),
      content: (
        <CreateStep1
          formikProjectForm={formikProjectForm}
          onNext={(step) => setStep(step)}
          isMaxFreeProjectsMeet={isMaxFreeProjectsMeet}
          freeProjectsCount={freeProjectsCount}
        />
      ),
    },
    {
      title: t('projectCreate.step2.title'),
      content: (
        <CreateStep2
          projectPlan={projectPlan as ProjectUsagePropertiesInterface}
          onNext={(step) => setStep(step)}
          modelList={modelList}
          onImportModels={setModelList}
          onImportGroups={setGroupList}
        />
      ),
    },
    {
      title: t('projectCreate.step3.title'),
      content: (
        <CreateStep3
          formikProjectForm={formikProjectForm}
          onNext={(step) => setStep(step)}
          prevFileList={fileList}
          onFileUpload={setFileList}
          isMaxFreeProjectsMeet={isMaxFreeProjectsMeet}
          freeProjectsCount={freeProjectsCount}
        />
      ),
    },
    {
      title: t('projectCreate.step4.title'),
      content: (
        <CreateStep4
          loading={loading}
          formikProjectForm={formikProjectForm}
          onNext={(step) => setStep(step)}
          onFinish={() => handleSubmitProjectCreate()}
          isMaxFreeProjectsMeet={isMaxFreeProjectsMeet}
          freeProjectsCount={freeProjectsCount}
        />
      ),
    },
  ]

  return (
    <Drawer
      width={'100%'}
      closeIcon={<CloseCircleOutlined title={t('close')} />}
      open={projectFormModal}
      maskClosable={false}
      closable={false}
      onClose={() => {
        if (!loading) dispatch(setProjectFormModal(false))
      }}
      title={
        t('createProject') +
        (formikProjectForm.values.name
          ? ' - ' + formikProjectForm.values.name
          : '')
      }
      extra={
        <CloseCircleOutlined
          className={`text-xl ${loading ? 'cursor-progress opacity-50' : ''}`}
          onClick={() => {
            if (!loading) {
              dispatch(setProjectFormModal(false))
            }
          }}
        />
      }
      footer={
        isMaxFreeProjectsMeet && (
          <div className="flex items-center space-x-2 text-right">
            <div
              className={`flex space-x-4 text-xs font-normal ${
                isMaxFreeProjectsMeet ? 'text-red-500' : ''
              }`}>
              <div>
                {projectPlan
                  ? t('maxFreeProject', {
                      max: projectPlan?.maxProjects,
                      count: freeProjectsCount,
                    })
                  : ''}
              </div>
              {/* {isMaxFreeProjectsMeet && (
                <a
                  href={LINK_SURVEY}
                  target="_blank"
                  rel="noreferrer"
                  className="text-xs"
                  title={t('freeUpgradePlanSurveyDesc')}>
                  {t('freeUpgradePlanSurveyDesc')}
                </a>
              )} */}
            </div>
          </div>
        )
      }>
      <>
        <div className="steps-content h-full overflow-hidden">
          {steps[step]?.content}
        </div>
      </>
    </Drawer>
  )
}
