import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, Input, Modal, message } from 'antd'
import { PlusCircleOutlined, QuestionOutlined } from '@ant-design/icons'
import { ModelInterface } from '@/types'
import { callModelRecommendations } from '@/api'
import { ComponentsItemReadonly } from '@/components/Components'
import { LAYOUT_MODAL_XL_WIDTH } from '@/configs'
import { RootState } from '@/states'

/**
 * 모델 구성 추천 AI
 * @param param0
 * @returns
 */
export const ModelRecommendations = ({
  onImportModel,
}: {
  onImportModel: (model) => void
}) => {
  const { t, i18n } = useTranslation()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject } = projectsState

  // State
  const [busy, setBusy] = React.useState<boolean>(false)
  const [name, setName] = React.useState<string>('')
  const [description, setDescription] = React.useState<string>('')
  const [showGeneratedModels, setShowGeneratedModels] =
    React.useState<boolean>(false)
  const [generatedModels, setGeneratedModels] = React.useState<
    ModelInterface[]
  >([])

  /**
   * 모델 구성 추천 AI 호출
   * @returns
   */
  const callOpenAI = () => {
    if (busy) return
    setBusy(true)
    message.loading(t('generatingModelRecommendations'), 0)

    try {
      const req = {
        projectUid: currentProject?.uid,
        name,
        description,
      }

      callModelRecommendations(req)
        .then((res) => {
          setGeneratedModels(res.data)
          setShowGeneratedModels(true)
          // onImportModel(res.data)

          message.destroy()
          message.success(t('modelRecommendationsComplete'))
        })
        .catch((e) => {
          message.destroy()
          message.error(
            e.response.data.message === 'PLAN_NOT_ALLOWED'
              ? t('error.planNotAllowed')
              : t('error.wrong')
          )
        })
        .then(() => {
          setBusy(false)
        })
    } catch (e) {
      message.destroy()
      message.error(t('error.wrong'))
      setBusy(false)
    }
  }

  /**
   * 모델 선택
   * @param model
   */
  const selectModel = (model) => {
    onImportModel(model)
    setShowGeneratedModels(false)
  }

  /**
   * 요금제 확인
   */
  const isValidAiPlan = useMemo(() => {
    return (
      !currentProject ||
      (currentProject &&
        (currentProject.price === 'PRO' ||
          currentProject.price === 'UNLIMITED'))
    )
  }, [currentProject])

  return (
    <>
      <div>
        <div className="space-y-4">
          <div>
            <label htmlFor="ai-model-name">
              <div className="flex space-x-1 items-center mb-2">
                <QuestionOutlined />
                <span>{t('whatsModelName')}</span>{' '}
                <span className="text-red-500">*</span>
              </div>
              <Input
                value={name}
                id="ai-model-name"
                disabled={!isValidAiPlan}
                placeholder={t('whatsModelNamePlaceHolder')}
                onChange={(e) => setName(e.target.value)}
              />
            </label>
          </div>
          {name && isValidAiPlan ? (
            <div>
              <label htmlFor="ai-model-desc">
                <div className="flex space-x-1 items-center mb-2">
                  <QuestionOutlined />
                  <span>{t('whatsModelDescription')}</span>
                </div>
                <Input.TextArea
                  value={description}
                  id="ai-model-desc"
                  placeholder={t('whatsModelDescriptionPlaceHolder')}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <p className="text-xs text-gray-600 pt-1">
                  {t('whatsModelDescriptionHelp')}
                </p>
              </label>
            </div>
          ) : (
            <></>
          )}
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            disabled={!name || !isValidAiPlan}
            loading={busy}
            onClick={() => callOpenAI()}>
            {t('addModel')}
          </Button>
        </div>
      </div>
      <Modal
        open={showGeneratedModels}
        width={LAYOUT_MODAL_XL_WIDTH}
        cancelText={t('close')}
        onCancel={() => setShowGeneratedModels(false)}
        okText={t('recreateModel')}
        onOk={() => callOpenAI()}
        confirmLoading={busy}>
        <div className="mb-8">
          <h2 className="text-xl text-center">
            {t('modelRecommendationsCompleteTitle')}
          </h2>
        </div>
        <div className="grid lg:grid-cols-3 gap-8">
          {generatedModels.map((model, mIdx) => (
            <div className="space-y-2" key={mIdx}>
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-1">
                  <div className="font-medium">
                    {mIdx + 1}. {model.languageMap?.KO}
                  </div>
                  <div className="text-xs text-gray-500 font-normal">
                    {model.devKey}
                  </div>
                </div>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => selectModel(model)}>
                  {t('select')}
                </Button>
              </div>
              <div className="max-h-96 overflow-y-auto">
                {model?.componentList?.map((component, index) => (
                  <ComponentsItemReadonly
                    key={index}
                    component={component}
                    customClassName="bg-gray-200 shadow rounded"
                    disabled={true}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  )
}
