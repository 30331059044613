import React from 'react'
import { UserInterface } from '@/types'

export const MembersDashboardItem = ({ member }: { member: UserInterface }) => {
  return (
    <div
      className={
        'flex items-center space-x-3 hover:bg-gray-300 rounded-lg p-2'
      }>
      {member?.image && member?.image?.path ? (
        <div
          className={`w-8 h-8 rounded-full bg-gray-300 flex-none bg-cover bg-center`}
          style={{ backgroundImage: `url(${member?.image?.path})` }}></div>
      ) : (
        <div
          className={`w-8 h-8 rounded-full bg-secondary flex-none memex-ico-user`}></div>
      )}
      <div className={'grow overflow-hidden'}>
        <div className={'leading-5 truncate'}>{member?.name}</div>
        <div className={'text-xs text-gray-500 leading-5 truncate'}>
          {member?.email}
        </div>
      </div>
    </div>
  )
}
