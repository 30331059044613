import React, { useEffect } from 'react'
import { shallowEqual, useSelector, connect } from 'react-redux'
import { useNavigate, useLocation, Outlet, useParams } from 'react-router'
import { Layout } from 'antd'
const { Content } = Layout
import { RootState, useAppDispatch } from '@/states'
import { LAYOUT_GNB_COLLAPSE_WIDTH, LAYOUT_GNB_WIDTH } from '@/configs'
import {
  getProfile,
  setAccountMenu,
  setWsClient,
  setWsClientSync,
} from '@/states/actions'
import { AccountModal } from '@/components/Account'
import { Gnb, Header } from '@/components/Layout'
import { ModelsFormModal } from '@/components/Models'
import { ProjectsFormModal } from '@/components/Projects'

export const DefaultLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()

  // Params
  const { projectUid, modelId } = useParams()

  // State (Redux)
  const { authState, layoutState, projectsState } = useSelector(
    (state: RootState) => ({
      authState: state.auth,
      layoutState: state.layout,
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { me, init } = authState
  const { accountMenu, gnb, stretch } = layoutState
  const { currentProject, ws } = projectsState

  // Effect
  useEffect(() => {
    if (!init) {
      // 내 프로필 가져오기
      dispatch(getProfile())
    }
  }, [init])

  useEffect(() => {
    if (!modelId) {
      closeWsConnect()
    }
  }, [modelId])

  /**
   * 헤더 메뉴 닫기
   * @param e
   */
  const closeMenu = (e) => {
    if (accountMenu && !e.target.closest('.stay-open')) {
      dispatch(setAccountMenu(false))
    }
  }

  /**
   * WebSocket 연결 종료
   */
  const closeWsConnect = () => {
    if (ws) {
      dispatch(setWsClient(null))
      dispatch(setWsClientSync([]))
      ws.close()
    }
  }

  return (
    <div
      className={`transition-all container mx-auto !bg-white ${
        stretch
          ? 'w-full !max-w-full'
          : ' border-l border-r border-gray-400 shadow-xl'
      }`}>
      <Layout hasSider onClick={(e) => closeMenu(e)}>
        <Gnb />
        <Layout
          className="site-layout h-screen"
          style={{
            width: `calc(100vw - ${
              gnb ? LAYOUT_GNB_WIDTH : LAYOUT_GNB_COLLAPSE_WIDTH
            }px)`,
          }}>
          <Content
            id="layout-main"
            className={`transition-all h-full grow overflow-y-auto`}>
            <div
              className={`sticky top-0 z-50 bg-gray-200 flex-none transition-all ${
                gnb ? 'pl-20 pr-4 lg:px-10' : 'pl-12 lg:pl-4 px-4'
              }`}>
              <Header />
            </div>
            <div className={``}>
              <div
                className={`transition-all h-full ${
                  gnb ? 'px-4 lg:px-10' : 'px-4'
                }`}>
                <Outlet />
              </div>
            </div>
          </Content>
        </Layout>
        <ProjectsFormModal />
        <AccountModal />
        {currentProject?.role === 'OWNER' ||
        currentProject?.role === 'ADMIN' ? (
          <>
            <ModelsFormModal />
          </>
        ) : (
          <></>
        )}
      </Layout>
    </div>
  )
}
