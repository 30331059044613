import { AlertStatus } from '@/components/Common'
import {
  COMPONENTS_QUICK_BUTTONS_KEY,
  LAYOUT_COMPONENT_TYPE_WIDTH,
  LAYOUT_MODAL_WIDTH,
  RESERVED_WORDS,
  availableComponents,
} from '@/configs'
import { LINK_HELP_DEVKEY, LINK_RICH_TEXT_EDITOR_GIT } from '@/constants'
import { RootState, useAppDispatch } from '@/states'
import {
  setCategoryFormModal,
  setComponentFormModal,
  setTmpModel,
} from '@/states/actions'
import {
  ComponentFieldType,
  ComponentOptionInterface,
  ComponentTypeInterface,
  LanguageMap,
} from '@/types'
import { setDefaultComponentOptionValue } from '@/utils/helpers'
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  InteractionOutlined,
  QuestionCircleOutlined,
  RightOutlined,
} from '@ant-design/icons'
import { Button, Col, Input, Modal, Row, Select, Tooltip, message } from 'antd'
import { useFormik } from 'formik'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'
import { ComponentsOption, ComponentsTypeItem } from '.'

const initialComponentFormValues = {
  name: '',
  devKey: '',
  selector: {
    KO: '',
    EN: '',
    JP: '',
    CN: '',
  },
  selectorGroupId: '',
}

export const ComponentsFormModal = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const [, updateState] = React.useState<{} | undefined>()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  // State (Redux)
  const { modalsState, projectsState } = useSelector(
    (state: RootState) => ({
      modalsState: state.modals,

      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { componentFormModal, componentInfo, parentComponentInfo } = modalsState
  const { currentProject, tmpModel, flattenComponentList, categoriesList } =
    projectsState

  // State
  const [showQuickButtons, setShowQuickButtons] = useState<boolean>(
    !Cookies.get(COMPONENTS_QUICK_BUTTONS_KEY) ||
      (Cookies.get(COMPONENTS_QUICK_BUTTONS_KEY) &&
        Cookies.get(COMPONENTS_QUICK_BUTTONS_KEY) === '1')
      ? true
      : false
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [mode, setMode] = useState<'type' | 'form'>('type')
  const [type, setType] = useState<ComponentFieldType>('')
  const [typeObj, setTypeObj] = useState<
    ComponentTypeInterface | null | undefined
  >(null)
  const [options, setOptions] = useState<ComponentOptionInterface>({})

  // Effect
  useEffect(() => {
    if (componentFormModal) {
      setTimeout(() => {
        document.getElementById('componentFormName')?.focus()
      })

      if (!componentInfo) {
        formikComponentForm.validateForm()
      }
    } else {
      resetForm()
    }
  }, [componentFormModal])

  useEffect(() => {
    if (mode === 'form') {
      setTimeout(() => {
        document.getElementById('componentFormName')?.focus()
      })
    }
  }, [mode])

  useEffect(() => {
    if (componentInfo && currentProject) {
      setMode('form')
      setType(componentInfo.type)
      setTypeObj(availableComponents.find((c) => c.type === componentInfo.type))
      formikComponentForm.setFieldValue(
        'name',
        componentInfo.languageMap[currentProject.defaultLang]
      )
      formikComponentForm.setFieldValue('devKey', componentInfo.devKey)

      // 옵션
      if (componentInfo.option) {
        setOptions(JSON.parse(JSON.stringify(componentInfo.option)))
      }

      // 카테고리 선택지
      if (componentInfo.selectorGroupId) {
        formikComponentForm.setFieldValue(
          'selectorGroupId',
          componentInfo.selectorGroupId
        )
      }
    }
  }, [componentInfo])

  useEffect(() => {
    if (typeObj && typeObj.options && !componentInfo) {
      const optionsFetch = {}
      typeObj.options.forEach((option) => {
        optionsFetch[option] = setDefaultComponentOptionValue(option)
      })

      setOptions(optionsFetch)
    }
  }, [typeObj])

  // Memo
  const hasTitleComponent = React.useMemo(() => {
    return !!tmpModel?.flattenComponentList?.find((f) => f.type === 'TITLE')
  }, [tmpModel])

  // Validation
  const validationComponentFormSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    devKey: Yup.string()
      .required(t('validation.required'))
      .notOneOf(
        [...RESERVED_WORDS, ...RESERVED_WORDS.map((w) => w.toLowerCase())],
        t('validation.reservedWordsCannotUsedForDevKey')
      ),
  })

  // Formik
  const formikComponentForm = useFormik({
    initialValues: initialComponentFormValues,
    validationSchema: validationComponentFormSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const updatedModel = tmpModel
      let updatedType = type

      if (
        !updatedModel ||
        !currentProject ||
        (updatedType === 'CATEGORY' && !values.selectorGroupId)
      )
        return false

      const updatedFlattenComponentList = tmpModel.flattenComponentList

      // 예약어 개발키 확인
      if (RESERVED_WORDS.includes(values.devKey.toUpperCase())) {
        formikComponentForm.setErrors({
          devKey: t('validation.reservedWordsCannotUsedForDevKey'),
        })
        document.getElementById('modelFormDevKey')?.focus()
        return false
      }

      // 중복 개발키 확인
      if (
        (!componentInfo &&
          updatedFlattenComponentList?.find(
            (fc) => fc.devKey === values.devKey
          )) ||
        (componentInfo &&
          updatedFlattenComponentList?.find(
            (fc) =>
              fc.devKey === values.devKey && fc.devKey !== componentInfo.devKey
          ))
      ) {
        message.warning(t('error.duplicatedDevKey'))
        document.getElementById('componentFormDevKey')?.focus()
        return false
      }

      // 숫자: 최소 <> 최대값 비교
      if (
        typeObj?.type === 'NUMBER' &&
        options &&
        options.min &&
        options.max &&
        typeof options.min === 'number' &&
        options.min > options.max
      ) {
        message.warning(t('error.minMaxRange'))
        return false
      }

      // 옵션 필수 값 선택
      if (typeObj?.type === 'DATE' && options && !options.dateFormats) {
        return false
      }

      // 언어별 값 선택
      const languageMap = {}
      currentProject.languageList.forEach((lang) => {
        languageMap[lang] = values.name.normalize('NFC').replace('\b', '')
      })

      const req = {
        name: values.name.normalize('NFC').replace('\b', ''),
        devKey: values.devKey.replace(/[^A-Z0-9]/gi, ''),
        selectorList: [] as { languageMap: LanguageMap }[],
        selectorGroupId: values.selectorGroupId,
      }

      const isOld = componentInfo && typeof componentInfo.id === 'number'

      // 다국어 옵션
      if (
        (updatedType === 'SINGLE_LINE_TEXT' ||
          updatedType === 'SINGLE_LINE_TEXT_MONO') &&
        !isOld
      ) {
        updatedType = !options.multiLanguage
          ? 'SINGLE_LINE_TEXT_MONO'
          : 'SINGLE_LINE_TEXT'
      } else if (
        (updatedType === 'LONG_LINE_TEXT' ||
          updatedType === 'LONG_LINE_TEXT_MONO') &&
        !isOld
      ) {
        updatedType = !options.multiLanguage
          ? 'LONG_LINE_TEXT_MONO'
          : 'LONG_LINE_TEXT'
      }

      // 추가
      if (!componentInfo) {
        if (!parentComponentInfo) {
          updatedModel.componentList = [
            ...updatedModel.componentList,
            {
              id: uuidv4(),
              type: updatedType,
              languageMap,
              devKey: req.devKey.replace(/[^A-Z0-9]/gi, ''),
              option: options,
              order: updatedModel.componentList.length + 1,
              selectorList: req.selectorList,
              selectorGroupId: Number(req.selectorGroupId),
              childList: [],
            },
          ]

          dispatch(setTmpModel(updatedModel))
        } else {
          addToComponents(updatedModel.componentList, req)
        }
      }
      // 수정
      else {
        if (!parentComponentInfo) {
          const idx = updatedModel.componentList.findIndex(
            (c) => c.id === componentInfo.id
          )

          updatedModel.componentList[idx] = {
            id: componentInfo.id,
            type: updatedType,
            languageMap,
            devKey: req.devKey.replace(/[^A-Z0-9]/gi, ''),
            option: options,
            order: componentInfo.order,
            selectorList: req.selectorList,
            selectorGroupId: Number(req.selectorGroupId),
            childList: componentInfo.childList,
          }

          dispatch(setTmpModel(updatedModel))
        } else {
          updateFromComponents(updatedModel.componentList, req)
        }
      }

      dispatch(setComponentFormModal(false))

      /**
       * 하위 컴포넌트에 추가
       * @param components
       */
      function addToComponents(components, newComponent) {
        components.forEach((com, cIdx) => {
          if (parentComponentInfo && com.id === parentComponentInfo.id) {
            com.childList = com.childList ? com.childList : []
            com.childList.push({
              id: uuidv4(),
              type: updatedType,
              languageMap,
              devKey: newComponent.devKey.replace(/[^A-Z0-9]/gi, ''),
              option: options,
              order: com.childList.length + 1,
              selectorList: newComponent.selectorList,
              selectorGroupId: newComponent.selectorGroupId
                ? Number(newComponent.selectorGroupId)
                : null,
              childList: [],
            })

            dispatch(setTmpModel(updatedModel))
          } else if (com.childList && com.childList.length) {
            addToComponents(com.childList, newComponent)
          }
        })
      }

      /**
       * 하위 컴포넌트 수정
       * @param components
       */
      function updateFromComponents(components, updatedComponent) {
        components.forEach((com, cIdx) => {
          if (
            componentInfo &&
            parentComponentInfo &&
            com.id === parentComponentInfo.id
          ) {
            const idx = com.childList.findIndex(
              (c) => c.id === componentInfo.id
            )

            com.childList[idx] = {
              id: componentInfo.id,
              type: componentInfo.type,
              languageMap,
              devKey: updatedComponent.devKey.replace(/[^A-Z0-9]/gi, ''),
              option: options,
              order: componentInfo.order,
              selectorList: updatedComponent.selectorList,
              selectorGroupId: updatedComponent.selectorGroupId
                ? Number(updatedComponent.selectorGroupId)
                : null,
              childList: componentInfo.childList,
            }
            dispatch(setTmpModel(updatedModel))
          } else if (com.childList && com.childList.length) {
            updateFromComponents(com.childList, updatedComponent)
          }
        })
      }
    },
  })

  /**
   * 폼 리셋
   */
  const resetForm = () => {
    formikComponentForm.resetForm()
    setLoading(false)
    setMode('type')
    setType('')
    setTypeObj(null)
    setOptions({})
  }

  /**
   * 컴포넌트 타입 선택
   * @param type
   */
  const selectComponentType = (type) => {
    if (!componentFormModal) {
      dispatch(setComponentFormModal(true))
    }

    setMode('form')
    setType(type)
    setTypeObj(availableComponents.find((c) => c.type === type))
  }

  /**
   * 옵션 변경
   * @param key
   * @param value
   */
  const onHandleChangeOption = (key, value) => {
    const updatedOptions = options
    updatedOptions[key] = value

    setOptions(updatedOptions)
    forceUpdate()
  }

  /**
   * 컴포넌트 타입 버튼 Toggle
   */
  const toggleComponentsQuickBtns = () => {
    setShowQuickButtons(!showQuickButtons)

    Cookies.set(COMPONENTS_QUICK_BUTTONS_KEY, !showQuickButtons ? '1' : '0', {
      expires: 86400,
    })
  }

  /**
   * 랜덤 개발키 생성
   */
  const generateRandomDevKey = () => {
    formikComponentForm.setFieldValue('devKey', uuidv4())
  }

  return currentProject && tmpModel ? (
    <>
      {/* 컴포넌트 타입 우측메뉴: 시작 */}
      <div
        className="absolute top-0 right-0 border-l border-gray-300 h-screen overflow-y-auto pt-4 px-2.5 pb-10 hidden lg:block"
        style={{ width: showQuickButtons ? LAYOUT_COMPONENT_TYPE_WIDTH : 35 }}>
        <div className="mb-9 space-y-4">
          <div
            className="cursor-pointer"
            onClick={toggleComponentsQuickBtns}
            title={showQuickButtons ? t('hide') : t('show')}>
            {showQuickButtons ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
          </div>
        </div>
        {showQuickButtons ? (
          <ul className="space-y-2.5">
            {availableComponents
              .filter((ac) => !ac.hidden)
              .map((component) => (
                <ComponentsTypeItem
                  disabled={hasTitleComponent && component.type === 'TITLE'}
                  key={component.type}
                  onClick={() => {
                    if (!hasTitleComponent || component.type !== 'TITLE') {
                      selectComponentType(component.type)
                    }
                  }}
                  componentType={component}></ComponentsTypeItem>
              ))}
          </ul>
        ) : (
          <></>
        )}
      </div>
      {/* 컴포넌트 타입 우측메뉴: 끝 */}
      {/* 컴포넌트 모달: 시작 */}
      <Modal
        width={LAYOUT_MODAL_WIDTH}
        closeIcon={<CloseCircleOutlined title={t('close')} />}
        open={componentFormModal}
        maskClosable={false}
        onCancel={() => dispatch(setComponentFormModal(false))}
        title={
          <div className="flex items-center space-x-2">
            <span>{tmpModel.languageMap[currentProject.defaultLang]}</span>
            <span className="flex items-center">
              <RightOutlined className="text-xs"></RightOutlined>
            </span>
            <span className="flex space-x-1 items-center">
              <span>
                {componentInfo ? t('editComponent') : t('addComponent')}
              </span>
            </span>
            {mode === 'form' ? (
              <span className="flex items-center">
                <RightOutlined className="text-xs"></RightOutlined>
              </span>
            ) : (
              <></>
            )}
            {mode === 'form' ? (
              <span>{t('componentTypes.' + type + '.name')}</span>
            ) : (
              <></>
            )}
          </div>
        }
        footer={[
          <div key={'footer'} className={'flex justify-between items-center'}>
            <div>
              {mode === 'form' ? (
                <Button
                  type="primary"
                  ghost
                  onClick={() => dispatch(setComponentFormModal(false))}>
                  {t('cancel')}
                </Button>
              ) : (
                <></>
              )}
            </div>
            {mode === 'form' ? (
              <div>
                <Button
                  type={'primary'}
                  icon={<CheckOutlined />}
                  onClick={() => formikComponentForm.submitForm()}
                  disabled={loading}
                  loading={loading}>
                  {componentInfo ? t('update') : t('addComponent')}
                </Button>
              </div>
            ) : (
              <></>
            )}
            {mode === 'type' && tmpModel.componentList.length === 0 ? (
              <div>
                <Button
                  type={'primary'}
                  onClick={() => dispatch(setComponentFormModal(false))}>
                  {t('skip')}
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>,
        ]}>
        <>
          <form onSubmit={formikComponentForm.handleSubmit} method="POST">
            <AlertStatus
              status={formikComponentForm.status}
              onClick={() => formikComponentForm.setStatus(null)}></AlertStatus>
            <div className={'space-y-6'}>
              {/* 컴포넌트 종류 선택: 시작 */}
              {mode === 'type' ? (
                <Row gutter={24}>
                  {availableComponents
                    .filter((ac) => !ac.hidden)
                    .map((component) => (
                      <Col
                        key={component.type}
                        className="mb-2.5"
                        xs={24}
                        md={12}>
                        <ComponentsTypeItem
                          disabled={
                            hasTitleComponent && component.type === 'TITLE'
                          }
                          onClick={() => {
                            if (
                              !hasTitleComponent ||
                              component.type !== 'TITLE'
                            ) {
                              selectComponentType(component.type)
                            }
                          }}
                          componentType={component}></ComponentsTypeItem>
                      </Col>
                    ))}
                </Row>
              ) : (
                <></>
              )}
              {/* 컴포넌트 종류 선택: 끝 */}
              {/* 컴포넌트 폼 선택: 시작 */}
              {mode === 'form' ? (
                <Row gutter={24}>
                  <Col xs={24} md={12} lg={12} className="mb-6 md:mb-0">
                    <div className="block">
                      <label htmlFor="componentFormName" className="">
                        <div className={'mb-2'}>
                          {t('componentName')}{' '}
                          <span className="text-red-500">*</span>
                        </div>
                        <Input
                          id={'componentFormName'}
                          name="name"
                          onChange={formikComponentForm.handleChange}
                          value={formikComponentForm.values.name}
                        />
                      </label>
                      {formikComponentForm.touched.name &&
                      formikComponentForm.errors.name ? (
                        <p className="my-1 text-xs text-red-500">
                          {formikComponentForm.errors.name}
                        </p>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={24} md={12} lg={12}>
                    <div className="space-y-4">
                      <div className="block">
                        <label htmlFor="componentFormDevKey" className="">
                          <div
                            className={
                              'mb-2 flex justify-between items-center'
                            }>
                            <div>
                              {t('devKey')}{' '}
                              <span className="text-red-500">*</span>
                            </div>
                            {!process.env.REACT_APP_PROJECT_ID && (
                              <a
                                href={LINK_HELP_DEVKEY}
                                tabIndex={-1}
                                target="_blank"
                                rel="noreferrer"
                                className="text-xs flex items-center space-x-0.5">
                                <QuestionCircleOutlined />
                                <span>{t('whatsDevKey')}</span>
                              </a>
                            )}
                          </div>
                          <Input.Group compact>
                            <Input
                              id={'componentFormDevKey'}
                              name="devKey"
                              onChange={formikComponentForm.handleChange}
                              value={formikComponentForm.values.devKey}
                              style={{
                                width: `calc(100% - 32px)`,
                              }}
                            />
                            <Tooltip title={t('generateRandomDevKey')}>
                              <Button
                                icon={<InteractionOutlined />}
                                onClick={() => generateRandomDevKey()}
                              />
                            </Tooltip>
                          </Input.Group>
                        </label>
                        {formikComponentForm.touched.devKey &&
                        formikComponentForm.errors.devKey ? (
                          <p className="my-1 text-xs text-red-500">
                            {formikComponentForm.errors.devKey}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              {/* 카테고리 선택: 시작 */}
              {type === 'CATEGORY' ? (
                <div>
                  <div className="mb-2">
                    {t('categoryType')} <span className="text-red-500">*</span>
                  </div>
                  <Input.Group compact>
                    <Select
                      value={formikComponentForm.values.selectorGroupId}
                      onChange={(val) => {
                        formikComponentForm.setFieldValue(
                          'selectorGroupId',
                          val
                        )
                      }}
                      style={{
                        width: `calc(100% - 60px)`,
                      }}
                      placeholder={
                        categoriesList.length
                          ? t('selectCategory')
                          : t('categoryNeed')
                      }>
                      {categoriesList.map((category) => (
                        <Select.Option key={category.id} value={category.id}>
                          {category.languageMap[currentProject.defaultLang]}
                        </Select.Option>
                      ))}
                    </Select>
                    <Button
                      type="primary"
                      style={{
                        width: `60px`,
                      }}
                      onClick={() => dispatch(setCategoryFormModal(true))}>
                      {t('add')}
                    </Button>
                  </Input.Group>
                  {type === 'CATEGORY' &&
                  formikComponentForm.dirty &&
                  !formikComponentForm.values.selectorGroupId ? (
                    <p className="my-1 text-xs text-red-500">
                      {t('validation.required')}
                    </p>
                  ) : null}
                </div>
              ) : (
                <></>
              )}
              {/* 카테고리 선택: 시작 */}
              {/* Rich Text: 시작 */}
              {type === 'RICH_TEXT' && (
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('componentTypes.RICH_TEXT.help', {
                        link: LINK_RICH_TEXT_EDITOR_GIT,
                      }),
                    }}></p>
                </div>
              )}
              {/* Rich Text: 끝 */}
              {/* 옵션: 시작 */}
              {mode === 'form' &&
              typeObj &&
              typeObj.options &&
              typeObj.options.length ? (
                <div>
                  <div className="mb-2">{t('options')}</div>
                  <Row gutter={24}>
                    {typeObj.options.map((option) => (
                      <Col
                        xs={24}
                        md={
                          option === 'min' || option === 'max'
                            ? 6
                            : option === 'multiLanguage'
                            ? 24
                            : 12
                        }
                        key={option}
                        className="mb-3">
                        <ComponentsOption
                          component={componentInfo}
                          componentType={typeObj.type}
                          componentOptionType={option}
                          onChangeOption={onHandleChangeOption}
                          defaultValue={
                            options && options[option]
                              ? options[option]
                              : setDefaultComponentOptionValue(option)
                          }
                        />
                        {formikComponentForm.submitCount &&
                        typeObj.type === 'DATE' &&
                        options &&
                        // @ts-ignore
                        !options.dateFormats ? (
                          <p className="my-1 text-xs text-red-500">
                            {t('validation.required')}
                          </p>
                        ) : null}
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : (
                <></>
              )}
              {/* 옵션: 시작 */}
              {/* 컴포넌트 폼 선택: 시작 */}
            </div>
          </form>
        </>
      </Modal>
      {/* 컴포넌트 모달: 끝 */}
    </>
  ) : (
    <></>
  )
}
