import { CustomRoute, PrivateRoute } from '@/components/Common'
import { DefaultLayout } from '@/components/Layout'
import NotFound from '@/pages/404'
import ForgetPassword from '@/pages/Auth/ForgetPassword'
import LoginPage from '@/pages/Auth/Login'
import ResetPassword from '@/pages/Auth/ResetPassword'
import SignUpPage from '@/pages/Auth/Signup'
import Home from '@/pages/Home'
import ProjectsCategories from '@/pages/Projects/ProjectsCategories'
import ProjectsDashboard from '@/pages/Projects/ProjectsDashboard'
import ProjectsDevelopersAPIs from '@/pages/Projects/ProjectsDevelopersAPIs'
import ProjectsDevelopersAccess from '@/pages/Projects/ProjectsDevelopersAccess'
import ProjectsDevelopersTypescript from '@/pages/Projects/ProjectsDevelopersTypescript'
import ProjectsIndexPage from '@/pages/Projects/ProjectsIndex'
import ProjectsMediaContents from '@/pages/Projects/ProjectsMediaContents'
import ProjectsMembers from '@/pages/Projects/ProjectsMembers'
import ProjectsModelsComponents from '@/pages/Projects/ProjectsModelsComponents'
import ProjectsModelsContents from '@/pages/Projects/ProjectsModelsContents'
import ProjectsModelsSettings from '@/pages/Projects/ProjectsModelsSettings'
import ProjectsPublishIndex from '@/pages/Projects/ProjectsPublish'
import ProjectsSettings from '@/pages/Projects/ProjectsSettings'
import ProjectsUsage from '@/pages/Projects/ProjectsUsage'
import { store } from '@/states/store'
import TagManager from 'react-gtm-module'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'
import ProjectsNotFound from './pages/Projects/ProjectsNotFound'

if (process.env.REACT_APP_GOOGLE_TAG_ID) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GOOGLE_TAG_ID,
  })
}

const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight()

const setNavigator = () => {
  const body = document.body
  body.classList.add(
    navigator.platform && navigator.platform.includes('Win') ? 'win' : 'non-win'
  )
}
setNavigator()

const App = () => {
  return (
    <Provider store={store}>
      <Helmet>
        {!!process.env.REACT_APP_STATUSPAGE_ID && (
          <script
            src={`https://${process.env.REACT_APP_STATUSPAGE_ID}.statuspage.io/embed/script.js`}
          />
        )}
        <script>
          {`
          (function(){var w=window;if(w.ChannelIO){return w.console.error("ChannelIO script included twice.");}var ch=function(){ch.c(arguments);};ch.q=[];ch.c=function(args){ch.q.push(args);};w.ChannelIO=ch;function l(){if(w.ChannelIOInitialized){return;}w.ChannelIOInitialized=true;var s=document.createElement("script");s.type="text/javascript";s.async=true;s.src="https://cdn.channel.io/plugin/ch-plugin-web.js";var x=document.getElementsByTagName("script")[0];if(x.parentNode){x.parentNode.insertBefore(s,x);}}if(document.readyState==="complete"){l();}else{w.addEventListener("DOMContentLoaded",l);w.addEventListener("load",l);}})();

          ChannelIO('boot', {
            "pluginKey": "${process.env.REACT_APP_CHANNEL_TALK_KEY}"
          });
          `}
        </script>
      </Helmet>
      <BrowserRouter>
        <CustomRoute>
          <Route index path="/" element={<Home />} />
          <Route element={<PrivateRoute authentication={false} />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/password/forget" element={<ForgetPassword />} />
            <Route path="/password/reset" element={<ResetPassword />} />
          </Route>
          <Route element={<PrivateRoute authentication={true} />}>
            <Route path="/projects" element={<DefaultLayout />}>
              <Route path="" element={<ProjectsIndexPage />} />

              <Route path=":projectUid">
                <Route path="" element={<ProjectsDashboard />} />
                <Route path="members" element={<ProjectsMembers />} />
                <Route path="settings" element={<ProjectsSettings />} />
                <Route path="usage" element={<ProjectsUsage />} />
                <Route path="media" element={<ProjectsMediaContents />} />
                <Route path="categories" element={<ProjectsCategories />} />
                <Route path="publish" element={<ProjectsPublishIndex />} />
                <Route
                  path=":modelId/contents"
                  element={<ProjectsModelsContents />}
                />
                <Route
                  path=":modelId/components"
                  element={<ProjectsModelsComponents />}
                />
                <Route
                  path=":modelId/settings"
                  element={<ProjectsModelsSettings />}
                />
                <Route path="developers">
                  <Route path="access" element={<ProjectsDevelopersAccess />} />
                  <Route
                    path="externalAPIs"
                    element={<ProjectsDevelopersAPIs />}
                  />
                  <Route
                    path="typescript"
                    element={<ProjectsDevelopersTypescript />}
                  />
                </Route>
              </Route>
            </Route>
            <Route path="/projects/notFound" element={<ProjectsNotFound />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </CustomRoute>
      </BrowserRouter>
      {process.env.REACT_APP_ENV === 'dev' ? (
        <div
          className="fixed left-6 leading-6 text-red-500 font-bold text-xl -mt-6"
          style={{
            top: '50%',
            zIndex: 9999,
          }}>
          DEVELOPMENT
          <br />
          개발, 테스트용
        </div>
      ) : (
        <></>
      )}
    </Provider>
  )
}

export default App
