import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import Cookies from 'js-cookie'
import { Button, Spin, Modal, message, Input } from 'antd'
import { SearchOutlined, PlusSquareOutlined } from '@ant-design/icons'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { RootState, useAppDispatch } from '@/states'
import {
  setProjectFormModal,
  setCurrentProject,
  setModelsList,
} from '@/states/actions'
import { createExternalContents } from '@/api'
import { SURVEY_OPEN_KEY } from '@/configs'
import { LINK_HELP_PROJECT } from '@/constants'
import {
  HelpSurvey1,
  HelpSurvey2,
  HelpSurvey3,
  FindMore,
} from '@/components/Help'
import { ExtIcon } from '@/components/Common'
import { ProjectsList, ListViewType } from '@/components/Projects'

const ProjectsIndex = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  if (process.env.REACT_APP_PROJECT_ID) {
    navigate(`/projects/${process.env.REACT_APP_PROJECT_ID}`)
  }

  // State (Redux)
  const { projectsState, authState, layoutState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const { projectsListInit, projectList } = projectsState
  const { init, me } = authState
  const { gnb } = layoutState

  // State
  const [isSurvey1, setIsSurvey1] = React.useState<boolean>(false)
  const [survey1Answer, setSurvey1Answer] = React.useState<string>('')
  const [surveyEtc1Answer, setSurveyEtc1Answer] = React.useState<string>('')
  const [isSurvey2, setIsSurvey2] = React.useState<boolean>(false)
  const [survey2Answer, setSurvey2Answer] = React.useState<string>('')
  const [isSurvey3, setIsSurvey3] = React.useState<boolean>(false)
  const [survey3Answer, setSurvey3Answer] = React.useState<CheckboxValueType[]>(
    []
  )
  const [surveyEtc3Answer, setSurveyEtc3Answer] = React.useState<string>('')
  const [q, setQ] = React.useState<string>('')

  // Params
  const [searchParams, setSearchParams] = useSearchParams()

  // Effect
  useEffect(() => {
    dispatch(setCurrentProject(null))
    dispatch(setModelsList([]))
  }, [])

  useEffect(() => {
    if (
      searchParams.get('new') &&
      searchParams.get('new') === 'hello' &&
      !Cookies.get(SURVEY_OPEN_KEY)
    ) {
      setIsSurvey1(true)
      Cookies.set(SURVEY_OPEN_KEY, '1')
    }
  }, [searchParams])

  /**
   * 설문 제출하기
   * @param isComplete
   */
  const submitSimpleSurvey = (isComplete) => {
    const req = {
      data: {
        email: me?.email,
        answer1: {
          KO:
            survey1Answer +
            (survey1Answer === 'ETC' ? ' - ' + surveyEtc1Answer : ''),
        },
        answer2: {
          KO: survey2Answer,
        },
        answer3: {
          KO: [
            ...survey3Answer.filter((s) => s !== 'ETC'),
            ...survey3Answer
              .filter((s) => s === 'ETC')
              .map((s) => s + (' - ' + surveyEtc3Answer)),
          ].join(','),
        },
      },
      publish: false,
    }

    createExternalContents('survey_test', req)
      .then((res) => {
        if (isComplete) {
          message.success(t('survey.complete'))
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .then(() => {
        setIsSurvey1(false)
        setIsSurvey2(false)
        setIsSurvey3(false)

        dispatch(setProjectFormModal(true))
      })
  }

  return (
    <>
      <Helmet>
        <title>
          {t('projects')} · {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      {projectsListInit ? (
        <div className="pb-10">
          <div className={`pb-7`}>
            {projectList.length > 0 && (
              <div
                className={`flex justify-between sticky top-0 z-10 bg-gray-200 p-2 ${
                  gnb ? '' : 'pl-8'
                }`}>
                <div className={'w-1/2 flex items-center'}>
                  <SearchOutlined className="opacity-40" />
                  <div className="hidden lg:block w-full">
                    <Input
                      className="w-full"
                      value={q}
                      onChange={(e) => setQ(e.target.value)}
                      bordered={false}
                      placeholder={t('enterKeywordForProject')}
                    />
                  </div>
                  <div className="lg:hidden w-full">
                    <Input
                      className="w-full"
                      value={q}
                      onChange={(e) => setQ(e.target.value)}
                      bordered={false}
                    />
                  </div>
                </div>
                <div className=" w-1/2 flex justify-end items-center space-x-4">
                  {/* 프로젝트 목록 보기 옵션: 시작 */}
                  <ListViewType />
                  {/* 프로젝트 목록 보기 옵션: 끝 */}
                  <div className="lg:hidden">
                    <Button
                      type={'primary'}
                      icon={<PlusSquareOutlined />}
                      htmlType={'button'}
                      onClick={() => dispatch(setProjectFormModal(true))}
                    />
                  </div>
                  <div className="hidden lg:block">
                    <Button
                      type={'primary'}
                      icon={<PlusSquareOutlined />}
                      htmlType={'button'}
                      onClick={() => dispatch(setProjectFormModal(true))}>
                      {t('createProject')}
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {projectList.length ? (
              <div className={'pt-0'}>
                {/* 프로젝트 목록: 시작 */}
                <ProjectsList q={q} />
                {/* 프로젝트 목록: 끝 */}
              </div>
            ) : (
              <div className={'py-5 lg:py-20'}>
                <div>
                  <h1 className="text-3xl font-semibold mb-5">
                    {t('createProjectTitle')}
                  </h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('createProjectDesc'),
                    }}
                  />
                  <div className="mb-10 flex">
                    <a
                      href={LINK_HELP_PROJECT}
                      target="_blank"
                      rel="noreferrer"
                      className="flex space-x-1 items-center">
                      <span>{t('learnMoreAboutProject')}</span>
                      <ExtIcon />
                    </a>
                  </div>
                  <div className={'flex'}>
                    <Button
                      type={'primary'}
                      htmlType={'button'}
                      onClick={() => {
                        dispatch(setProjectFormModal(true))
                      }}>
                      {t('createProject')}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* 더 알아보기: 시작 */}
          <FindMore />
          {/* 더 알아보기: 끝 */}
        </div>
      ) : (
        <div className={'h-screen flex justify-center items-center'}>
          <Spin />
        </div>
      )}
      {/* 첫 가입 설문: 시작 */}
      <Modal
        open={isSurvey1}
        width={460}
        cancelText={t('skip')}
        okText={t('next')}
        onCancel={() => {
          setIsSurvey1(false)
          dispatch(setProjectFormModal(true))
        }}
        onOk={() => {
          if (survey1Answer) {
            setIsSurvey1(false)
            setIsSurvey2(true)
          }
        }}>
        <HelpSurvey1
          answer={survey1Answer}
          onSelect={setSurvey1Answer}
          answerEtc={surveyEtc1Answer}
          onSelectEtc={setSurveyEtc1Answer}
        />
      </Modal>
      <Modal
        open={isSurvey2}
        width={460}
        cancelText={t('skip')}
        okText={t('next')}
        onCancel={() => {
          submitSimpleSurvey(false)
        }}
        onOk={() => {
          if (survey2Answer) {
            setIsSurvey2(false)
            setIsSurvey3(true)
          }
        }}>
        <HelpSurvey2 answer={survey2Answer} onSelect={setSurvey2Answer} />
      </Modal>
      <Modal
        open={isSurvey3}
        width={460}
        cancelText={t('skip')}
        okText={t('complete')}
        onCancel={() => {
          submitSimpleSurvey(false)
        }}
        onOk={() => {
          submitSimpleSurvey(true)
        }}>
        <HelpSurvey3
          answer={survey3Answer}
          onSelect={(options) => {
            setSurvey3Answer(options)
          }}
          answerEtc={surveyEtc3Answer}
          onSelectEtc={setSurveyEtc3Answer}
        />
      </Modal>
      {/* 첫 가입 설문: 끝 */}
    </>
  )
}

export default ProjectsIndex
