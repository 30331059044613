import { TreeDataInterface } from '@/types/component.type'
import { TreeSelect } from 'antd'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const CategoriesPreview = ({ value }: { value: string }) => {
  const { t, i18n } = useTranslation()

  const treeData: TreeDataInterface[] = useMemo(() => {
    const curCateArr = value.split('\n')
    const previewArr = [] as TreeDataInterface[]

    curCateArr
      .filter((c) => !c.includes(':'))
      .forEach(async (cate, cateIdx) => {
        previewArr.push({
          value: cate,
          title: cate,
          children: [],
        })

        /**
         * 하위 카테고리 구하기
         * @param parentNameArr
         * @param depth
         */
        const setChildList = (
          parentNameArr: string[],
          depth: number,
          parentTree: TreeDataInterface
        ) => {
          // parentSelector.childList = []

          curCateArr
            .filter((c) => {
              return (
                c.includes(':') &&
                c.split(':').length - 1 === depth &&
                c.split(':')[depth - 1] === parentNameArr[depth - 1] &&
                c.includes(parentNameArr.join(':'))
              )
            })
            .forEach((childCate, order) => {
              const nextChildCate = childCate.split(':')[depth]

              const childObj = {
                value: childCate,
                title: childCate.split(':')[childCate.split(':').length - 1],
                children: [],
              }

              parentTree.children?.push(childObj)

              if (parentTree) {
                setChildList(
                  [...parentNameArr, nextChildCate],
                  depth + 1,
                  childObj
                )
              }
            })
        }

        setChildList([cate], 1, previewArr[cateIdx])
      })

    return previewArr
  }, [value])

  return (
    <div>
      <TreeSelect
        showSearch
        value={[]}
        style={{ width: '100%' }}
        placeholder={t('categoryPreview')}
        multiple
        treeDefaultExpandAll
        treeData={treeData}
      />
    </div>
  )
}

export default CategoriesPreview
