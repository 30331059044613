import { MediaThumb } from '@/components/Media'
import {
  LAYOUT_DEFAULT_CELL_BODY_HEIGHT,
  LAYOUT_DEFAULT_CELL_WIDTH,
} from '@/configs'
import { RootState, useAppDispatch } from '@/states'
import {
  setContentsRelationSelectorModal,
  setMediaSelectorModal,
} from '@/states/actions'
import {
  CellInterface,
  ComponentInterface,
  ContentsInterface,
  LanguageMap,
  LanguagesAvailable,
  UserInterface,
} from '@/types'
import { TreeDataInterface } from '@/types/component.type'
import { LoadingOutlined } from '@ant-design/icons'
import {
  DatePicker,
  Input,
  InputNumber,
  Spin,
  Switch,
  Tooltip,
  TreeSelect,
} from 'antd'
import locale from 'antd/es/date-picker/locale/ko_KR'
import moment from 'moment-timezone'
import 'moment/locale/ko'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { getTreeSelectors } from '../../utils/helpers'

let focusClass = 'relative cell-active bg-white z-10'

interface CellComponentProps {
  contents: ContentsInterface
  component: ComponentInterface
  cell: CellInterface | null | undefined
  onCellChange: (
    componentId: number | undefined,
    key: string,
    value: boolean | number | string | object | null,
    lang?: LanguagesAvailable
  ) => void
  parentComponent?: ComponentInterface
  customClassName?: string
}

export const CellComponent = ({
  contents,
  component,
  cell,
  onCellChange,
  parentComponent,
  customClassName,
}: CellComponentProps) => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // Params
  const { projectUid, modelId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  // State (Redux)
  const { projectsState, modalState, authState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      modalState: state.modals,
      authState: state.auth,
    }),
    shallowEqual
  )
  const {
    currentProject,
    currentModel,
    currentLanguage,
    contentsPagination,
    contentsSort,
    contentsListLoading,
    categoriesList,
    ws,
    wsSync,
  } = projectsState
  const { mediaSelectorModal, contentsRelationSelectorModal } = modalState
  const { me } = authState

  // State
  const [isDirtyMedia, setIsDirtyMedia] = useState<boolean>(false)
  const [isDirtyRelation, setIsDirtyRelation] = useState<boolean>(false)
  const [isFocus, setIsFocus] = useState<boolean>(false)
  const [currentValue, setCurrentValue] = useState<any>(null)
  const [languageMap, setLanguageMap] = useState<LanguageMap>({})

  // Effect
  useEffect(() => {
    if (
      (component.type === 'TITLE' ||
        component.type === 'SINGLE_LINE_TEXT' ||
        component.type === 'LONG_LINE_TEXT') &&
      cell &&
      cell.languageMap
    ) {
      setLanguageMap(cell.languageMap)
    } else if (cell && cell.value) {
      setCurrentValue(cell.value)
    } else if (component.type === 'RICH_TEXT' && cell) {
      getTextFromRichText(cell.languageMap)
    } else {
      setLanguageMap({})
      setCurrentValue(null)
    }
  }, [cell, component])

  useEffect(() => {
    if (ws && isFocus) {
      onSyncCell('SELECT', cell?.uid)
    }
  }, [ws, isFocus])

  useEffect(() => {
    if (isDirtyMedia && !mediaSelectorModal && ws) {
      onSyncCell('UNSELECT')
    }
  }, [isDirtyMedia, mediaSelectorModal])

  useEffect(() => {
    if (isDirtyRelation && !contentsRelationSelectorModal && ws) {
      onSyncCell('UNSELECT')
    }
  }, [isDirtyRelation, contentsRelationSelectorModal])

  // Memo
  const valid = useMemo(() => {
    return !(
      component &&
      component.option &&
      component.option.required &&
      (!cell ||
        (cell &&
          (component.type === 'TITLE' ||
            component.type === 'SINGLE_LINE_TEXT' ||
            component.type === 'LONG_LINE_TEXT') &&
          (!cell.languageMap || !cell.languageMap[currentLanguage])) ||
        (component.type !== 'TITLE' &&
          component.type !== 'SINGLE_LINE_TEXT' &&
          component.type !== 'LONG_LINE_TEXT' &&
          component.type !== 'CATEGORY' &&
          component.type !== 'RELATION' &&
          !cell.value) ||
        (component.type === 'CATEGORY' &&
          (!cell.selectorList || cell.selectorList.length === 0)) ||
        (component.type === 'RELATION' &&
          (!cell.relationList || cell.relationList.length === 0)))
    )
  }, [cell, component, currentLanguage])

  const categorySelectors = useMemo(() => {
    return component.type === 'CATEGORY'
      ? categoriesList.find((c) => c.id === component.selectorGroupId)
      : null
  }, [categoriesList, component])

  const treeCategorySelectors: TreeDataInterface[] = useMemo(() => {
    return getTreeSelectors(categorySelectors, currentLanguage)
  }, [categorySelectors, currentLanguage])

  /**
   * 포커스 아웃 & 셀 변경
   * @param key
   * @param value
   * @param lang
   */
  const onBlurChange = (key, value, lang) => {
    setIsFocus(false)
    onSyncCell('UNSELECT')

    // 변경 미확인
    if (
      (component.type === 'TITLE' ||
        component.type === 'SINGLE_LINE_TEXT' ||
        component.type === 'LONG_LINE_TEXT') &&
      cell &&
      cell.languageMap &&
      languageMap &&
      languageMap[lang] === cell?.languageMap[lang]
    ) {
      return false
    } else if (
      (component.type === 'EMAIL' ||
        component.type === 'PASSWORD' ||
        component.type === 'DATE' ||
        component.type === 'SINGLE_LINE_TEXT_MONO' ||
        component.type === 'LONG_LINE_TEXT_MONO' ||
        component.type === 'NUMBER') &&
      ((cell &&
        typeof cell.value !== 'undefined' &&
        cell.value === currentValue) ||
        (cell && typeof cell.value === 'undefined' && !currentValue))
    ) {
      return false
    }

    onCellChange(component.id, key, value, lang)
  }

  /**
   * 텍스트 변경
   * @param value
   * @param lang
   */
  const onTextChange = (value, lang) => {
    if (languageMap) {
      const updatedLanguage = JSON.parse(JSON.stringify(languageMap))
      updatedLanguage[lang] = value
      setLanguageMap(updatedLanguage)
    }
  }

  /**
   * 값 변경
   * @param value
   */
  const onValueChange = (value) => {
    setCurrentValue(value)
  }

  /**
   * 모달 수정폼 열기
   * @param contentsToBeEdited
   */
  const onContentsOpenModal = () => {
    navigate(
      `/projects/${currentProject?.uid}/${currentModel?.id}/contents?page=${
        contentsPagination.page
      }&size=${
        contentsPagination.size
      }&sort=${contentsSort}&q=${searchParams.get('q')}&edit=modal&id=${
        contents.uid
      }&component=${component.devKey}`
    )
  }

  /**
   * RICH TEXT 텍스트 추출
   * @param languageMap
   * @returns
   */
  const getTextFromRichText = (languageMap) => {
    if (!languageMap || !currentProject) return false

    try {
      const tmpLanguageMap = {}

      currentProject.languageList.forEach((lang) => {
        if (languageMap[lang]) {
          const jsonRichTextData = JSON.parse(
            languageMap[lang].replace(/(?:\r\n|\r|\n|\t)/g, ' ')
          )

          tmpLanguageMap[lang] = jsonRichTextData.blocks
            .filter((b) => b.type === 'paragraph')
            .map((b) => b.data.text)
            .join(' ')
        }
      })

      setLanguageMap(tmpLanguageMap)
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Websocket 편집 Sync
   * @param action
   * @param uid
   */
  const onSyncCell = (action: 'SELECT' | 'UNSELECT', uid?: string) => {
    if (ws) {
      // WS 정보 업데이트
      const wsReq = {
        action,
        type: 'MODEL',
        projectUid,
        modelId: Number(modelId),
        select: 'CELL',
        uid,
      }

      ws.send(JSON.stringify(wsReq))
    }
  }

  // Memo
  const syncCell = useMemo(() => {
    return cell
      ? wsSync.filter((ws) => {
          return ws.select === 'CELL' && ws.uid === cell.uid
        })
      : []
  }, [wsSync, cell])

  const syncCellMembers: UserInterface[] = useMemo(() => {
    return syncCell.length &&
      currentProject &&
      currentProject.memberList &&
      wsSync.length
      ? currentProject.memberList.filter(
          (m) => syncCell.map((ws) => Number(ws.memberId)).indexOf(m.id) >= 0
        )
      : []
  }, [syncCell, currentProject?.memberList])

  return currentProject && currentModel && component ? (
    <div
      id={`cell-item-${cell?.uid}`}
      className={`text-sm cell-component ${
        valid ? '' : 'cell-component-warning'
      } ${isFocus ? '' : 'overflow-hidden'} hover-trigger`}
      style={{
        height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
      }}>
      {syncCellMembers.length > 0 &&
        syncCellMembers.map((m) => m.id).indexOf(me?.id as number) === -1 && (
          <div
            className="hover-target absolute top-0 right-0 h-8 cell-sync-user text-white flex justify-center items-center"
            style={{
              zIndex: 1020,
            }}>
            {syncCellMembers.length > 0 && (
              <div className="w-full leading-8 truncate text-xxs px-px text-center">
                {syncCellMembers[0].name}
              </div>
            )}
          </div>
        )}

      {/* Single Line Text: 시작 */}
      {component.type === 'TITLE' || component.type === 'SINGLE_LINE_TEXT' ? (
        <div>
          {currentProject.languageList.map((lang) => (
            <div
              key={lang}
              className={`cell-component-container ${
                lang === currentLanguage ? '' : 'hidden'
              } ${isFocus ? focusClass : ''}`}>
              <Input
                name={component.devKey}
                autoComplete={'off'}
                required={component.option?.required}
                bordered={false}
                maxLength={component.option?.maxText}
                value={
                  languageMap && languageMap[lang]
                    ? (languageMap[lang] as string)
                    : ''
                }
                onFocus={(e) => setIsFocus(true)}
                onBlur={(e) =>
                  onBlurChange('languageMap', languageMap[lang], lang)
                }
                onPressEnter={(e) =>
                  onBlurChange('languageMap', languageMap[lang], lang)
                }
                onChange={(e) => onTextChange(e.target.value, lang)}
                style={{
                  width:
                    (component?.option?.width
                      ? component.option.width - 1
                      : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
                  height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
                }}
                readOnly={currentProject.role === 'VIEWER'}
              />
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
      {/* Single Line Text: 끝 */}
      {/* Single Line Text Mono: 시작 */}
      {component.type === 'SINGLE_LINE_TEXT_MONO' ? (
        <div
          className={`cell-component-container ${isFocus ? focusClass : ''}`}>
          <Input
            name={component.devKey}
            autoComplete={'off'}
            required={component.option?.required}
            bordered={false}
            maxLength={component.option?.maxText}
            onFocus={(e) => setIsFocus(true)}
            onBlur={(e) => onBlurChange('value', e.target.value, null)}
            onPressEnter={(e) => onBlurChange('value', currentValue, null)}
            value={currentValue}
            onChange={(e) => onValueChange(e.target.value)}
            style={{
              width:
                (component?.option?.width
                  ? component.option.width - 1
                  : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
              height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
            }}
            readOnly={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Single Line Text Mono: 끝 */}
      {/* Long Line Text: 시작 */}
      {component.type === 'LONG_LINE_TEXT' ? (
        <div>
          {currentProject.languageList.map((lang) => (
            <div
              key={lang}
              className={`cell-component-container ${
                lang === currentLanguage ? '' : 'hidden'
              } ${isFocus ? focusClass + ' absolute' : ''}`}>
              <Input.TextArea
                rows={5}
                name={component.devKey}
                required={component.option?.required}
                bordered={false}
                value={
                  languageMap && languageMap[lang]
                    ? (languageMap[lang] as string)
                    : ''
                }
                onFocus={(e) => setIsFocus(true)}
                onBlur={(e) =>
                  onBlurChange('languageMap', languageMap[lang], lang)
                }
                onChange={(e) => onTextChange(e.target.value, lang)}
                style={{
                  width:
                    (component?.option?.width
                      ? component.option.width - 1
                      : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
                }}
                readOnly={currentProject.role === 'VIEWER'}
              />
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
      {/* Long Line Text: 끝 */}
      {/* Long Line Text Mono: 시작 */}
      {component.type === 'LONG_LINE_TEXT_MONO' ? (
        <div
          className={`cell-component-container ${isFocus ? focusClass : ''}`}>
          <Input.TextArea
            rows={5}
            name={component.devKey}
            required={component.option?.required}
            bordered={false}
            value={currentValue}
            onFocus={(e) => setIsFocus(true)}
            onBlur={(e) => onBlurChange('value', e.target.value, null)}
            onChange={(e) => onValueChange(e.target.value)}
            style={{
              width:
                (component?.option?.width
                  ? component.option.width - 1
                  : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
            }}
            readOnly={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Long Line Text Mono: 끝 */}
      {/* Rich Text: 시작 */}
      {component.type === 'RICH_TEXT' ? (
        !contentsListLoading ? (
          <div>
            {currentProject.languageList.map((lang) => (
              <div
                key={lang}
                className={`cell-component-container ${
                  lang === currentLanguage ? '' : 'hidden'
                } ${isFocus ? focusClass + ' absolute' : ''}`}
                onClick={() => onContentsOpenModal()}>
                <div
                  className={`ant-input ant-input-borderless ant-editor-input relative cursor-pointer`}
                  style={{
                    width:
                      (component?.option?.width
                        ? component.option.width - 1
                        : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: languageMap[lang] as string,
                  }}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="py-1 px-2">
            <Spin size="small" indicator={<LoadingOutlined spin />} />
          </div>
        )
      ) : (
        <></>
      )}
      {/* Rich Text: 끝 */}
      {/* Number: 시작 */}
      {component.type === 'NUMBER' ? (
        <div
          className={`cell-component-container ${isFocus ? focusClass : ''}`}>
          <InputNumber
            name={component.devKey}
            required={component.option?.required}
            bordered={false}
            controls={false}
            min={component.option?.min}
            max={component.option?.max}
            value={currentValue}
            onFocus={(e) => setIsFocus(true)}
            onBlur={(e) => {
              onBlurChange('value', e.target.value, null)
            }}
            onPressEnter={(e) => onBlurChange('value', currentValue, null)}
            onChange={(val) => {
              onValueChange(val)
            }}
            style={{
              width:
                (component?.option?.width
                  ? component.option.width - 1
                  : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
              height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
            }}
            readOnly={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Number: 끝 */}
      {/* Category: 시작 */}
      {component.type === 'CATEGORY' && currentLanguage ? (
        <div
          className={`cell-component-container ${isFocus ? focusClass : ''}`}>
          {component.option?.multiple ? (
            <TreeSelect
              onFocus={(e) => setIsFocus(true)}
              onBlur={(e) => {
                onSyncCell('UNSELECT')
                setIsFocus(false)
              }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder={t('selectCategory')}
              treeCheckable
              bordered={false}
              multiple
              treeDefaultExpandAll
              allowClear={!component?.option?.required}
              onChange={(val) =>
                onCellChange(component.id, 'selectorIdList', val)
              }
              value={
                cell && cell.selectorList
                  ? cell.selectorList.map((s) => s.id)
                  : []
              }
              treeData={treeCategorySelectors}
              style={{
                width:
                  (component?.option?.width
                    ? component.option.width - 1
                    : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
              }}
            />
          ) : (
            <TreeSelect
              className={`w-full`}
              onFocus={(e) => setIsFocus(true)}
              onBlur={(e) => {
                onSyncCell('UNSELECT')
                setIsFocus(false)
              }}
              bordered={false}
              onChange={(val) =>
                onCellChange(component.id, 'selectorIdList', [val])
              }
              value={
                cell && cell.selectorList && cell.selectorList.length
                  ? cell.selectorList.map((s) => s.id)[0]
                  : null
              }
              style={{
                width:
                  (component?.option?.width
                    ? component.option.width - 1
                    : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
                height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
              }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder={t('selectCategory')}
              allowClear
              treeDefaultExpandAll
              treeData={treeCategorySelectors}
              disabled={currentProject.role === 'VIEWER'}
            />
          )}
        </div>
      ) : (
        <></>
      )}
      {/* Category: 끝 */}
      {/* Boolean: 시작 */}
      {component.type === 'BOOLEAN' ? (
        <div className="p-1">
          <Switch
            checked={!!(cell && cell.value)}
            onChange={(checked) => onCellChange(component.id, 'value', checked)}
            disabled={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Boolean 끝 */}
      {/* Date: 시작 */}
      {component.type === 'DATE' ? (
        <div
          className={`cell-component-container ${isFocus ? focusClass : ''}`}>
          <DatePicker
            locale={locale}
            autoComplete={'off'}
            picker={component?.option?.dateFormats}
            className={`${isFocus ? focusClass : ''}`}
            bordered={false}
            onFocus={(e) => setIsFocus(true)}
            onBlur={(e) => setIsFocus(false)}
            onChange={(val, dateString) =>
              onCellChange(component.id, 'value', dateString)
            }
            value={
              cell && cell.value
                ? moment(cell.value as string, 'YYYY-MM-DDTHH:mm:ss')
                : undefined
            }
            style={{
              width:
                (component?.option?.width
                  ? component.option.width - 1
                  : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
              height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
            }}
            disabled={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Date 끝 */}
      {/* Email: 시작 */}
      {component.type === 'EMAIL' ? (
        <div
          className={`cell-component-container ${isFocus ? focusClass : ''}`}>
          <Input
            type={'email'}
            autoComplete={'off'}
            name={component.devKey}
            className={`${isFocus ? focusClass : ''}`}
            onFocus={(e) => setIsFocus(true)}
            onBlur={(e) => onBlurChange('value', e.target.value, null)}
            onPressEnter={(e) => onBlurChange('value', currentValue, null)}
            onChange={(e) => onValueChange(e.target.value)}
            required={component.option?.required}
            bordered={false}
            value={currentValue}
            style={{
              width:
                (component?.option?.width
                  ? component.option.width - 1
                  : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
              height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
            }}
            readOnly={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Email: 끝 */}
      {/* Password: 시작 */}
      {component.type === 'PASSWORD' ? (
        <div
          className={`cell-component-container ${isFocus ? focusClass : ''}`}>
          <Input.Password
            autoComplete={'off'}
            name={component.devKey}
            className={`${isFocus ? focusClass : ''}`}
            onFocus={(e) => setIsFocus(true)}
            onBlur={(e) => onBlurChange('value', e.target.value, null)}
            onPressEnter={(e) => onBlurChange('value', currentValue, null)}
            onChange={(e) => onValueChange(e.target.value)}
            required={component.option?.required}
            bordered={false}
            value={currentValue}
            style={{
              width:
                (component?.option?.width
                  ? component.option.width - 1
                  : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
              height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
            }}
            readOnly={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Password: 끝 */}
      {/* Media: 시작 */}
      {component.type === 'MEDIA' ? (
        <div>
          {!contentsListLoading ? (
            <div
              className={`cell-component-container overflow-x-auto px-2 ${
                currentProject.role === 'VIEWER' ? '' : 'cursor-pointer'
              }  ${isFocus ? focusClass : ''}`}
              style={{
                width:
                  (component?.option?.width
                    ? component.option.width - 1
                    : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
                height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
              }}
              onClick={() => {
                setIsDirtyMedia(true)
                onSyncCell('SELECT', cell?.uid)
                dispatch(
                  setMediaSelectorModal(
                    currentProject.role !== 'VIEWER',
                    cell ? cell : undefined,
                    component,
                    contents
                  )
                )
              }}>
              <ul
                className="space-x-1 text-xs flex items-center w-max mb-0"
                style={{
                  height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
                }}>
                {cell?.mediaList?.map((media) => (
                  <li
                    className="pr-2 border border-gray-400 bg-gray-200 flex items-center"
                    key={media.id}>
                    <MediaThumb
                      media={media}
                      // width="20"
                      outerWidth={20}
                      outerHeight={20}
                      textSize="xs"
                      thumbnail="SMALL"
                    />
                    <div className="pl-2 leading-5">
                      {currentProject &&
                      media.languageMap &&
                      media.languageMap[currentProject.defaultLang]
                        ? (media.languageMap[currentProject.defaultLang]
                            ?.name as string)
                        : ''}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="py-1 px-2">
              <Spin size="small" indicator={<LoadingOutlined spin />} />
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
      {/* Media: 끝 */}
      {/* Relation: 시작 */}
      {component.type === 'RELATION' ? (
        <div>
          <div
            className={`cell-component-container overflow-x-auto text-xs px-2 pt-2 ${
              currentProject.role === 'VIEWER' ? '' : 'cursor-pointer'
            }  ${isFocus ? focusClass : ''}`}
            style={{
              width:
                (component?.option?.width
                  ? component.option.width - 1
                  : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
              height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
            }}
            onClick={() => {
              setIsDirtyRelation(true)
              onSyncCell('SELECT', cell?.uid)
              dispatch(
                setContentsRelationSelectorModal(
                  currentProject.role !== 'VIEWER',
                  cell ? cell : undefined,
                  component,
                  contents
                )
              )
            }}>
            {cell && cell?.relationList ? (
              <Tooltip
                placement="right"
                title={cell?.relationList.map(
                  (r, rIdx) =>
                    `${rIdx + 1}) ${
                      r.languageMap ? r.languageMap[currentLanguage] : '-'
                    }\n`
                )}>
                <div className="overflow-hidden text-xxs flex flex-wrap space-x-1">
                  {cell?.relationList.map((r, rIdx) => (
                    <div key={r.uid} className="flex space-x-px">
                      <div>{`${rIdx + 1})`}</div>
                      <div className={`rel-contentsTitle-${r.uid}`}>
                        {r.languageMap ? r.languageMap[currentLanguage] : '-'}
                      </div>
                    </div>
                  ))}
                </div>
              </Tooltip>
            ) : (
              ''
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* Relation: 끝 */}
    </div>
  ) : (
    <></>
  )
}
