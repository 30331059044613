import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Split from 'react-split'
import { Button, Input, Tag, Empty, Spin, Collapse, Tooltip } from 'antd'
import {
  PlusCircleOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  ReconciliationOutlined,
  RobotOutlined,
} from '@ant-design/icons'
import {
  ModelInterface,
  ProjectUsagePropertiesInterface,
  SelectorGroupInterface,
  ProjectSampleModelCategoryType,
} from '@/types'
const { Panel } = Collapse
import { ComponentsItemReadonly } from '@/components/Components'
import {
  PROJECT_SAMPLE_MODELS,
  PROJECT_SAMPLE_MODELS_CATEGORIES,
} from '@/configs'
import { LINK_HELP_DEVKEY } from '@/constants'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '@/states'
import { ModelRecommendations } from '@/components/Ai'
import { getSampleProjectGroups, getSampleProjectModels } from '@/api'

const sampleProjectUid = process.env
  .REACT_APP_SAMPLE_MODEL_PROJECT_UID as string

export const ModelsSamples = ({
  projectPlan,
  modelList,
  onImportModel,
  onImportGroups,
}: {
  projectPlan: ProjectUsagePropertiesInterface
  modelList: ModelInterface[]
  onImportModel: (model) => void
  onImportGroups: (groups) => void
}) => {
  const { t, i18n } = useTranslation()

  // State (Redux)
  const { modalsState, projectsState } = useSelector(
    (state: RootState) => ({
      modalsState: state.modals,
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { modelFormModal, modelInfo } = modalsState
  const { currentProject } = projectsState

  // State
  const [models, setModels] = React.useState<ModelInterface[]>([])
  const [importedModels, setImportedModels] =
    React.useState<ModelInterface[]>(modelList)
  const [groups, setGroups] = React.useState<SelectorGroupInterface[]>([])
  const [importedGroups, setImportedGroups] = React.useState<
    SelectorGroupInterface[]
  >([])
  const [loadingModels, setLoadingModels] = React.useState<boolean>(false)
  const [loadingGroups, setLoadingGroups] = React.useState<boolean>(false)
  const [selectedCategory, setSelectedCategory] =
    React.useState<ProjectSampleModelCategoryType>('all')

  // Memo
  const isValidAiPlan = useMemo(() => {
    return (
      !currentProject ||
      (currentProject &&
        (currentProject.price === 'PRO' ||
          currentProject.price === 'UNLIMITED'))
    )
  }, [currentProject])

  // Effect
  useEffect(() => {
    if (modelFormModal) {
      getSampleProjectModels(sampleProjectUid).then((res) => {
        const sortedModels: ModelInterface[] = res.data

        setModels(sortedModels)
        setLoadingModels(true)
      })

      getSampleProjectGroups(sampleProjectUid).then((res) => {
        setGroups(res.data)
        setLoadingGroups(true)
      })
    } else {
      setImportedModels([])
      setImportedGroups([])
      setLoadingModels(false)
      setLoadingGroups(false)
    }
  }, [modelFormModal])

  /**
   * 샘플 모델 가져오기
   * @param model
   */
  const importSampleModel = (model) => {
    const modelToBeImported: ModelInterface = JSON.parse(JSON.stringify(model))

    setImportedModels([modelToBeImported])

    // 해당 카테고리 가져오기
    const selectorGroups = modelToBeImported.componentList.filter(
      (c) => c.type === 'CATEGORY'
    )
    const updatedImportedGroup = importedGroups

    selectorGroups.forEach((selectors) => {
      const targetGroup = groups.find((g) => selectors.selectorGroupId === g.id)
      if (!targetGroup) return

      const importedOldGroup = importedGroups.find(
        (g) => g.id === targetGroup.id
      )

      if (!importedOldGroup) {
        updatedImportedGroup.push(targetGroup)
      }
    })

    setImportedGroups(updatedImportedGroup)

    onImportModel(modelToBeImported)
    onImportGroups(updatedImportedGroup)
  }

  /**
   * 샘플 모델 제거
   * @param e
   * @param idx
   * @param model
   */
  const removeImportedModel = (e, idx, model: ModelInterface) => {
    e.stopPropagation()

    const noUsed = importedModels.filter((m) => m.id === model.id).length
    const updatedImportedModels = importedModels.filter(
      (m, mIdx) => mIdx !== idx
    )
    const targetModel = importedModels[idx]

    if (!targetModel) return

    // 여러개일 경우 devKey 재정의
    if (noUsed > 1) {
      let i = 0
      updatedImportedModels.forEach((m, mIdx) => {
        if (m.id === model.id) {
          m.devKey =
            i > 0 ? targetModel.devKey + '_' + (i + 1) : targetModel.devKey
          i++
        }
      })
    } else {
      // ID값 있는 그룹 제거
      const removeIds = model.componentList
        .filter((c) => c.type === 'CATEGORY')
        .map((c) => c.selectorGroupId)

      setImportedGroups(importedGroups.filter((g) => !removeIds.includes(g.id)))
    }

    setImportedModels(updatedImportedModels)
  }

  /**
   * 샘플 모델
   * @param param0
   * @returns
   */
  const SampleModelItem = ({ model }: { model: ModelInterface }) => {
    // 이미 가져온 모델인지 확인
    const isUsed = useMemo(() => {
      return importedModels.find((m) => m.id === model.id)
    }, [importSampleModel])

    // 모델 추가 정보
    const modelConfig = useMemo(() => {
      return PROJECT_SAMPLE_MODELS.find(
        (s) => s.devKey.toLowerCase() === model.devKey.toLowerCase()
      )
    }, [model])

    return modelConfig ? (
      <div key={model.id} className="">
        <div className="text-center mb-1">
          <h3
            className={
              'mb-0 leading-5 font-semibold flex justify-center items-center space-x-1'
            }>
            <span>{model.languageMap?.KO}</span>
            <Tooltip
              title={t(`projectSampleModelInfo.${modelConfig.devKey}.desc`)}>
              <a
                href={LINK_HELP_DEVKEY}
                tabIndex={-1}
                target="_blank"
                rel="noreferrer"
                className="text-xs flex items-center space-x-0.5">
                <InfoCircleOutlined />
              </a>
            </Tooltip>
          </h3>
        </div>
        <div
          className="relative aspect-1 overflow-hidden bg-gray-200 bg-cover bg-no-repeat bg-center hover:scale-105 transition-all hover:shadow"
          style={{
            backgroundImage: `url(${
              modelConfig ? modelConfig.thumbnailUrl : ''
            })`,
          }}>
          <Tag className="absolute top-2 left-2">
            {t(
              `projectCreateCategory.${
                modelConfig ? modelConfig.category : 'board'
              }.title`
            )}
          </Tag>
          <Button
            size="small"
            className="!absolute bottom-4 right-4"
            icon={<PlusCircleOutlined />}
            onClick={() => importSampleModel(model)}>
            <span className="text-sm">{t('import')}</span>
          </Button>
        </div>
      </div>
    ) : (
      <></>
    )
  }

  /**
   * 모델 추가 유형별 포커스
   * @param val
   */
  const onHandleCollapseFocus = (val) => {
    if (val) {
      setTimeout(() => {
        document.getElementById('ai-model-name')?.focus()
      })
    }
  }

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="w-full max-w-[1920px] mx-auto grow overflow-hidden">
          <Split
            className={'w-full h-full flex split pb-4'}
            sizes={[50, 50]}
            minSize={[368, 368]}>
            <div className="pr-5 overflow-y-auto h-full">
              <Collapse accordion onChange={onHandleCollapseFocus}>
                <Panel
                  header={
                    <div className="flex items-center space-x-2.5">
                      <strong>{t('sampleModelTitle')}</strong>
                      <ReconciliationOutlined />
                    </div>
                  }
                  key="sample">
                  {loadingModels && loadingGroups ? (
                    <div className="space-y-5">
                      <div className="w-full overflow-x-auto sticky top-0 z-10 bg-white">
                        <ul className="flex justify-center min-w-full w-max space-x-5">
                          {PROJECT_SAMPLE_MODELS_CATEGORIES.map((category) => (
                            <li
                              key={category.slug}
                              className={`cursor-pointer text-gray-600 hover:text-secondary ${
                                category.slug === selectedCategory
                                  ? 'font-bold text-secondary'
                                  : ''
                              }`}
                              onClick={() =>
                                setSelectedCategory(category.slug)
                              }>
                              <div>
                                {t(
                                  `projectCreateCategory.${category.slug}.title`
                                )}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="grid lg:grid-cols-2 2xl:grid-cols-3 gap-x-4 gap-y-10">
                        {models
                          .filter((model) => {
                            return (
                              (model.componentList &&
                                model.componentList.length &&
                                selectedCategory === 'all') ||
                              (PROJECT_SAMPLE_MODELS.find(
                                (s) =>
                                  s.devKey.toLowerCase() ===
                                  model.devKey.toLowerCase()
                              ) &&
                                PROJECT_SAMPLE_MODELS.find(
                                  (s) =>
                                    s.devKey.toLowerCase() ===
                                    model.devKey.toLowerCase()
                                )?.category === selectedCategory)
                            )
                          })
                          .map((model) => (
                            <SampleModelItem key={model.id} model={model} />
                          ))}
                      </div>
                    </div>
                  ) : (
                    <div className="h-full flex items-center justify-center">
                      <Spin />
                    </div>
                  )}
                </Panel>
                <Panel
                  header={
                    <div className="flex items-center space-x-2.5">
                      <strong>{t('aiRecommendations')}</strong>
                      <RobotOutlined />
                    </div>
                  }
                  key="ai">
                  {!isValidAiPlan ? (
                    <div className="pt-0 flex items-center space-x-2">
                      <p className="text-xs mb-0">{t('aiNeedProPlanNow')}</p>
                      <Button
                        size="small"
                        className="!text-xs"
                        onClick={() => {
                          // @ts-ignore
                          ChannelIO('openChat')
                        }}>
                        {t('contactUs')}
                      </Button>
                    </div>
                  ) : (
                    <ModelRecommendations onImportModel={importSampleModel} />
                  )}
                </Panel>
              </Collapse>
            </div>
            <div className="pl-5 overflow-y-auto h-full pb-20">
              {importedModels.length > 0 ? (
                <div className="flex flex-col space-y-2">
                  <Collapse defaultActiveKey={['1']} ghost>
                    {importedModels.map((model, mIdx) => (
                      <div className="space-y-2" key={mIdx}>
                        <div>
                          {model?.componentList?.map((component, index) => (
                            <ComponentsItemReadonly
                              key={index}
                              component={component}
                              customClassName="bg-gray-200 shadow rounded"
                              disabled={true}
                            />
                          ))}
                        </div>
                        <div className="flex justify-end">
                          <Button
                            type="link"
                            size="small"
                            onClick={(e) => removeImportedModel(e, mIdx, model)}
                            icon={<DeleteOutlined />}>
                            <span>{t('clear')}</span>
                          </Button>
                        </div>
                      </div>
                    ))}
                  </Collapse>
                </div>
              ) : (
                <div className="h-full flex items-center justify-center">
                  <Empty
                    description={
                      <div>
                        <div className="font-semibold">{t('emptyModel')}</div>
                        <div
                          className="text-xs"
                          dangerouslySetInnerHTML={{
                            __html: t('emptyModelDesc'),
                          }}></div>
                      </div>
                    }>
                    {}
                  </Empty>
                </div>
              )}
            </div>
          </Split>
        </div>
      </div>
    </>
  )
}
