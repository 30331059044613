import { HeaderCells } from '@/components/Contents'
import {
  LAYOUT_CHECKBOX_CELL_WIDTH,
  LAYOUT_DEFAULT_CELL_DATE_WIDTH,
  LAYOUT_DEFAULT_CELL_HEIGHT,
  LAYOUT_MIN_CELL_WIDTH,
} from '@/configs'
import { LINK_HELP_PUBLIC } from '@/constants'
import { RootState, useAppDispatch } from '@/states'
import { setCheckedContentsList } from '@/states/actions'
import {
  CaretDownFilled,
  CaretUpFilled,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Checkbox } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { useLocation, useSearchParams } from 'react-router-dom'

export const ContentsListHeaders = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // Params
  let { projectUid, modelId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  // State (Redux)
  const { projectsState, modalsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      modalsState: state.modals,
    }),
    shallowEqual
  )
  const {
    currentProject,
    contentsPagination,
    currentModel,
    contentsList,
    checkedContentsList,
    contentsSort,
    flattenComponentList,
  } = projectsState
  const { contentsFormModal, contentsRelationSelectorModal, contentsInfo } =
    modalsState

  // State
  const [localLoading, setLocalLoading] = useState<boolean>(false)

  /**
   * 체크하기
   */
  const onToggleCheckAll = (e) => {
    dispatch(setCheckedContentsList(e.target.checked ? contentsList : []))
  }

  /**
   * 정렬 순서 변경
   * @param comp
   */
  const onHandleDateSort = (dateField) => {
    navigate(
      `/projects/${currentProject?.uid}/${currentModel?.id}/contents?page=${
        searchParams.get('page')
          ? searchParams.get('page')
          : contentsPagination.page
      }&size=${
        searchParams.get('size')
          ? searchParams.get('size')
          : contentsPagination.size
      }&sort=${
        dateField + '.' + (contentsSort.indexOf('.DESC') >= 0 ? 'ASC' : 'DESC')
      }&q=${searchParams.get('q') ? searchParams.get('q') : ''}`
    )
  }

  /**
   * 엑셀 헤더
   * @returns
   */
  const getHeader = () => {
    return (
      <div
        id="contents-list-header-contents"
        className="flex h-6 text-xs text-gray-600 leading-6">
        <div
          className="px-2 flex items-center justify-center border-b border-t border-r border-gray-300 bg-gray-200 sticky z-40 header-fixed-cell"
          style={{
            width: LAYOUT_CHECKBOX_CELL_WIDTH + 'px',
            height: LAYOUT_DEFAULT_CELL_HEIGHT,
          }}
          title={t('selectAll')}>
          <Checkbox
            indeterminate={
              checkedContentsList.length > 0 &&
              checkedContentsList.length !== contentsList.length
            }
            checked={
              checkedContentsList.length > 0 &&
              checkedContentsList.length === contentsList.length
            }
            onChange={onToggleCheckAll}
            disabled={currentProject?.role === 'VIEWER'}
          />
        </div>
        <HeaderCells />
        <div
          className="px-2 flex justify-between items-center border-b border-t border-r border-gray-300 bg-gray-200"
          style={{
            width: LAYOUT_MIN_CELL_WIDTH + 10 + 'px',
            height: LAYOUT_DEFAULT_CELL_HEIGHT,
          }}>
          <div className="flex items-center space-x-0.5">
            <span>{t('isPublic')}</span>
            <a
              href={LINK_HELP_PUBLIC}
              target="_blank"
              rel="noreferrer"
              title={t('learnMore')}
              className="text-xs flex items-center space-x-0.5 font-normal">
              <QuestionCircleOutlined />
            </a>
          </div>
          <div
            className="flex flex-col space-y-0 cursor-pointer"
            onClick={() => onHandleDateSort('OPEN')}>
            <CaretUpFilled
              className={`h-2.5 cursor-pointer text-xxs hover:opacity-80`}
              style={{
                color:
                  contentsSort &&
                  contentsSort.split('.') &&
                  contentsSort.split('.')[0] === 'OPEN' &&
                  contentsSort.split('.')[1] === 'ASC'
                    ? '#1890FF'
                    : '#BFBFBF',
              }}
            />
            <CaretDownFilled
              className={`h-2.5 cursor-pointer text-xxs hover:opacity-80`}
              style={{
                color:
                  contentsSort &&
                  contentsSort.split('.') &&
                  contentsSort.split('.')[0] === 'OPEN' &&
                  contentsSort.split('.')[1] === 'DESC'
                    ? '#1890FF'
                    : '#BFBFBF',
              }}
            />
          </div>
        </div>
        <div
          className="px-2 flex justify-between items-center border-b border-t border-r border-gray-300 bg-gray-200"
          style={{
            width: LAYOUT_DEFAULT_CELL_DATE_WIDTH + 'px',
            height: LAYOUT_DEFAULT_CELL_HEIGHT,
          }}>
          <div>{t('createdDate')}</div>
          <div
            className="flex flex-col space-y-0 cursor-pointer"
            onClick={() => onHandleDateSort('DATE_CREATE')}>
            <CaretUpFilled
              className={`h-2.5 cursor-pointer text-xxs hover:opacity-80`}
              style={{
                color:
                  contentsSort &&
                  contentsSort.split('.') &&
                  contentsSort.split('.')[0] === 'DATE_CREATE' &&
                  contentsSort.split('.')[1] === 'ASC'
                    ? '#1890FF'
                    : '#BFBFBF',
              }}
            />
            <CaretDownFilled
              className={`h-2.5 cursor-pointer text-xxs hover:opacity-80`}
              style={{
                color:
                  contentsSort &&
                  contentsSort.split('.') &&
                  contentsSort.split('.')[0] === 'DATE_CREATE' &&
                  contentsSort.split('.')[1] === 'DESC'
                    ? '#1890FF'
                    : '#BFBFBF',
              }}
            />
          </div>
        </div>
        <div
          className="px-2 flex justify-between items-center border-b border-t border-r border-gray-300 bg-gray-200"
          style={{
            width: LAYOUT_DEFAULT_CELL_DATE_WIDTH + 'px',
            height: LAYOUT_DEFAULT_CELL_HEIGHT,
          }}>
          <div>{t('editedDate')}</div>
          <div
            className="flex flex-col space-y-0 cursor-pointer"
            onClick={() => onHandleDateSort('DATE_UPDATE')}>
            <CaretUpFilled
              className={`h-2.5 cursor-pointer text-xxs hover:opacity-80`}
              style={{
                color:
                  contentsSort &&
                  contentsSort.split('.') &&
                  contentsSort.split('.')[0] === 'DATE_UPDATE' &&
                  contentsSort.split('.')[1] === 'ASC'
                    ? '#1890FF'
                    : '#BFBFBF',
              }}
            />
            <CaretDownFilled
              className={`h-2.5 cursor-pointer text-xxs hover:opacity-80`}
              style={{
                color:
                  contentsSort &&
                  contentsSort.split('.') &&
                  contentsSort.split('.')[0] === 'DATE_UPDATE' &&
                  contentsSort.split('.')[1] === 'DESC'
                    ? '#1890FF'
                    : '#BFBFBF',
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  return currentProject && currentModel ? (
    <>
      {flattenComponentList && flattenComponentList.length ? (
        <div
          className="flex sticky top-24 z-50 bg-gray-200"
          id="contents-list-header">
          {getHeader()}
        </div>
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  )
}
