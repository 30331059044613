/**
 * 웹사이트 링크
 */
export const LINK_WEBSITE = `https://memexdata.io`

/**
 * 이용약관 링크
 */
export const LINK_TERMS = `https://memexdata.io/terms`

/**
 * 개인정보처리방침 링크
 */
export const LINK_PRIVACY = `https://memexdata.io/privacy`

/**
 * 이메일
 */
export const LINK_EMAIL = 'hello@memexdata.io'

/**
 * 디스코드
 */
export const LINK_DISCORD = 'https://discord.gg/9ANh4Cc5cT'

/**
 * 사용법 링크
 */
export const LINK_HELP = `https://memexguide.notion.site/2fda86dae5274dd29ad1e0981f06b133`

/**
 * 서비스 상태 링크
 */
export const LINK_STATUSPAGE = 'https://memex.statuspage.io'

/**
 * 설문 링크
 */
export const LINK_SURVEY =
  'https://docs.google.com/forms/d/e/1FAIpQLScTFPilB88g-pqGav6QoUi02GYghtDJIrDYmrkzth47zabMIA/viewform?usp=sf_link'

/**
 * 요금제 페이지 링크
 */
export const LINK_PLAN = 'https://memexdata.io/pricing'

/**
 * 문의하기 구글 폼
 */
export const LINK_FORM = 'https://forms.gle/Cc83Hda7do2gxEmN6'

/**
 * 사용법 - 개발키
 */
export const LINK_HELP_DEVKEY = `https://memexguide.notion.site/Dev-Key-6a464a512f7b4abcbf90d85bd4f5773e`

/**
 * 프로젝트 사용법
 */
export const LINK_HELP_PROJECT = `https://memexguide.notion.site/STEP-1-16d432d9059e4673b42046c1855b9d87`

/**
 * 모델 사용법
 */
export const LINK_HELP_MODEL = `https://memexguide.notion.site/STEP-2-fa9ea1b928d34218931e1291d541a763`

/**
 * 컴포넌트 사용법
 */
export const LINK_HELP_COMPONENT = `https://memexguide.notion.site/STEP-3-e521f298e89642c8813a3ca2e32247b9`

/**
 * 권한 사용법
 */
export const LINK_HELP_ROLE =
  'https://memexguide.notion.site/962c83fd7bae43f6b3d12443ab66d86e'

/**
 * 콘텐츠 공개여부 사용법
 */
export const LINK_HELP_PUBLIC =
  'https://memexguide.notion.site/ad7c4338038c45baa23b3da2b11df544'

/**
 * 제작사례
 */
export const LINK_SHOWCASE = 'https://memexdata.io/showcase'

/**
 * 블로그
 */
export const LINK_BLOG = 'https://memexdata.io/blog'

/**
 * Rich Text Git 소스 링크
 */
export const LINK_RICH_TEXT_EDITOR_GIT =
  'https://github.com/memexdata/richtext-editorjs'
