import React, { useEffect, useState } from 'react'
import { Button, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '@/states'

export const CreateStep1 = ({
  formikProjectForm,
  onNext,
  isMaxFreeProjectsMeet,
  freeProjectsCount,
}: {
  formikProjectForm: any
  onNext: (number) => void
  isMaxFreeProjectsMeet: boolean
  freeProjectsCount: number
}) => {
  const { t, i18n } = useTranslation()

  // State (Redux)
  const { projectsState, authState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { projectsListInit, projectList } = projectsState

  // State
  const [nameReady, setNameReady] = useState<boolean>(false)

  // Effect
  useEffect(() => {
    if (!formikProjectForm.values.name) {
      setNameReady(false)
    }
  }, [formikProjectForm.values.name])

  /**
   * 가용 언어 선택
   * @param val
   */
  const onHandleSubmit = (e) => {
    e.preventDefault()

    if (isMaxFreeProjectsMeet) return

    onNext(1)
  }

  return (
    <div className={`h-full flex ${nameReady ? '' : 'items-center pb-40'}`}>
      <div className="w-full max-w-lg mx-auto">
        <div className="text-center mb-10">
          <h1 className="text-3xl font-bold mb-2">
            {t('projectCreate.step1.title')}
          </h1>
          <p
            className="text-sm mb-0"
            dangerouslySetInnerHTML={{
              __html: projectList.length
                ? t('projectCreate.step1.descAlt')
                : t('projectCreate.step1.desc'),
            }}
          />
        </div>
        <form onSubmit={onHandleSubmit}>
          <div className="space-y-4">
            <div className="block">
              <label htmlFor="projectFormName" className="">
                <Input
                  id={'projectFormName'}
                  name="name"
                  type={'text'}
                  size="large"
                  autoComplete="off"
                  placeholder={t('projectName')}
                  onChange={formikProjectForm.handleChange}
                  onBlur={() => {
                    if (formikProjectForm.values.name) {
                      // setNameReady(true)
                    }
                  }}
                  value={formikProjectForm.values.name}
                />
              </label>
              {formikProjectForm.touched.name &&
              formikProjectForm.errors.name ? (
                <p className="my-1 text-xs text-red-500">
                  {formikProjectForm.errors.name}
                </p>
              ) : null}
            </div>
            <div className="flex justify-between items-center">
              <div className="text-gray-500 text-xs">1/4</div>
              <div>
                <Button
                  type="primary"
                  disabled={
                    !formikProjectForm.values.name || isMaxFreeProjectsMeet
                  }
                  onClick={onHandleSubmit}>
                  {t('next')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
