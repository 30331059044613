import {
  convertToVideoStreaming,
  createMedia,
  getMedia,
  getOriginalMedia,
  getProjectUsage,
  updateMedia,
  uploadFileToS3,
} from '@/api'
import { AlertStatus } from '@/components/Common'
import {
  ConnectedContents,
  FileInfo,
  MediaThumb,
  MediaVideoStreaming,
} from '@/components/Media'
import { LAYOUT_MODAL_WIDTH, imageExtensions } from '@/configs'
import { RootState, useAppDispatch } from '@/states'
import { setMediaFormModal, setNewMediaList } from '@/states/actions'
import {
  ConnectedContentsInterface,
  FileResponseInterface,
  MediaInterface,
  MediaType,
} from '@/types'
import { humanFileSize, vimeoParser, youtubeParser } from '@/utils/helpers'
import {
  CheckOutlined,
  CloseCircleOutlined,
  DeleteFilled,
  DownloadOutlined,
  ExpandOutlined,
  ExportOutlined,
  FileOutlined,
  LoadingOutlined,
  PlayCircleOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Input,
  Modal,
  Radio,
  Row,
  Spin,
  Tabs,
  message,
  notification,
} from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import axios, { AxiosResponse, CancelTokenSource } from 'axios'
import { useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import * as Yup from 'yup'

let cancelToken: CancelTokenSource

const initialMediaFormValues = {
  KO: {
    name: '',
    description: '',
  },
  EN: {
    name: '',
    description: '',
  },
  JP: {
    name: '',
    description: '',
  },
  CN: {
    name: '',
    description: '',
  },
  value: '',
}

interface MediaChangeProps {
  onChangeMedia?: () => void
}

export const MediaFormModal = ({ onChangeMedia }: MediaChangeProps) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // State (Redux)
  const { projectsState, modalsState, authState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      modalsState: state.modals,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { currentProject } = projectsState
  const { mediaFormModal, mediaInfo, mediaUploadUid } = modalsState
  const { me } = authState

  // State
  const [mediaType, setMediaType] = useState<MediaType>('FILE')
  const [isFileRemove, setIsFileRemove] = useState<boolean>(true)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [previewUrl, setPreviewUrl] = useState<string>('')
  const [busy, setBusy] = useState<boolean>(false)
  const [contentsList, setContentsList] = useState<
    ConnectedContentsInterface[]
  >([])
  const [currentMedia, setCurrentMedia] = useState<MediaInterface | null>(null)
  const [isConvertStreaming, setIsConvertStreaming] = useState<boolean>(false)

  // Memo
  const maxFileCount = useMemo(() => {
    return currentProject?.properties?.maxFileCount
  }, [currentProject])

  const maxFileSize = useMemo(() => {
    return currentProject?.properties?.maxFileSize
  }, [currentProject])

  const maxFileRes = useMemo(() => {
    return currentProject?.properties?.maxFileResolutions
  }, [currentProject])

  // Effect
  useEffect(() => {
    if (mediaFormModal) {
      setTimeout(() => {
        document.getElementById('mediaFormName')?.focus()
      })

      if (!mediaInfo) {
        formikMediaForm.validateForm()
      } else {
        getMediaContentsInfo(mediaInfo)
      }
    } else {
      resetForm()
    }
  }, [mediaFormModal])

  useEffect(() => {
    if (mediaInfo) {
      setMediaType(mediaInfo.mediaType)

      currentProject?.languageList.map((lang) => {
        formikMediaForm.setFieldValue(
          lang + '.name',
          mediaInfo.languageMap[lang]?.name
        )
        formikMediaForm.setFieldValue(
          lang + '.description',
          mediaInfo.languageMap[lang]?.description
        )
      })

      if (mediaInfo.mediaType === 'FILE') {
        setIsFileRemove(false)
      } else if (mediaInfo.mediaType === 'URL') {
        formikMediaForm.setFieldValue('value', mediaInfo.value)
      }
    }
  }, [mediaInfo, contentsList])

  useEffect(() => {
    // Set XHR
    const nativeOpen = XMLHttpRequest.prototype.open

    XMLHttpRequest.prototype.open = function () {
      const url = arguments[1].split('?')[0]

      // @ts-ignore
      const videoQuery = window.videoQuery

      if (arguments[1].includes('STREAMING') && videoQuery) {
        arguments[1] = url + '?' + videoQuery
      }

      // @ts-ignore
      nativeOpen.apply(this, arguments)
    }
  }, [])

  // Validation
  const validationMediaFormSchema = Yup.object().shape({
    // name: Yup.string().required(t('validation.required')),
  })

  // Formik
  const formikMediaForm = useFormik({
    initialValues: initialMediaFormValues,
    validationSchema: validationMediaFormSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      // 새 파일 업로드 확인
      if (mediaType === 'FILE' && isFileRemove && fileList.length === 0) {
        message.error(t('error.noFile'))
        return false
      }

      // 프로젝트 미디어 스토리지 체크
      if (
        currentProject &&
        currentProject.usage &&
        currentProject.price !== 'UNLIMITED' &&
        fileList &&
        fileList[0] &&
        (fileList[0].size as number) + currentProject?.usage?.storage.current >
          currentProject?.usage?.storage.limit
      ) {
        message.error(
          t('error.mediaStorageShortage', {
            current: humanFileSize(currentProject?.usage?.storage.current),
            max: humanFileSize(currentProject?.usage?.storage.limit),
          })
        )
        return false
      }

      // Cancel duplicated requests
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel()
      }
      cancelToken = axios.CancelToken.source()

      setStatus(null)
      setLoading(true)

      // 언어별 값 선택
      const languageMap = {}
      currentProject?.languageList.forEach((lang) => {
        languageMap[lang] = {
          name: values[lang].name
            ? values[lang].name.normalize('NFC').trim()
            : '',
          description: values[lang].description
            ? values[lang].description.normalize('NFC').trim()
            : '',
        }
      })

      const req = {
        type: mediaType,
        languageMap,
        fileId:
          mediaType === 'FILE' && mediaInfo?.file
            ? (mediaInfo?.file.id as number)
            : null,
        value: mediaType === 'FILE' ? null : '',
      }

      if (mediaType === 'FILE' && isFileRemove) {
        notification.destroy()
        notification.open({
          message: t('uploading'),
          icon: <LoadingOutlined spin />,
          placement: 'bottomRight',
          duration: 0,
        })

        // 새 파일 업로드
        if (fileList && fileList.length) {
          await uploadFileToS3(fileList, cancelToken)
            .then((res) => {
              const resData = (res as AxiosResponse)
                .data as FileResponseInterface[]
              req.fileId = resData[0].fileId
              req.value = resData[0].value
            })
            .catch((e) => {
              notification.destroy()
              message.error(t('error.wrong'))
            })
        }
      }

      if (mediaType === 'URL') {
        req.value = values.value
      }

      if (mediaInfo) {
        await updateMedia(currentProject?.uid, mediaInfo?.id, req)
          .then(async (res) => {
            notification.destroy()
            message.success(t('saveSuccess'))

            await setTimeout(() => {
              getMedia(currentProject?.uid, mediaInfo?.id).then((res) => {
                dispatch(setMediaFormModal(false, null, res.data))
                dispatch(getProjectUsage(currentProject?.uid as string))

                if (onChangeMedia) onChangeMedia()
              })
            }, 1000)
          })
          .catch((e) => {
            message.error(e.response.data.error)
            setLoading(false)
          })
      } else {
        await createMedia(currentProject?.uid, req)
          .then(async (res) => {
            notification.destroy()
            message.success(t('saveSuccess'))

            const newMediaId = res.data

            await setTimeout(() => {
              getMedia(currentProject?.uid, newMediaId).then((res) => {
                const fileType = res.data.fileType

                // 비디오 스트리밍 변환
                if (
                  isConvertStreaming &&
                  me?.email.includes('rebel9.co.kr') &&
                  fileType === 'VIDEO'
                ) {
                  convertToVideoStreaming(currentProject?.uid, newMediaId, {
                    types: ['FHD', 'HD', 'SD'],
                  })
                    .then((res) => {
                      dispatch(setMediaFormModal(false, null, res.data))
                    })
                    .catch((e) => {
                      message.error(e.response.data.error)
                    })
                }

                dispatch(setMediaFormModal(false, null, newMediaId))

                const uploadedMediaList: MediaInterface[] = [res.data]

                dispatch(getProjectUsage(currentProject?.uid as string))
                dispatch(setNewMediaList(uploadedMediaList, mediaUploadUid))
                if (onChangeMedia) onChangeMedia()
              })
            }, 1000)
          })
          .catch((e) => {
            message.error(e.response.data.error)
            setLoading(false)
          })
      }
    },
  })

  // Upload props
  const uploadProps = {
    onRemove: (file) => {
      setFileList([])
    },
    beforeUpload: (file) => {
      const reader = new FileReader()

      reader.onload = (e) => {
        // @ts-ignore
        if (e.target && e.target.result) {
          const fileAdded = file

          // 프로젝트 미디어 스토리지 체크
          if (
            currentProject &&
            currentProject.usage &&
            currentProject.price !== 'UNLIMITED' &&
            file.size + currentProject?.usage?.storage.current >
              currentProject?.usage?.storage.limit
          ) {
            message.error(
              t('error.mediaStorageShortage', {
                current: humanFileSize(currentProject?.usage?.storage.current),
                max: humanFileSize(currentProject?.usage?.storage.limit),
              })
            )
            return false
          }

          // 파일 크기 체크
          if (maxFileSize && file.size > maxFileSize * 1024) {
            message.error(
              t('error.fileTooBig', {
                name: file.name,
                size: maxFileSize / 1024,
              })
            )
            return false
          }

          // 이미지의 경우 해상도 확인
          if (file.type.indexOf('image') >= 0 && imageExtensions[file.type]) {
            const image = new Image()

            // @ts-ignore
            image.src = e.target.result
            image.onload = function () {
              // @ts-ignore
              const height = this.height
              // @ts-ignore
              const width = this.width

              if (
                maxFileRes &&
                width &&
                height &&
                (width > maxFileRes || height > maxFileRes)
              ) {
                message.error(
                  t('error.imageSizeTooBig', {
                    name: file.name,
                    px: maxFileRes,
                  })
                )
                return false
              } else {
                const fileAdded = file
                fileAdded.status = 'done'
                // @ts-ignore
                fileAdded.url = e.target.result
                setFileList([fileAdded])
              }

              return true
            }
          } else {
            const fileAdded = file
            fileAdded.status = 'done'
            fileAdded.url = e.target.result
            setFileList([fileAdded])
          }
        }
      }
      reader.onerror = (error) => {
        console.log(error)
        message.error(t('error.wrong'))
      }
      reader.readAsDataURL(file)

      return false
    },
    fileList,
  }

  /**
   * 폼 리셋
   */
  const resetForm = () => {
    formikMediaForm.resetForm()
    setLoading(false)
    setMediaType('FILE')
    setFileList([])
    setIsFileRemove(true)
    setShowPreview(false)
    setContentsList([])
    setCurrentMedia(null)
    setIsConvertStreaming(false)

    // Reset file input #mediaFormFile
    const mediaFormFile = document.getElementById('mediaFormFile')
    if (mediaFormFile) {
      mediaFormFile['value'] = ''
    }

    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel()
    }
  }

  /**
   * 이미지 프리뷰
   * @param mediaInfo
   */
  const onHandlePreview = (mediaInfo) => {
    getOriginalMedia(currentProject?.uid, mediaInfo.id)
      .then((res) => {
        window.open(res.data, '_blank')
      })
      .catch((e) => {
        message.error(t('error.wrong'))
      })
  }

  /**
   * 다운로드
   * @param active
   */
  const download = () => {
    if (!mediaInfo || busy) return

    getOriginalMedia(currentProject?.uid, mediaInfo.id)
      .then((res) => {
        setBusy(true)
        const source = res.data
        const fileName = mediaInfo.file.name.normalize('NFC')

        var xhr = new XMLHttpRequest()
        xhr.open('GET', source, true)
        xhr.responseType = 'blob'
        xhr.onload = function () {
          var urlCreator = window.URL || window.webkitURL
          var imageUrl = urlCreator.createObjectURL(this.response)
          var tag = document.createElement('a')
          tag.href = imageUrl
          tag.download = fileName
          document.body.appendChild(tag)
          tag.click()
          document.body.removeChild(tag)
          setBusy(false)
        }
        xhr.onerror = function () {
          message.error(t('error.wrong'))
          setBusy(false)
        }
        xhr.send()
      })
      .catch((e) => {
        message.error(t('error.wrong'))
        setBusy(false)
      })
  }

  /**
   * 파일 도구
   * @returns
   */
  const fileTools = () => {
    return (
      <div className="absolute top-3 right-3 z-10">
        <ul className="flex space-x-2">
          {mediaInfo &&
          mediaInfo.file &&
          mediaInfo &&
          mediaInfo.file.type === 'IMAGE' ? (
            <li
              className="cursor-pointer"
              onClick={() => onHandlePreview(mediaInfo)}>
              <div className="w-6 h-6 border border-gray-400 rounded-sm bg-white flex justify-center items-center">
                <ExpandOutlined title={t('expandFullSize')} />
              </div>
            </li>
          ) : (
            <></>
          )}
          <li className="cursor-pointer" onClick={() => download()}>
            <div
              className={`w-6 h-6 border border-gray-400 rounded-sm bg-white flex justify-center items-center ${
                busy ? 'opacity-50 cursor-not-allowed' : ''
              }`}>
              <DownloadOutlined title={t('download')} />
            </div>
          </li>
          {currentProject?.role !== 'VIEWER' ? (
            <li
              className="cursor-pointer"
              onClick={() => setIsFileRemove(true)}>
              <div className="w-6 h-6 border border-gray-400 rounded-sm bg-white flex justify-center items-center">
                <DeleteFilled title={t('deleteFile')} />
              </div>
            </li>
          ) : (
            <></>
          )}
        </ul>
      </div>
    )
  }

  /**
   * 업로드 취소 확인
   */
  const confirmModalClose = () => {
    if (!loading || (loading && confirm(t('confirmFileUploadCancel')))) {
      setCurrentMedia(null)

      setTimeout(() => {
        dispatch(setMediaFormModal(false))
      })
    }
  }

  /**
   * 미디어 정보 가져오기
   * @param media
   */
  const getMediaContentsInfo = (media: MediaInterface) => {
    getMedia(currentProject?.uid, media.id)
      .then((res) => {
        setCurrentMedia(res.data)
        setContentsList(res.data.contentList)

        if (res.data.file && res.data.file.video?.query) {
          // @ts-ignore
          window.videoQuery = res.data.file.video.query
        }
      })
      .catch((e) => {
        message.error(t('error.wrong'))
        resetForm()
      })
  }

  const generateVideoStreaming = () => {
    if (!currentMedia) return

    if (confirm('비디오 스트리밍을 생성하시겠습니까?')) {
      convertToVideoStreaming(currentProject?.uid, currentMedia.id, {
        types: ['FHD', 'HD', 'SD'],
      })
        .then((res) => {
          dispatch(setMediaFormModal(false, null, res.data))
          message.success(
            '비디오 스트리밍 생성 완료. 페이지를 새로고침하여 확인하세요.'
          )
        })
        .catch((e) => {
          message.error(e.response.data.error)
        })
    }
  }

  return (
    <Modal
      width={LAYOUT_MODAL_WIDTH}
      zIndex={1010}
      closeIcon={<CloseCircleOutlined title={t('close')} />}
      open={mediaFormModal}
      maskClosable={false}
      onCancel={() => confirmModalClose()}
      title={
        mediaInfo
          ? mediaInfo.file
            ? mediaInfo.file.name
            : mediaInfo.value
          : t('addMediaContents')
      }
      footer={[
        <div key={'footer'} className={'flex justify-between items-center'}>
          <div>
            <Button type="primary" ghost onClick={() => confirmModalClose()}>
              {t('cancel')}
            </Button>
          </div>
          {currentProject?.role !== 'VIEWER' ? (
            <div>
              <Button
                type={'primary'}
                icon={<CheckOutlined />}
                onClick={() => formikMediaForm.submitForm()}
                disabled={
                  (mediaType === 'FILE' &&
                    isFileRemove &&
                    fileList.length === 0) ||
                  (mediaType === 'URL' && !formikMediaForm.values.value) ||
                  loading
                }
                loading={loading}>
                {t('save')}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>,
      ]}>
      {currentProject && mediaFormModal ? (
        <>
          <form onSubmit={formikMediaForm.handleSubmit} method="POST">
            <AlertStatus
              status={formikMediaForm.status}
              onClick={() => formikMediaForm.setStatus(null)}></AlertStatus>
            <div className={'space-y-6'}>
              <Row gutter={24}>
                <Col xs={24} lg={12} className="space-y-6">
                  {!mediaInfo ? (
                    <div>
                      <Radio.Group
                        onChange={(e) => setMediaType(e.target.value)}
                        value={mediaType}
                        disabled={currentProject.role === 'VIEWER'}>
                        <Radio value={'FILE'}>{t('uploadFile')}</Radio>
                        <Radio value={'URL'}>{t('addUrl')}</Radio>
                      </Radio.Group>
                    </div>
                  ) : (
                    <></>
                  )}
                  {mediaType === 'FILE' ? (
                    isFileRemove ? (
                      <div>
                        <input
                          type="file"
                          id="mediaFormFile"
                          onChange={(e) => {
                            // @ts-ignore
                            const file = e.target.files[0]
                            if (file) {
                              // @ts-ignore
                              setFileList([file])
                            }
                          }}
                        />
                        {/* <Upload
                          {...uploadProps}
                          listType={'picture-card'}
                          maxCount={1}
                          multiple={true}
                          disabled={loading}>
                          <div>
                            <p className="ant-upload-drag-icon">
                              <PlusOutlined />
                            </p>
                            <p className="ant-upload-text">Upload</p>
                          </div>
                        </Upload> */}
                        {/* {me?.email.includes('rebel9.co.kr') &&
                          fileList.length > 0 &&
                          fileList[0].type?.includes('video') && (
                            <div className="py-2">
                              <Checkbox
                                checked={isConvertStreaming}
                                onChange={(e) =>
                                  setIsConvertStreaming(e.target.checked)
                                }>
                                <div className="flex items-center space-x-1 relative top-px">
                                  <PlayCircleOutlined className="text-sm !text-red-500" />
                                  <div>Video Streaming</div>
                                </div>
                              </Checkbox>
                            </div>
                          )} */}
                        <p className="mt-1">
                          {maxFileSize
                            ? t('maxFileDesc', {
                                size: maxFileSize / 1024,
                              })
                            : ''}
                        </p>
                      </div>
                    ) : currentMedia &&
                      currentMedia.file &&
                      currentMedia.file.processType === 'PROCESS' ? (
                      <div
                        className={
                          'w-full aspect-1 bg-gray-300 flex-none flex items-center justify-center'
                        }>
                        <Spin />
                      </div>
                    ) : (
                      <>
                        <div className="relative">
                          {fileTools()}
                          {currentMedia &&
                          currentMedia.file &&
                          currentMedia.file.type === 'IMAGE' ? (
                            <div className="w-full flex-none bg-gray-300 border">
                              <MediaThumb
                                media={currentMedia}
                                textSize="3xl"
                                thumbnail="MEDIUM"
                              />
                            </div>
                          ) : currentMedia ? (
                            <div
                              className={
                                'w-full aspect-1 bg-gray-300 flex-none flex items-center justify-center'
                              }>
                              {currentMedia?.mediaType === 'FILE' &&
                              currentMedia.file.type === 'VIDEO' &&
                              currentMedia.file.video ? (
                                <MediaVideoStreaming media={currentMedia} />
                              ) : currentMedia?.mediaType === 'FILE' &&
                                currentMedia.file.type === 'VIDEO' ? (
                                <VideoCameraOutlined className="text-5xl" />
                              ) : (
                                /* currentMedia.file.name.split('.').pop() ===
                                  'glb' ||
                                currentMedia.file.name.split('.').pop() ===
                                  'gltf' ? (
                                <GlbViewer file={currentMedia} />
                              ) : (
                                <FileOutlined className="text-5xl" />
                              ) */
                                <FileOutlined className="text-5xl" />
                              )}
                              {me?.email.includes('rebel9.co.kr') &&
                                currentMedia.file.type === 'VIDEO' &&
                                !currentMedia.file.video && (
                                  <div className="absolute left-3 top-3">
                                    <Button
                                      size="small"
                                      onClick={() => generateVideoStreaming()}>
                                      <div className="flex items-center space-x-1 relative">
                                        <PlayCircleOutlined className="text-xs !text-red-500" />
                                        <div className="text-xs">
                                          Generate Video Streaming
                                        </div>
                                      </div>
                                    </Button>
                                  </div>
                                )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    )
                  ) : (
                    <></>
                  )}
                  {mediaType === 'URL' ? (
                    <div className="block">
                      <label htmlFor={`mediaFormNameValue`} className="">
                        <div className={'mb-2'}>
                          URL <span className="text-500">*</span>
                        </div>
                        <Input.Group compact>
                          <Input
                            id={'mediaFormNameValue'}
                            name={`value`}
                            type={'url'}
                            onChange={formikMediaForm.handleChange}
                            value={formikMediaForm.values.value}
                            readOnly={currentProject.role === 'VIEWER'}
                            style={{
                              width:
                                mediaInfo &&
                                mediaInfo.value === formikMediaForm.values.value
                                  ? `calc(100% - 32px)`
                                  : '100%',
                            }}
                          />
                          {mediaInfo &&
                          mediaInfo.value === formikMediaForm.values.value ? (
                            <Button
                              icon={<ExportOutlined title={t('openNewTab')} />}
                              onClick={() => onHandlePreview(mediaInfo.value)}
                              title={t('openNewTab')}
                            />
                          ) : (
                            <></>
                          )}
                        </Input.Group>
                      </label>
                    </div>
                  ) : (
                    <></>
                  )}
                  {currentMedia &&
                  currentMedia.mediaType === 'URL' &&
                  (youtubeParser(currentMedia.value) ||
                    vimeoParser(currentMedia.value)) ? (
                    <MediaThumb media={currentMedia} textSize="5xl" />
                  ) : (
                    <></>
                  )}
                  {currentMedia &&
                  mediaType === 'FILE' &&
                  currentMedia.file &&
                  currentMedia.file.processType === 'COMPLETE' ? (
                    <FileInfo mediaInfo={currentMedia} />
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xs={24} lg={12}>
                  <div className="space-y-4">
                    <Tabs defaultActiveKey={currentProject.defaultLang}>
                      {currentProject.languageList.map((lang) => (
                        <Tabs.TabPane
                          tab={t('lang.' + lang.toLocaleLowerCase())}
                          key={lang}
                          className={'space-y-6'}>
                          <div className="block">
                            <label
                              htmlFor={`mediaFormName${lang}`}
                              className="">
                              <div className={'mb-2'}>{t('title')}</div>
                              <Input
                                id={'mediaFormName' + lang}
                                name={`${lang}.name`}
                                type={'text'}
                                onChange={formikMediaForm.handleChange}
                                value={formikMediaForm.values[lang].name}
                                readOnly={currentProject.role === 'VIEWER'}
                              />
                            </label>
                          </div>
                          <div className="block">
                            <label
                              htmlFor={`mediaFormDesc${lang}`}
                              className="">
                              <div className={'mb-2'}>{t('description')}</div>
                              <Input.TextArea
                                id={'mediaFormDesc' + lang}
                                name={`${lang}.description`}
                                rows={5}
                                onChange={formikMediaForm.handleChange}
                                value={formikMediaForm.values[lang].description}
                                readOnly={currentProject.role === 'VIEWER'}
                              />
                            </label>
                          </div>
                        </Tabs.TabPane>
                      ))}
                    </Tabs>
                  </div>
                </Col>
              </Row>
              {/* 미디어 연결 콘텐츠: 시작 */}
              <ConnectedContents mediaContentsList={contentsList} />
              {/* 미디어 연결 콘텐츠: 끝 */}
            </div>
          </form>
          <Modal
            width={'100%'}
            centered={true}
            open={showPreview}
            maskClosable={false}
            footer={null}
            onCancel={() => setShowPreview(false)}>
            <img src={previewUrl} className={'w-full'} />
          </Modal>
        </>
      ) : (
        <></>
      )}
    </Modal>
  )
}
