import React, { useState, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Modal, Tooltip, message } from 'antd'
import {
  CaretDownFilled,
  CaretRightFilled,
  EditFilled,
  DeleteFilled,
  HolderOutlined,
  ExclamationCircleOutlined,
  CodeOutlined,
  DiffOutlined,
  KeyOutlined,
  AuditOutlined,
  CopyFilled,
  TranslationOutlined,
} from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'
import { ComponentTypeInterface, ComponentInterface } from '@/types'
import { availableComponents } from '@/configs'
import { setTmpModel, setComponentFormModal } from '@/states/actions'
import { ComponentsAdd } from '.'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DragHandlerProps,
} from 'react-beautiful-dnd'
import { RootState, useAppDispatch } from '@/states'

interface ComponentsItemProps {
  dragHandler: DragHandlerProps
  component: ComponentInterface
  parentComponent?: ComponentInterface
  customClassName?: string
  disabled: boolean
}

export const ComponentsItem = ({
  dragHandler,
  component,
  parentComponent,
  customClassName,
  disabled,
}: ComponentsItemProps) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject, tmpModel } = projectsState

  // State
  const [showChildren, setShowChildren] = useState<boolean>(true)
  const [componentTypeObject, setComponentTypeObject] = useState<
    ComponentTypeInterface | null | undefined
  >(null)

  // Effect
  useEffect(() => {
    setComponentTypeObject(
      availableComponents.find((c) => c.type === component.type)
    )
  }, [component])

  /**
   * 컴포넌트 정렬
   * @param oldIndex
   * @param newIndex
   */
  const onSortEnd = (result) => {
    if (!result.destination) {
      return
    }

    const updateModelComponents = JSON.parse(
      JSON.stringify(tmpModel?.componentList)
    )

    function iterateComponents(components) {
      components.forEach((com) => {
        if (com.id === component.id) {
          // Sort 실행
          const startIndex = result.source.index
          const endIndex = result.destination.index
          const components = com?.childList
          const [removed] = components.splice(startIndex, 1)
          components.splice(endIndex, 0, removed)

          const updatedModel = tmpModel
          if (updatedModel) {
            updatedModel.componentList = updateModelComponents
            dispatch(setTmpModel(updatedModel))
          }
        } else if (com.childList && com.childList.length) {
          iterateComponents(com.childList)
        }
      })
    }

    iterateComponents(updateModelComponents)
  }

  /**
   * 컴포넌트 수정
   */
  const onEditComponent = () => {
    dispatch(setComponentFormModal(true, component, parentComponent))
  }

  /**
   * 컴포넌트 삭제
   */
  const onDeleteComponent = () => {
    Modal.confirm({
      centered: true,
      title: t('confirmDeleteComponentTitle', {
        component: currentProject
          ? component.languageMap[currentProject?.defaultLang]
          : '',
      }),
      icon: <ExclamationCircleOutlined />,
      content: t('confirmDeleteComponentDesc'),
      okText: t('delete'),
      cancelText: t('cancel'),
      onOk() {
        const updatedModel = tmpModel
        if (!updatedModel) return false

        if (!parentComponent) {
          updatedModel.componentList = updatedModel.componentList.filter(
            (c) => c.id !== component.id
          )
        } else {
          removeFromComponents(updatedModel.componentList)
        }

        function removeFromComponents(components) {
          components.forEach((com, cIdx) => {
            if (parentComponent && com.id === parentComponent.id) {
              com.childList = com.childList.filter((c) => c.id !== component.id)
              dispatch(setTmpModel(updatedModel))
            } else if (com.childList && com.childList.length) {
              removeFromComponents(com.childList)
            }
          })
        }

        dispatch(setTmpModel(updatedModel))
      },
      onCancel() {},
    })
  }

  /**
   * 컴포넌트 복제
   */
  const onDuplicateComponent = () => {
    const updatedModel = tmpModel

    if (!updatedModel) return false

    // 부모 컴포넌트 있을 경우
    if (parentComponent) {
      const newComponent: ComponentInterface = JSON.parse(
        JSON.stringify(component)
      )
      newComponent.id = uuidv4()
      newComponent.devKey = uuidv4()
      newComponent.order = parentComponent.childList?.length
      newComponent.first = false

      const parentComponentItem = updatedModel.componentList.find(
        (c) => c.id === parentComponent.id
      )

      if (parentComponentItem && parentComponentItem.childList) {
        parentComponentItem.childList = [
          ...parentComponentItem.childList,
          newComponent,
        ]

        dispatch(setTmpModel(updatedModel))
      } else if (parentComponentItem) {
        parentComponentItem.childList = [newComponent]
      }
    }
    // 최상위 컴포넌트
    else {
      const newComponent: ComponentInterface = JSON.parse(
        JSON.stringify(component)
      )
      newComponent.id = uuidv4()
      newComponent.devKey = uuidv4()
      newComponent.order = updatedModel.componentList.length
      newComponent.first = false

      updatedModel.componentList = [...updatedModel.componentList, newComponent]
      dispatch(setTmpModel(updatedModel))
    }

    message.success(t('duplicateComponentSuccess'))
  }

  return currentProject ? (
    <div className={`${customClassName}`}>
      <div className="flex items-center p-3 space-x-2">
        <div
          className={`flex-none w-6 h-6 flex justify-center items-center ${
            component.type === 'BLOCK' ? 'cursor-pointer' : ''
          }`}
          onClick={() => setShowChildren(!showChildren)}>
          {component.type === 'BLOCK' ? (
            !showChildren ? (
              <CaretRightFilled />
            ) : (
              <CaretDownFilled />
            )
          ) : (
            <></>
          )}
        </div>
        <div className="w-6 h-6 bg-gray-200 text-xs flex-none flex justify-center items-center text-gray-500">
          {componentTypeObject ? <componentTypeObject.icon /> : <></>}
        </div>
        <div className="grow flex overflow-hidden">
          <div
            className={`w-1/2 ${
              component.type === 'TITLE' ? 'font-bold' : ''
            } text-xs leading-5 truncate`}>
            {component.languageMap[currentProject?.defaultLang]}{' '}
            {component.option && component.option.required ? (
              <Tooltip
                title={t('optionsType.required.desc')}
                className="cursor-help">
                <span className="text-red-500 mr-1">*</span>
              </Tooltip>
            ) : (
              ''
            )}
            <small className="text-xxs text-gray-500 font-normal">
              {component.devKey}
            </small>
          </div>
          <div className="w-1/2 text-xs leading-5 truncate flex space-x-1 items-center">
            <span>{t('componentTypes.' + component.type + '.name')}</span>
            {component.type === 'TITLE' ? (
              <span>
                <Tooltip
                  title={t('optionsType.title.desc')}
                  className="cursor-help">
                  <KeyOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
            {component.type === 'TITLE' ||
            component.type === 'SINGLE_LINE_TEXT' ||
            component.type === 'LONG_LINE_TEXT' ||
            component.type === 'RICH_TEXT' ? (
              <span>
                <Tooltip
                  title={t('optionsType.multiLanguage.desc')}
                  className="cursor-help">
                  <TranslationOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
            {component.option?.unique ? (
              <span>
                <Tooltip
                  title={t('optionsType.unique.desc')}
                  className="cursor-help">
                  <AuditOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
            {component.option?.allowHtml ? (
              <span>
                <Tooltip
                  title={t('optionsType.allowHtml.desc')}
                  className="cursor-help">
                  <CodeOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
            {component.option?.multiple ? (
              <span>
                <Tooltip
                  title={t(`optionsType.multiple.${component.type}.desc`)}
                  className="cursor-help">
                  <DiffOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
            {component.option?.multipleMedia ? (
              <span>
                <Tooltip
                  title={t('optionsType.multipleMedia.desc')}
                  className="cursor-help">
                  <DiffOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
        {currentProject?.role === 'OWNER' ||
        currentProject?.role === 'ADMIN' ? (
          <div className="flex-none flex justify-between items-center w-20">
            <EditFilled
              className="cursor-pointer"
              onClick={() => onEditComponent()}
              title={t('editComponent')}
            />
            <CopyFilled
              className={
                component.type === 'TITLE'
                  ? 'opacity-50 !cursor-not-allowed'
                  : 'cursor-pointer'
              }
              onClick={() => {
                if (component.type !== 'TITLE') {
                  onDuplicateComponent()
                }
              }}
              title={t('duplicateComponent')}
            />
            <DeleteFilled
              className="cursor-pointer"
              onClick={() => onDeleteComponent()}
              title={t('deleteComponent')}
            />
            <div {...dragHandler}>
              <HolderOutlined
                className="cursor-move"
                title={t('changeOrder')}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {component.type === 'BLOCK' ? (
        <div className={`pl-10 pr-2.5 pb-3 ${showChildren ? '' : 'hidden'}`}>
          <DragDropContext onDragEnd={onSortEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  className="space-y-2"
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  {component.childList?.map((subComponent, index) => (
                    <Draggable
                      disabled={true}
                      key={subComponent.id}
                      draggableId={(subComponent.id as number).toString()}
                      index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}>
                          <div>
                            <ComponentsItem
                              dragHandler={provided.dragHandleProps}
                              component={subComponent}
                              parentComponent={component}
                              customClassName="bg-gray-100 shadow-sm border border-gray-200 rounded"
                              disabled={disabled}
                            />
                            {provided.placeholder}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {currentProject?.role === 'OWNER' ||
                  currentProject?.role === 'ADMIN' ? (
                    <Draggable
                      key={'new-' + component.id}
                      draggableId={'new-' + component.id}
                      isDragDisabled={true}
                      disableInteractiveElementBlocking={true}
                      index={999999}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          <div className="mt-2">
                            <ComponentsAdd
                              parentComponent={component}
                              disabled={disabled}
                            />
                          </div>
                          {provided.placeholder}
                        </div>
                      )}
                    </Draggable>
                  ) : (
                    <></>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  )
}
