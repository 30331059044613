import { AppDispatch } from '@/states'
import { ActionTypesProjects } from '@/states/interfaces'
import { MediaInterface } from '@/types'

/**
 * 미디어 목록 설정
 * @param list
 * @returns
 */
export const setMediaList =
  (list: MediaInterface[]) => (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_MEDIA_LIST,
      payload: {
        mediaList: list,
      },
    })
  }

/**
 * 새 업로드 미디어 목록 설정
 * @param list
 * @param uid
 * @returns
 */
export const setNewMediaList =
  (list: MediaInterface[], uid) => (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_NEW_MEDIA_LIST,
      payload: {
        mediaList: list,
        uid: uid,
      },
    })
  }

/**
 * 체크된 미디어 목록 설정
 * @param list
 * @returns
 */
export const setCheckedMediaList =
  (list: MediaInterface[]) => (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_CHECKED_MEDIA_LIST,
      payload: {
        checkedMediaList: list,
      },
    })
  }
