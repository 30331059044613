import {
  createCategoryGroup,
  createCategorySelector,
  deleteCategoryGroup,
  getCategoryGroups,
  updateCategoryGroup,
  updateCategorySelector,
} from '@/api'
import { AlertStatus } from '@/components/Common'
import { LAYOUT_MODAL_WIDTH } from '@/configs'
import { RootState, useAppDispatch } from '@/states'
import { setCategoryFormModal } from '@/states/actions'
import { LanguageMap, SelectorInterface } from '@/types'
import {
  CheckOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { Button, Col, Collapse, Drawer, Input, Modal, Row, message } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import * as Yup from 'yup'
import CategoriesPreview from './CategoriesPreview'

const initialComponentFormValues = {
  name: '',
  selector: {
    KO: '',
    EN: '',
    JP: '',
    CN: '',
  },
}

export const CategoriesFormModal = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const [, updateState] = React.useState<{} | undefined>()

  // State (Redux)
  const { modalsState, projectsState } = useSelector(
    (state: RootState) => ({
      modalsState: state.modals,

      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { categoryFormModal, categoryInfo } = modalsState
  const { currentProject, categoriesList } = projectsState

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [previousVersionIds, setPreviousVersionIds] = useState<number[]>([])
  const [previousVersionKeys, setPreviousVersionKeys] = useState<any>({})

  // Memo
  const isEditable = useMemo(() => {
    return currentProject?.role === 'OWNER' || currentProject?.role === 'ADMIN'
  }, [currentProject])

  // Effect
  useEffect(() => {
    if (categoryFormModal) {
      setTimeout(() => {
        document.getElementById('componentFormName')?.focus()
      })

      if (!categoryInfo) {
        formikCategoryForm.validateForm()
      }
    } else {
      resetForm()
    }
  }, [categoryFormModal])

  useEffect(() => {
    if (categoryInfo && currentProject) {
      formikCategoryForm.setFieldValue(
        'name',
        categoryInfo.languageMap[currentProject.defaultLang]
      )

      // 카테고리 선택지
      if (categoryInfo.selectorList) {
        currentProject.languageList.forEach((curLang, curLangIdx) => {
          let categoryStr = ''
          let previousVersionIdsArr = [] as number[]
          let previousVersionKeys = {} as any

          categoryInfo.selectorList?.forEach((cate, cIdx) => {
            if (cIdx) categoryStr += '\n'
            categoryStr += cate.languageMap[curLang]

            if (curLangIdx === 0) {
              previousVersionIdsArr.push(cate.id as number)
              previousVersionKeys[cate.languageMap[curLang] as string] = cate.id
            }

            const getChildLang = (
              selector: SelectorInterface,
              depth: number,
              parentName: string
            ) => {
              if (selector.childList) {
                selector.childList.forEach((child, cIdx) => {
                  categoryStr += '\n'
                  categoryStr += parentName + child.languageMap[curLang]

                  if (curLangIdx === 0) {
                    previousVersionIdsArr.push(child.id as number)
                    previousVersionKeys[
                      parentName + child.languageMap[curLang]
                    ] = child.id
                  }

                  getChildLang(
                    child,
                    depth + 1,
                    parentName + child.languageMap[curLang] + ':'
                  )
                })
              }
            }

            getChildLang(cate, 1, cate.languageMap[curLang] + ':')
          })

          formikCategoryForm.setFieldValue('selector.' + curLang, categoryStr)
          setPreviousVersionIds(previousVersionIdsArr)
          setPreviousVersionKeys(previousVersionKeys)
        })
      }
    } else {
      setPreviousVersionIds([])
    }
  }, [categoryInfo])

  // Validation
  const validationComponentFormSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
  })

  // Formik
  const formikCategoryForm = useFormik({
    initialValues: initialComponentFormValues,
    validationSchema: validationComponentFormSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (!currentProject) return false

      // 언어별 값 선택
      const languageMap = {}
      currentProject.languageList.forEach((lang) => {
        languageMap[lang] = values.name.normalize('NFC').replace('\b', '')
      })

      const req = {
        order: 0,
        languageMap,
        selectorList: [] as { languageMap: LanguageMap }[],
      }

      // 카테고리 선택
      let isCategoryValid = true
      const oldSelectorLength = categoryInfo?.selectorList?.length
      const selectorList: {
        languageMap: LanguageMap
        id?: number
        order?: number
        previousVersionId?: number
        childList?: SelectorInterface[]
      }[] = []
      let initCategoryLength = values.selector[
        currentProject.defaultLang
      ].trim()
        ? values.selector[currentProject.defaultLang].trim().split('\n').length
        : 0
      let allCateIdx = 0

      await currentProject.languageList.forEach(async (lang, langIdx) => {
        const curCateArr = values.selector[lang].trim()
          ? values.selector[lang].trim().split('\n')
          : []

        if (new Set(curCateArr).size !== curCateArr.length) {
          message.warning(t('categoryValueDuplicate'))
          isCategoryValid = false
          return false
        }

        if (initCategoryLength !== curCateArr.length) {
          message.warning(t('categoryValueLength'))
          isCategoryValid = false
          return false
        }

        // 언어별 selector 넣기
        await curCateArr
          .filter((c) => !c.includes(':'))
          .forEach(async (cate, cateIdx) => {
            if (langIdx === 0) {
              const languageMapValue = {
                languageMap: {},
              } as { languageMap: LanguageMap }
              languageMapValue.languageMap[lang] = cate

              selectorList.push(languageMapValue)
            } else {
              selectorList[cateIdx].languageMap[lang] = cate
            }

            selectorList[cateIdx].order = cateIdx + 1

            if (
              categoryInfo &&
              categoryInfo.selectorList &&
              oldSelectorLength &&
              oldSelectorLength > cateIdx
            ) {
              selectorList[cateIdx].previousVersionId =
                categoryInfo?.selectorList[cateIdx].id
              selectorList[cateIdx].id = categoryInfo?.selectorList[cateIdx].id
            }

            selectorList[cateIdx].childList = []

            if (langIdx === 0) {
              /**
               * 하위 카테고리 구하기
               * @param parentNameArr
               * @param depth
               */
              const setChildList = (
                parentNameArr: string[],
                depth: number,
                parentSelector: SelectorInterface
              ) => {
                curCateArr
                  .filter((c) => {
                    return (
                      c.includes(':') &&
                      c.split(':').length - 1 === depth &&
                      c.split(':')[depth - 1] === parentNameArr[depth - 1] &&
                      c.includes(parentNameArr.join(':'))
                    )
                  })
                  .forEach(async (childCate, order) => {
                    const nextChildCate = childCate.split(':')[depth]

                    // Set Langauge map
                    const languageMapValue = {
                      languageMap: {},
                    } as { languageMap: LanguageMap }

                    await currentProject.languageList.forEach(
                      (childLang, childLangIdx) => {
                        languageMapValue.languageMap[childLang] = nextChildCate
                      }
                    )

                    allCateIdx += 1
                    let childOrder = 1

                    const childObj = {
                      order: childOrder,
                      languageMap: languageMapValue.languageMap,
                      childList: [],
                      id: previousVersionKeys[
                        parentNameArr.join(':') +
                          ':' +
                          languageMapValue.languageMap[lang]
                      ]
                        ? previousVersionKeys[
                            parentNameArr.join(':') +
                              ':' +
                              languageMapValue.languageMap[lang]
                          ]
                        : null,
                      // id: previousVersionIds[allCateIdx],
                    }

                    childOrder += 1

                    if (parentSelector.childList) {
                      parentSelector.childList.push(childObj)
                    } else {
                      parentSelector.childList = [childObj]
                    }

                    if (parentSelector) {
                      setChildList(
                        [...parentNameArr, nextChildCate],
                        depth + 1,
                        childObj
                      )
                    }
                  })
              }

              await setChildList([cate], 1, selectorList[cateIdx])
            }
          })
      })

      if (!isCategoryValid) return false

      // 카테고리 값 확인
      if (selectorList.length === 0) {
        message.warning(t('categoryValueEmpty'))
        return false
      }

      req.selectorList = selectorList

      // 추가
      if (!categoryInfo) {
        req.order = categoriesList.length

        await createCategoryGroup(currentProject.uid, req)
          .then(async (res) => {
            await createCategorySelector(currentProject?.uid, res.data, req)
            await dispatch(getCategoryGroups(currentProject.uid))
          })
          .catch((e) => {})
          .then(() => {
            dispatch(setCategoryFormModal(false))
          })
      }
      // 수정
      else {
        req.order = categoryInfo.order

        await updateCategoryGroup(currentProject.uid, categoryInfo.id, req)
          .then(async (res) => {
            await updateCategorySelector(
              currentProject?.uid,
              categoryInfo.id,
              req
            )
            await dispatch(getCategoryGroups(currentProject.uid))
          })
          .catch((e) => {})
          .then(() => {
            dispatch(setCategoryFormModal(false))
          })
      }
    },
  })

  /**
   * 폼 리셋
   */
  const resetForm = () => {
    formikCategoryForm.resetForm()
    setLoading(false)
  }

  /**
   * 카테고리 삭제
   */
  const onDeleteCategory = () => {
    if (!currentProject) return

    Modal.confirm({
      centered: true,
      title: t('confirmDeleteCategoryTitle', {
        category: categoryInfo?.languageMap[currentProject.defaultLang],
      }),
      icon: <ExclamationCircleOutlined />,
      content: t('confirmDeleteCategoryDesc'),
      okText: t('delete'),
      cancelText: t('cancel'),
      onOk() {
        deleteCategoryGroup(currentProject?.uid, categoryInfo?.id).then(
          (res) => {
            dispatch(getCategoryGroups(currentProject?.uid))
            dispatch(setCategoryFormModal(false))
            message.success(t('deleteSuccess'))
          }
        )
      },
      onCancel() {},
    })
  }

  return currentProject ? (
    <>
      {/* 카테고리 모달: 시작 */}
      <Drawer
        width={LAYOUT_MODAL_WIDTH}
        closeIcon={<CloseCircleOutlined title={t('close')} />}
        open={categoryFormModal}
        maskClosable={false}
        onClose={() => dispatch(setCategoryFormModal(false))}
        title={
          <div className="flex items-center space-x-2">
            <span>{categoryInfo ? t('editCategory') : t('addCategory')}</span>
          </div>
        }
        extra={[
          <div key={'extra'} className={'flex justify-between items-center'}>
            <div></div>
            {isEditable && (
              <div>
                <Button
                  type={'primary'}
                  icon={<CheckOutlined />}
                  onClick={() => formikCategoryForm.submitForm()}
                  disabled={loading}
                  loading={loading}>
                  {categoryInfo ? t('update') : t('addCategory')}
                </Button>
              </div>
            )}
          </div>,
        ]}
        footer={[
          <div key={'footer'} className={'flex justify-between items-center'}>
            <div className="flex space-x-2">
              <Button
                type="primary"
                ghost
                onClick={() => dispatch(setCategoryFormModal(false))}>
                {t('close')}
              </Button>
              {categoryInfo && isEditable ? (
                <Button
                  type="primary"
                  danger
                  onClick={() => onDeleteCategory()}>
                  {t('deleteCategory')}
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>,
        ]}>
        <>
          <form onSubmit={formikCategoryForm.handleSubmit} method="POST">
            <AlertStatus
              status={formikCategoryForm.status}
              onClick={() => formikCategoryForm.setStatus(null)}></AlertStatus>
            <div className={'space-y-6'}>
              {/* 카테고리 폼 선택: 시작 */}
              <Row gutter={24}>
                <Col span={24}>
                  <div className="block">
                    <label htmlFor="categoryFormName" className="">
                      <div className={'mb-2'}>
                        {t('categoryName')}{' '}
                        <span className="text-red-500">*</span>
                      </div>
                      <Input
                        id={'categoryFormName'}
                        name="name"
                        disabled={!isEditable}
                        onChange={formikCategoryForm.handleChange}
                        value={formikCategoryForm.values.name}
                      />
                    </label>
                    {formikCategoryForm.touched.name &&
                    formikCategoryForm.errors.name ? (
                      <p className="my-1 text-xs text-red-500">
                        {formikCategoryForm.errors.name}
                      </p>
                    ) : null}
                  </div>
                </Col>
              </Row>
              {/* 카테고리 선택: 시작 */}
              <div>
                <div className="mb-2">{t('categoryValue')}</div>
                <Collapse>
                  {currentProject.languageList.map((lang) => (
                    <Collapse.Panel
                      header={
                        t('lang.' + lang.toLowerCase()) +
                        '(' +
                        (categoryInfo?.selectorList
                          ? categoryInfo?.selectorList.length
                          : 0) +
                        ')'
                      }
                      key={lang}>
                      <div className="grid grid-cols-2 gap-6">
                        <Input.TextArea
                          disabled={!isEditable}
                          size="small"
                          name={`selector.${lang}`}
                          rows={15}
                          onChange={formikCategoryForm.handleChange}
                          value={formikCategoryForm.values.selector[lang]}
                        />
                        <CategoriesPreview
                          value={formikCategoryForm.values.selector[lang]}
                        />
                      </div>
                    </Collapse.Panel>
                  ))}
                </Collapse>
                <p
                  className="text-xs text-gray-500 mt-1"
                  dangerouslySetInnerHTML={{
                    __html: t('categoryValueDesc').replace(
                      /(?:\r\n|\r|\n)/g,
                      '<br/>'
                    ),
                  }}
                />
              </div>
              {/* 카테고리 선택: 시작 */}
            </div>
          </form>
        </>
      </Drawer>
      {/* 카테고리 모달: 끝 */}
    </>
  ) : (
    <></>
  )
}
