import { axiosInstance, PROJECTS_URL } from '@/configs'
import { validateCellRequest } from '@/utils/helpers'

/**
 * 콘텐츠 목록 불러오기 - 엑셀뷰
 * @param projectUid
 * @returns
 */
export const getContentsListSearch = (projectUid, modelId, req) => {
  return projectUid && modelId
    ? axiosInstance.post(
        `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents/search`,
        req
      )
    : Promise.reject()
}

/**
 * 콘텐츠 목록 불러오기 - 제목뷰
 * @param projectUid
 * @returns
 */
export const getContentsListSearchCompact = (projectUid, modelId, req) => {
  return projectUid && modelId
    ? axiosInstance.post(
        `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents/search/compact`,
        req
      )
    : Promise.reject()
}

/**
 * 콘텐츠 목록 제목 검색
 * @param projectUid
 * @returns
 */
export const getContentsListSearchByTitle = (projectUid, modelId, req) => {
  return projectUid && modelId
    ? axiosInstance.post(
        `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents/title/search`,
        req
      )
    : Promise.reject()
}

/**
 * 콘텐츠 불러오기
 * @param projectUid
 * @returns
 */
export const getContentsItem = (projectUid, modelId, id) => {
  return projectUid && modelId
    ? axiosInstance.get(
        `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents/${id}`
      )
    : Promise.reject()
}

/**
 * 콘텐츠 추가
 * @param projectUid
 * @param modelId
 * @param req
 * @returns
 */
export const createContents = (projectUid, modelId, req) => {
  return axiosInstance.post(
    `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents`,
    req
  )
}

/**
 * 콘텐츠 여러개 추가
 * @param projectUid
 * @param modelId
 * @param req
 * @returns
 */
export const createContentsList = (projectUid, modelId, req) => {
  return axiosInstance.post(
    `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents/list`,
    req
  )
}

/**
 * 콘텐츠 수정
 * @param projectUid
 * @param modelId
 * @param id
 * @param req
 * @returns
 */
export const updateContents = (projectUid, modelId, id, req) => {
  const contentsItem = req

  // Generate Request params
  contentsItem.cellList.forEach((c, clIdx) => {
    validateCellRequest(c)
  })

  return axiosInstance.put(
    `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents/${id}`,
    contentsItem
  )
}

/**
 * 콘텐츠 삭제
 * @param projectUid
 * @param modelId
 * @param id
 * @returns
 */
export const deleteContents = (projectUid, modelId, id) => {
  return axiosInstance.delete(
    `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents/${id}`
  )
}

/**
 * 콘텐츠 삭제
 * @param projectUid
 * @param modelId
 * @param id
 * @param uidArray
 * @returns
 */
export const deleteMultiContents = (projectUid, modelId, uidArray) => {
  return axiosInstance.delete(
    `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents`,
    {
      data: uidArray,
    }
  )
}

/**
 * 콘텐츠 모두 삭제
 * @param projectUid
 * @param modelId
 * @returns
 */
export const truncateContents = (projectUid, modelId) => {
  return axiosInstance.post(
    `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents/truncate`
  )
}

/**
 * 셀 추가
 * @param projectUid
 * @param modelId
 * @param contentsId
 * @param req
 * @returns
 */
export const createCell = (projectUid, modelId, contentsId, req) => {
  return axiosInstance.post(
    `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents/${contentsId}/cells`,
    req
  )
}

/**
 * 셀 수정
 * @param projectUid
 * @param modelId
 * @param contentsId
 * @param uid
 * @param req
 * @returns
 */
export const updateCell = (projectUid, modelId, contentsId, uid, req) => {
  return axiosInstance.put(
    `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents/${contentsId}/cells/${uid}`,
    validateCellRequest(req)
  )
}

/**
 * 콘텐츠 공개 상태 수정
 * @param projectUid
 * @param modelId
 * @param req
 * @returns
 */
export const updateContentsOpen = (projectUid, modelId, req) => {
  return axiosInstance.put(
    `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents/open`,
    req
  )
}

/**
 * 콘텐츠 정보 불러오기
 * @param projectUid
 * @param modelId
 * @param id
 * @returns
 */
export const updatePublicFile = (projectUid, modelId, fileId) => {
  return axiosInstance.get(
    `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents/files/${fileId}`
  )
}
