export const DEFAULT_CONTENTS_PAGE_SIZE = 20
export const DEFAULT_MEDIA_PAGE_SIZE = 20
export const DEFAULT_TEXT_SIZE = 200
export const MAX_TEXT_SIZE = 200
export const DEFAULT_CONTENTS_GET_SIZE = 20
export const CHUNK_CONTENTS_GET_SIZE = 500
export const CHUNK_SHEET_UPLOAD_SIZE = 20
export const DEFAULT_SORT = 'DATE_CREATE.DESC'
export const DEFAULT_PAGE_VALUE = {
  totalElements: 0,
  size: DEFAULT_CONTENTS_PAGE_SIZE,
  page: 0,
}

export const DEFAULT_TIMEZONE = 'Asia/Seoul'

/**
 * 예약어 (개발키 사용 금지)
 */
export const RESERVED_WORDS = [
  'ABSTRACT',
  'ADD',
  'ALL',
  'ALTER',
  'ANALYZE',
  'AND',
  'ARGUMENTS',
  'AS',
  'ASC',
  'ASENSITIVE',
  'AWAIT',
  'BEFORE',
  'BETWEEN',
  'BIGINT',
  'BINARY',
  'BLOB',
  'BOOLEAN',
  'BOTH',
  'BREAK',
  'BY',
  'BYTE',
  'CALL',
  'CASCADE',
  'CASE',
  'CATCH',
  'CHANGE',
  'CHAR',
  'CHARACTER',
  'CHECK',
  'CLASS',
  'COLLATE',
  'COLUMN',
  'CONDITION',
  'CONST',
  'CONSTRAINT',
  'CONTINUE',
  'CONVERT',
  'CREATE',
  'CREATEDAT',
  'CROSS',
  'CURRENT_DATE',
  'CURRENT_TIME',
  'CURRENT_TIMESTAMP',
  'CURRENT_USER',
  'CURSOR',
  'DATABASE',
  'DATABASES',
  'DATE',
  'DAY_HOUR',
  'DAY_MICROSECOND',
  'DAY_MINUTE',
  'DAY_SECOND',
  'DEBUGGER',
  'DEC',
  'DECIMAL',
  'DECLARE',
  'DEFAULT',
  'DELAYED',
  'DELETE',
  'DELETEDAT',
  'DESC',
  'DESCRIBE',
  'DETERMINISTIC',
  'DISTINCT',
  'DISTINCTROW',
  'DIV',
  'DO',
  'DOUBLE',
  'DROP',
  'DUAL',
  'EACH',
  'ELSE',
  'ELSEIF',
  'ENCLOSED',
  'ENUM',
  'ESCAPED',
  'EVAL',
  'EXISTS',
  'EXIT',
  'EXPLAIN',
  'EXPORT',
  'EXTENDS',
  'FETCH',
  'FINAL',
  'FINALLY',
  'FLOAT',
  'FLOAT4',
  'FLOAT8',
  'FOR',
  'FORCE',
  'FOREIGN',
  'FROM',
  'FULLTEXT',
  'FUNCTION',
  'GOTO',
  'GRANT',
  'GROUP',
  'HAVING',
  'HIGH_PRIORITY',
  'HOUR_MICROSECOND',
  'HOUR_MINUTE',
  'HOUR_SECOND',
  'IF',
  'IGNORE',
  'IMPLEMENTS',
  'IMPORT',
  'IN',
  'INDEX',
  'INFILE',
  'INNER',
  'INOUT',
  'INSENSITIVE',
  'INSERT',
  'INSTANCEOF',
  'INT',
  'INT1',
  'INT2',
  'INT3',
  'INT4',
  'INT8',
  'INTEGER',
  'INTERFACE',
  'INTERVAL',
  'INTO',
  'IS',
  'ITERATE',
  'JOIN',
  'KEY',
  'KEYS',
  'KILL',
  'LEADING',
  'LEAVE',
  'LEFT',
  'LET',
  'LIKE',
  'LIMIT',
  'LINES',
  'LOAD',
  'LOCALTIME',
  'LOCALTIMESTAMP',
  'LOCK',
  'LONG',
  'LONGBLOB',
  'LONGTEXT',
  'LOOP',
  'LOW_PRIORITY',
  'MATCH',
  'MEDIUMBLOB',
  'MEDIUMINT',
  'MEDIUMTEXT',
  'MIDDLEINT',
  'MINUTE_MICROSECOND',
  'MINUTE_SECOND',
  'MOD',
  'MODIFIES',
  'NATIVE',
  'NATURAL',
  'NEW',
  'NO_WRITE_TO_BINLOG',
  'NOT',
  'NULL',
  'NUMERIC',
  'ON',
  'OPTIMIZE',
  'OPTION',
  'OPTIONALLY',
  'OR',
  'ORDER',
  'OUT',
  'OUTER',
  'OUTFILE',
  'PACKAGE',
  'PRECISION',
  'PRIMARY',
  'PRIVATE',
  'PROCEDURE',
  'PROTECTED',
  'PUBLIC',
  'PUBLISH',
  'PURGE',
  'READ',
  'READS',
  'REAL',
  'REFERENCES',
  'REGEXP',
  'RELEASE',
  'RENAME',
  'REPEAT',
  'REPLACE',
  'REQUIRE',
  'RESTRICT',
  'RETURN',
  'REVOKE',
  'RIGHT',
  'RLIKE',
  'SCHEMA',
  'SCHEMAS',
  'SECOND_MICROSECOND',
  'SELECT',
  'SENSITIVE',
  'SEPARATOR',
  'SET',
  'SHORT',
  'SHOW',
  'SMALLINT',
  'SONAME',
  'SPATIAL',
  'SPECIFIC',
  'SQL',
  'SQL_BIG_RESULT',
  'SQL_CALC_FOUND_ROWS',
  'SQL_SMALL_RESULT',
  'SQLEXCEPTION',
  'SQLSTATE',
  'SQLWARNING',
  'SSL',
  'STARTING',
  'STATIC',
  'STRAIGHT_JOIN',
  'SUPER',
  'SWITCH',
  'SYNCHRONIZED',
  'TABLE',
  'TERMINATED',
  'THEN',
  'THIS',
  'THROW',
  'THROWS',
  'TINYBLOB',
  'TINYINT',
  'TINYTEXT',
  'TO',
  'TRAILING',
  'TRANSIENT',
  'TRIGGER',
  'TRY',
  'TYPEOF',
  'UNDO',
  'UNION',
  'UNIQUE',
  'UNLOCK',
  'UNSIGNED',
  'UPDATE',
  'UPDATEDAT',
  'USAGE',
  'USE',
  'USING',
  'UTC_DATE',
  'UTC_TIME',
  'UTC_TIMESTAMP',
  'VALUES',
  'VAR',
  'VARBINARY',
  'VARCHAR',
  'VARCHARACTER',
  'VARYING',
  'VOID',
  'VOLATILE',
  'WHEN',
  'WHERE',
  'WHILE',
  'WITH',
  'WRITE',
  'XOR',
  'YEAR_MONTH',
  'YIELD',
  'ZEROFILL',
  'FALSE',
  'TRUE',
]
