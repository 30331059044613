import {
  ProjectRoleType,
  ProjectUsageType,
  ProjectUsagePropertiesInterface,
  ProjectStarterExampleInterface,
  ProjectSampleModelInterface,
  ProjectSampleModelCategoryInterface,
} from '@/types'
export const ProjectRoles: ProjectRoleType[] = ['ADMIN', 'USER', 'VIEWER']

/**
 * Static File Cloundfront 주소
 */
const assetUrl = process.env.REACT_APP_ASSETS_URL as string

/**
 * 프로젝트 요금제 정보
 */
export const ProjectUsageProperties: ProjectUsagePropertiesInterface[] = [
  {
    price: 'FREE',
    availableRoles: ['OWNER', 'ADMIN'],
    maxLangauges: 2,
    maxProjects: 3,
    maxModels: 5,
    maxMembers: 3,
    storage: '100MB',
    maxFileCount: 5,
    maxFileSize: 1024 * 10,
    maxFileResolutions: 1920,
  },
  {
    price: 'BASIC',
    availableRoles: ['OWNER', 'ADMIN', 'USER', 'VIEWER'],
    maxLangauges: 3,
    maxProjects: 5,
    maxModels: 10,
    maxMembers: 10,
    storage: '500MB',
    maxFileCount: 10,
    maxFileSize: 1024 * 25,
    maxFileResolutions: 3840,
  },
  {
    price: 'PRO',
    availableRoles: ['OWNER', 'ADMIN', 'USER', 'VIEWER'],
    maxLangauges: 5,
    maxProjects: 5,
    maxModels: 20,
    maxMembers: null,
    storage: '3GB',
    maxFileCount: 20,
    maxFileSize: 1024 * 50,
    maxFileResolutions: 3840 * 2,
  },
  {
    price: 'UNLIMITED',
    availableRoles: ['OWNER', 'ADMIN', 'USER', 'VIEWER'],
    maxLangauges: null,
    maxProjects: null,
    maxModels: null,
    maxMembers: null,
    storage: null,
    maxFileCount: 30,
    maxFileSize: 1024 * 3000,
    maxFileResolutions: 10328,
  },
  {
    price: 'TEST',
    availableRoles: ['OWNER', 'ADMIN'],
    maxLangauges: 1,
    maxProjects: 1,
    maxModels: 1,
    maxMembers: null,
    storage: null,
    maxFileCount: 30,
    maxFileSize: 1024 * 50,
    maxFileResolutions: 3840 * 2,
  },
]

/**
 * 기본 프로젝트 요금제
 */
export const DEFAULT_PROJECT_PLAN: ProjectUsageType = 'FREE'

/**
 * 프로젝트 Starter Example
 */
export const PROJECT_STARTER_EXAMPLES: ProjectStarterExampleInterface[] = [
  {
    slug: 'blog',
    thumbnailUrl: assetUrl + '/assets/demo/img-demo-blog.jpg',
    demoUrl: 'https://demo.memexdata.io/blog',
    selectorGroups: [],
    models: [
      {
        languageMap: {
          KO: '블로그',
          EN: 'Blog',
          JP: 'ブログ」',
        },
        order: 0,
        devKey: 'blog',
        componentList: [
          {
            type: 'TITLE',
            languageMap: {
              KO: '제목',
              EN: 'Title',
              JP: 'タイトル',
            },
            devKey: 'title',
            order: 0,
          },
          {
            type: 'LONG_LINE_TEXT',
            languageMap: {
              KO: '짧은설명',
              EN: 'Short Description',
              JP: '短い説明」です',
            },
            devKey: 'title',
            order: 1,
          },
          {
            type: 'DATE',
            languageMap: {
              KO: '날짜',
              EN: 'Date',
              JP: '日付',
            },
            devKey: 'date',
            order: 2,
            option: {
              dateFormats: 'date',
            },
          },
          {
            type: 'LONG_LINE_TEXT',
            languageMap: {
              KO: '내용',
              EN: 'Content',
              JP: '内容',
            },
            devKey: 'content',
            order: 3,
          },
          {
            type: 'SINGLE_LINE_TEXT',
            languageMap: {
              KO: '글쓴이',
              EN: 'author',
              JP: '著者',
            },
            devKey: 'author',
            order: 4,
          },
          {
            type: 'MEDIA',
            languageMap: {
              KO: '썸네일',
              EN: 'Thumbnail',
              JP: 'サムネイル',
            },
            devKey: 'thumbnail',
            order: 5,
          },
          {
            type: 'SINGLE_LINE_TEXT',
            languageMap: {
              KO: '키워드',
              EN: 'Keywords',
              JP: 'キーワード',
            },
            devKey: 'keywords',
            order: 6,
          },
        ],
      },
    ],
    contents: [],
  },
  {
    slug: 'board',
    thumbnailUrl: assetUrl + assetUrl + '/assets/demo/img-demo-board.jpg',
    demoUrl: 'https://demo.memexdata.io/board',
    selectorGroups: [
      {
        languageMap: {
          KO: '카테고리',
          EN: 'Category',
          JP: 'カテゴリー',
        },
        order: 0,
        selectorList: [
          {
            order: 0,
            languageMap: {
              KO: '공지',
              EN: 'Notice',
              JP: 'お知らせ',
            },
          },
          {
            order: 1,
            languageMap: {
              KO: '이벤트',
              EN: 'Event',
              JP: 'イベント',
            },
          },
          {
            order: 2,
            languageMap: {
              KO: '업데이트',
              EN: 'Update',
              JP: 'アップデート',
            },
          },
        ],
      },
    ],
    models: [
      {
        languageMap: {
          KO: '게시판',
          EN: 'Board',
          JP: '掲示板',
        },
        order: 0,
        devKey: 'board',
        componentList: [
          {
            type: 'TITLE',
            languageMap: {
              KO: '제목',
              EN: 'Title',
              JP: 'タイトル',
            },
            devKey: 'title',
            order: 0,
          },
          {
            type: 'RICH_TEXT',
            languageMap: {
              KO: '내용',
              EN: 'Content',
              JP: '内容',
            },
            devKey: 'content',
            order: 1,
          },
          {
            type: 'SINGLE_LINE_TEXT',
            languageMap: {
              KO: '글쓴이',
              EN: 'author',
              JP: '著者',
            },
            devKey: 'author',
            order: 2,
          },
          {
            type: 'CATEGORY',
            languageMap: {
              KO: '카테고리',
              EN: 'Category',
              JP: 'カテゴリー',
            },
            devKey: 'category',
            order: 3,
            selectorGroupId: 0, // selectorGroups[0]
          },
          {
            type: 'DATE',
            languageMap: {
              KO: '날짜',
              EN: 'Date',
              JP: '日付',
            },
            devKey: 'date',
            order: 4,
            option: {
              dateFormats: 'date',
            },
          },
        ],
      },
    ],
  },
  {
    slug: 'portfolio',
    thumbnailUrl: assetUrl + '/assets/demo/img-demo-portfolio.png',
    demoUrl: 'https://demo.memexdata.io/portfolio',
    selectorGroups: [
      {
        languageMap: {
          KO: '카테고리',
          EN: 'Category',
          JP: 'カテゴリー',
        },
        order: 0,
        selectorList: [
          {
            order: 0,
            languageMap: {
              KO: '수납',
              EN: 'Cabinet',
              JP: '収納',
            },
          },
          {
            order: 1,
            languageMap: {
              KO: '테이블',
              EN: 'Table',
              JP: 'テーブル',
            },
          },
          {
            order: 2,
            languageMap: {
              KO: '의자',
              EN: 'Chair',
              JP: '椅子',
            },
          },
        ],
      },
    ],
    models: [
      {
        languageMap: {
          KO: '포트폴리오',
          EN: 'Portfolio',
          JP: 'ポートフォリオ',
        },
        order: 0,
        devKey: 'board',
        componentList: [
          {
            type: 'TITLE',
            languageMap: {
              KO: '제목',
              EN: 'Title',
              JP: 'タイトル',
            },
            devKey: 'title',
            order: 0,
          },
          {
            type: 'RICH_TEXT',
            languageMap: {
              KO: '내용',
              EN: 'Content',
              JP: '内容',
            },
            devKey: 'content',
            order: 1,
          },
          {
            type: 'MEDIA',
            languageMap: {
              KO: '썸네일',
              EN: 'Thumbnail',
              JP: 'サムネイル',
            },
            devKey: 'thumbnail',
            order: 2,
          },
          {
            type: 'CATEGORY',
            languageMap: {
              KO: '카테고리',
              EN: 'Category',
              JP: 'カテゴリー',
            },
            devKey: 'category',
            order: 3,
            selectorGroupId: 0, // selectorGroups[0]
          },
          {
            type: 'NUMBER',
            languageMap: {
              KO: '가격',
              EN: 'Price',
              JP: '価格',
            },
            devKey: 'price',
            order: 4,
          },
        ],
      },
    ],
  },
]

export const PROJECT_SAMPLE_MODELS_CATEGORIES: ProjectSampleModelCategoryInterface[] =
  [
    {
      slug: 'all',
      value: '',
    },
    {
      slug: 'board',
      value: 'board',
    },
    {
      slug: 'product',
      value: 'product',
    },
    /* {
      slug: 'entertainment',
      value: 'entertainment',
    }, */
    {
      slug: 'archive',
      value: 'archive',
    },
    {
      slug: 'promotion',
      value: 'promotion',
    },
    {
      slug: 'personal',
      value: 'personal',
    },
  ]

/**
 * 프로젝트 예제 모델 데이터
 */
export const PROJECT_SAMPLE_MODELS: ProjectSampleModelInterface[] = [
  {
    devKey: 'menufood',
    category: 'product',
    thumbnailUrl: assetUrl + '/assets/samples/img-menu-food.png',
    // thumbnailUrl: assetUrl + '/assets/demo/img-demo-blog.jpg',
    // demoUrl: 'https://demo.memexdata.io/blog',
  },
  {
    devKey: 'menucafeteria',
    category: 'product',
    thumbnailUrl: assetUrl + '/assets/samples/img-menu-cafe.png',
  },
  {
    devKey: 'product',
    category: 'product',
    thumbnailUrl: assetUrl + '/assets/samples/img-product.png',
  },
  {
    devKey: 'lookbook',
    category: 'product',
    thumbnailUrl: assetUrl + '/assets/samples/img-lookbook.png',
  },
  {
    devKey: 'program',
    category: 'promotion',
    thumbnailUrl: assetUrl + '/assets/samples/img-program.png',
  },
  {
    devKey: 'portfolio',
    category: 'archive',
    thumbnailUrl: assetUrl + '/assets/samples/img-portfolio.png',
  },
  {
    devKey: 'map',
    category: 'archive',
    thumbnailUrl: assetUrl + '/assets/samples/img-map.png',
  },
  {
    devKey: 'exhibition',
    category: 'archive',
    thumbnailUrl: assetUrl + '/assets/samples/img-exhibition.png',
  },
  {
    devKey: 'gallery',
    category: 'archive',
    thumbnailUrl: assetUrl + '/assets/samples/img-gallery.png',
  },
  {
    devKey: 'timeline',
    category: 'archive',
    thumbnailUrl: assetUrl + '/assets/samples/img-timeline.png',
  },
  {
    devKey: 'board',
    category: 'board',
    thumbnailUrl: assetUrl + '/assets/samples/img-board.png',
  },
  {
    devKey: 'blog',
    category: 'board',
    thumbnailUrl: assetUrl + '/assets/samples/img-blog.png',
  },
  {
    devKey: 'article',
    category: 'board',
    thumbnailUrl: assetUrl + '/assets/samples/img-article.png',
  },
  {
    devKey: 'address',
    category: 'personal',
    thumbnailUrl: assetUrl + '/assets/samples/img-address.png',
  },
  {
    devKey: 'bookmarks',
    category: 'personal',
    thumbnailUrl: assetUrl + '/assets/samples/img-bookmarks.png',
  },
]
