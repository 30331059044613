import { DEFAULT_CONTENTS_PAGE_SIZE, LAYOUT_SUCCESS_COLOR } from '@/configs'
import { RootState, useAppDispatch } from '@/states'
import { getSavedContentsSortKey } from '@/utils/helpers'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { ModelInterface } from '@/types'
import { ApiOutlined, CloudSyncOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router'
import ModelFavorite from './ModelsFavorite'

export const ModelsDashboardItem = ({ model }: { model: ModelInterface }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // State (Redux)
  const { projectsState, authState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { currentProject, modelList } = projectsState
  const { init } = authState

  return currentProject ? (
    <Link
      to={`/projects/${currentProject?.uid}/${
        model.id
      }/contents?page=1&size=${DEFAULT_CONTENTS_PAGE_SIZE}&sort=${getSavedContentsSortKey(
        model.id
      )}&q=`}
      className={`block w-full space-y-0.5 cursor-pointer hover:bg-gray-300 rounded-lg p-2`}>
      <div className="grow w-full flex items-center space-x-2 overflow-hidden">
        <div className="truncate flex items-center space-x-2">
          <div className="truncate">
            {model.languageMap[currentProject.defaultLang]}
          </div>
          {model.lastValidate ? (
            <CloudSyncOutlined
              style={{
                color: LAYOUT_SUCCESS_COLOR,
              }}
            />
          ) : (
            <></>
          )}
        </div>
        {model.hasTitle ? (
          <div className="flex-none flex items-center">
            <ApiOutlined title={t('availableRelationConnected')} />
          </div>
        ) : (
          <></>
        )}
        <div className="flex-none flex items-center ico-favorite">
          <ModelFavorite model={model} />
        </div>
      </div>
      <div className="text-xs text-gray-500 font-normal">{model.devKey}</div>
    </Link>
  ) : (
    <></>
  )
}
