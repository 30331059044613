import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Modal, Input, Button, message } from 'antd'
import { RootState } from '@/states'
import { deactivate } from '@/states/actions'
import { setLogout } from '@/utils/auth'
import { LAYOUT_MODAL_SM_WIDTH } from '@/configs'

export const DeactivateModal = () => {
  const { t, i18n } = useTranslation()

  // State (Redux)
  const { authState } = useSelector(
    (state: RootState) => ({
      authState: state.auth,
    }),
    shallowEqual
  )

  const { me } = authState

  // State
  const [showModal, setShowModal] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')

  /**
   * 회원 탈퇴 하기
   */
  const onHandleDeactivate = () => {
    if (confirm(t('deactivateConfirm'))) {
      deactivate(me?.id)
        .then((res) => {
          alert(t('deactivateSuccess'))
          setLogout(false, '/login')
        })
        .catch((e) => {
          message.error(t('error.wrong'))
        })
    }
  }

  return (
    <div>
      <div>
        <Button type="text" danger onClick={() => setShowModal(true)}>
          {t('deactivate')}
        </Button>
      </div>
      <Modal
        open={showModal}
        maskClosable={false}
        centered
        title={t('deactivateTitle')}
        footer={null}
        width={LAYOUT_MODAL_SM_WIDTH}
        style={{ padding: 0, borderRadius: 0 }}
        onCancel={() => setShowModal(false)}>
        <div className="space-y-6">
          <div className="space-y-4">
            <p>{t('deactivateDesc')}</p>
          </div>
          <div>
            <Input onChange={(e) => setEmail(e.target.value)}></Input>
          </div>
          <div className="space-y-3">
            <Button
              danger
              ghost
              block
              disabled={me?.email !== email}
              onClick={() => onHandleDeactivate()}>
              {t('deactivePermanently')}
            </Button>
            <Button block onClick={() => setShowModal(false)}>
              {t('cancel')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
