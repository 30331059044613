import React, { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RootState, useAppDispatch } from '@/states'
import { Card, Spin, Button } from 'antd'
import { PlusSquareOutlined } from '@ant-design/icons'
import {
  setContentsFormModal,
  setModelFormModal,
  setCurrentModel,
} from '@/states/actions'
import { LayoutProjectsTab } from '@/components/Layout/TabProjects'
import { Checklist, UsageBar } from '@/components/Projects'
import { ModelsDashboardItem } from '@/components/Models'
import { MembersDashboardItem } from '@/components/Members'
import { DashboardItem } from '@/components/Contents'
import { FindMore } from '@/components/Help'

const ProjectsDashboard = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // State (Redux)
  const { projectsState, authState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { currentProject, modelList } = projectsState
  const { init, favorites } = authState

  // Effect
  useEffect(() => {
    dispatch(setCurrentModel(null))
    dispatch(setContentsFormModal(false))
  }, [])

  // Memo
  const favoritesContentList = useMemo(() => {
    return currentProject && favorites.contents
      ? favorites.contents.filter((c) => c.project.uid === currentProject?.uid)
      : []
  }, [currentProject, favorites.contents])

  return (
    <>
      {currentProject && (
        <Helmet>
          <title>
            {t('projectHome')} · {currentProject.name} ·{' '}
            {process.env.REACT_APP_NAME}
          </title>
        </Helmet>
      )}
      {currentProject ? (
        <div className="space-y-10 py-5 lg:py-7">
          {/* 프로젝트 Tab: 시작 */}
          <LayoutProjectsTab />
          {/* 프로젝트 Tab: 끝 */}
          <div
            className={`grid xl:grid-cols-${
              process.env.REACT_APP_HIDE_USAGE !== '1' ? '2' : '1'
            } gap-7`}>
            <div className="space-y-7">
              {/* 체크리스트: 시작 */}
              {!process.env.REACT_APP_PROJECT_ID && <Checklist />}
              {/* 체크리스트: 끝 */}
              {/* 사용량: 시작 */}
              {process.env.REACT_APP_HIDE_USAGE !== '1' && (
                <Card
                  title={
                    <div>
                      {t('usage')}
                      <span className="ml-1 text-xs leading-3 border border-gray-300 rounded-sm px-1 font-normal">
                        {t(`planTypes.${currentProject?.usage?.type}.title`)}
                      </span>
                    </div>
                  }
                  extra={
                    <Link
                      to={`/projects/${currentProject?.uid}/usage`}
                      className="ant-btn ant-btn-text ant-btn-sm">
                      {t('more')}
                    </Link>
                  }>
                  <div className="grid lg:grid-cols-2 gap-5">
                    <UsageBar
                      usage={currentProject?.usage?.content}
                      label={t('noContents')}
                    />
                    <UsageBar
                      usage={currentProject?.usage?.storage}
                      label={t('mediaStorage')}
                      isFile
                    />
                    <UsageBar
                      usage={currentProject?.usage?.member}
                      label={t('noMembers')}
                    />
                    <UsageBar
                      usage={currentProject?.usage?.model}
                      label={t('noModels')}
                    />
                  </div>
                </Card>
              )}
              {/* 사용량: 끝 */}
            </div>
            {/* 즐겨찾기: 시작 */}
            {process.env.REACT_APP_HIDE_FAVORITES !== '1' && (
              <Card title={t('favorites')} className="h-full">
                <div>
                  {favoritesContentList && favoritesContentList.length ? (
                    <ul className="mb-0">
                      {favoritesContentList.map((contents) => (
                        <li key={contents.uid} className="cursor-pointer">
                          <DashboardItem contents={contents} />
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>{t('noFavoritesContents')}</p>
                  )}
                </div>
              </Card>
            )}
            {/* 즐겨찾기: 끝 */}
          </div>
          <div
            className={`grid xl:grid-cols-${
              process.env.REACT_APP_HIDE_MEMBERS !== '1' ? '2' : '1'
            } gap-7`}>
            {/* 모델 목록: 시작 */}
            <Card
              title={<div>{t('models')}</div>}
              extra={
                process.env.REACT_APP_HIDE_BUILDER !== '1' &&
                (currentProject.role === 'OWNER' ||
                  currentProject.role === 'ADMIN') ? (
                  <Button
                    size="small"
                    type="text"
                    icon={<PlusSquareOutlined />}
                    onClick={() => dispatch(setModelFormModal(true))}
                    disabled={
                      currentProject?.usage &&
                      modelList.length >= currentProject.usage.model.limit
                    }>
                    {t('addModel')}
                  </Button>
                ) : (
                  <></>
                )
              }>
              <ul className="grid grid-cols-1 xl:grid-cols-2 mb-0">
                {modelList.map((model) => (
                  <li key={model.id} className="cursor-pointer">
                    <ModelsDashboardItem model={model} />
                  </li>
                ))}
              </ul>
            </Card>
            {/* 모델 목록: 끝 */}
            {/* 멤버: 시작 */}
            {process.env.REACT_APP_HIDE_MEMBERS !== '1' && (
              <Card
                title={<div>{t('members')}</div>}
                extra={
                  <Link
                    to={`/projects/${currentProject?.uid}/members`}
                    className="ant-btn ant-btn-text ant-btn-sm">
                    {t('more')}
                  </Link>
                }>
                <ul className="grid grid-cols-1 xl:grid-cols-2 mb-0">
                  {currentProject.memberList.map((member) => (
                    <li key={member.id}>
                      <MembersDashboardItem member={member} />
                    </li>
                  ))}
                </ul>
              </Card>
            )}
            {/* 멤버: 끝 */}
          </div>
          {/* 더 알아보기: 시작 */}
          <FindMore />
          {/* 더 알아보기: 끝 */}
        </div>
      ) : (
        <div className="flex justify-center p-4">
          <Spin size="small" />
        </div>
      )}
    </>
  )
}

export default ProjectsDashboard
