import { axiosInstance, FILES_URL } from '@/configs'
import { UploadFile } from 'antd/lib/upload/interface'
import axios, { CancelTokenSource } from 'axios'
import axiosRetry from 'axios-retry'

/**
 * S3 presigned URL 받기
 * @param fileNameArr
 * @param source
 * @returns {Promise<AxiosResponse<any>>}
 */
export const requestS3PresignedUrl = (
  fileNameArr: string[],
  source?: CancelTokenSource | undefined
) => {
  return axiosInstance.post(`${FILES_URL}/upload/access`, fileNameArr, {
    cancelToken: source ? source.token : undefined,
  })
}

/**
 * S3 presigned 업로드
 * @param url
 * @param fileTmp
 * @param name
 * @param createdBy
 * @param source
 * @returns
 */
export const uploadViaS3PresignedUrl = (
  url,
  fileTmp,
  name,
  createdBy,
  source?: CancelTokenSource | undefined
) => {
  const fileUpload = new File([fileTmp], fileTmp.name.normalize('NFC'), {
    type: fileTmp.type,
  })

  axiosRetry(axiosInstance, {
    retries: 10,
    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 3000),
    retryCondition(error) {
      return true
    },
  })

  return axiosInstance.put(url, fileUpload, {
    headers: {
      'Content-Type': fileUpload.type,
      'x-amz-meta-name': name,
      'x-amz-meta-create-by': createdBy,
    },
    cancelToken: source ? source.token : undefined,
  })
}

/**
 * 파일 정보 저장
 * @param pathArr
 * @param source
 * @param isEditor
 * @returns
 */
export const saveFileInfo = (
  pathArr,
  source?: CancelTokenSource | undefined,
  isEditor?: boolean
) => {
  return axiosInstance.post(
    isEditor ? `${FILES_URL}/upload/attachment` : `${FILES_URL}/upload`,
    pathArr,
    {
      cancelToken: source ? source.token : undefined,
    }
  )
}

/**
 * S3 파일 업로드
 * @param fileList
 * @param source
 * @returns
 */
export const uploadFileToS3 = async (
  fileList: UploadFile[],
  source?: CancelTokenSource | undefined,
  isEditor?: boolean
) => {
  return new Promise((resolve, reject) => {
    requestS3PresignedUrl(
      fileList.map((file) => file.name.normalize('NFC')),
      source
    )
      .then(async (resUrl) => {
        setTimeout(() => {
          Promise.all(
            resUrl.data.map((access, index) =>
              uploadViaS3PresignedUrl(
                access.url,
                fileList[index],
                access.metadata['x-amz-meta-name'],
                access.metadata['x-amz-meta-create-by'],
                source
              )
            )
          ).then(
            axios.spread((...allData) => {
              setTimeout(() => {
                saveFileInfo(
                  resUrl.data.map((url) => url.key),
                  source,
                  isEditor
                )
                  .then((resInfo) => {
                    resolve(resInfo)
                  })
                  .catch((e) => {
                    console.log(e)
                  })
              }, 1000)
            })
          )
        }, 1000)
      })
      .catch((e) => {
        console.log(e)
      })
  })
}

/**
 * 파일 설명 수정
 * @param req
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateFileDescription = (req) => {
  return axiosInstance.put(`${FILES_URL}/description`, req)
}

/**
 * 파일 삭제
 * @param req
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteFile = (id) => {
  return axiosInstance.delete(`${FILES_URL}/${id}`)
}
