import {
  createCategoryGroup,
  createCategorySelector,
  createModel,
  deleteModel,
  getCategoryGroups,
  getModelData,
  getModels,
  getProjectUsage,
  getProjectUsageData,
  truncateContents,
  updateModel,
} from '@/api'
import { AlertStatus } from '@/components/Common'
import { TabModels } from '@/components/Layout'
import { UsageBar } from '@/components/Projects/UsageBar'
import { RESERVED_WORDS, TIP_DEV_KEY_KEY } from '@/configs'
import { LINK_HELP_DEVKEY } from '@/constants'
import { RootState, useAppDispatch } from '@/states'
import {
  resetContentsList,
  setCurrentProject,
  setModelFormModal,
} from '@/states/actions'
import {
  ComponentInterface,
  ModelInterface,
  ProjectModelUsageInterface,
  SelectorGroupInterface,
} from '@/types'
import { deleteItem } from '@/utils/indexedDb'
import {
  CopyOutlined,
  DeleteFilled,
  ExclamationCircleOutlined,
  ExportOutlined,
  ImportOutlined,
  InteractionOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Spin,
  Typography,
  message,
} from 'antd'
import axios from 'axios'
import { useFormik } from 'formik'
import Cookies from 'js-cookie'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'

const initialModelFormValues = {
  name: '',
  devKey: '',
  description: '',
}

const ProjectsModelsSettings = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // State (Redux)
  const { projectsState, authState, layoutState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const {
    currentProject,
    modelListInit,
    modelList,
    currentModel,
    categoriesList,
    currentLanguage,
  } = projectsState
  const { init } = authState
  const { gnb } = layoutState

  // State
  const [loading, setLoading] = useState<boolean>(false)

  const [isTipComponentSave, setIsTipComponentSave] = useState<boolean>(false)

  // Memo
  const modelUsage: ProjectModelUsageInterface | null | undefined =
    useMemo(() => {
      return currentModel && currentProject?.usage
        ? currentProject?.usage?.modelList.find(
            (m) => m.id === currentModel?.id
          )
        : null
    }, [currentModel, currentProject?.usage])

  // Effect
  useEffect(() => {
    dispatch(resetContentsList())
  }, [])

  // Effect
  useEffect(() => {
    if (currentModel && currentProject) {
      formikModelForm.setFieldValue(
        'name',
        currentModel.languageMap[currentProject.defaultLang]
      )
      formikModelForm.setFieldValue('devKey', currentModel.devKey)
      formikModelForm.setFieldValue('description', currentModel.description)
    }
  }, [currentModel, currentProject])

  /**
   * 모델 삭제
   * @param model
   */
  const onModelDelete = (model) => {
    Modal.confirm({
      centered: true,
      title: t('confirmDeleteModelTitle', {
        model: currentProject
          ? model.languageMap[currentProject?.defaultLang]
          : '',
      }),
      icon: <ExclamationCircleOutlined />,
      content: t('confirmDeleteModelDesc'),
      okText: t('delete'),
      cancelText: t('cancel'),
      onOk() {
        return new Promise((resolve, reject) => {
          deleteModel(currentProject?.uid, model.id)
            .then((res) => {
              dispatch(
                getModels(currentProject?.uid, model.id === currentModel?.id)
              )
              dispatch(getProjectUsage(currentProject?.uid as string))
              message.success(t('deleteSuccess'))

              navigate('/projects/' + currentProject?.uid)
              resolve(res)
            })
            .catch((e) => {
              message.error(e.response.data.error)
              reject(e)
            })
        }).catch((e) => console.log(e))
      },
      onCancel() {},
    })
  }

  /**
   * 모델 복제
   * @param model
   */
  const onModelDuplicate = (model) => {
    Modal.confirm({
      centered: true,
      title: t('confirmDuplicateModelTitle', {
        model: currentProject
          ? model.languageMap[currentProject?.defaultLang]
          : '',
      }),
      icon: <CopyOutlined />,
      content: t('confirmDuplicateModelDesc'),
      okText: t('duplicate'),
      cancelText: t('cancel'),
      onOk() {
        return new Promise((resolve, reject) => {
          if (!currentProject || !currentModel) return

          // 언어별 값 선택
          const languageMap = {}
          currentProject.languageList.forEach((lang) => {
            languageMap[lang] = currentModel.languageMap[lang] + '_copied'
          })

          const req = {
            languageMap,
            devKey: uuidv4(),
            componentList: JSON.parse(
              JSON.stringify(currentModel.componentList)
            ),
            order: modelList.length,
          }

          createModel(currentProject?.uid, req)
            .then((res) => {
              getModelData(currentProject?.uid, res.data).then((res) => {
                // 사용량 갱신
                getProjectUsageData(currentProject?.uid)
                  .then((res) => {
                    const updatedProject = currentProject
                    updatedProject.usage = res.data

                    dispatch(setCurrentProject(updatedProject))
                    dispatch(getModels(currentProject?.uid, currentModel.id))
                    resolve(res)
                  })
                  .catch((e) => {
                    message.error(e.response.data.error)
                  })
              })
            })
            .catch((e) => {
              message.error(e.response.data.error)
              reject(e)
            })
        }).catch((e) => console.log(e))
      },
      onCancel() {},
    })
  }

  /**
   * 모델 내보내기
   * @param model
   */
  const onModelExport = (model: ModelInterface) => {
    Modal.confirm({
      centered: true,
      title: t('confirmExportModelTitle', {
        model: currentProject
          ? model.languageMap[currentProject?.defaultLang]
          : '',
      }),
      icon: <ExportOutlined />,
      content: t('confirmExportModelDesc'),
      okText: t('export'),
      cancelText: t('cancel'),
      onOk() {
        return new Promise((resolve, reject) => {
          if (!currentProject || !currentModel) return

          const exportedModel = JSON.parse(JSON.stringify(currentModel))
          const exportedGroups: SelectorGroupInterface[] = []

          exportedModel.componentList.forEach((component) => {
            // 카테고리
            if (
              component.type === 'CATEGORY' &&
              component.selectorGroupId &&
              (exportedGroups.length === 0 ||
                exportedGroups.findIndex(
                  (group) => group.id === component.selectorGroupId
                ) === -1)
            ) {
              exportedGroups.push(
                categoriesList.find(
                  (group) => group.id === component.selectorGroupId
                ) as SelectorGroupInterface
              )
            }

            // 내부 컴포넌트 ID 제거
            if (component.type === 'BLOCK' && component.childList) {
              component.childList.forEach((child) => {
                // 카테고리
                if (
                  child.type === 'CATEGORY' &&
                  child.selectorGroupId &&
                  (exportedGroups.length === 0 ||
                    exportedGroups.findIndex(
                      (group) => group.id === child.selectorGroupId
                    ) === -1)
                ) {
                  exportedGroups.push(
                    categoriesList.find(
                      (group) => group.id === child.selectorGroupId
                    ) as SelectorGroupInterface
                  )
                }
              })
            }
          })

          // Group component 제거
          exportedGroups.forEach((group) => {
            delete group.componentList
          })

          const exportedObj = {
            components: exportedModel.componentList,
            groups: exportedGroups,
          }

          // 모델 정보 JSON 파일로 다운로드
          const element = document.createElement('a')
          const file = new Blob([JSON.stringify(exportedObj)], {
            type: 'application/json',
          })
          element.href = URL.createObjectURL(file)
          element.download = `${currentProject.name}_${exportedModel.devKey}.json`
          document.body.appendChild(element) // Required for this to work in FireFox
          element.click()
          resolve(true)
        }).catch((e) => console.log(e))
      },
      onCancel() {},
    })
  }

  /**
   * 모델 가져오기 파일 선택
   */
  const onModelImport = (model: ModelInterface) => {
    Modal.confirm({
      centered: true,
      title: t('confirmImportModelTitle', {
        model: currentProject
          ? model.languageMap[currentProject?.defaultLang]
          : '',
      }),
      icon: <ImportOutlined />,
      content: t('confirmImportModelDesc'),
      okText: t('import'),
      cancelText: t('cancel'),
      onOk() {
        const fileJson = document.getElementById('file-json')
        fileJson?.click()
      },
    })
  }

  /**
   * 모델 가져오기
   * @param e
   */
  const importModel = async (e) => {
    if (!currentProject || !currentModel) return

    // JSON 파일 읽기
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsText(file, 'UTF-8')
    reader.onload = async (e) => {
      const json = JSON.parse(e.target?.result as string)
      const importedComponents = json.components
      const flattedComponentsArr: ComponentInterface[] = []
      const importedGroupList = json.groups

      // 컴포넌트 구성 확인
      if (!importedComponents) {
        message.error(t('error.invalidModelFile'))
        return false
      }

      // Component 평탄화
      const flattenComponents = () => {
        var flatComponents = (compList) => {
          compList.forEach((comp) => {
            if (comp.childList) {
              flattedComponentsArr.push(comp)
              flatComponents(comp.childList)
            } else flattedComponentsArr.push(comp)
          })
        }

        flatComponents(importedComponents)
      }

      flattenComponents()

      // 컴포넌트 갯수 확인
      const maxComponentsLength = currentProject?.usage?.modelList[0].component
        .limit as number

      if (flattedComponentsArr.length > maxComponentsLength) {
        message.error(
          t('error.componentsByModelTooMany', {
            max: maxComponentsLength,
          })
        )
        return false
      }

      message.loading(t('importingModel'))

      const req = {
        languageMap: currentModel.languageMap,
        devKey: currentModel.devKey.replace(/[^A-Z0-9]/gi, ''),
        componentList: importedComponents,
        order: currentModel.order,
      }

      // 카테고리 생성
      const projectStarterSelectorReqs: any[] = []
      const selectorGroupIds: {
        oldCategoryGroupId: number
        newCategoryGroupId: number
      }[] = []
      const currentGroupLength = categoriesList.length

      // 카테고리 Order 재설정
      importedGroupList.forEach((group, idx) => {
        group.order = currentGroupLength + idx
      })

      // 카테고리 생성
      await importedGroupList.forEach((selectorGroupReq, sIdx) => {
        return projectStarterSelectorReqs.push(
          createCategoryGroup(currentProject.uid, selectorGroupReq).then(
            async (res) => {
              const newCategoryGroupId = res.data
              const categorySelectorReq = {
                languageMap: selectorGroupReq.languageMap,
                order: selectorGroupReq.order,
                selectorList: selectorGroupReq.selectorList,
              }

              selectorGroupIds.push({
                oldCategoryGroupId: selectorGroupReq.id as number,
                newCategoryGroupId,
              })

              return await createCategorySelector(
                currentProject.uid,
                newCategoryGroupId,
                categorySelectorReq
              )
            }
          )
        )
      })

      if (projectStarterSelectorReqs.length > 0) {
        await axios.all([...projectStarterSelectorReqs]).then(
          axios.spread(async (...responses) => {
            // 카테고리 연결
            return req.componentList.forEach((component) => {
              const selectorGroupId = selectorGroupIds.find(
                (s) => s.oldCategoryGroupId === component.selectorGroupId
              )?.newCategoryGroupId

              if (component.type === 'CATEGORY' && selectorGroupId) {
                component.selectorGroupId = selectorGroupId
              }

              component.childList?.forEach((child) => {
                const selectorGroupId = selectorGroupIds.find(
                  (s) => s.oldCategoryGroupId === child.selectorGroupId
                )?.newCategoryGroupId

                if (child.type === 'CATEGORY' && selectorGroupId) {
                  child.selectorGroupId = selectorGroupId
                }
              })
            })
          })
        )
      }

      await updateModel(currentProject?.uid, currentModel.id, req)
        .then((res) => {
          dispatch(setModelFormModal(false))
          dispatch(getCategoryGroups(currentProject?.uid))
          dispatch(getModels(currentProject?.uid, currentModel.id))
          message.destroy()
          message.success(t('importComponentSuccess'))
        })
        .catch((e) => {
          message.destroy()
          message.error(e.response.data.error)
        })
        .then(() => {
          const fileSheet = document.getElementById(
            'file-sheet'
          ) as HTMLInputElement
          if (fileSheet) {
            fileSheet.value = ''
          }
        })
    }
  }

  // Validation
  const validationModelFormSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    devKey: Yup.string()
      .required(t('validation.required'))
      .notOneOf(
        [...RESERVED_WORDS, ...RESERVED_WORDS.map((w) => w.toLowerCase())],
        t('validation.reservedWordsCannotUsedForDevKey')
      ),
  })

  // Formik
  const formikModelForm = useFormik({
    initialValues: initialModelFormValues,
    validationSchema: validationModelFormSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (!currentProject || !currentModel) return

      // 예약어 개발키 확인
      if (RESERVED_WORDS.includes(values.devKey.toUpperCase())) {
        formikModelForm.setErrors({
          devKey: t('validation.reservedWordsCannotUsedForDevKey'),
        })
        document.getElementById('modelFormDevKey')?.focus()
        return false
      }

      // 중복 개발키 확인
      if (
        currentModel &&
        modelList.find(
          (m) => m.devKey === values.devKey && m.devKey !== currentModel.devKey
        )
      ) {
        message.warning(t('error.duplicatedDevKey'))
        document.getElementById('modelFormDevKey')?.focus()
        return false
      }

      setStatus(null)
      setLoading(true)

      // 언어별 값 선택
      const languageMap = {}
      currentProject.languageList.forEach((lang) => {
        languageMap[lang] = values.name
      })

      const req = {
        languageMap,
        devKey: values.devKey.replace(/[^A-Z0-9]/gi, ''),
        componentList: currentModel.componentList
          ? currentModel.componentList
          : [],
        order: currentModel.order as number,
      }

      await updateModel(currentProject?.uid, currentModel.id, req)
        .then((res) => {
          dispatch(getModels(currentProject?.uid, currentModel.id))
          message.success(t('saveSuccess'))
        })
        .catch((e) => {
          setSubmitting(false)
          setStatus(e.response.data.error)
        })

      setLoading(false)
    },
  })

  /**
   * 랜덤 개발키 생성
   */
  const generateRandomDevKey = () => {
    formikModelForm.setFieldValue('devKey', uuidv4())
  }

  /**
   * 개발키 Tip 완료
   */
  const handleTipDevKeyComplete = (skipAll: boolean) => {
    Cookies.set(TIP_DEV_KEY_KEY, '1')
  }

  /**
   * 콘텐츠 모두 지우기
   * @param contentsToBeDeleted
   */
  const truncateAllCotents = () => {
    Modal.confirm({
      centered: true,
      title: t('confirmTruncateContentsTitle', {
        model: currentModel?.languageMap[currentLanguage],
      }),
      icon: <ExclamationCircleOutlined />,
      content: t('confirmTruncateContentsDesc'),
      okText: t('delete'),
      cancelText: t('cancel'),
      onOk() {
        return new Promise((resolve, reject) => {
          truncateContents(currentProject?.uid, currentModel?.id)
            .then((res) => {
              dispatch(getProjectUsage(currentProject?.uid as string))
              message.success(t('deleteSuccess'))
              deleteItem(currentModel?.id)
              resolve(res)
            })
            .catch((e) => {
              message.error(e.response.data.error)
              reject(e)
            })
        }).catch((e) => console.log(e))
      },
      onCancel() {},
    })
  }

  return (
    <>
      {currentProject && currentModel && currentModel.languageMap && (
        <Helmet>
          <title>
            {currentModel.languageMap[currentProject.defaultLang]} ·{' '}
            {t('contentsBuilder')} · {currentProject.name} ·{' '}
            {process.env.REACT_APP_NAME}
          </title>
        </Helmet>
      )}
      {currentModel && currentProject ? (
        <div className={''}>
          <TabModels />
          {/* Contents Builder body: 시작 */}
          <form
            onSubmit={formikModelForm.handleSubmit}
            method="POST"
            className="h-full">
            <div className="space-y-5">
              <Card>
                <AlertStatus
                  status={formikModelForm.status}
                  onClick={() => formikModelForm.setStatus(null)}></AlertStatus>
                <div className={'space-y-7 h-full flex flex-col'}>
                  <Typography.Title level={5}>
                    {currentModel.languageMap[currentProject?.defaultLang]}
                  </Typography.Title>
                  <Row gutter={24}>
                    <Col xs={24} md={12} className="mb-6 md:mb-0">
                      <div className="block">
                        <label htmlFor="modelFormName" className="">
                          <div className={'mb-2'}>
                            {t('modelName')}{' '}
                            <span className="text-red-500">*</span>
                          </div>
                          <Input
                            id={'modelFormName'}
                            name="name"
                            onChange={formikModelForm.handleChange}
                            value={formikModelForm.values.name}
                            disabled={
                              process.env.REACT_APP_HIDE_BUILDER === '1'
                            }
                          />
                        </label>
                        {formikModelForm.touched.name &&
                        formikModelForm.errors.name ? (
                          <p className="my-1 text-xs text-red-500">
                            {formikModelForm.errors.name}
                          </p>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs={24} md={12}>
                      <div className="space-y-4">
                        <div className="block">
                          <label htmlFor="modelFormDevKey" className="">
                            <div
                              className={
                                'mb-2 flex justify-between items-center'
                              }>
                              <div>
                                {t('devKey')}{' '}
                                <span className="text-red-500">*</span>
                              </div>
                              {!process.env.REACT_APP_PROJECT_ID && (
                                <a
                                  href={LINK_HELP_DEVKEY}
                                  tabIndex={-1}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-xs flex items-center space-x-0.5">
                                  <QuestionCircleOutlined />
                                  <span>{t('whatsDevKey')}</span>
                                </a>
                              )}
                            </div>
                            <Input.Group compact>
                              <Input
                                id={'modelFormDevKey'}
                                name="devKey"
                                onChange={formikModelForm.handleChange}
                                value={formikModelForm.values.devKey}
                                style={{
                                  width: `calc(100% - 32px)`,
                                }}
                                disabled={
                                  process.env.REACT_APP_HIDE_BUILDER === '1'
                                }
                              />
                              <Button
                                icon={<InteractionOutlined />}
                                onClick={() => {
                                  handleTipDevKeyComplete(false)
                                  generateRandomDevKey()
                                }}
                              />
                            </Input.Group>
                          </label>
                          {formikModelForm.touched.devKey &&
                          formikModelForm.errors.devKey ? (
                            <p className="my-1 text-xs text-red-500">
                              {formikModelForm.errors.devKey}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {process.env.REACT_APP_HIDE_BUILDER !== '1' && (
                    <>
                      <div className="flex justify-between items-center">
                        <div className="block">
                          <div className="mb-1">{t('duplicateModel')}</div>
                          <p className="text-xs text-gray-600 mb-0">
                            {t('confirmDuplicateModelDesc')}
                          </p>
                        </div>
                        <Button
                          type="primary"
                          className="btn-sm-ico-only"
                          icon={<CopyOutlined />}
                          onClick={() => onModelDuplicate(currentModel)}>
                          {t('duplicateModel')}
                        </Button>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="block">
                          <div className="mb-1">{t('exportModel')}</div>
                          <p className="text-xs text-gray-600 mb-0">
                            {t('exportModelDesc')}
                          </p>
                        </div>
                        <Button
                          type="primary"
                          className="btn-sm-ico-only"
                          icon={<ExportOutlined />}
                          onClick={() => onModelExport(currentModel)}>
                          {t('exportModel')}
                        </Button>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="block">
                          <div className="mb-1">{t('importModel')}</div>
                          <p className="text-xs text-gray-600 mb-0">
                            {t('importModelDesc')}
                          </p>
                        </div>
                        <Button
                          type="primary"
                          className="btn-sm-ico-only"
                          icon={<ImportOutlined />}
                          onClick={() => onModelImport(currentModel)}>
                          {t('importModel')}
                        </Button>
                      </div>
                    </>
                  )}
                  {process.env.REACT_APP_HIDE_USAGE !== '1' && modelUsage ? (
                    <div>
                      <div className="grid grid-cols-8 gap-6">
                        <div className="col-span-3">{t('usage')}</div>
                        <div className="col-span-5 space-y-2">
                          <UsageBar
                            usage={modelUsage.component}
                            label={t('noComponents')}
                          />
                          <UsageBar
                            usage={modelUsage.content}
                            label={t('noContents')}
                          />
                        </div>
                      </div>
                      <div className="flex justify-end">
                        <Link
                          to={`/projects/${currentProject?.uid}/usage`}
                          className="ant-btn ant-btn-text ant-btn-sm">
                          {t('more')}
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="flex justify-between items-center">
                    <div className="block">
                      <div className="mb-1">{t('truncateContents')}</div>
                      <p className="text-xs text-gray-600 mb-0">
                        {t('confirmTruncateContentsDesc')}
                      </p>
                    </div>
                    <div>
                      <Button
                        danger
                        size="small"
                        type="text"
                        disabled={
                          !(
                            currentProject?.role === 'OWNER' ||
                            currentProject?.role === 'ADMIN'
                          )
                        }
                        icon={<DeleteFilled />}
                        onClick={() => truncateAllCotents()}>
                        {t('truncateContents')}
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
              <div className="flex justify-between items-center">
                <div>
                  {(currentProject?.role === 'OWNER' ||
                    currentProject?.role === 'ADMIN') && (
                    <a
                      className="mb-0 text-red-500"
                      onClick={() => onModelDelete(currentModel)}>
                      {t('deleteModel')}
                    </a>
                  )}
                </div>
                <div
                  className={`transition-all !fixed top-2 ${
                    gnb ? 'right-4 lg:right-10' : 'right-4'
                  }  z-50`}>
                  <Button
                    type={'primary'}
                    className="btn-sm-ico-only"
                    icon={<SaveOutlined />}
                    onClick={() => formikModelForm.submitForm()}
                    disabled={loading}
                    loading={loading}>
                    {t('save')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
          {/* Contents Builder body: 끝 */}
          <input
            id="file-json"
            type="file"
            className="hidden"
            onChange={(e) => importModel(e)}
            accept=".json"
          />
        </div>
      ) : (
        <div className={'flex justify-center items-center h-screen'}>
          <Spin />
        </div>
      )}
    </>
  )
}

export default ProjectsModelsSettings
