import { AppDispatch } from '@/states'
import { ActionTypesProjects } from '@/states/interfaces'
import { axiosInstance, PROJECTS_URL } from '@/configs'

/**
 * 프로젝트 모델 불러오기
 * @param projectUid
 * @param initModelId
 * @param isPreserveContents
 * @returns
 */
export const getModels =
  (
    projectUid,
    initModelId: number | null | boolean = null,
    isPreserveContents = false
  ) =>
  (dispatch: AppDispatch) => {
    return axiosInstance
      .get(`${PROJECTS_URL}/${projectUid}/models`)
      .then((res) => {
        dispatch({
          type: ActionTypesProjects.SET_MODEL_LIST,
          payload: {
            models: res.data,
          },
        })

        if (initModelId) {
          const targetModel = res.data.find((i) => i.id === initModelId)

          dispatch({
            type: ActionTypesProjects.SET_CURRENT_MODEL,
            payload: {
              model: targetModel
                ? targetModel
                : res.data && res.data.length
                ? res.data[0]
                : null,
              isPreserveContents: isPreserveContents,
            },
          })
        }
      })
      .catch((e) => {
        dispatch({
          type: ActionTypesProjects.SET_MODEL_LIST,
          payload: {
            models: [],
          },
        })
      })
  }

/**
 * 프로젝트 모델 불러오기 (Data)
 * @param projectUid
 * @param initModelId
 * @returns
 */
export const getModelsData = (projectUid) => {
  return axiosInstance.get(`${PROJECTS_URL}/${projectUid}/models`)
}

/**
 * 모델 정보 불러오기
 * @param projectUid
 * @param id
 * @returns
 */
export const getModel = (projectUid, id) => (dispatch: AppDispatch) => {
  return axiosInstance
    .get(`${PROJECTS_URL}/${projectUid}/models/${id}`)
    .then((res) => {
      dispatch({
        type: ActionTypesProjects.SET_CURRENT_PROJECT,
        payload: {
          project: res.data,
        },
      })
    })
    .catch((e) => {
      dispatch({
        type: ActionTypesProjects.SET_CURRENT_PROJECT,
        payload: {
          project: null,
        },
      })
    })
}

/**
 * 모델 정보 불러오기 (data)
 * @param projectUid
 * @param id
 * @returns
 */
export const getModelData = (projectUid, id) => {
  return axiosInstance.get(`${PROJECTS_URL}/${projectUid}/models/${id}`)
}

/**
 * 모델 추가
 * @param projectUid
 * @param req
 * @returns
 */
export const createModel = (projectUid, req) => {
  return axiosInstance.post(`${PROJECTS_URL}/${projectUid}/models`, req)
}

/**
 * 모델 수정
 * @param projectUid
 * @param id
 * @param req
 * @returns
 */
export const updateModel = (projectUid, id, req) => {
  return axiosInstance.put(`${PROJECTS_URL}/${projectUid}/models/${id}`, req)
  /* return axios
    .put(`${PROJECTS_URL}/${projectUid}/models/${id}`, req)
    .then((res) => {
      dispatch({
        type: ActionTypesProjects.SET_CURRENT_MODEL,
        payload: {
          model: res.data.data,
        },
      })
    })
    .catch((e) => {
      console.log(e)
    }) */
}

/**
 * 모델 검증 & 배포
 * @param projectUid
 * @param modelId
 * @param version
 * @returns
 */
export const validateModel = (projectUid, modelId, version?: string) => {
  return axiosInstance.post(
    `${PROJECTS_URL}/${projectUid}/models/${modelId}/validate${
      version ? `/${version}` : ''
    }`
  )
}

/**
 * 모델 삭제
 * @param projectUid
 * @param id
 * @returns
 */
export const deleteModel = (projectUid, id) => {
  return axiosInstance.delete(`${PROJECTS_URL}/${projectUid}/models/${id}`)
}
