import Cookies from 'js-cookie'
import { CancelTokenSource } from 'axios'
import { ActionTypesAuth } from '@/states/interfaces'
import { AppDispatch } from '@/states'
import {
  axiosInstance,
  AUTH_URL,
  XSRF_TOKEN_KEY,
  USER_ID_KEY,
  getAuthRefreshHeader,
  USER_INFO_KEY,
} from '@/configs'
import {
  AuthLoginInterface,
  AuthPasswordForgetInterface,
  AuthPasswordResetInterface,
  AuthSignUpInterface,
  UserInterface,
  AuthRequestInterface,
  AuthVerifyInterface,
  AuthUpdateProfileInterface,
  AuthUpdatePasswordInterface,
} from '@/types'
import { getUser } from '@/utils/auth'

/**
 * CSRF 토큰 생성
 * @param isOverride
 * @param source
 */
export const getCsrfToken = (
  isOverride: boolean = false,
  source?: CancelTokenSource | undefined
) => {
  return Cookies.get(XSRF_TOKEN_KEY) && !isOverride
    ? Promise.resolve()
    : axiosInstance.get(`/csrf`, {
        cancelToken: source ? source.token : undefined,
      })
}

/**
 * 토큰 재발행
 */
export const issueToken = () => {
  return axiosInstance.put(`${AUTH_URL}/token`, null, getAuthRefreshHeader())
}

/**
 * 이메일 가입 검색
 * @param req
 */
export const searchEmail = (req: AuthRequestInterface) => {
  return axiosInstance.post(`${AUTH_URL}/search/emails`, req)
}

/**
 * 비밀번호 토큰 인증
 * @param password
 */
export const verifyPasswordToken = (token: string) => {
  return axiosInstance.post(`${AUTH_URL}/validation/passwords/${token}`)
}

/**
 * 이메일 인증코드 전송
 * @param req
 */
export const requestEmail = (req: AuthRequestInterface) => {
  return axiosInstance.post(`${AUTH_URL}/validation/emails`, req)
}

/**
 * 이메일 인증코드 인증
 * @param req
 * @param code
 */
export const verifyCode = (req: AuthVerifyInterface, code) => {
  return axiosInstance.post(`${AUTH_URL}/validation/emails/${code}`, req)
}

/**
 * 회원가입
 * @param req
 */
export const signupWithEmail = (req: AuthSignUpInterface) => {
  return axiosInstance.post(`${AUTH_URL}/members`, req)
}

/**
 * 로그인
 * @param req
 * @returns {Promise<AxiosResponse<any>>}
 */
export const loginWithEmailAndPassword = (req: AuthLoginInterface) => {
  return axiosInstance.post(`${AUTH_URL}/login`, req)
}

/**
 * 로그아웃
 */
export const logout = () => {
  return axiosInstance.post(`${AUTH_URL}/logout`, null)
}

/**
 * 이메일 재설정 링크 보내기
 * @param req
 */
export const searchPassword = (req: AuthPasswordForgetInterface) => {
  return axiosInstance.post(`${AUTH_URL}/search/passwords`, req)
}

/**
 * 비밀번호 재설정
 * @param req
 * @param token
 */
export const resetPassword = (req: AuthPasswordResetInterface, token) => {
  return axiosInstance.put(`${AUTH_URL}/members/password`, req, {
    headers: {
      'Access-Token': `${token}`,
    },
  })
}

/**
 * 내 정보 불러오기
 * @returns {function(*): Promise<void>}
 */
export const getProfile = () => (dispatch: AppDispatch) => {
  return Cookies.get(USER_ID_KEY) && Cookies.get(USER_INFO_KEY)
    ? axiosInstance
        .get(`${AUTH_URL}/members/${Cookies.get(USER_ID_KEY)}`)
        .then((res) => {
          dispatch({
            type: ActionTypesAuth.SET_PROFILE,
            payload: {
              profile: res.data,
            },
          })
        })
        .catch((e) => {
          dispatch({
            type: ActionTypesAuth.SET_PROFILE,
            payload: {
              profile: getUser(),
            },
          })
        })
    : Promise.resolve()
}

/**
 * 프로필 설정
 */
export const setProfile =
  (profile: UserInterface) => (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesAuth.SET_PROFILE,
      payload: {
        profile: profile,
      },
    })
  }

/**
 * 회원정보 수정
 * @param req
 */
export const updateProfile =
  (req: AuthUpdateProfileInterface) => (dispatch: AppDispatch) => {
    return axiosInstance
      .put(`${AUTH_URL}/members/${Cookies.get(USER_ID_KEY)}`, req)
      .then((res) => {
        dispatch({
          type: ActionTypesAuth.SET_PROFILE,
          payload: {
            profile: res.data,
          },
        })
      })
      .catch((e) => {
        dispatch({
          type: ActionTypesAuth.SET_PROFILE,
          payload: null,
        })
      })
  }

/**
 * 비밀번호 수정
 * @param req
 */
export const updatePassword = (req: AuthUpdatePasswordInterface) => {
  return axiosInstance.put(
    `${AUTH_URL}/members/${Cookies.get(USER_ID_KEY)}/password`,
    req
  )
}

/**
 * 회원 탈퇴
 */
export const deactivate = (userId) => {
  return axiosInstance.delete(`${AUTH_URL}/members/${userId}`)
}

/**
 * 임시 회원인증코드 설정
 */
export const setTmpCode = (code) => (dispatch: AppDispatch) => {
  return dispatch({
    type: ActionTypesAuth.SET_TMP_CODE,
    payload: {
      code,
    },
  })
}
