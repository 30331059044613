import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Segmented } from 'antd'
import { RootState, useAppDispatch } from '@/states'
import { setCurrentLanguage } from '@/states/actions'
import { LanguagesAvailable } from '@/types'

export const ContentsLanguage = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { currentProject, currentLanguage } = projectsState

  // State
  const [langs, setLangs] = useState<
    { label: string; value: LanguagesAvailable }[]
  >([])

  // Effect
  useEffect(() => {
    if (currentProject) {
      setLangs(
        currentProject.languageList.map((l) => {
          return { label: l.toUpperCase(), value: l }
        })
      )
    }
  }, [currentProject])

  return currentProject ? (
    <div>
      <Segmented
        options={langs}
        value={currentLanguage}
        onChange={(value) =>
          dispatch(setCurrentLanguage(value as LanguagesAvailable))
        }></Segmented>
    </div>
  ) : (
    <></>
  )
}
