import React from 'react'

export const ExtIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      data-icon="hygraph-icon"
      aria-hidden="true"
      focusable="false"
      tabIndex={-1}
      className="w-4 h-4">
      <path
        d="M10 6v2H5v11h11v-5h2v6a1 1 0 01-1 1H4a1 1 0 01-1-1V7a1 1 0 011-1h6zm11-3v8h-2V6.413l-7.793 7.794-1.414-1.414L17.585 5H13V3h8z"
        fill="currentColor"></path>
    </svg>
  )
}
