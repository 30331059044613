import { RootState, useAppDispatch } from '@/states'
import { LanguageMap } from '@/types'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { setContentsFormModal } from '@/states/actions'
import { Col, Row } from 'antd'

export const FormPrevNext = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )

  const {
    currentProject,
    currentModel,
    contentsPagination,
    contentsList,
    contentsSort,
    currentLanguage,
  } = projectsState

  // Params
  const [searchParams, setSearchParams] = useSearchParams()

  // Memo
  const prevContents = useMemo(() => {
    const prevIdx =
      contentsList.findIndex((item) => item.uid === searchParams.get('id')) - 1

    return prevIdx < 0 ? null : contentsList[prevIdx]
  }, [searchParams.get('id')])

  const prevContentsTitle = useMemo(() => {
    if (
      !prevContents ||
      !prevContents.cellList ||
      prevContents.cellList.length === 0
    )
      return null

    const titleIdx = prevContents.cellList.findIndex(
      (c) => c.component?.type === 'TITLE'
    )

    if (titleIdx === -1) {
      return prevContents.cellList[0].languageMap
        ? prevContents.cellList[0].languageMap[currentLanguage]
        : prevContents.cellList[0].value
        ? prevContents.cellList[0].value
        : 'N/A'
    } else {
      return prevContents.cellList[titleIdx] &&
        prevContents.cellList[titleIdx].languageMap
        ? (prevContents.cellList[titleIdx].languageMap as LanguageMap)[
            currentLanguage
          ]
        : '-'
    }
  }, [prevContents])

  const nextContents = useMemo(() => {
    const nextIdx =
      contentsList.findIndex((item) => item.uid === searchParams.get('id')) + 1

    return nextIdx >= contentsList.length ? null : contentsList[nextIdx]
  }, [searchParams.get('id')])

  const nextContentsTitle = useMemo(() => {
    if (!nextContents || !nextContents.cellList) return null

    const titleIdx = nextContents.cellList.findIndex(
      (c) => c.component?.type === 'TITLE'
    )

    if (titleIdx === -1) {
      return nextContents.cellList[0].languageMap
        ? nextContents.cellList[0].languageMap[currentLanguage]
        : nextContents.cellList[0].value
        ? nextContents.cellList[0].value
        : 'N/A'
    } else {
      return nextContents.cellList[titleIdx] &&
        nextContents.cellList[titleIdx].languageMap
        ? (nextContents.cellList[titleIdx].languageMap as LanguageMap)[
            currentLanguage
          ]
        : '-'
    }
  }, [nextContents])

  /**
   * 콘텐츠 수정
   * @param uid
   */
  const changeContents = (uid) => {
    dispatch(setContentsFormModal(false))

    setTimeout(() => {
      navigate(
        `/projects/${currentProject?.uid}/${currentModel?.id}/contents?page=${
          contentsPagination.page
        }&size=${
          contentsPagination.size
        }&sort=${contentsSort}&q=${searchParams.get('q')}&edit=modal&id=${uid}`
      )
    }, 500)
  }

  return (
    <Row gutter={48}>
      <Col xs={24} lg={18}>
        <div className="flex justify-between space-x-4">
          <div className="w-1/2">
            {prevContents && (
              <div
                className="flex space-x-2 items-center w-full cursor-pointer text-blue-500 hover:underline"
                onClick={() => changeContents(prevContents.uid)}>
                <ArrowLeftOutlined />
                <div className="w-full truncate">{prevContentsTitle}</div>
              </div>
            )}
          </div>
          <div className="w-1/2">
            {nextContents && (
              <div
                className="flex justify-end space-x-2 items-center w-full cursor-pointer text-blue-500 hover:underline"
                onClick={() => changeContents(nextContents.uid)}>
                <div className="w-full truncate text-right">
                  {nextContentsTitle}
                </div>
                <ArrowRightOutlined />
              </div>
            )}
          </div>
        </div>
      </Col>
    </Row>
  )
}
