import { FileType } from '@/types'

// 기본 업로드 가능 파일 최대 크기 (1024 * #MB)
export const FILE_MAX_SIZE_UPLOAD = 1024 * 5

// 기본 업로드 가능 최대 Resolution
export const FILE_IMAGE_MAX_RES = 1920

// 파일 종류
export const availableFileTypes: FileType[] = [
  'IMAGE',
  'VIDEO',
  'AUDIO',
  'PDF',
  'FILE',
]

/**
 * 이미지서버 파일 확장자
 * @see https://iiif.io/api/image/3.0/#45-format
 */
export const imageExtensions = {
  'image/png': 'png',
  'image/jpeg': 'jpg',
  'image/gif': 'gif',
  'image/bmp': 'jpg',
  'image/tiff': 'tif',
  'image/jp2': 'jp2',
  'application/pdf': 'pdf',
  'image/webp': 'webp',
}
