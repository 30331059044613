import React, { useMemo } from 'react'
import { Tooltip } from 'antd'
import { SYNC_USER_COLORS } from '@/configs'
import { UserInterface } from '@/types'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '@/states'

export const SyncMember = ({
  member,
  idx,
}: {
  member: UserInterface
  idx: number
}) => {
  // State (Redux)
  const { projectsState, authState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { currentProject } = projectsState
  const { me } = authState

  // Color
  const syncColor = useMemo(() => {
    return SYNC_USER_COLORS.split('\n').filter((c) => c)[idx]
  }, [idx])

  return (
    <li key={member.id} className={'flex items-center'}>
      <Tooltip
        title={
          <div className={'grow overflow-hidden'}>
            <div className={'leading-5 truncate text-xs'}>
              {member?.name}
              {me?.id === member.id && (
                <span className="ml-1 text-xxs rounded-sm px-0.5 bg-secondary text-white">
                  Me
                </span>
              )}
            </div>
            <div
              className={'text-xxs text-gray-500 leading-4 -mt-0.5 truncate'}>
              {member?.email}
            </div>
          </div>
        }>
        {member?.image && member?.image?.path ? (
          <div
            className={`w-7 h-7 rounded-full bg-gray-300 flex-none bg-cover bg-center border-2`}
            style={{
              backgroundImage: `url(${member?.image?.path})`,
              borderColor: syncColor,
            }}></div>
        ) : (
          <div
            className={`w-7 h-7 rounded-full bg-secondary flex-none memex-ico-user border-2`}
            style={{
              borderColor: syncColor,
            }}
          />
        )}
      </Tooltip>
    </li>
  )
}
