import {
  ModalsState,
  ActionTypesModals,
  ActionsModals,
} from '@/states/interfaces'

export const initialState: ModalsState = {
  accountModal: false,
  accountPasswordModal: false,
  projectFormModal: false,
  projectInfo: null,
  projectInviteModal: false,
  projectAccessInfo: null,
  projectAccessFormModal: false,
  modelFormModal: false,
  modelInfo: null,
  componentFormModal: false,
  componentInfo: null,
  parentComponentInfo: null,
  mediaUploadModal: false,
  mediaFormModal: false,
  mediaUploadUid: '',
  mediaInfo: null,
  updatedMediaInfo: null,
  mediaSelectorModal: false,
  contentsRelationSelectorModal: false,
  contentsFormModal: false,
  contentsPreviewFormModal: false,
  contentsInfo: null,
  contentsPreviewInfo: null,
  contentsModelId: null,
  contentsPreviewModelId: null,
  cellInfo: null,
  categoryFormModal: false,
  categoryInfo: null,
}

interface HydratePayload {
  modals: ModalsState
}

const ModalsReducer = (
  state = initialState,
  action: ActionsModals
): ModalsState => {
  switch (action.type) {
    // 계정 정보 모달
    case ActionTypesModals.SET_ACCOUNT_MODAL: {
      return {
        ...state,
        accountModal: action.payload.isOpen,
      }
    }
    // 계정 비밀번호 변경 모달
    case ActionTypesModals.SET_ACCOUNT_PASSWORD_MODAL: {
      return {
        ...state,
        accountPasswordModal: action.payload.isOpen,
      }
    }
    // 프로젝트 폼 모달
    case ActionTypesModals.SET_PROJECT_FORM_MODAL: {
      return {
        ...state,
        projectFormModal: action.payload.isOpen,
        projectInfo: action.payload.info ? action.payload.info : null,
      }
    }
    // 프로젝트 초대 모달
    case ActionTypesModals.SET_PROJECT_INVITE_MODAL: {
      return {
        ...state,
        projectInviteModal: action.payload.isOpen,
      }
    }
    // 프로젝트 API Key 모달
    case ActionTypesModals.SET_PROJECT_ACCESS_FORM_MODAL: {
      return {
        ...state,
        projectAccessFormModal: action.payload.isOpen,
        projectAccessInfo: action.payload.info ? action.payload.info : null,
      }
    }
    // 모델 폼 모달
    case ActionTypesModals.SET_MODEL_FORM_MODAL: {
      return {
        ...state,
        modelFormModal: action.payload.isOpen,
        modelInfo: action.payload.info ? action.payload.info : null,
      }
    }
    // 컴포넌트 폼 모달
    case ActionTypesModals.SET_COMPONENT_FORM_MODAL: {
      return {
        ...state,
        componentFormModal: action.payload.isOpen,
        componentInfo: action.payload.info ? action.payload.info : null,
        parentComponentInfo: action.payload.parentInfo
          ? action.payload.parentInfo
          : null,
      }
    }
    // 미디어 업로드 모달
    case ActionTypesModals.SET_MEDIA_UPLOAD_MODAL: {
      return {
        ...state,
        mediaUploadModal: action.payload.isOpen,
        mediaUploadUid: action.payload.uid ? action.payload.uid : '',
      }
    }
    // 미디어 폼 모달
    case ActionTypesModals.SET_MEDIA_FORM_MODAL: {
      return {
        ...state,
        mediaFormModal: action.payload.isOpen,
        mediaInfo:
          action.payload.isOpen && action.payload.info
            ? action.payload.info
            : null,
        updatedMediaInfo: action.payload.updatedInfo
          ? action.payload.updatedInfo
          : null,
        mediaUploadUid: action.payload.uid ? action.payload.uid : '',
      }
    }
    // 미디어 선택 모달
    case ActionTypesModals.SET_MEDIA_SELECTOR_MODAL: {
      return {
        ...state,
        mediaSelectorModal: action.payload.isOpen,
        cellInfo: action.payload.info ? action.payload.info : null,
        componentInfo: action.payload.componentInfo
          ? action.payload.componentInfo
          : null,
        contentsInfo: action.payload.contentsInfo
          ? action.payload.contentsInfo
          : null,
      }
    }
    // 연관 콘텐츠 선택 모달
    case ActionTypesModals.SET_CONTENTS_RELATION_SELECTOR_MODAL: {
      return {
        ...state,
        contentsRelationSelectorModal: action.payload.isOpen,
        cellInfo: action.payload.info ? action.payload.info : null,
        componentInfo: action.payload.componentInfo
          ? action.payload.componentInfo
          : null,
        contentsInfo: action.payload.contentsInfo
          ? action.payload.contentsInfo
          : null,
      }
    }
    // 콘텐츠 폼 모달
    case ActionTypesModals.SET_CONTENTS_FORM_MODAL: {
      return {
        ...state,
        contentsFormModal: action.payload.isOpen,
        contentsInfo: action.payload.info ? action.payload.info : null,
        contentsModelId: action.payload.modelId ? action.payload.modelId : null,
      }
    }
    // 콘텐츠 프리뷰 폼 모달
    case ActionTypesModals.SET_CONTENTS_PREVIEW_FORM_MODAL: {
      return {
        ...state,
        contentsPreviewFormModal: action.payload.isOpen,
        contentsPreviewInfo: action.payload.info ? action.payload.info : null,
        contentsPreviewModelId: action.payload.modelId
          ? action.payload.modelId
          : null,
      }
    }
    // 카테고리 폼 모달
    case ActionTypesModals.SET_CATEGORY_FORM_MODAL: {
      return {
        ...state,
        categoryFormModal: action.payload.isOpen,
        categoryInfo: action.payload.info ? action.payload.info : null,
      }
    }
    default:
      return state
  }
}

export default ModalsReducer
