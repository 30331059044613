import { updateModel } from '@/api'
import ModelFavorite from '@/components/Models/ModelsFavorite'
import {
  DEFAULT_CONTENTS_PAGE_SIZE,
  LAYOUT_OKAY_COLOR,
  LAYOUT_SUCCESS_COLOR,
  LAYOUT_WARNING_COLOR,
} from '@/configs'
import { RootState, useAppDispatch } from '@/states'
import { setModelFormModal, setModelsList } from '@/states/actions'
import { ModelInterface } from '@/types'
import { getSavedContentsSortKey } from '@/utils/helpers'
import {
  ApiOutlined,
  CloudSyncOutlined,
  HolderOutlined,
  InfoCircleOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons'
import { Button, Spin, Tooltip } from 'antd'
import axios from 'axios'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'

export const GnbProjectModels = ({
  isGnb2Collapse,
}: {
  isGnb2Collapse: boolean
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()

  // Params
  let { modelId } = useParams()

  // State (Redux)
  const { layoutState, projectsState, authState } = useSelector(
    (state: RootState) => ({
      layoutState: state.layout,
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { gnb, gnb2, gnb3 } = layoutState
  const {
    projectsListInit,
    currentProject,
    currentModel,
    modelList,
    modelListInit,
    contentsListLoading,
  } = projectsState
  const { init, me } = authState

  /**
   * 모델 콘텐츠 링크 이동
   * @param e
   * @param model
   * @returns
   */
  const enterModelContents = (e, model: ModelInterface) => {
    if (
      contentsListLoading ||
      e.target.closest('.handler') ||
      e.target.closest('.ico-favorite')
    )
      return

    const targetModelId = model.id
    const targetModel = modelList.find((m) => m.id === targetModelId)

    if (!targetModel || Number(modelId) === targetModel.id) return

    navigate(
      `/projects/${currentProject?.uid}/${
        targetModel.id
      }/contents?page=1&size=${DEFAULT_CONTENTS_PAGE_SIZE}&sort=${getSavedContentsSortKey(
        targetModel.id
      )}&q=`
    )
  }

  /**
   * 모델 순서 변경
   * @param result
   * @returns
   */
  const onSortModelEnd = async (result) => {
    if (!result.destination || contentsListLoading) {
      return
    }

    // Sort 실행
    const updateModels = JSON.parse(JSON.stringify(modelList))
    const startIndex = result.source.index
    const endIndex = result.destination.index
    const [removed] = updateModels.splice(startIndex, 1)
    updateModels.splice(endIndex, 0, removed)
    await dispatch(setModelsList(updateModels))

    const categoryReqs: any[] = []
    await updateModels.forEach((model, idx) => {
      model.order = idx

      categoryReqs.push(updateModel(currentProject?.uid, model.id, model))
    })

    axios.all([...categoryReqs]).then(
      axios.spread((...responses) => {
        setTimeout(() => {
          // dispatch(getModels(currentProject?.uid, currentModel?.id, true))
        })
      })
    )
  }

  return modelListInit && currentProject ? (
    <div className="space-y-0">
      {/* <h4
        className={
          'py-3 px-6 text-xs leading-5 mb-0 flex justify-between items-center'
        }>
        <span className="font-bold uppercase">{t('models')}</span>
        {currentProject?.usage?.type !== 'UNLIMITED' &&
        currentProject?.usage?.model ? (
          <Tooltip title={t('noModelsDesc')} className="cursor-help">
            <span
              className="font-normal flex space-x-1 items-center"
              style={{
                color:
                  modelList.length >= currentProject?.usage?.model.limit
                    ? LAYOUT_WARNING_COLOR
                    : LAYOUT_OKAY_COLOR,
              }}>
              <span>
                {modelList.length} / {currentProject?.usage?.model.limit}
              </span>
              <InfoCircleOutlined className="mt-px" />
            </span>
          </Tooltip>
        ) : (
          <></>
        )}
      </h4> */}

      <div className="relative">
        <DragDropContext onDragEnd={onSortModelEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {modelList.map((model, index) => (
                  <Draggable
                    key={model.id}
                    draggableId={(model.id as number).toString()}
                    index={index}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        {model.id === 108 && (
                          <div className="font-bold px-2 py-1.5 mt-2">사지</div>
                        )}
                        {model.id === 50 && (
                          <div className="font-bold px-2 py-1.5 mt-2">
                            소재문화재
                          </div>
                        )}
                        {model.id === 418 && (
                          <div className="font-bold px-2 py-1.5 mt-2">
                            웹사이트 관리
                          </div>
                        )}
                        <div
                          className={`w-full flex justify-between items-center space-x-1 cursor-pointer ${
                            parseInt(modelId as string, 10) === model.id
                              ? 'bg-gray-300 font-bold'
                              : ''
                          } hover:bg-gray-300 rounded-lg pr-2 pl-6 py-1.5`}
                          role="menuitem"
                          onClick={(e) => enterModelContents(e, model)}>
                          <div className="grow w-full flex items-center space-x-1 overflow-hidden">
                            <div className="truncate flex items-center space-x-1">
                              <div className="truncate">
                                {model.languageMap[currentProject.defaultLang]}
                              </div>
                              {model.lastValidate ? (
                                <CloudSyncOutlined
                                  style={{
                                    color: LAYOUT_SUCCESS_COLOR,
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                            {model.hasTitle ? (
                              <div className="flex-none flex items-center">
                                <ApiOutlined
                                  title={t('availableRelationConnected')}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="ico-favorite">
                              <ModelFavorite model={model} />
                            </div>
                          </div>
                          {!isGnb2Collapse &&
                            process.env.REACT_APP_HIDE_BUILDER !== '1' && (
                              <div
                                className="flex-none handler"
                                {...provided.dragHandleProps}>
                                <HolderOutlined
                                  className="cursor-move"
                                  title={t('changeOrder')}
                                />
                              </div>
                            )}

                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {process.env.REACT_APP_HIDE_BUILDER !== '1' &&
      (currentProject.role === 'OWNER' || currentProject.role === 'ADMIN') ? (
        <Button
          type={'text'}
          block
          className="!w-full !rounded-lg !px-2"
          onClick={() => dispatch(setModelFormModal(true))}
          disabled={
            currentProject?.usage &&
            modelList.length >= currentProject.usage.model.limit
          }>
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-1">
              <PlusSquareOutlined />
              <span>{t('addModel')}</span>
            </div>
            {process.env.REACT_APP_HIDE_USAGE !== '1' &&
            currentProject?.usage?.type !== 'UNLIMITED' &&
            currentProject?.usage?.model ? (
              <Tooltip title={t('noModelsDesc')} className="cursor-help">
                <span
                  className="font-normal flex space-x-1 items-center text-xs"
                  style={{
                    color:
                      modelList.length >= currentProject?.usage?.model.limit
                        ? LAYOUT_WARNING_COLOR
                        : LAYOUT_OKAY_COLOR,
                  }}>
                  <span>
                    {modelList.length} / {currentProject?.usage?.model.limit}
                  </span>
                  <InfoCircleOutlined className="mt-px" />
                </span>
              </Tooltip>
            ) : (
              <></>
            )}
          </div>
        </Button>
      ) : (
        <></>
      )}

      {/* <ul>
        {modelList.map((model) => (
          <li
            key={model.id}
            className={`flex items-center space-x-2 cursor-pointer hover-trigger ${
              parseInt(modelId as string, 10) === model.id ? 'bg-gray-300' : ''
            } hover:bg-gray-300 rounded-lg px-2 py-1.5`}
            onClick={() => enterModelContents(model)}>
            <div className="flex items-center space-x-2">
              {model.languageMap[currentProject?.defaultLang]}
            </div>
          </li>
        ))}
      </ul> */}
    </div>
  ) : (
    <div className="flex justify-center p-2">
      <Spin size="small" />
    </div>
  )
}
