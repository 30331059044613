import { logout } from '@/states/actions'
import { setLogout } from '@/utils/auth'
import { Button, Result, message } from 'antd'
import { useTranslation } from 'react-i18next'

const ProjectsNotFound = () => {
  const { t, i18n } = useTranslation()

  /**
   * 로그아웃
   */
  const goLogout = () => {
    logout()
      .then((res) => {
        setLogout(true)
      })
      .catch((e) => {
        message.error(t('error.wrong'))
      })
  }

  return (
    <div className={'h-screen flex justify-center items-center'}>
      <Result
        status="403"
        title="프로젝트에 가입"
        subTitle={
          <p>
            본 프로젝트에 가입되어 있지 않습니다. 관리자에게 프로젝트 초대를
            요청하세요.
          </p>
        }
        extra={
          <Button type="primary" key="console" onClick={() => goLogout()}>
            로그아웃
          </Button>
        }
      />
    </div>
  )
}

export default ProjectsNotFound
