import { UserInterface, AuthFavoriteInterface } from '@/types'

export enum ActionTypesAuth {
  SET_PROFILE = 'SET_PROFILE', // 프로필 설정
  SET_TMP_CODE = 'SET_TMP_CODE', // 임시 회원인증코드 설정
  SET_FAVORITES = 'SET_FAVORITES', // 즐겨찾기
}

export type ActionsAuth = AuthSetProfile | AuthSetTmpCode | AuthSetFavorites

export interface AuthSetProfile {
  type: ActionTypesAuth.SET_PROFILE
  payload: {
    profile: UserInterface | null
  }
}

export interface AuthSetTmpCode {
  type: ActionTypesAuth.SET_TMP_CODE
  payload: {
    code: string
  }
}

export interface AuthSetFavorites {
  type: ActionTypesAuth.SET_FAVORITES
  payload: {
    favorites: AuthFavoriteInterface
  }
}
