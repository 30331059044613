import React, { useEffect, useMemo } from 'react'
import Split from 'react-split'
import { Button, Tag, Empty, Spin, Collapse, message, Tooltip } from 'antd'
import {
  PlusCircleOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  ReconciliationOutlined,
  RobotOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import {
  ModelInterface,
  ProjectSampleModelCategoryType,
  ProjectUsagePropertiesInterface,
  SelectorGroupInterface,
} from '@/types'
const { Panel } = Collapse
import { ComponentsItemReadonly } from '@/components/Components'
import {
  PROJECT_SAMPLE_MODELS,
  PROJECT_SAMPLE_MODELS_CATEGORIES,
} from '@/configs'
import { LINK_HELP_DEVKEY } from '@/constants'
import { ModelRecommendations } from '@/components/Ai'
import { getSampleProjectGroups, getSampleProjectModels } from '@/api'

const sampleProjectUid = process.env
  .REACT_APP_SAMPLE_MODEL_PROJECT_UID as string

export const CreateStep2 = ({
  projectPlan,
  onNext,
  modelList,
  onImportModels,
  onImportGroups,
}: {
  projectPlan: ProjectUsagePropertiesInterface
  onNext: (number) => void
  modelList: ModelInterface[]
  onImportModels: (models) => void
  onImportGroups: (groups) => void
}) => {
  const { t, i18n } = useTranslation()

  // State
  const [models, setModels] = React.useState<ModelInterface[]>([])
  const [importedModels, setImportedModels] =
    React.useState<ModelInterface[]>(modelList)
  const [groups, setGroups] = React.useState<SelectorGroupInterface[]>([])
  const [importedGroups, setImportedGroups] = React.useState<
    SelectorGroupInterface[]
  >([])
  const [loadingModels, setLoadingModels] = React.useState<boolean>(false)
  const [loadingGroups, setLoadingGroups] = React.useState<boolean>(false)
  const [selectedCategory, setSelectedCategory] =
    React.useState<ProjectSampleModelCategoryType>('all')

  // Effect
  useEffect(() => {
    getSampleProjectModels(sampleProjectUid).then((res) => {
      const sortedModels: ModelInterface[] = res.data

      setModels(sortedModels)
      setLoadingModels(true)
    })

    getSampleProjectGroups(sampleProjectUid).then((res) => {
      setGroups(res.data)
      setLoadingGroups(true)
    })
  }, [])

  /**
   * 샘플 모델 가져오기
   * @param model
   */
  const importSampleModel = (model) => {
    if (
      !projectPlan.maxModels ||
      importedModels.length < (projectPlan.maxModels as number)
    ) {
      const noUsed = importedModels.filter((m) => m.id === model.id).length
      const modelToBeImported: ModelInterface = JSON.parse(
        JSON.stringify(model)
      )

      if (noUsed) {
        modelToBeImported.devKey = modelToBeImported.devKey + '_' + (noUsed + 1)
      }

      setImportedModels([...importedModels, modelToBeImported])

      // 해당 카테고리 가져오기
      const selectorGroups = modelToBeImported.componentList.filter(
        (c) => c.type === 'CATEGORY'
      )
      const updatedImportedGroup = importedGroups

      selectorGroups.forEach((selectors) => {
        const targetGroup = groups.find(
          (g) => selectors.selectorGroupId === g.id
        )
        if (!targetGroup) return

        const importedOldGroup = importedGroups.find(
          (g) => g.id === targetGroup.id
        )

        if (!importedOldGroup) {
          updatedImportedGroup.push(targetGroup)
        }
      })

      setImportedGroups(updatedImportedGroup)
    } else {
      message.destroy()
      message.open({
        type: 'warning',
        content: t('maxFreeProjectModelExceed', {
          count: projectPlan.maxModels,
        }),
      })
    }
  }

  /**
   * 샘플 모델 제거
   * @param e
   * @param idx
   * @param model
   */
  const removeImportedModel = (e, idx, model: ModelInterface) => {
    e.stopPropagation()

    const noUsed = importedModels.filter((m) => m.id === model.id).length
    const updatedImportedModels = importedModels.filter(
      (m, mIdx) => mIdx !== idx
    )
    const targetModel = importedModels[idx]

    if (!targetModel) return

    // 여러개일 경우 devKey 재정의
    if (noUsed > 1) {
      let i = 0
      updatedImportedModels.forEach((m, mIdx) => {
        if (m.id === model.id) {
          m.devKey =
            i > 0 ? targetModel.devKey + '_' + (i + 1) : targetModel.devKey
          i++
        }
      })
    } else {
      // ID값 있는 그룹 제거
      const removeIds = model.componentList
        .filter((c) => c.type === 'CATEGORY')
        .map((c) => c.selectorGroupId)

      setImportedGroups(importedGroups.filter((g) => !removeIds.includes(g.id)))
    }

    setImportedModels(updatedImportedModels)
  }

  /**
   * 건너뛰기
   */
  const onHandleSkip = () => {
    if (
      importedModels.length &&
      !confirm(t('projectCreate.step2.confirmSkip'))
    ) {
      return
    }

    onNext(2)
  }

  /**
   * 모델 등록 & 다음단계
   */
  const onHandleCreateModels = () => {
    if (importedModels.length) {
      onImportModels(importedModels)
      onImportGroups(importedGroups)
    }
    onNext(2)
  }

  /**
   * 샘플 모델
   * @param param0
   * @returns
   */
  const SampleModelItem = ({ model }: { model: ModelInterface }) => {
    // 이미 가져온 모델인지 확인
    const isUsed = useMemo(() => {
      return importedModels.find((m) => m.id === model.id)
    }, [importSampleModel])

    // 모델 추가 정보
    const modelConfig = useMemo(() => {
      return PROJECT_SAMPLE_MODELS.find(
        (s) => s.devKey.toLowerCase() === model.devKey.toLowerCase()
      )
    }, [model])

    return modelConfig ? (
      <div key={model.id} className="">
        <div className="text-center mb-1">
          <h3
            className={
              'mb-0 leading-5 font-semibold flex justify-center items-center space-x-1'
            }>
            <span>{model.languageMap?.KO}</span>
            <Tooltip
              title={t(`projectSampleModelInfo.${modelConfig.devKey}.desc`)}>
              <a
                href={LINK_HELP_DEVKEY}
                tabIndex={-1}
                target="_blank"
                rel="noreferrer"
                className="text-xs flex items-center space-x-0.5">
                <InfoCircleOutlined />
              </a>
            </Tooltip>
          </h3>
        </div>
        <div
          className="relative aspect-1 overflow-hidden bg-gray-200 bg-cover bg-no-repeat bg-center hover:scale-105 transition-all hover:shadow"
          style={{
            backgroundImage: `url(${
              modelConfig ? modelConfig.thumbnailUrl : ''
            })`,
          }}>
          <Tag className="absolute top-2 left-2">
            {t(
              `projectCreateCategory.${
                modelConfig ? modelConfig.category : 'board'
              }.title`
            )}
          </Tag>
          <Button
            size="small"
            className="!absolute bottom-4 right-4"
            icon={<PlusCircleOutlined />}
            onClick={() => importSampleModel(model)}>
            <span className="text-sm">
              {isUsed ? t('importAgain') : t('import')}
            </span>
          </Button>
        </div>
      </div>
    ) : (
      <></>
    )
  }

  // Memo
  const isMaxModelMeet = useMemo(() => {
    return (
      projectPlan.maxModels && importedModels.length >= projectPlan.maxModels
    )
  }, [importedModels, projectPlan.maxModels])

  /**
   * 모델 추가 유형별 포커스
   * @param val
   */
  const onHandleCollapseFocus = (val) => {
    if (val) {
      setTimeout(() => {
        document.getElementById('ai-model-name')?.focus()
      })
    }
  }

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="text-center flex-none">
          <h1 className="text-3xl font-bold mb-2">
            {t('projectCreate.step2.title')}
          </h1>
          <p
            className="text-sm mb-0 hidden lg:block"
            dangerouslySetInnerHTML={{
              __html: t('projectCreate.step2.desc'),
            }}
          />
          <p
            className="text-sm mb-0 lg:hidden"
            dangerouslySetInnerHTML={{
              __html: t('projectCreate.step2.descMobile'),
            }}
          />
        </div>
        <div className="w-full max-w-[1920px] mx-auto grow overflow-hidden hidden lg:block">
          <Split
            className={'w-full h-full flex split pt-8 pb-4'}
            sizes={[50, 50]}
            minSize={[368, 368]}>
            <div className="pr-5 overflow-y-auto h-full">
              <Collapse accordion>
                <Panel
                  header={
                    <div className="flex items-center space-x-2.5">
                      <strong>{t('sampleModelTitle')}</strong>
                      <ReconciliationOutlined />
                    </div>
                  }
                  key="sample">
                  {loadingModels && loadingGroups ? (
                    <div className="space-y-10">
                      <div className="w-full overflow-x-auto sticky top-0 z-10 bg-white">
                        <ul className="flex justify-center min-w-full w-max space-x-5">
                          {PROJECT_SAMPLE_MODELS_CATEGORIES.map((category) => (
                            <li
                              key={category.slug}
                              className={`cursor-pointer text-gray-600 hover:text-secondary ${
                                category.slug === selectedCategory
                                  ? 'font-bold text-secondary'
                                  : ''
                              }`}
                              onClick={() =>
                                setSelectedCategory(category.slug)
                              }>
                              <div>
                                {t(
                                  `projectCreateCategory.${category.slug}.title`
                                )}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="grid lg:grid-cols-3 2xl:grid-cols-4 gap-x-4 gap-y-10">
                        {models
                          .filter((model) => {
                            return (
                              (model.componentList &&
                                model.componentList.length &&
                                selectedCategory === 'all') ||
                              (PROJECT_SAMPLE_MODELS.find(
                                (s) =>
                                  s.devKey.toLowerCase() ===
                                  model.devKey.toLowerCase()
                              ) &&
                                PROJECT_SAMPLE_MODELS.find(
                                  (s) =>
                                    s.devKey.toLowerCase() ===
                                    model.devKey.toLowerCase()
                                )?.category === selectedCategory)
                            )
                          })
                          .map((model) => (
                            <SampleModelItem key={model.id} model={model} />
                          ))}
                      </div>
                    </div>
                  ) : (
                    <div className="h-full flex items-center justify-center">
                      <Spin />
                    </div>
                  )}
                </Panel>
                <Panel
                  header={
                    <div className="flex items-center space-x-2.5">
                      <strong>{t('aiRecommendations')}</strong>
                      <RobotOutlined />
                      <p className="mb-0 text-xxs">{t('aiNeedProPlan')}</p>
                    </div>
                  }
                  key="ai">
                  <ModelRecommendations onImportModel={importSampleModel} />
                </Panel>
              </Collapse>
            </div>
            <div className="pl-5 overflow-y-auto h-full">
              {importedModels.length > 0 ? (
                <div className="flex flex-col space-y-2">
                  <Collapse accordion onChange={onHandleCollapseFocus}>
                    {importedModels.map((model, mIdx) => (
                      <Panel
                        header={
                          <div className="flex items-center space-x-1">
                            <div>{model.languageMap?.KO}</div>
                            <div className="text-xs text-gray-500 font-normal">
                              {model.devKey}
                            </div>
                          </div>
                        }
                        key={mIdx}
                        extra={
                          <div>
                            <Button
                              type="text"
                              size="small"
                              onClick={(e) =>
                                removeImportedModel(e, mIdx, model)
                              }
                              icon={<DeleteOutlined />}
                            />
                          </div>
                        }>
                        <div className="max-h-96 overflow-y-auto space-y-2">
                          <p className="font-medium">{t('components')}</p>
                          {model?.componentList?.map((component, index) => (
                            <ComponentsItemReadonly
                              key={index}
                              component={component}
                              customClassName="bg-gray-200 shadow rounded"
                              disabled={true}
                            />
                          ))}
                        </div>
                      </Panel>
                    ))}
                  </Collapse>
                  {projectPlan.maxModels && (
                    <div
                      className={`text-xs ${
                        isMaxModelMeet ? 'text-yellow-600' : ''
                      }`}>
                      {t('maxFreeProjectModel', {
                        max: projectPlan.maxModels,
                        count: importedModels.length,
                      })}
                    </div>
                  )}
                  {/* {importedGroups.length && (
                    <div>
                      {importedGroups.map((g, gIdx) => (
                        <div key={gIdx}>
                          {g.id} - {g.languageMap.KO}
                        </div>
                      ))}
                    </div>
                  )} */}
                </div>
              ) : (
                <div className="h-full flex items-center justify-center">
                  <Empty
                    description={
                      <div>
                        <div className="font-semibold">{t('emptyProject')}</div>
                        <div
                          className="text-xs"
                          dangerouslySetInnerHTML={{
                            __html: t('emptyProjectDesc'),
                          }}></div>
                      </div>
                    }>
                    {}
                  </Empty>
                </div>
              )}
            </div>
          </Split>
        </div>
        <div className="w-full max-w-xl mx-auto flex justify-between items-center sticky bottom-0 bg-white pt-32 lg:pt-0">
          <div className="flex space-x-2 items-center">
            <div
              className="text-gray-500 cursor-pointer text-xs"
              onClick={() => onNext(0)}>
              {t('previous')}
            </div>
            <div className="text-gray-500 text-xs">2/4</div>
          </div>
          <div className="flex space-x-2">
            <Button type="text" onClick={() => onHandleSkip()}>
              {t('skip')}
            </Button>
            <Button type="primary" onClick={() => onHandleCreateModels()}>
              {t('next')}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
