/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState, Suspense, useMemo } from 'react'
import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import * as THREE from 'three'
import { OrbitControls } from '@react-three/drei'
import { Environment } from '@react-three/drei'
import CameraControls from 'camera-controls'
import { MediaInterface } from '@/types'

import { Html, useProgress } from '@react-three/drei'

export const CanLoader = () => {
  const { progress } = useProgress()
  return <Html center>{progress} % loaded</Html>
}

function GlbViewer({ file }: { file: MediaInterface }) {
  const gltf = useLoader(GLTFLoader, `${file.file.path}`)
  const glbRef: any = useRef(null)

  return (
    <Canvas
      style={{ height: '100%', width: '100%' }}
      shadows
      gl={{ alpha: true }}
      camera={{
        fov: 50,
        near: 0.1,
        far: 1000,
        position: [1, 1, 1],
      }}>
      <Suspense fallback={<CanLoader />}>
        <directionalLight intensity={2.5} />
        <ambientLight intensity={1} />
        <pointLight position={[5, 5, 5]} intensity={1} />
        <primitive
          // @ts-ignore
          ref={glbRef}
          position={[0, 0, 0]}
          // @ts-ignore
          object={gltf.scene}>
          <boxGeometry />
        </primitive>
        <OrbitControls autoRotate={false} minDistance={0.5} />
      </Suspense>
    </Canvas>
  )
}

export default GlbViewer
