import { getModels, updateModel } from '@/api'
import { CategoriesFormModal } from '@/components/Categories'
import { ExtIcon } from '@/components/Common'
import {
  ComponentsAdd,
  ComponentsFormModal,
  ComponentsItem,
} from '@/components/Components'
import { TabModels } from '@/components/Layout'
import { LAYOUT_OKAY_COLOR, LAYOUT_WARNING_COLOR } from '@/configs'
import { LINK_HELP_COMPONENT, LINK_HELP_MODEL } from '@/constants'
import { RootState, useAppDispatch } from '@/states'
import {
  resetContentsList,
  setModelFormModal,
  setTmpModel,
} from '@/states/actions'
import { ProjectModelUsageInterface } from '@/types'
import { setComponentsRequestBody } from '@/utils/helpers'
import { InfoCircleOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, message, Spin, Tooltip } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

const ProjectsModelsComponents = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // State (Redux)
  const { projectsState, authState, layoutState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const {
    currentProject,
    modelListInit,
    modelList,
    currentModel,
    tmpModel,
    categoriesList,
  } = projectsState
  const { init } = authState
  const { gnb } = layoutState

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [modelUsage, setModelUsage] = useState<
    ProjectModelUsageInterface | null | undefined
  >(null)
  const [componentsSaved, setComponentsSaved] = useState<boolean>(false)

  // Effect
  useEffect(() => {
    dispatch(resetContentsList())
    window.addEventListener('resize', setContentsBuilderLayout)
  }, [])

  // Effect
  useEffect(() => {
    if (currentProject && modelListInit) {
      setTimeout(() => {
        setContentsBuilderLayout()
      })
    }
  }, [currentProject, modelListInit])

  useEffect(() => {
    if (currentModel && currentProject && currentProject.usage) {
      setModelUsage(
        currentProject.usage.modelList.find((m) => m.id === currentModel.id)
      )
    }
  }, [currentModel, currentProject])

  /**
   * 본문영역 높이 조절
   */
  const setContentsBuilderLayout = () => {
    const body = document.getElementById('contentsBuilder-body')
    const header = document.getElementById('contentsBuilder-header')

    if (body && header) {
      body.style.height = window.innerHeight - (header.clientHeight - 1) + 'px'
    }
  }

  /**
   * 컴포넌트 정렬
   * @param oldIndex
   * @param newIndex
   */
  const onSortEnd = (result) => {
    if (!result.destination) {
      return
    }

    const startIndex = result.source.index
    const endIndex = result.destination.index
    const components = JSON.parse(JSON.stringify(tmpModel?.componentList))
    const [removed] = components.splice(startIndex, 1)
    components.splice(endIndex, 0, removed)

    const updatedModel = tmpModel
    if (updatedModel) {
      updatedModel.componentList = components
      // dispatch(setCurrentModel(updatedModel))

      dispatch(setTmpModel(updatedModel))
    }
  }

  /**
   * 저장
   * @returns
   */
  const saveModel = async () => {
    if (!tmpModel || loading) return false

    setLoading(true)

    const req = {
      languageMap: tmpModel.languageMap,
      devKey: tmpModel.devKey.replace(/[^A-Z0-9]/gi, ''),
      componentList: setComponentsRequestBody(tmpModel.componentList),
    }

    await updateModel(currentProject?.uid, tmpModel.id, req)
      .then(async (res) => {
        await dispatch(getModels(currentProject?.uid, currentModel?.id))
        message.success(t('saveSuccess'))
        setComponentsSaved(true)
      })
      .catch((e) => {
        message.error(e.response.data.error)
      })
      .then(() => {
        setLoading(false)
      })
  }

  // Memo
  const isDirty = useMemo(() => {
    return loading || JSON.stringify(currentModel) === JSON.stringify(tmpModel)
  }, [loading, currentModel, tmpModel])

  return (
    <>
      {currentProject && currentModel && currentModel.languageMap && (
        <Helmet>
          <title>
            {currentModel.languageMap[currentProject.defaultLang]} ·{' '}
            {t('contentsBuilder')} · {currentProject.name} ·{' '}
            {process.env.REACT_APP_NAME}
          </title>
        </Helmet>
      )}
      {currentProject && modelListInit ? (
        tmpModel ? (
          <div className={''}>
            <TabModels />
            {/* Contents Builder body: 시작 */}
            <div
              id="contentsBuilder-body"
              className={`grow h-full relative lg:pr-60`}>
              <div className="pb-10 space-y-10 lg:pr-8">
                <div className="py-2">
                  {tmpModel?.componentList && tmpModel?.componentList.length ? (
                    <></>
                  ) : (
                    <div className="space-y-2 mb-10">
                      <div>
                        <h1
                          className={
                            'mb-2 text-lg leading-7 flex items-center space-x-4'
                          }>
                          {t('component')}
                        </h1>
                        <p
                          className="mb-0"
                          dangerouslySetInnerHTML={{
                            __html: t('componentDesc'),
                          }}
                        />
                      </div>
                      <div className="flex">
                        <a
                          href={LINK_HELP_COMPONENT}
                          target="_blank"
                          rel="noreferrer"
                          className="flex space-x-1 items-center">
                          <span>{t('learnMoreAboutComponent')}</span>
                          <ExtIcon />
                        </a>
                      </div>
                    </div>
                  )}
                  <DragDropContext onDragEnd={onSortEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          className="space-y-2"
                          {...provided.droppableProps}
                          ref={provided.innerRef}>
                          {tmpModel?.componentList?.map((component, index) => (
                            <Draggable
                              disabled={true}
                              key={component.id}
                              draggableId={(component.id as number).toString()}
                              index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}>
                                  <div>
                                    <ComponentsItem
                                      dragHandler={provided.dragHandleProps}
                                      component={component}
                                      customClassName="bg-white shadow rounded"
                                      disabled={
                                        !!(
                                          tmpModel?.flattenComponentList &&
                                          modelUsage &&
                                          tmpModel.flattenComponentList
                                            .length >=
                                            modelUsage?.component.limit
                                        )
                                      }
                                    />
                                    {provided.placeholder}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {currentProject?.role === 'OWNER' ||
                          currentProject?.role === 'ADMIN' ? (
                            <Draggable
                              key={999999}
                              draggableId={'999999'}
                              isDragDisabled={true}
                              disableInteractiveElementBlocking={true}
                              index={999999}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}>
                                  <div className={`mt-2`}>
                                    <ComponentsAdd
                                      disabled={
                                        !!(
                                          tmpModel?.flattenComponentList &&
                                          modelUsage &&
                                          tmpModel.flattenComponentList
                                            .length >=
                                            modelUsage?.component.limit
                                        )
                                      }
                                    />
                                  </div>
                                  {provided.placeholder}
                                </div>
                              )}
                            </Draggable>
                          ) : (
                            <></>
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                <div className="flex justify-between items-center">
                  {currentProject.role === 'OWNER' ||
                  currentProject.role === 'ADMIN' ? (
                    <div className={'flex items-center space-x-3'}>
                      {process.env.REACT_APP_HIDE_USAGE !== '1' &&
                      currentProject.usage?.type !== 'UNLIMITED' &&
                      modelUsage?.component &&
                      currentModel?.flattenComponentList ? (
                        <Tooltip
                          title={t('noComponentsByModelDesc')}
                          className="cursor-help">
                          <span
                            className="font-normal flex space-x-1 items-center"
                            style={{
                              color:
                                currentModel.flattenComponentList.length >=
                                modelUsage?.component.limit
                                  ? LAYOUT_WARNING_COLOR
                                  : LAYOUT_OKAY_COLOR,
                            }}>
                            <span>
                              {currentModel?.flattenComponentList.length} /{' '}
                              {modelUsage?.component.limit}
                            </span>
                            <InfoCircleOutlined className="mt-px" />
                          </span>
                        </Tooltip>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div
                    className={`transition-all !fixed top-2 ${
                      gnb ? 'right-4 lg:right-10' : 'right-4'
                    }  z-50`}>
                    <Button
                      type={'primary'}
                      disabled={isDirty}
                      className="relative btn-sm-ico-only"
                      loading={loading}
                      icon={<SaveOutlined />}
                      onClick={() => {
                        saveModel()
                      }}
                      title={t('save')}>
                      {t('save')}
                      {isDirty ? (
                        ''
                      ) : (
                        <span
                          className={`animate-ping w-4 h-4 absolute -right-2 -top-2 rounded-full bg-green-500 opacity-100`}></span>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
              {currentProject?.role === 'OWNER' ||
              currentProject?.role === 'ADMIN' ? (
                <>
                  <ComponentsFormModal />
                </>
              ) : (
                <></>
              )}
            </div>
            {/* Contents Builder body: 끝 */}
          </div>
        ) : (
          <div className={'py-5 lg:py-[89px] px-5 lg:px-20'}>
            <div className="">
              <h1 className="text-3xl font-semibold mb-5">{t('model')}</h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('modelDesc'),
                }}
              />
              <div className="mb-10 flex">
                <a
                  href={LINK_HELP_MODEL}
                  target="_blank"
                  rel="noreferrer"
                  className="flex space-x-1 items-center">
                  <span>{t('learnMoreAboutModel')}</span>
                  <ExtIcon />
                </a>
              </div>
              <div className="flex">
                <div>
                  {/* <h3 className={'text-lg font-medium mb-4'}>
                    {t('startCreateModel')}
                  </h3> */}
                  {currentProject?.role === 'OWNER' ||
                  currentProject?.role === 'ADMIN' ? (
                    <div className={'flex justify-center'}>
                      <Button
                        type={'primary'}
                        htmlType={'button'}
                        onClick={() => {
                          dispatch(setModelFormModal(true))
                        }}>
                        {t('addModel')}
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className={'flex justify-center items-center h-screen'}>
          <Spin />
        </div>
      )}
      <CategoriesFormModal />
    </>
  )
}

export default ProjectsModelsComponents
