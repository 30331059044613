import axios from 'axios'
import { EXTERNAL_URL } from '@/configs'

/**
 * Contents API 입력
 */
export const createExternalContents = (modelDevKey, req) => {
  return axios.post(
    `${EXTERNAL_URL}/projects/${process.env.REACT_APP_MEMEX_PROJECT_UID}/models/${modelDevKey}/contents`,
    req,
    {
      headers: {
        'Content-Type': 'application/json',
        'Access-Token': process.env.REACT_APP_MEMEX_ACCESS_KEY as string,
      },
    }
  )
}
