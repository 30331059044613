/**
 * API
 */
export const AUTH_URL = `${process.env.REACT_APP_API_URL}auth`
export const FILES_URL = `${process.env.REACT_APP_API_URL}file`
export const PROJECTS_URL = `${process.env.REACT_APP_API_URL}projects`
export const USAGE_URL = `${process.env.REACT_APP_API_URL}usage`
export const MODELS_URL = `${process.env.REACT_APP_API_URL}models`
export const COMPONENTS_URL = `${process.env.REACT_APP_API_URL}components`
export const API_URL = `${process.env.REACT_APP_API_URL}api`
export const EXTERNAL_URL = `https://api.memexdata.io/memex/external`
// export const EXTERNAL_URL = `${process.env.REACT_APP_API_URL}external`
export const AI_API_URL = `${process.env.REACT_APP_API_AI_URL}api`
export const SYNC_API_URL = `${process.env.REACT_APP_WS_URL}`
