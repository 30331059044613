import { getModels, validateModel } from '@/api'
import { RootState, useAppDispatch } from '@/states'
import { CellInterface } from '@/types'
import {
  CloudUploadOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Button, Modal, message } from 'antd'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { SyncContents } from '../Sync'

export const HeaderContents = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  // Params
  const { projectUid, modelId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  // State (Redux)
  const { projectsState, layoutState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const {
    currentProject,
    currentModel,
    contentsList,
    contentsListInit,
    contentsListLoading,
    contentsPagination,
    contentsSort,
    flattenComponentList,
  } = projectsState
  const { gnb, splitSize } = layoutState

  // State
  const [loading, setLoading] = useState<boolean>(false)
  /* const [modelUsage, setModelUsage] = useState<
    ProjectModelUsageInterface | null | undefined
  >(null) */

  // Memo
  const modelUsage = useMemo(() => {
    return currentProject && currentProject.usage && currentModel
      ? currentProject.usage.modelList.find((m) => m.id === currentModel.id)
      : null
  }, [currentProject, currentModel])

  /**
   * 콘텐츠 추가 열기
   */
  const openNewFormModal = () => {
    if (
      contentsListLoading ||
      loading ||
      (modelUsage && modelUsage.content.current >= modelUsage.content.limit)
    )
      return false

    navigate(
      `/projects/${currentProject?.uid}/${currentModel?.id}/contents?page=${
        contentsPagination.page
      }&size=${contentsPagination.size}&sort=${contentsSort}&q=${
        searchParams.get('q') ? searchParams.get('q') : ''
      }&new=modal`
    )
  }

  /**
   * 검증 & 배포하기
   */
  const validatePublish = (version?: string) => {
    // 비어있는 콘텐츠 확인
    const requiredComponents = flattenComponentList.filter(
      (fc) => fc.option && fc.option.required
    )
    let isValid = true

    contentsList.forEach((ac) => {
      requiredComponents.forEach((rc) => {
        const cellToBeRequired: CellInterface | undefined | null = ac.cellList
          ? ac.cellList.find(
              (cell) => cell.component && cell.component.id === rc.id
            )
          : null

        if (!cellToBeRequired) {
          isValid = false
          return false
        }

        if (
          cellToBeRequired.component &&
          (cellToBeRequired.component.type === 'TITLE' ||
            cellToBeRequired.component.type === 'SINGLE_LINE_TEXT' ||
            cellToBeRequired.component.type === 'LONG_LINE_TEXT')
        ) {
          currentProject?.languageList.forEach((lang, lIdx) => {
            if (
              !cellToBeRequired.languageMap ||
              !cellToBeRequired.languageMap[lang]
            ) {
              isValid = false
              return false
            }
          })
        }

        if (
          !cellToBeRequired ||
          (cellToBeRequired &&
            cellToBeRequired.component &&
            ((cellToBeRequired.component.type !== 'TITLE' &&
              cellToBeRequired.component.type !== 'SINGLE_LINE_TEXT' &&
              cellToBeRequired.component.type !== 'LONG_LINE_TEXT' &&
              cellToBeRequired.component.type !== 'CATEGORY' &&
              cellToBeRequired.component.type !== 'RELATION' &&
              !cellToBeRequired.value) ||
              (cellToBeRequired.component.type === 'CATEGORY' &&
                (!cellToBeRequired.selectorList ||
                  cellToBeRequired.selectorList.length === 0)) ||
              (cellToBeRequired.component.type === 'RELATION' &&
                (!cellToBeRequired.relationList ||
                  cellToBeRequired.relationList.length === 0))))
        ) {
          isValid = false
          return false
        }
      })
    })

    if (!isValid) {
      message.error(t('error.requiredDetected'))
      return false
    }

    Modal.confirm({
      centered: true,
      title: t('validatePublish'),
      icon: <ExclamationCircleOutlined />,
      content: t('validatePublishDesc'),
      okText: t('validatePublish'),
      cancelText: t('cancel'),
      onOk() {
        return new Promise((resolve, reject) => {
          validateModel(currentProject?.uid, currentModel?.id, version)
            .then((res) => {
              message.success(t('validatePublishComplete'))
              dispatch(getModels(currentProject?.uid, currentModel?.id, true))
              resolve(res)
            })
            .catch((e) => {
              message.error(e.response.data.error)
              reject(e)
            })
        }).catch((e) => console.log(e))
      },
      onCancel() {},
    })
  }

  return currentProject ? (
    <div className="flex items-center">
      {contentsListInit && !contentsListLoading && <SyncContents />}
      {process.env.REACT_APP_HIDE_MEMBERS !== '1' &&
      (currentProject.role === 'OWNER' || currentProject.role === 'ADMIN') &&
      flattenComponentList &&
      flattenComponentList.length ? (
        <>
          <Button
            className="btn-sm-ico-only"
            icon={<CloudUploadOutlined />}
            onClick={() => validatePublish('v3')}
            type={'primary'}
            disabled={contentsListLoading}
            title={t('validatePublish')}>
            {t('validatePublish')}
          </Button>
        </>
      ) : (
        <></>
      )}
      {/* Contents 추가: 시작 */}
      {currentProject.role !== 'VIEWER' &&
      flattenComponentList &&
      flattenComponentList.length ? (
        <div
          id="contents-add"
          className={`ml-2.5 ${
            contentsListLoading ||
            loading ||
            (modelUsage &&
              modelUsage.content.current >= modelUsage.content.limit)
              ? 'cursor-not-allowed opacity-50'
              : 'cursor-pointer'
          }`}
          onClick={() => {
            openNewFormModal()
          }}
          title={t('addContents')}>
          <Button
            type={'primary'}
            disabled={
              contentsListLoading ||
              loading ||
              !!(
                modelUsage &&
                modelUsage.content.current >= modelUsage.content.limit
              )
            }
            className={`btn-sm-ico-only flex items-center text-gray-600 ${
              contentsListLoading ||
              loading ||
              (modelUsage &&
                modelUsage.content.current >= modelUsage.content.limit)
                ? 'cursor-not-allowed'
                : 'cursor-pointer'
            }`}
            icon={<PlusOutlined />}>
            {t('addContents')}
          </Button>
        </div>
      ) : (
        <></>
      )}
      {/* Contents 추가: 끝 */}
    </div>
  ) : (
    <></>
  )
}
