import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RootState, useAppDispatch } from '@/states'
import { useNavigate, useParams } from 'react-router'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'

export const LayoutProjectsTab = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // Params
  const { projectUid, modelId } = useParams()

  // State (Redux)
  const { projectsState, authState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { currentProject, modelList } = projectsState
  const { init } = authState

  const items: TabsProps['items'] = [
    { label: t('projectHome'), key: 'projectHome' },
  ]

  if (process.env.REACT_APP_HIDE_MEMBERS !== '1') {
    items.push({
      label: (
        <div className="flex items-center space-x-1">
          <div>
            {t('members')} ({currentProject?.memberList.length})
          </div>
        </div>
      ),
      key: 'members',
    })
  }

  items.push({ label: t('settings'), key: 'settings' })

  if (process.env.REACT_APP_HIDE_USAGE !== '1') {
    items.push({ label: t('usage'), key: 'usage' })
  }

  // Memo
  const activeKey = useMemo(() => {
    const path = location.pathname
    const pathArr = path.split('/')

    return pathArr[pathArr.length - 1]
      ? pathArr[pathArr.length - 1]
      : 'projectHome'
  }, [location.pathname])

  const isProjectDashboard = useMemo(() => {
    return (
      location.pathname === `/projects/${projectUid}` ||
      location.pathname === `/projects/${projectUid}/`
    )
  }, [location.pathname])

  /**
   * Tab 메뉴 변경
   * @param key
   */
  const onChangeTab = (key: string) => {
    navigate(
      key === 'projectHome'
        ? `/projects/${currentProject?.uid}`
        : `/projects/${currentProject?.uid}/${key}`
    )
  }

  return currentProject ? (
    <div
      className={`sticky ${
        isProjectDashboard ? 'top-12 lg:top-24' : 'top-12'
      } z-10 bg-gray-200`}>
      <Tabs
        items={items}
        onChange={onChangeTab}
        defaultActiveKey={activeKey}></Tabs>
    </div>
  ) : (
    <></>
  )
}
