import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Spin, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { RootState, useAppDispatch } from '@/states'
import { MediaList } from '@/components/Media'

import { setCurrentModel } from '@/states/actions'

const ProjectsMediaContents = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState, authState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { currentProject, mediaListInit } = projectsState

  // Effect
  useEffect(() => {
    dispatch(setCurrentModel(null))
  }, [])

  /**
   * 모든 미디어 컨텐츠 다운로드
   */
  const downloadAllMedia = () => {
    Modal.confirm({
      centered: true,
      title: t('massMediaContentsDownloadWarningTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('massMediaContentsDownloadWarningDesc'),
      okText: t('download'),
      cancelText: t('cancel'),
      onOk() {
        window.open(
          `${process.env.REACT_APP_API_FILE_URL}download/bulk/s3/${currentProject?.uid}`,
          '_blank'
        )
      },
      onCancel() {},
    })
  }

  return (
    <>
      {currentProject && (
        <Helmet>
          <title>
            {t('mediaContents')} · {currentProject.name} ·{' '}
            {process.env.REACT_APP_NAME}
          </title>
        </Helmet>
      )}
      {/* 미디어 목록: 시작 */}
      {mediaListInit ? (
        <MediaList />
      ) : (
        <div className={'h-screen flex justify-center items-center'}>
          <Spin />
        </div>
      )}
      {/* 미디어 목록: 끝 */}
    </>
  )
}

export default ProjectsMediaContents
