import { axiosInstance, PROJECTS_URL } from '@/configs'
import { ActionTypesProjects } from '@/states/interfaces'
import { AppDispatch } from '@/states'

/**
 * 프로젝트 카테고리 그룹 불러오기
 * @param projectUid
 * @param initModelId
 * @param isPreserveContents
 * @returns
 */
export const getCategoryGroups = (projectUid) => (dispatch: AppDispatch) => {
  return axiosInstance
    .get(`${PROJECTS_URL}/${projectUid}/groups`)
    .then((res) => {
      const sortedCategories = res.data.sort(function (a, b) {
        return a.order - b.order
      })

      dispatch({
        type: ActionTypesProjects.SET_CATEGORIES_LIST,
        payload: {
          categoriesList: sortedCategories,
        },
      })
    })
    .catch((e) => {
      dispatch({
        type: ActionTypesProjects.SET_CATEGORIES_LIST,
        payload: {
          categoriesList: [],
        },
      })
    })
}

/**
 * 카테고리 그룹 목록 불러오기
 * @param projectUid
 * @returns
 */
export const getCategoryGroupList = (projectUid) => {
  return projectUid
    ? axiosInstance.get(`${PROJECTS_URL}/${projectUid}/groups`)
    : Promise.reject()
}

/**
 * 카테고리 그룹 목록 불러오기
 * @param projectUid
 * @param groupId
 * @returns
 */
export const getCategoryGroup = (projectUid, groupId) => {
  return projectUid
    ? axiosInstance.get(`${PROJECTS_URL}/${projectUid}/groups/${groupId}`)
    : Promise.reject()
}

/**
 * 카테고리 그룹 추가
 * @param projectUid
 * @param req
 * @returns
 */
export const createCategoryGroup = (projectUid, req) => {
  return axiosInstance.post(`${PROJECTS_URL}/${projectUid}/groups`, req)
}

/**
 * 카테고리 그룹 수정
 * @param projectUid
 * @param groupId
 * @param req
 * @returns
 */
export const updateCategoryGroup = (projectUid, groupId, req) => {
  return axiosInstance.put(
    `${PROJECTS_URL}/${projectUid}/groups/${groupId}`,
    req
  )
}

/**
 * 카테고리 그룹 삭제
 * @param projectUid
 * @param groupId
 * @param id
 * @returns
 */
export const deleteCategoryGroup = (projectUid, groupId) => {
  return axiosInstance.delete(`${PROJECTS_URL}/${projectUid}/groups/${groupId}`)
}

/**
 * 카테고리 셀렉터 목록 불러오기
 * @param projectUid
 * @returns
 */
export const getCategorySelectorList = (projectUid, groupdId) => {
  return projectUid
    ? axiosInstance.get(
        `${PROJECTS_URL}/${projectUid}/groups/${groupdId}/selectors`
      )
    : Promise.reject()
}

/**
 * 카테고리 셀렉터 목록 불러오기
 * @param projectUid
 * @param groupId
 * @param selectorId
 * @returns
 */
export const getCategorySelector = (projectUid, groupId, selectorId) => {
  return projectUid
    ? axiosInstance.get(
        `${PROJECTS_URL}/${projectUid}/groups/${groupId}/selectors/${selectorId}`
      )
    : Promise.reject()
}

/**
 * 카테고리 셀렉터 추가
 * @param projectUid
 * @param groupdId
 * @param req
 * @returns
 */
export const createCategorySelector = (projectUid, groupdId, req) => {
  return axiosInstance.post(
    `${PROJECTS_URL}/${projectUid}/groups/${groupdId}/selectors`,
    req
  )
}

/**
 * 카테고리 셀렉터 수정
 * @param projectUid
 * @param groupId
 * @param req
 * @returns
 */
export const updateCategorySelector = (projectUid, groupId, req) => {
  return axiosInstance.put(
    `${PROJECTS_URL}/${projectUid}/groups/${groupId}/selectors`,
    req
  )
}

/**
 * 카테고리 셀렉터 삭제
 * @param projectUid
 * @param groupId
 * @param id
 * @returns
 */
export const deleteCategorySelector = (projectUid, groupId) => {
  return axiosInstance.delete(
    `${PROJECTS_URL}/${projectUid}/groups/${groupId}/selectors`
  )
}
