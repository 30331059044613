import { updatePublicFile, uploadFileToS3 } from '@/api'
import { RootState } from '@/states'
import { FileResponseInterface } from '@/types'
import CheckList from '@editorjs/checklist'
import CodeTool from '@editorjs/code'
import Delimiter from '@editorjs/delimiter'
import Embed from '@editorjs/embed'
import Header from '@editorjs/header'
import Image from '@editorjs/image'
import InlineCode from '@editorjs/inline-code'
import LinkTool from '@editorjs/link'
import NestedList from '@editorjs/nested-list'
import Quote from '@editorjs/quote'
import RawTool from '@editorjs/raw'
import SimpleImage from '@editorjs/simple-image'
import Table from '@editorjs/table'
import Underline from '@editorjs/underline'
import { message } from 'antd'
import { UploadFile } from 'antd/es/upload/interface'
import axios, { AxiosResponse, CancelTokenSource } from 'axios'
import React from 'react'
import { createReactEditorJS } from 'react-editor-js'
import { shallowEqual, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
let cancelToken: CancelTokenSource

const ReactEditorJS = createReactEditorJS()

interface EditorJsProps {
  editorCore: any
  handleInstance: any
  onSave: any
  readOnly?: boolean
}

export const EditorJs = ({
  editorCore,
  handleInstance,
  onSave,
  readOnly = false,
}: EditorJsProps) => {
  const handleInitialize = React.useCallback(async (instance) => {
    handleInstance(instance)
  }, [])
  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject, currentModel } = projectsState

  const EDITOR_JS_TOOLS = {
    embed: Embed,
    header: Header,
    // list: List,
    list: {
      class: NestedList,
      inlineToolbar: true,
      config: {
        defaultStyle: 'unordered',
      },
    },
    linkTool: LinkTool,
    image: {
      class: Image,
      config: {
        uploader: {
          uploadByFile(file) {
            // Cancel duplicated requests
            if (typeof cancelToken !== typeof undefined) {
              cancelToken.cancel()
            }
            cancelToken = axios.CancelToken.source()

            const fileList: UploadFile[] = []
            fileList.push(file)

            // 파일 업로드
            return uploadFileToS3(fileList, cancelToken, true).then((res) => {
              const resData = (res as AxiosResponse)
                .data as FileResponseInterface[]

              const fileId = resData[0].fileId

              return updatePublicFile(
                currentProject?.uid,
                currentModel?.id,
                fileId
              )
                .then((res) => {
                  onSave()

                  return {
                    success: 1,
                    file: {
                      url: res.data.path,
                    },
                  }
                })
                .catch((e) => {
                  message.error(e.response.data.error)
                })
            })
          },
        },
      },
    },
    quote: Quote,
    checklist: CheckList,
    delimiter: Delimiter,
    inlineCode: InlineCode,
    simpleImage: SimpleImage,
    table: Table,
    raw: RawTool,
    underline: Underline,
    code: CodeTool,
    /* Marker: {
      class: Marker,
      shortcut: 'CMD+SHIFT+M',
    }, */
  }

  return (
    <ReactEditorJS
      holder={uuidv4()}
      onInitialize={handleInitialize}
      // @ts-ignore
      tools={EDITOR_JS_TOOLS}
      placeholder={``}
      readOnly={readOnly}
    />
  )
}
