import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Checkbox, InputNumber, Select } from 'antd'
import {
  ComponentOptionType,
  ComponentFieldType,
  ComponentInterface,
} from '@/types'
import { MAX_TEXT_SIZE } from '@/configs'
import { RootState, useAppDispatch } from '@/states'

interface ComponentsTypeItemProps {
  component: ComponentInterface | null
  componentType: ComponentFieldType
  componentOptionType: ComponentOptionType
  onChangeOption: (
    key: string,
    value: boolean | number | string | object
  ) => void
  defaultValue: boolean | number | string | object
}

export const ComponentsOption = ({
  component,
  componentType,
  componentOptionType,
  onChangeOption,
  defaultValue,
}: ComponentsTypeItemProps) => {
  const { t, i18n } = useTranslation()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )

  const { tmpModel, flattenComponentList } = projectsState

  /**
   * 해당 옵션 선택
   * @param e
   * @param key
   */
  const onToggle = (e, key) => {
    onChangeOption(key, e.target.checked)
  }

  // Memo
  const isMultiLanguageSelected = React.useMemo(() => {
    return !!(component && typeof component.id === 'number')
  }, [component])

  return (
    <>
      {/* 다국어: 시작 */}
      {componentOptionType === 'multiLanguage' ? (
        <div>
          <div className="flex items-center space-x-2">
            <Checkbox
              checked={
                isMultiLanguageSelected
                  ? isMultiLanguageSelected && !component?.type.includes('MONO')
                  : (defaultValue as boolean)
              }
              disabled={isMultiLanguageSelected}
              onChange={(e) => onToggle(e, 'multiLanguage')}>
              {t('optionsType.multiLanguage.name')}
            </Checkbox>
          </div>
          <p className="text-xs text-gray-500 mb-0">
            {t('optionsType.multiLanguage.desc')}
          </p>
        </div>
      ) : (
        <></>
      )}
      {/* 다국어: 끝 */}
      {/* 필수: 시작 */}
      {componentOptionType === 'required' ? (
        <div>
          <div className="flex items-center space-x-2">
            <Checkbox
              checked={defaultValue as boolean}
              onChange={(e) => onToggle(e, 'required')}>
              {t('optionsType.required.name')}
            </Checkbox>
          </div>
          <p className="text-xs text-gray-500 mb-0">
            {t('optionsType.required.desc')}
          </p>
        </div>
      ) : (
        <></>
      )}
      {/* 필수: 끝 */}
      {/* 고유값: 시작 */}
      {componentOptionType === 'unique' ? (
        <div>
          <div className="flex items-center space-x-2">
            <Checkbox
              checked={defaultValue as boolean}
              onChange={(e) => onToggle(e, 'unique')}>
              {t('optionsType.unique.name')}
            </Checkbox>
          </div>
          <p className="text-xs text-gray-500 mb-0">
            {t('optionsType.unique.desc')}
          </p>
        </div>
      ) : (
        <></>
      )}
      {/* 고유값: 끝 */}
      {/* 복수등록: 시작 */}
      {componentOptionType === 'multiple' ? (
        <div>
          <div className="flex items-center space-x-2">
            <Checkbox
              checked={defaultValue as boolean}
              onChange={(e) => onToggle(e, 'multiple')}>
              {t('optionsType.multiple.name')}
            </Checkbox>
          </div>
          <p className="text-xs text-gray-500 mb-0">
            {t('optionsType.multiple.' + componentType + '.desc')}
          </p>
        </div>
      ) : (
        <></>
      )}
      {/* 복수등록: 끝 */}
      {/* 미디어 복수등록: 시작 */}
      {componentOptionType === 'multipleMedia' ? (
        <div>
          <div className="flex items-center space-x-2">
            <Checkbox
              checked={defaultValue as boolean}
              onChange={(e) => onToggle(e, 'multipleMedia')}>
              {t('optionsType.multipleMedia.name')}
            </Checkbox>
          </div>
          <p className="text-xs text-gray-500 mb-0">
            {t('optionsType.multipleMedia.desc')}
          </p>
        </div>
      ) : (
        <></>
      )}
      {/* 미디어 복수등록: 끝 */}
      {/* HTML 태그 허용: 시작 */}
      {componentOptionType === 'allowHtml' ? (
        <div>
          <div className="flex items-center space-x-2">
            <Checkbox
              checked={defaultValue as boolean}
              onChange={(e) => onToggle(e, 'allowHtml')}>
              {t('optionsType.allowHtml.name')}
            </Checkbox>
          </div>
          <p className="text-xs text-gray-500 mb-0">
            {t('optionsType.allowHtml.desc')}
          </p>
        </div>
      ) : (
        <></>
      )}
      {/* HTML 태그 허용: 끝 */}
      {/* 최소 텍스트 길이: 시작 */}
      {componentOptionType === 'minText' ? (
        <div className="space-y-1">
          <div>{t('optionsType.minText.name')}</div>
          <div>
            <InputNumber
              min={0}
              max={MAX_TEXT_SIZE}
              defaultValue={defaultValue as number}
              onChange={(val) => onChangeOption('minText', val as number)}
            />
          </div>
          <p className="text-xs text-gray-500 mb-0">
            {t('optionsType.minText.desc')}
          </p>
        </div>
      ) : (
        <></>
      )}
      {/* 최소 텍스트 길이: 끝 */}
      {/* 최대 텍스트 길이: 시작 */}
      {componentOptionType === 'maxText' ? (
        <div className="space-y-1">
          <div>{t('optionsType.maxText.name')}</div>
          <div>
            <InputNumber
              min={0}
              max={MAX_TEXT_SIZE}
              value={defaultValue as number}
              onChange={(val) => onChangeOption('maxText', val as number)}
            />
          </div>
          <p className="text-xs text-gray-500 mb-0">
            {t('optionsType.maxText.desc')}
          </p>
        </div>
      ) : (
        <></>
      )}
      {/* 최대 텍스트 길이: 끝 */}
      {/* 최소 숫자: 시작 */}
      {componentOptionType === 'min' ? (
        <div className="space-y-1">
          <div>{t('optionsType.min.name')}</div>
          <div>
            <InputNumber
              defaultValue={defaultValue as number}
              onChange={(val) => onChangeOption('min', val as number)}
            />
          </div>
          <p className="text-xs text-gray-500 mb-0">
            {t('optionsType.min.desc')}
          </p>
        </div>
      ) : (
        <></>
      )}
      {/* 최소 숫자: 끝 */}
      {/* 최대 숫자: 시작 */}
      {componentOptionType === 'max' ? (
        <div className="space-y-1">
          <div>{t('optionsType.max.name')}</div>
          <div>
            <InputNumber
              defaultValue={defaultValue as number}
              onChange={(val) => onChangeOption('max', val as number)}
            />
          </div>
          <p className="text-xs text-gray-500 mb-0">
            {t('optionsType.max.desc')}
          </p>
        </div>
      ) : (
        <></>
      )}
      {/* 최대 숫자: 끝 */}
      {/* 저장 가능 날짜 포맷: 시작 */}
      {componentOptionType === 'dateFormats' ? (
        <div className="space-y-1">
          <div>
            {t('optionsType.dateFormats.name')}{' '}
            <span className="text-red-500">*</span>
          </div>
          <div>
            <Select
              defaultValue={defaultValue ? defaultValue : []}
              onChange={(val) => onChangeOption('dateFormats', val)}
              style={{ width: '100%' }}>
              <Select.Option value="date">Date (yyyy-mm-dd)</Select.Option>
              <Select.Option value="year">Year (yyyy)</Select.Option>
              <Select.Option value="month">Month (mm)</Select.Option>
              <Select.Option value="time">Time (HH:mm:ss)</Select.Option>
            </Select>
          </div>
          <p className="text-xs text-gray-500 mb-0">
            {t('optionsType.dateFormats.desc')}
          </p>
        </div>
      ) : (
        <></>
      )}
      {/* 저장 가능 날짜 포맷: 끝 */}
    </>
  )
}
