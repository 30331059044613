import React, { useEffect, useState } from 'react'
import { Modal, Button, message } from 'antd'
import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '@/states'
import { useTranslation } from 'react-i18next'
import { LAYOUT_MODAL_WIDTH } from '@/configs'
import { RelationSelector } from '@/components/Contents'
import {
  CellInterface,
  ComponentInterface,
  ContentsInterface,
  RelationInterface,
} from '@/types'
import {
  updateContentsItem,
  setContentsRelationSelectorModal,
} from '@/states/actions'
import { getContentsItem, updateContents } from '@/api'

interface ContentsRelationProps {
  onChangeContents?: () => void
}

export const RelationSelectorModal = ({
  onChangeContents,
}: ContentsRelationProps) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState, modalsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      modalsState: state.modals,
    }),
    shallowEqual
  )
  const { currentProject, currentModel, contentsList, flattenComponentList } =
    projectsState
  const {
    contentsRelationSelectorModal,
    cellInfo,
    componentInfo,
    contentsInfo,
  } = modalsState

  // State
  const [component, setComponent] = useState<
    ComponentInterface | null | undefined
  >(null)
  const [relationList, setRelationList] = useState<
    (ContentsInterface | RelationInterface)[]
  >([])
  const [cell, setCell] = useState<CellInterface | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  // Effect
  useEffect(() => {
    if (cellInfo && flattenComponentList) {
      setComponent(
        flattenComponentList.find((comp) => comp.id === cellInfo?.component?.id)
      )
    }
  }, [cellInfo, flattenComponentList])

  useEffect(() => {
    if (contentsRelationSelectorModal) {
      if (cellInfo) {
        setCell(cellInfo)

        if (cellInfo.relationList) {
          setRelationList(cellInfo.relationList)
        }
      } else if (componentInfo && contentsInfo) {
        setCell({
          component: {
            id: componentInfo.id as number,
            order: componentInfo.order as number,
            type: componentInfo.type,
          },
          content: {
            // @ts-ignore
            uid: contentsInfo?.uid,
          },
          relationList: [],
          option: componentInfo?.option,
        })
      }
    } else {
      setRelationList([])
    }
  }, [contentsRelationSelectorModal])

  /**
   * 미디어 변경
   * @param componentId
   * @param key
   * @param value
   */
  const onCellChange = (value) => {
    if (cell) {
      const updatedCell = JSON.parse(JSON.stringify(cell))
      updatedCell.relationList = value
      updatedCell.relationUidList = value.map((m) => m.uid)
      setCell(updatedCell)

      setRelationList(value)
    }
  }

  /**
   * 저장
   * @returns
   */
  const onSaveRelation = () => {
    if (!cell) return

    const updatedContents = JSON.parse(JSON.stringify(contentsList))
    const contentsItem = updatedContents.find(
      (cn) => cn.uid === contentsInfo?.uid
    )
    const contentsIdx = updatedContents.indexOf(contentsItem)
    const cellItem = contentsItem.cellList?.find((cl) => cl.uid === cell.uid)
    const cellIdx = contentsItem.cellList?.indexOf(cellItem)

    // 기존 cell
    if (cellIdx >= 0) {
      contentsItem.cellList[cellIdx]['relationList'] = cell.relationList
      contentsItem.cellList[cellIdx]['relationUidList'] = cell.relationUidList
    }
    // cell 추가
    else {
      if (!contentsItem.cellList) contentsItem.cellList = []
      contentsItem.cellList.push(cell)
    }

    contentsItem.cellList[cellIdx] = cellItem
    updatedContents[contentsIdx] = contentsItem

    setLoading(true)

    updateContents(
      currentProject?.uid,
      currentModel?.id,
      contentsItem.uid,
      contentsItem
    )
      .then((res) => {
        getContentsItem(
          currentProject?.uid,
          currentModel?.id,
          contentsItem.uid
        ).then((res) => {
          setLoading(false)
          dispatch(setContentsRelationSelectorModal(false))
          dispatch(updateContentsItem(res.data))
        })
      })
      .catch((e) => {
        message.error(e.response.data.error)
        setLoading(false)
      })
  }

  return (
    <Modal
      width={LAYOUT_MODAL_WIDTH}
      closeIcon={<CloseCircleOutlined title={t('close')} />}
      open={contentsRelationSelectorModal}
      onCancel={() => dispatch(setContentsRelationSelectorModal(false))}
      title={t('editRelationContents')}
      footer={[
        <div key={'footer'} className={'flex justify-between items-center'}>
          <div>
            <Button
              type="primary"
              ghost
              onClick={() => dispatch(setContentsRelationSelectorModal(false))}>
              {t('cancel')}
            </Button>
          </div>
          <div>
            <Button
              type={'primary'}
              icon={<CheckOutlined />}
              disabled={loading}
              loading={loading}
              onClick={() => onSaveRelation()}>
              {t('save')}
            </Button>
          </div>
        </div>,
      ]}>
      {currentModel && componentInfo ? (
        <div>
          <RelationSelector
            component={componentInfo}
            cell={cell}
            relationList={relationList}
            onSelect={(relationList) => {
              onCellChange(relationList)
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </Modal>
  )
}
