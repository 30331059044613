import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'
import { StarOutlined, StarFilled } from '@ant-design/icons'
import { RootState, useAppDispatch } from '@/states'
import {
  getFavorites,
  addFavoriteContents,
  removeFavoriteContents,
} from '@/api'
import { ContentsInterface, AuthFavoriteContentInterface } from '@/types'
import { useParams } from 'react-router'

export const ContentsFavorite = ({
  contents,
}: {
  contents: ContentsInterface | AuthFavoriteContentInterface
}) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // Params
  const { modelId } = useParams()

  // State (Redux)
  const { projectsState, authState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )

  const { modelList, currentProject, contentsList } = projectsState
  const { init, me, favorites } = authState

  // Memo
  const isFavorite: boolean = useMemo(() => {
    return favorites.contents
      ? !!favorites.contents.find((item) => item.uid === contents.uid)
      : false
  }, [favorites.contents, contents])

  const contentsModelId = useMemo(() => {
    // @ts-ignore
    return contents.model ? contents.model.id : 0
  }, [contents])

  /**
   * 즐겨찾기 제거
   * @param e
   */
  const removeFavoritesModel = (e) => {
    if (e.target.closest('.ico-favorite')) {
      e.preventDefault()
    }

    removeFavoriteContents(
      currentProject?.uid as string,
      modelId ? Number(modelId) : contentsModelId,
      contents.uid as string
    )
      .then((res) => {
        dispatch(getFavorites())
      })
      .catch((e) => {
        message.error(e.response.data.error)
      })
  }

  /**
   * 즐겨찾기 추가
   * @param e
   * @param modelId
   */
  const addFavoritesContent = (e) => {
    if (e.target.closest('.ico-favorite')) {
      e.preventDefault()
    }

    addFavoriteContents(
      currentProject?.uid as string,
      modelId ? Number(modelId) : contentsModelId,
      contents.uid as string
    )
      .then((res) => {
        dispatch(getFavorites())
      })
      .catch((e) => {
        message.error(e.response.data.error)
      })
  }

  return process.env.REACT_APP_HIDE_FAVORITES !== '1' ? (
    isFavorite ? (
      <StarFilled
        style={{ color: '#facc15' }}
        onClick={(e) => {
          removeFavoritesModel(e)
        }}
      />
    ) : (
      <div className="hover-target-flex flex items-center">
        <StarOutlined
          onClick={(e) => {
            addFavoritesContent(e)
          }}
        />
      </div>
    )
  ) : (
    <></>
  )
}
