import { AppDispatch } from '@/states'
import { ActionTypesProjects } from '@/states/interfaces'

/**
 * 모델 목록 설정
 * @param list
 */
export const setModelsList = (list) => (dispatch: AppDispatch) => {
  dispatch({
    type: ActionTypesProjects.SET_MODEL_LIST,
    payload: {
      models: list,
    },
  })
}

/**
 * 현재 모델 설정
 * @param data
 * @param isPreserveContents
 * @returns {function(*): *}
 */
export const setCurrentModel =
  (data, isPreserveContents = false) =>
  (dispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_CURRENT_MODEL,
      payload: {
        model: data,
        isPreserveContents: isPreserveContents,
      },
    })
  }

/**
 * 임시 모델 설정
 * @param data
 
 * @returns {function(*): *}
 */
export const setTmpModel = (data) => (dispatch) => {
  return dispatch({
    type: ActionTypesProjects.SET_TMP_MODEL,
    payload: {
      model: JSON.parse(JSON.stringify(data)),
    },
  })
}
