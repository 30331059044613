import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ComponentTypeInterface } from '@/types'

interface ComponentsTypeItemProps {
  componentType: ComponentTypeInterface
  onClick?: (e: React.MouseEvent) => void
  disabled?: boolean
}

export const ComponentsTypeItem = ({
  componentType,
  onClick,
  disabled,
}: ComponentsTypeItemProps) => {
  const { t, i18n } = useTranslation()

  return (
    <li
      onClick={onClick}
      className={`${
        disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
      } bg-white flex items-center space-x-2 border border-gray-300 shadow rounded py-2 px-3`}>
      <div className="w-6 h-6 bg-gray-200 text-xs flex-none flex justify-center items-center text-gray-500">
        <componentType.icon></componentType.icon>
      </div>
      <div>
        <div className="leading-5">
          {t('componentTypes.' + componentType.type + '.name')}
        </div>
        <div className="text-xs text-gray-600 leading-5">
          {t('componentTypes.' + componentType.type + '.desc')}
        </div>
      </div>
    </li>
  )
}
