import React, { useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, message, Modal } from 'antd'
import {
  CloudUploadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import axios from 'axios'
import { RootState, useAppDispatch } from '@/states'
import { toggleBusy } from '@/states/actions'
import { getModels, validateModel } from '@/api'

export const PublishAll = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { layoutState, projectsState, authState } = useSelector(
    (state: RootState) => ({
      layoutState: state.layout,
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { gnb, stretch, busy } = layoutState
  const { currentProject, modelList } = projectsState
  const { init, me } = authState

  /**
   * 모든 모델 배포하기
   * @param version
   */
  const validatePublishAllModels = (version?: string) => {
    if (!modelList || busy || !currentProject) return

    Modal.confirm({
      centered: true,
      title: t('validatePublish'),
      icon: <ExclamationCircleOutlined />,
      content: t('validatePublishDesc'),
      okText: t('validatePublish'),
      cancelText: t('cancel'),
      onOk() {
        dispatch(toggleBusy(true))
        const validateReqs: any[] = []

        // 아이콘 변경
        const iconsLoading = document.querySelectorAll('.model-ico-loading')
        const iconsUpload = document.querySelectorAll('.model-ico-upload')
        const btnPublish = document.querySelectorAll('.model-publish-btn')

        iconsLoading.forEach((icon, idx) => {
          btnPublish[idx].setAttribute('disabled', 'disabled')
          icon.classList.remove('!hidden')
          iconsUpload[idx].classList.add('!hidden')
        })

        modelList.forEach(async (model, idx) => {
          const req = validateModel(currentProject?.uid, model.id, version)

          validateReqs.push(req)

          req
            .then((res) => {
              const btn = document.getElementById(`model-btn-${model.id}`)
              const loadingIcon = document.getElementById(
                `model-ico-loading-${model.id}`
              )
              const uploadIcon = document.getElementById(
                `model-ico-upload-${model.id}`
              )

              btn?.removeAttribute('disabled')
              loadingIcon?.classList.add('!hidden')
              uploadIcon?.classList.remove('!hidden')
            })
            .catch((e) => {
              dispatch(toggleBusy(false))
            })
        })

        return new Promise((resolve, reject) => {
          axios
            .all([...validateReqs])
            .then(
              axios.spread((...responses) => {
                setTimeout(() => {
                  message.success(t('validatePublishComplete'))
                  dispatch(getModels(currentProject?.uid, null, true))
                  dispatch(toggleBusy(false))
                  resolve(responses)
                })
              })
            )
            .catch((e) => {
              dispatch(toggleBusy(false))
              message.error(e.response.data.error)
              reject(e)
              // completeModelList = []
            })
        }).catch((e) => {
          dispatch(toggleBusy(false))
          // completeModelList = []
        })
      },
      onCancel() {},
    })
  }

  return (
    <div>
      <Button
        type="primary"
        className="btn-sm-ico-only"
        icon={<CloudUploadOutlined />}
        disabled={
          currentProject?.role !== 'OWNER' && currentProject?.role !== 'ADMIN'
        }
        onClick={() => validatePublishAllModels('v3')}>
        {t('publishAllModels')}
      </Button>
    </div>
  )
}
