import { AuthState, ActionTypesAuth, ActionsAuth } from '@/states/interfaces'
import { setUserInfo } from '@/utils/auth'

export const initialState: AuthState = {
  init: false,
  me: null,
  tmpCode: '',
  favorites: {
    projects: [],
    models: [],
    contents: [],
  },
}

interface HydratePayload {
  auth: AuthState
}

const authReducer = (state = initialState, action: ActionsAuth): AuthState => {
  switch (action.type) {
    // 프로필 설정
    case ActionTypesAuth.SET_PROFILE: {
      setUserInfo(action.payload.profile)

      return {
        ...state,
        init: true,
        me: action.payload.profile,
      }
    }
    // 임시 회원인증코드 설정
    case ActionTypesAuth.SET_TMP_CODE: {
      return {
        ...state,
        tmpCode: action.payload.code,
      }
    }
    // 즐겨찾기
    case ActionTypesAuth.SET_FAVORITES: {
      return {
        ...state,
        favorites: action.payload.favorites,
      }
    }
    default:
      return state
  }
}

export default authReducer
