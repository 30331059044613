import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, Input, message, Cascader } from 'antd'
import {
  RobotOutlined,
  TranslationOutlined,
  HighlightOutlined,
  KeyOutlined,
  FileDoneOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { RootState } from '@/states'
import { callTextTransform } from '@/api'
import { ComponentInterface, LanguagesAvailable } from '@/types'
import { languagesConfig } from '@/configs'

interface Option {
  value: string | number
  label?: React.ReactNode
  disabled?: boolean
  children?: Option[]
}

/**
 * Text 변환 AI
 * @param param0
 * @returns
 */
export const TextTransform = ({
  text,
  component,
  onTextChange,
}: {
  text: string
  component: ComponentInterface
  onTextChange: (str: string) => void
}) => {
  const { t, i18n } = useTranslation()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject } = projectsState

  // Memo
  const isValidAiPlan = useMemo(() => {
    return (
      !currentProject ||
      (currentProject &&
        (currentProject.price === 'PRO' ||
          currentProject.price === 'UNLIMITED'))
    )
  }, [currentProject])

  // 선택 Option
  const options: Option[] = [
    {
      value: 'translation',
      label: (
        <div className="flex items-center space-x-1">
          <TranslationOutlined />
          <div>{t('translate')}</div>
        </div>
      ),
      children: languagesConfig.availableLanguages.map((lang) => {
        return {
          value: lang.code,
          label: lang.name,
        }
      }),
    },
    {
      value: 'correction',
      label: (
        <div className="flex items-center space-x-1">
          <HighlightOutlined />
          <div>{t('correction')}</div>
        </div>
      ),
    },
    {
      value: 'summary',
      label: (
        <div className="flex items-center space-x-1">
          <FileDoneOutlined />
          <div>{t('summary')}</div>
        </div>
      ),
    },
    {
      value: 'keywords',
      label: (
        <div className="flex items-center space-x-1">
          <KeyOutlined />
          <div>{t('keywords')}</div>
        </div>
      ),
    },
  ]

  // State
  const [complete, setComplete] = React.useState<boolean>(false)
  const [busy, setBusy] = React.useState<boolean>(false)
  const [resultText, setResultText] = React.useState<string>('')

  /**
   * 텍스트 변환 옵션 변경
   * @param value
   */
  const onChangeTextOption = (value) => {
    const type = value[0]
    const lang = value[1]

    callOpenAI(type, lang)
  }

  /**
   * 모델 구성 추천 AI 호출
   * @returns
   */
  const callOpenAI = (type: string, lang: LanguagesAvailable) => {
    if (busy) return
    setBusy(true)
    message.loading(t('transformingText'), 0)

    try {
      const req = {
        projectUid: currentProject?.uid,
        type,
        text,
        lang,
      }

      callTextTransform(req)
        .then((res) => {
          setComplete(true)
          setResultText(res.data)
          message.destroy()
          message.success(t('textTransformationComplete'))
        })
        .catch((e) => {
          message.destroy()
          message.error(
            e.response.data.message === 'PLAN_NOT_ALLOWED'
              ? t('error.planNotAllowed')
              : t('error.wrong')
          )
        })
        .then(() => {
          setBusy(false)
        })
    } catch (e) {
      message.destroy()
      message.error(t('error.wrong'))
      setBusy(false)
    }
  }

  /**
   * 수정된 텍스트 적용
   */
  const applyText = () => {
    onTextChange(resultText)
    setComplete(false)
    setBusy(false)
    setResultText('')
  }

  /**
   * 문자열 복사
   * @returns {boolean}
   */
  const copyStr = (str) => {
    const string = str
    let textarea
    let result

    try {
      textarea = document.createElement('textarea')
      textarea.setAttribute('readonly', true)
      textarea.setAttribute('contenteditable', true)
      textarea.style.position = 'fixed' // prevent scroll from jumping to the bottom when focus is set.
      textarea.value = string

      document.body.appendChild(textarea)

      textarea.focus()
      textarea.select()

      const range = document.createRange()
      range.selectNodeContents(textarea)

      const sel = window.getSelection()
      sel?.removeAllRanges()
      sel?.addRange(range)

      textarea.setSelectionRange(0, textarea.value.length)
      result = document.execCommand('copy')

      message.success(t('copied'))
    } catch (err) {
      // console.error(err)
      result = null
    } finally {
      document.body.removeChild(textarea)
    }
  }

  return (
    <>
      <div className="flex space-x-4 items-center">
        <Cascader
          size="small"
          className={`!h-auto transition-all ${
            text ? 'opaicty-100' : 'opacity-0'
          }`}
          disabled={!text || !isValidAiPlan}
          bordered={false}
          options={options}
          onChange={onChangeTextOption}
          placeholder={
            <div className="h-6 flex items-center space-x-1 text-xs cursor-pointer">
              <span className="flex items-center text-secondary">
                <RobotOutlined />
              </span>
              <p className="mb-0">{t('editTextWithAi')}</p>
            </div>
          }
          value={[]}
        />
        {text && !isValidAiPlan ? (
          <div className="pt-0.5 flex items-center space-x-1">
            <p className="text-xxs mb-0 opacity-70">{t('aiNeedProPlanNow')}</p>
            <div
              className="text-xxs cursor-pointer text-secondary"
              onClick={() => {
                // @ts-ignore
                ChannelIO('openChat')
              }}>
              {t('contactUs')}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <>
        {complete || busy ? (
          <div className="mt-2 p-3 border border-gray-400 rounded-lg">
            <div className="flex items-center text-secondary mb-2">
              <RobotOutlined />
            </div>
            {/* Title or Single Line Text - AI: 시작 */}
            {component.type === 'TITLE' ||
            component.type === 'SINGLE_LINE_TEXT' ||
            component.type === 'SINGLE_LINE_TEXT_MONO' ? (
              <div>
                <div>
                  <Input
                    value={complete ? resultText : text}
                    maxLength={component.option?.maxText}
                    readOnly
                    showCount
                    suffix={busy ? <LoadingOutlined /> : <></>}
                    disabled={busy}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {/* Title or Single Line Text - AI: 끝 */}
            {/* Long Line Text - AI: 시작 */}
            {component.type === 'LONG_LINE_TEXT' ||
            component.type === 'LONG_LINE_TEXT_MONO' ? (
              <div>
                <div>
                  <Input.TextArea
                    rows={5}
                    value={complete ? resultText : text}
                    maxLength={component.option?.maxText}
                    readOnly
                    showCount
                    /* suffix={!busy ? <LoadingOutlined /> : <></>} */
                    disabled={busy}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {/* Long Line Text - AI: 끝 */}
            {busy && <p className="pt-1 mb-0 text-xs">{t('transforming')}</p>}
            {complete && (
              <div className="pt-2 flex justify-between items-center">
                <div className="flex space-x-1">
                  <Button
                    size="small"
                    className="!text-xs"
                    disabled={busy || !resultText}
                    onClick={() => applyText()}>
                    {t('apply')}
                  </Button>
                  <Button
                    size="small"
                    className="!text-xs"
                    disabled={busy || !resultText}
                    onClick={() => copyStr(resultText)}>
                    {t('copy')}
                  </Button>
                  <Button
                    size="small"
                    className="!text-xs"
                    onClick={() => {
                      setComplete(false)
                      setBusy(false)
                      setResultText('')
                    }}>
                    {t('close')}
                  </Button>
                  <p className="text-xxs mb-0 opacity-50 leading-6">
                    {t('aiMayNotPerfect')}
                  </p>
                </div>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
      </>
    </>
  )
}
