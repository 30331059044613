export const Logo = ({
  isIcon,
  isVertical,
  customClass,
}: {
  isIcon?: boolean
  isVertical?: boolean
  customClass?: string
}) => {
  return (
    <div
      className={`bg-contain bg-no-repeat bg-center ${customClass}`}
      style={{
        backgroundImage: `url(${
          isIcon
            ? process.env.REACT_APP_LOGO_ICON_IMAGE_URL
            : isVertical && process.env.REACT_APP_LOGO_VERTICAL_IMAGE_URL
            ? process.env.REACT_APP_LOGO_VERTICAL_IMAGE_URL
            : process.env.REACT_APP_LOGO_IMAGE_URL
        })`,
      }}
    />
  )
}
