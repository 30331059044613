import { AppDispatch } from '@/states'
import { ActionTypesProjects } from '@/states/interfaces'
import { axiosInstance, PROJECTS_URL } from '@/configs'

/**
 * 원본 미디어 파일 접근
 * @param projectUid
 * @param mediaId
 * @returns
 */
export const getOriginalMedia = (projectUid, mediaId) => {
  return axiosInstance.get(
    `${PROJECTS_URL}/${projectUid}/media/${mediaId}/original`
  )
}

/**
 * 프로젝트 미디어 불러오기
 * @param projectUid
 * @returns
 */
export const getMediaListSearch = (projectUid, req) => {
  return axiosInstance.post(`${PROJECTS_URL}/${projectUid}/media/search`, req)
}

/**
 * 프로젝트 미디어 불러오기
 * @param projectUid
 * @returns
 */
export const getMedias = (projectUid) => (dispatch: AppDispatch) => {
  return axiosInstance
    .get(`${PROJECTS_URL}/${projectUid}/media`)
    .then((res) => {
      dispatch({
        type: ActionTypesProjects.SET_MEDIA_LIST,
        payload: {
          mediaList: res.data.list,
        },
      })
    })
    .catch((e) => {
      dispatch({
        type: ActionTypesProjects.SET_MEDIA_LIST,
        payload: {
          mediaList: [],
        },
      })
    })
}

/**
 * 미디어 정보 불러오기
 * @param projectUid
 * @param id
 * @returns
 */
export const getMedia = (projectUid, id) => {
  return axiosInstance.get(`${PROJECTS_URL}/${projectUid}/media/${id}`)
}

/**
 * 미디어 추가
 * @param projectUid
 * @param req
 * @returns
 */
export const createMedia = (projectUid, req) => {
  return axiosInstance.post(`${PROJECTS_URL}/${projectUid}/media`, req)
}

/**
 * 미디어 여러개 추가
 * @param projectUid
 * @param req
 * @returns
 */
export const createMultiMedia = (projectUid, req) => {
  return axiosInstance.post(`${PROJECTS_URL}/${projectUid}/media/bulk`, req)
}

/**
 * 미디어 수정
 * @param projectUid
 * @param id
 * @param req
 * @returns
 */
export const updateMedia = (projectUid, id, req) => {
  return axiosInstance.put(`${PROJECTS_URL}/${projectUid}/media/${id}`, req)
}

/**
 * 미디어 삭제
 * @param projectUid
 * @param id
 * @returns
 */
export const deleteMedia = (projectUid, id) => {
  return axiosInstance.delete(`${PROJECTS_URL}/${projectUid}/media/${id}`)
}

/**
 * 미디어 여러개 삭제
 * @param projectUid
 * @param id
 * @param idArray
 * @returns
 */
export const deleteMultiMedia = (projectUid, idArray) => {
  return axiosInstance.delete(`${PROJECTS_URL}/${projectUid}/media`, {
    data: idArray,
  })
}

/**
 * 파일 이름으로 미디어  ID 검색
 * @param projectUid
 * @param fileNameId
 * @returns
 */
export const getMediaIdByFileName = (projectUid, fileNameId) => {
  return axiosInstance.post(
    `${PROJECTS_URL}/${projectUid}/media/files`,
    fileNameId
  )
}

/**
 * 스트리밍용 비디오 변환
 * @param projectUid
 * @param id
 * @param req
 * @returns
 */
export const convertToVideoStreaming = (projectUid, id, req) => {
  return axiosInstance.put(
    `${PROJECTS_URL}/${projectUid}/media/${id}/convert`,
    req
  )
}
