import { DEFAULT_CONTENTS_PAGE_SIZE } from '@/configs'
import { RootState, useAppDispatch } from '@/states'
import { getSavedContentsSortKey } from '@/utils/helpers'
import type { TabsProps } from 'antd'
import { Tabs } from 'antd'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'

export const TabModels = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // Params
  const { projectUid, modelId } = useParams()

  // State (Redux)
  const { projectsState, authState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { currentProject, modelList } = projectsState
  const { init } = authState

  const items: TabsProps['items'] = [{ label: t('contents'), key: 'contents' }]

  if (process.env.REACT_APP_HIDE_BUILDER !== '1') {
    items.push({ label: t('components'), key: 'components' })
  }

  items.push({ label: t('settings'), key: 'settings' })

  // Memo
  const activeKey = useMemo(() => {
    const path = location.pathname
    const pathArr = path.split('/')

    return pathArr[pathArr.length - 1]
      ? pathArr[pathArr.length - 1]
      : 'projectHome'
  }, [location.pathname])

  const isProjectDashboard = useMemo(() => {
    return (
      location.pathname === `/projects/${projectUid}` ||
      location.pathname === `/projects/${projectUid}/`
    )
  }, [location.pathname])

  /**
   * Tab 메뉴 변경
   * @param key
   */
  const onChangeTab = (key: string) => {
    if (key === 'contents') {
      navigate(
        `/projects/${
          currentProject?.uid
        }/${modelId}/contents?page=1&size=${DEFAULT_CONTENTS_PAGE_SIZE}&sort=${getSavedContentsSortKey(
          modelId
        )}&q=`
      )
    } else {
      navigate(`/projects/${projectUid}/${modelId}/${key}`)
    }
  }

  return projectUid ? (
    <div
      className={`sticky ${
        isProjectDashboard ? 'top-24' : 'top-12'
      } z-10 bg-gray-200`}>
      <Tabs
        items={items}
        onChange={onChangeTab}
        defaultActiveKey={activeKey}></Tabs>
    </div>
  ) : (
    <></>
  )
}
