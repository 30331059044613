import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ReactHlsPlayer from 'react-hls-player'
import { MediaInterface } from '@/types'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '@/states'

interface MediaFileInfoProps {
  media: MediaInterface | null
}

export const MediaVideoStreaming = ({ media }: MediaFileInfoProps) => {
  const { t, i18n } = useTranslation()
  const playerRef = React.useRef()

  return media ? (
    <div className="relative">
      <ReactHlsPlayer
        // @ts-ignore
        playerRef={playerRef}
        src={media.file.video.hls}
        autoPlay={false}
        controls={true}
        hlsConfig={{
          maxBufferSize: 1 * 1000 * 1000,
        }}
      />
    </div>
  ) : (
    <></>
  )
}
