import { AppDispatch } from '@/states'
import { ActionTypesProjects } from '@/states/interfaces'
import { CellInterface, ContentsInterface } from '@/types'

/**
 * 콘텐츠 목록 다시불러오기
 * @param reload
 * @returns {function(*): *}
 */
export const setContentsListReload = (reload) => (dispatch: AppDispatch) => {
  return dispatch({
    type: ActionTypesProjects.SET_CONTENTS_LIST_RELOAD,
    payload: {
      reload,
    },
  })
}

/**
 * 콘텐츠 목록 로딩
 * @param isLoading
 * @returns
 */
export const setContentsListLoading =
  (isLoading) => (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_CONTENTS_LIST_LOADING,
      payload: {
        isLoading,
      },
    })
  }

/**
 * 콘텐츠 목록 설정
 * @param list
 * @returns
 */
export const setContentsList =
  (
    list: ContentsInterface[],
    pagination,
    keyword,
    sort,
    isPreserveCondition = false
  ) =>
  (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_CONTENTS_LIST,
      payload: {
        contentsList: list,
        pagination,
        keyword,
        sort,
        isPreserveCondition,
      },
    })
  }

/**
 * 콘텐츠 목록 초기화

 * @returns
 */
export const resetContentsList = () => (dispatch: AppDispatch) => {
  return dispatch({
    type: ActionTypesProjects.RESET_CONTENTS_LIST,
  })
}

/**
 * 콘텐츠 목록에 추가
 * @param item
 * @returns
 */
export const addContentsList =
  (item, skipSort = false) =>
  (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesProjects.ADD_CONTENTS_TO_LIST,
      payload: {
        contentsAdded: item,
        skipSort,
      },
    })
  }

/**
 * 콘텐츠 수정
 * @param item
 * @returns
 */
export const updateContentsItem =
  (item: ContentsInterface) => (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesProjects.UPDATE_CONTENTS_ITEM,
      payload: {
        contentsItem: item,
      },
    })
  }

/**
 * 콘텐츠 삭제
 * @param contentsItemUidList
 * @returns
 */
export const deleteContentsList =
  (contentsItemUidList: string[]) => (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesProjects.DELETE_CONTENTS_ITEM,
      payload: {
        contentsItemUidList: contentsItemUidList,
      },
    })
  }

/**
 * 체크된 콘텐츠 목록 설정
 * @param list
 * @returns
 */
export const setCheckedContentsList =
  (list: ContentsInterface[]) => (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_CHECKED_CONTENTS_LIST,
      payload: {
        checkedContentsList: list,
      },
    })
  }

/**
 * 콘텐츠 정렬 설정
 * @param sort
 * @param modelId
 * @returns
 */
export const setContentsSort =
  (sort: string, modelId?: number) => (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_CONTENTS_SORT,
      payload: {
        sort,
        modelId,
      },
    })
  }

/**
 * Req 컴포넌트 설정
 * @param componentsArr
 * @returns
 */
export const setContentsReqComponents =
  (componentsArr: CellInterface[]) => (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_CONTENTS_REQ_COMPONENTS,
      payload: {
        componentsArr: componentsArr,
      },
    })
  }

/**
 * Req 컴포넌트 설정 (Preview)
 * @param componentsArr
 * @returns
 */
export const setContentsPreviewReqComponents =
  (componentsArr: CellInterface[]) => (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_CONTENTS_PREVIEW_REQ_COMPONENTS,
      payload: {
        componentsArr: componentsArr,
      },
    })
  }

/**
 * 콘텐츠 Cell 설정
 * @param contentsUid
 * @param cellUid
 * @param title
 * @param modelTitle
 * @returns
 */
export const setContentsCellItem =
  (
    contentsUid: string,
    cellUid: string,
    relationUid: string,
    title: string,
    modelTitle: string
  ) =>
  (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_CONTENTS_CELL_ITEM,
      payload: {
        contentsUid,
        cellUid,
        relationUid,
        title,
        modelTitle,
      },
    })
  }
