import { createContents, getContentsItem, getProjectUsage } from '@/api'
import {
  ContentsList,
  ContentsListHeaders,
  ContentsTools,
} from '@/components/Contents'
import { TabModels } from '@/components/Layout'
import { ModelsFormModal } from '@/components/Models'
import { LAYOUT_OKAY_COLOR, LAYOUT_WARNING_COLOR } from '@/configs'
import { RootState, useAppDispatch } from '@/states'
import {
  addContentsList,
  resetContentsList,
  setContentsFormModal,
} from '@/states/actions'
import { ProjectModelUsageInterface } from '@/types'
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Pagination, Spin, Tooltip, message } from 'antd'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

const ProjectsModelsContents = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  // Params
  const { projectUid, modelId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [modelUsage, setModelUsage] = useState<
    ProjectModelUsageInterface | null | undefined
  >(null)

  // State (Redux)
  const { projectsState, layoutState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const {
    currentProject,
    modelListInit,
    currentModel,
    contentsListLoading,
    contentsPagination,
    contentsSort,
    reqComponents,
    flattenComponentList,
  } = projectsState
  const { gnb, splitSize } = layoutState

  // Effect
  useEffect(() => {
    dispatch(resetContentsList())
  }, [])

  useEffect(() => {
    initScrollSync()
  }, [gnb, splitSize])

  useEffect(() => {
    if (currentProject && currentModel) {
      initScrollSync()
      window.addEventListener('resize', initScrollSync)
    }
  }, [currentProject, currentModel])

  useEffect(() => {
    if (searchParams.get('new') && searchParams.get('new') === 'modal') {
      dispatch(setContentsFormModal(true, null, Number(modelId)))
    }

    initScrollSync()
  }, [location, modelListInit, currentProject])

  useEffect(() => {
    if (currentModel && currentProject && currentProject.usage) {
      setModelUsage(
        currentProject.usage.modelList.find((m) => m.id === currentModel.id)
      )
    }
  }, [currentModel, currentProject, currentProject?.usage])

  /**
   * 스크롤 UI 고정 (가로)
   */
  const contentsListHorizontalLayout = () => {
    const body = document.getElementById('contents-body')
    const tools = document.getElementById('contents-tools')
    const list = document.getElementById('contents-list')
    const listHeader = document.getElementById('contents-list-header')

    if (body && tools && list && listHeader && currentModel) {
      const hc = document.querySelector('.header-cell') as HTMLElement
      hc.style.marginLeft = -1 * list.scrollLeft + 'px'
    }
  }

  /**
   * 컨텐츠 스크롤 싱크 설정
   */
  const initScrollSync = () => {
    return false
  }

  /**
   * 페이지네이션 변경
   * @param page
   * @param pageSize
   */
  const onHandleChangePagination = (page, pageSize) => {
    navigate(
      `/projects/${currentProject?.uid}/${currentModel?.id}/contents?page=${
        contentsPagination.size !== pageSize ? 1 : page
      }&size=${pageSize}&sort=${contentsSort}&q=${
        searchParams.get('q') ? searchParams.get('q') : ''
      }`
    )
  }

  /**
   * 컨텐츠 한줄 추가
   */
  const addNewContentsRow = () => {
    if (
      contentsListLoading ||
      loading ||
      (modelUsage && modelUsage.content.current >= modelUsage.content.limit)
    )
      return false

    const req = {
      order: null,
      uid: '',
      cellList: reqComponents,
    }

    setLoading(true)

    createContents(currentProject?.uid, currentModel?.id, req)
      .then((res) => {
        setLoading(false)

        getContentsItem(currentProject?.uid, currentModel?.id, res.data).then(
          (res) => {
            dispatch(addContentsList(res.data, true))
            dispatch(getProjectUsage(currentProject?.uid as string))
          }
        )
      })
      .catch((e) => {
        message.error(e.response.data.error)
        setLoading(false)
      })
  }

  return (
    <>
      {currentProject && currentModel && (
        <Helmet>
          <title>
            {currentModel.languageMap[currentProject.defaultLang]} ·{' '}
            {t('contents')} · {currentProject.name} ·{' '}
            {process.env.REACT_APP_NAME}
          </title>
        </Helmet>
      )}
      {currentProject && currentModel ? (
        <>
          <TabModels />
          <div
            id="contents-body"
            className="grow relative overflow-visible overflow-x-clip">
            {/* Contents tools: 시작 */}
            <div
              id="contents-tools"
              className="sticky top-12 z-50 grow-0 bg-gray-200 flex-none h-12 flex items-center w-full">
              <ContentsTools />
            </div>
            {/* Contents tools: 끝 */}
            {/* Contents 언어: 시작 */}
            {/* <div
              id="contents-lang"
              className="grow-0 bg-gray-200 flex-none h-12 flex justify-between items-center">
            </div> */}
            {/* Contents 언어: 끝 */}
            <ContentsListHeaders />
            {/* Contents list: 시작 */}
            <div
              id="contents-list"
              // onScroll={() => contentsListHorizontalLayout()}
              onScroll={() => contentsListHorizontalLayout()}
              className="grow overflow-x-auto">
              <ContentsList />
              {/* Contents Row 추가: 시작 */}
              {currentProject.role !== 'VIEWER' &&
              flattenComponentList &&
              flattenComponentList.length ? (
                <div
                  id="contents-add-row"
                  className={`sticky left-0 w-full grow-0 bg-gray-200 hover:bg-gray-300 flex-none h-8 flex items-center px-4 border-b border-r border-gray-300 mb-8 ${
                    contentsListLoading ||
                    loading ||
                    (modelUsage &&
                      modelUsage.content.current >= modelUsage.content.limit)
                      ? 'cursor-not-allowed opacity-50'
                      : 'cursor-pointer'
                  }`}
                  onClick={() => addNewContentsRow()}
                  title={t('addContents')}>
                  <button
                    type="button"
                    disabled={
                      contentsListLoading ||
                      loading ||
                      !!(
                        modelUsage &&
                        modelUsage.content.current >= modelUsage.content.limit
                      )
                    }
                    className={`sticky left-4 flex space-x-6 items-center text-gray-600 ${
                      contentsListLoading ||
                      loading ||
                      (modelUsage &&
                        modelUsage.content.current >= modelUsage.content.limit)
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                    }`}>
                    <PlusOutlined />
                    <span>{t('addContents')}</span>
                  </button>
                </div>
              ) : (
                <></>
              )}
              {/* Contents Row 추가: 끝 */}
            </div>

            {/* Pagination: 시작 */}
            {!contentsListLoading ? (
              <div
                id="contents-pagination"
                className="flex justify-center items-center pt-6 pb-14 relative">
                <div className="flex space-x-3 items-center absolute left-0">
                  <div className="text-gray-600">
                    {t('noOfContents', {
                      no: contentsPagination.totalElements,
                    })}
                  </div>
                  {process.env.REACT_APP_HIDE_USAGE !== '1' &&
                  currentProject.usage?.type !== 'UNLIMITED' ? (
                    <Tooltip
                      title={t('noContentsByModelDesc')}
                      className="cursor-help">
                      <div
                        className="flex space-x-1 items-center text-xs"
                        style={{
                          color:
                            modelUsage &&
                            modelUsage.content.current >=
                              modelUsage.content.limit
                              ? LAYOUT_WARNING_COLOR
                              : LAYOUT_OKAY_COLOR,
                        }}>
                        <span>
                          {modelUsage?.content.current} /{' '}
                          {modelUsage?.content.limit}
                        </span>
                        <InfoCircleOutlined className="mt-px" />
                      </div>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </div>
                <Pagination
                  defaultCurrent={1}
                  current={contentsPagination.page}
                  total={contentsPagination.totalElements}
                  pageSize={contentsPagination.size}
                  onChange={onHandleChangePagination}
                />
                <div></div>
              </div>
            ) : (
              <></>
            )}
            {/* Pagination: 끝 */}
            {/* Contents list: 끝 */}
          </div>
          {currentProject?.role === 'OWNER' ||
          currentProject?.role === 'ADMIN' ? (
            <ModelsFormModal />
          ) : (
            <></>
          )}
        </>
      ) : (
        <div className={'flex justify-center items-center h-screen'}>
          <Spin />
        </div>
      )}
    </>
  )
}

export default ProjectsModelsContents
