import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Button, Spin, Modal, message, Card, Table } from 'antd'
import {
  CloudSyncOutlined,
  CloudUploadOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import moment from 'moment-timezone'
import { RootState, useAppDispatch } from '@/states'
import { setCurrentModel, toggleBusy } from '@/states/actions'
import { getModels, validateModel } from '@/api'
import { LAYOUT_SUCCESS_COLOR } from '@/configs'
import { ModelInterface } from '@/types'
import NotFound from '../404'

const ProjectsPublish = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  if (process.env.REACT_APP_HIDE_PUBLISH === '1') {
    return <NotFound />
  }

  // State (Redux)
  const { projectsState, layoutState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const { currentProject, modelList } = projectsState
  const { busy } = layoutState

  // State
  const [modelIds, setModelIds] = useState<number[]>([])

  // Effect
  useEffect(() => {
    dispatch(setCurrentModel(null))
  }, [])

  /**
   * 검증 & 배포하기
   */
  const validatePublish = (model: ModelInterface, version?: string) => {
    if (!model || busy) return

    Modal.confirm({
      centered: true,
      title: t('validatePublish'),
      icon: <ExclamationCircleOutlined />,
      content: t('validatePublishDesc'),
      okText: t('validatePublish'),
      cancelText: t('cancel'),
      onOk() {
        dispatch(toggleBusy(true))
        setModelIds([model.id as number])

        return new Promise((resolve, reject) => {
          validateModel(currentProject?.uid, model?.id, version)
            .then((res) => {
              dispatch(toggleBusy(false))
              setModelIds([])
              message.success(t('validatePublishComplete'))
              dispatch(getModels(currentProject?.uid, model?.id, true))
              resolve(res)
            })
            .catch((e) => {
              dispatch(toggleBusy(false))
              setModelIds([])
              message.error(e.response.data.error)
              reject(e)
            })
        }).catch((e) => {
          setModelIds([])
          dispatch(toggleBusy(false))
        })
      },
      onCancel() {},
    })
  }

  // Table columns
  const tableCols = [
    {
      title: t('modelName'),
      key: 'name',
      width: 'auto',
      render: (model) => (
        <div>
          {currentProject ? model.languageMap[currentProject.defaultLang] : '-'}
        </div>
      ),
    },
    {
      title: t('lastValidateLabel'),
      key: 'lastValidate',
      width: 300,
      render: (model) => (
        <div className="text-xs">
          {model.lastValidate ? (
            <div className="flex items-center space-x-2">
              <CloudSyncOutlined
                style={{
                  color: LAYOUT_SUCCESS_COLOR,
                }}
              />
              <span>
                {t('lastValidate', {
                  date: moment(model.lastValidate, 'YYYYMMDDHHmmss').format(
                    'YYYY-MM-DD HH:mm:ss'
                  ),
                })}
              </span>
            </div>
          ) : (
            <div className="opacity-30">-</div>
          )}
        </div>
      ),
      sorter: (a, b) => a.lastValidate.localeCompare(b.lastValidate),
    },
    {
      title: t('publish'),
      key: 'publish',
      width: 200,
      render: (model) => (
        <Button
          type="primary"
          id={`model-btn-${model.id}`}
          className="model-publish-btn"
          disabled={
            (currentProject?.role !== 'OWNER' &&
              currentProject?.role !== 'ADMIN') ||
            modelIds.includes(model.id as number)
          }
          onClick={() => validatePublish(model, 'v3')}>
          <span className="anticon anticon-cloud-upload">
            <LoadingOutlined
              id={`model-ico-loading-${model.id}`}
              className={`model-ico-loading ${
                !modelIds.includes(model.id as number) && '!hidden'
              }`}
            />
            <CloudUploadOutlined
              id={`model-ico-upload-${model.id}`}
              className={`model-ico-upload ${
                modelIds.includes(model.id as number) && '!hidden'
              }`}
            />
          </span>
          <span>{t('publish')}</span>
        </Button>
      ),
    },
  ]

  return (
    <>
      {currentProject && (
        <Helmet>
          <title>
            {t('projects')} · {t('settings')} · {currentProject.name} ·{' '}
            {process.env.REACT_APP_NAME}
          </title>
        </Helmet>
      )}
      {currentProject ? (
        <div className={'py-10'}>
          {/* Publish header: 시작 */}
          <div className="mb-10">
            <h1
              className={'mb-2 text-lg leading-7 flex items-center space-x-4'}>
              {t('publishContents')}
            </h1>
            <p className="mb-0">{t('publishDesc')}</p>
          </div>
          {/* Publish header: 끝 */}
          {/* Publish body: 시작 */}
          <Table
            // @ts-ignore
            columns={tableCols}
            dataSource={modelList}
            rowKey={'id'}
            pagination={false}
          />
          {/* Publish body: 끝 */}
        </div>
      ) : (
        <div className={'flex justify-center items-center h-screen'}>
          <Spin />
        </div>
      )}
    </>
  )
}

export default ProjectsPublish
