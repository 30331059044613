import { AlertStatus, Logo } from '@/components/Common'
import { LINK_PRIVACY, LINK_TERMS } from '@/constants'
import { RootState, useAppDispatch } from '@/states'
import { loginWithEmailAndPassword, signupWithEmail } from '@/states/actions'
import { AuthLoginInterface, AuthSignUpInterface } from '@/types'
import { setLogin } from '@/utils/auth'
import { getDeviceType } from '@/utils/helpers'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Button, Checkbox, Input } from 'antd'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

const initialSignUpValues: AuthSignUpInterface = {
  email: '',
  name: '',
  password: '',
}

const SignUpPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [agreeTerms, setAgreeTerm] = useState<boolean>(
    !!process.env.REACT_APP_PROJECT_ID
  )
  const [agreePrivacy, setAgreePrivacy] = useState<boolean>(
    !!process.env.REACT_APP_PROJECT_ID
  )
  const [newsLetter, setNewsLetter] = useState<boolean>(false)

  // State (Redux)
  const { authState } = useSelector(
    (state: RootState) => ({
      authState: state.auth,
    }),
    shallowEqual
  )
  const { tmpCode } = authState

  // Effect
  useEffect(() => {
    if (!tmpCode) {
      navigate('/login')
    } else {
      formikSignUp.setFieldValue('email', tmpCode.split('#')[0])
    }
  }, [tmpCode])

  // Validation
  const validationSignUpSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('validation.required'))
      .email(t('validation.email')),
    name: Yup.string().required(t('validation.required')),
    password: Yup.string()
      .required(t('validation.required'))
      .min(8, t('validation.min', { len: 8 }))
      .max(50, t('validation.max', { len: 50 }))
      .matches(
        /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/,
        t('validation.passwordPattern')
      ),
  })

  // Formik
  const formikSignUp = useFormik({
    initialValues: initialSignUpValues,
    validationSchema: validationSignUpSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setStatus(null)

      const req: AuthSignUpInterface = {
        email: values.email,
        name: values.name,
        password: values.password,
        code: tmpCode.split('#')[1],
        newsLetter,
      }

      // Signup
      signupWithEmail(req)
        .then((res) => {
          // Login
          const logReq: AuthLoginInterface = {
            email: values.email,
            password: values.password,
            meta: {
              device: getDeviceType(),
              os: navigator.userAgent,
            },
          }

          loginWithEmailAndPassword(logReq)
            .then((res) => {
              setLogin(res.data)

              if (process.env.REACT_APP_PROJECT_ID) {
                location.href = `/projects/${process.env.REACT_APP_PROJECT_ID}`
              } else {
                location.href = '/projects?new=hello'
              }
            })
            .catch((e) => {
              setLoading(false)
              setSubmitting(false)

              // 비밀번호 오류
              setStatus(t('error.credentials'))
            })
            .then(() => {})
        })
        .catch((e) => {
          setLoading(false)
          setSubmitting(false)
          setStatus(t('error.wrong'))
        })
    },
  })

  return (
    <>
      <Helmet>
        <title>
          {t('auth.signup.title')} · {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <div className="min-h-screen flex items-center justify-center ">
        <div className={'max-w-md w-full space-y-8'}>
          <div className="pt-8 pb-5 px-5 lg:px-16 space-y-5">
            {!process.env.REACT_APP_PROJECT_ID ? (
              <div className="flex justify-center pb-2">
                <Logo customClass="w-32 h-20" isVertical />
              </div>
            ) : (
              <></>
            )}
            <div className={'space-y-1'}>
              <h2 className="text-center text-2xl leading-8 font-bold text-gray-900 mb-0">
                {t('auth.signup.title')}
              </h2>
              <p className={'text-center text-gray-500'}>
                {t('auth.signup.desc')}
              </p>
            </div>
            {/* Form - 시작 */}
            <form onSubmit={formikSignUp.handleSubmit} method="POST">
              <AlertStatus
                status={formikSignUp.status}
                onClick={() => formikSignUp.setStatus(null)}></AlertStatus>
              <div className="space-y-2">
                <div className="block">
                  <label htmlFor="signUpEmail" className="">
                    <div className={'mb-0.5'}>{t('email')}</div>
                    <Input
                      id={'signUpEmail'}
                      name="email"
                      type={'email'}
                      onChange={formikSignUp.handleChange}
                      value={formikSignUp.values.email}
                      placeholder={t('validation.email')}
                      readOnly
                    />
                  </label>
                  {formikSignUp.touched.email && formikSignUp.errors.email ? (
                    <p className="my-1 text-xs text-red-500">
                      {formikSignUp.errors.email}
                    </p>
                  ) : null}
                </div>
                <div className="block">
                  <label htmlFor="signUpName" className="">
                    <div className={'mb-0.5'}>{t('name')}</div>
                    <Input
                      id={'signUpName'}
                      name="name"
                      type={'text'}
                      onChange={formikSignUp.handleChange}
                      value={formikSignUp.values.name}
                      placeholder={t('name')}
                      allowClear
                    />
                  </label>
                  {formikSignUp.touched.name && formikSignUp.errors.name ? (
                    <p className="my-1 text-xs text-red-500">
                      {formikSignUp.errors.name}
                    </p>
                  ) : null}
                </div>
                <div className="block">
                  <label htmlFor="loginPassword" className="">
                    <div className={'mb-0.5'}>{t('password')}</div>
                    <Input.Password
                      id="loginPassword"
                      name="password"
                      type="password"
                      onChange={formikSignUp.handleChange}
                      value={formikSignUp.values.password}
                      placeholder={t('validation.password')}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </label>
                  {formikSignUp.touched.password &&
                  formikSignUp.errors.password ? (
                    <p className="my-1 text-xs text-red-500">
                      {formikSignUp.errors.password}
                    </p>
                  ) : null}
                </div>
                {!process.env.REACT_APP_PROJECT_ID ? (
                  <div className="space-y-2 pt-4">
                    <div>
                      <Checkbox
                        checked={agreeTerms && agreeTerms && newsLetter}
                        onChange={(e) => {
                          if (agreeTerms && agreeTerms && newsLetter) {
                            setAgreeTerm(false)
                            setAgreePrivacy(false)
                            setNewsLetter(false)
                          } else {
                            setAgreeTerm(true)
                            setAgreePrivacy(true)
                            setNewsLetter(true)
                          }
                        }}>
                        <div>
                          <p
                            className="mb-0 font-bold"
                            dangerouslySetInnerHTML={{
                              __html: t('auth.signup.agreeAll'),
                            }}
                          />
                        </div>
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        checked={agreeTerms}
                        onChange={(e) => setAgreeTerm(!agreeTerms)}>
                        <div>
                          <p
                            className="mb-0"
                            dangerouslySetInnerHTML={{
                              __html: t('auth.signup.agreeTerms', {
                                linkTerms: LINK_TERMS,
                                linkPrivacy: LINK_PRIVACY,
                              }),
                            }}
                          />
                        </div>
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        checked={agreePrivacy}
                        onChange={(e) => setAgreePrivacy(!agreePrivacy)}>
                        <div>
                          <p
                            className="mb-0"
                            dangerouslySetInnerHTML={{
                              __html: t('auth.signup.agreePrivacy', {
                                linkTerms: LINK_TERMS,
                                linkPrivacy: LINK_PRIVACY,
                              }),
                            }}
                          />
                        </div>
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        checked={newsLetter}
                        onChange={(e) => setNewsLetter(!newsLetter)}>
                        <div>
                          <p className="mb-0">
                            {t('auth.signup.emailNotification')}
                          </p>
                          <p className="mb-0 text-xs text-gray-600">
                            {t('auth.signup.emailNotificationDesc')}
                          </p>
                        </div>
                      </Checkbox>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="mt-4">
                <Button
                  type={'primary'}
                  htmlType={'submit'}
                  block
                  disabled={loading || !agreeTerms || !agreeTerms}
                  loading={loading}>
                  {t('auth.signup.submit')}
                </Button>
              </div>
            </form>
            {/* Form - 끝 */}
          </div>
        </div>
      </div>
    </>
  )
}

export default SignUpPage
