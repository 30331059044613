import { LanguagesConfigInterface } from '@/types'

export const languagesConfig: LanguagesConfigInterface = {
  availableLanguages: [
    {
      code: 'KO',
      flag: '🇰🇷',
      name: '한국어',
    },
    {
      code: 'EN',
      flag: '🇺🇸',
      name: 'English',
    },
    /*{
      code: 'CN',
      flag: '🇨🇳',
      name: '中國語',
    },*/
    {
      code: 'JP',
      flag: '🇯🇵',
      name: '日本語',
    },
  ],
}
