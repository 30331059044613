import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Button } from 'antd'
import { Link } from 'react-router-dom'
import { LINK_HELP, LINK_SHOWCASE } from '@/constants'
import {
  BookOutlined,
  RightOutlined,
  ExperimentOutlined,
} from '@ant-design/icons'

export const FindMore = () => {
  const { t } = useTranslation()

  return !process.env.REACT_APP_PROJECT_ID ? (
    <div className="grid xl:grid-cols-2 gap-7">
      <Card title={<div>{t('learnMore')}</div>}>
        <div className="space-y-0">
          <Link
            to={LINK_HELP}
            target="_blank"
            className="flex justify-between hover:bg-gray-300 rounded-lg px-3 py-4">
            <div className="flex space-x-2">
              <div className="w-10 h-10 flex justify-center items-center text-2xl">
                <BookOutlined />
              </div>
              <div>
                <div className="text-base font-medium">{t('help')}</div>
                <p className="text-xs text-gray-500 mb-0">{t('helpDesc')}</p>
              </div>
            </div>
            <RightOutlined />
          </Link>
          <Link
            to={LINK_SHOWCASE}
            target="_blank"
            className="flex justify-between hover:bg-gray-300 rounded-lg px-3 py-4">
            <div className="flex space-x-2">
              <div className="w-10 h-10 flex justify-center items-center text-2xl">
                <ExperimentOutlined />
              </div>
              <div>
                <div className="text-base font-medium">{t('showcase')}</div>
                <p className="text-xs text-gray-500 mb-0">
                  {t('showcaseDesc')}
                </p>
              </div>
            </div>
            <RightOutlined />
          </Link>
        </div>
      </Card>
      <Card className="flex justify-center items-center">
        <div className="py-5">
          <div className="text-center mb-7">
            <h3 className="text-3xl font-bold leading-[130%] mb-2">
              {t('dashboardAskTitle')}
            </h3>
            <p
              className="text-base font-medium"
              dangerouslySetInnerHTML={{
                __html: t('dashboardAskDescription'),
              }}
            />
          </div>
          <div className="flex justify-center">
            <Button
              type="primary"
              onClick={() => {
                // @ts-ignore
                ChannelIO('openChat')
              }}>
              {t('contactUs')}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  ) : (
    <></>
  )
}
