export const LAYOUT_DEFAULT_BG = '#F5F5F5'
export const LAYOUT_GNB_WIDTH = 288
export const LAYOUT_GNB_COLLAPSE_WIDTH = '3.5rem'
export const LAYOUT_SIDEBAR_WIDTH = 238
export const LAYOUT_SIDEBAR_MAX_WIDTH = 500
export const LAYOUT_COMPONENT_TYPE_WIDTH = 236
export const LAYOUT_MODAL_WIDTH = 816
export const LAYOUT_MODAL_SM_WIDTH = 360
export const LAYOUT_MODAL_LG_WIDTH = 1100
export const LAYOUT_MODAL_XL_WIDTH = 1440
export const LAYOUT_CHECKBOX_CELL_WIDTH = 48
export const LAYOUT_DEFAULT_CELL_WIDTH = 160
export const LAYOUT_DEFAULT_CELL_DATE_WIDTH = 150
export const LAYOUT_MIN_CELL_WIDTH = 80
export const LAYOUT_DEFAULT_CELL_HEIGHT = 24
export const LAYOUT_DEFAULT_CELL_BODY_HEIGHT = 32
export const LAYOUT_EXCEL_WIDTH_DOWNLOAD_RATIO = 0.8

export const LAYOUT_WARNING_COLOR = '#F5222D'
export const LAYOUT_SUCCESS_COLOR = '#52c41a'
export const LAYOUT_OKAY_COLOR = '#40A9FF'

export const SYNC_USER_COLORS = `
#8A2BE2
#FF4500
#00FFFF
#7FFF00
#D2691E
#DC143C
#00CED1
#B22222
#8B008B
#8B4513
#2E8B57
#9932CC
#8B0000
#8B008B
#8B4513
#00CED1
#B22222
#D2691E
#FF4500
#00FFFF
#7FFF00
#8A2BE2
#8B0000
#2E8B57
#8B4513
#9932CC
#DC143C
#8B4513
#8B008B
#B22222
#8A2BE2
#00CED1
#D2691E
#8B0000
#FF4500
#00FFFF
#7FFF00
#8B008B
#2E8B57
#9932CC
#DC143C
#00CED1
#B22222
#8B4513
#8A2BE2
#8B4513
#8B008B
#8B0000
#D2691E
#FF4500
#00FFFF
#7FFF00
#9932CC
#2E8B57
#8A2BE2
#8B008B
#B22222
#DC143C
#00CED1
#8B4513
#8B0000
#8B008B
#D2691E
#FF4500
#00FFFF
#7FFF00
#9932CC
#2E8B57
#8A2BE2
#B22222
#8B4513
#00CED1
#DC143C
#8B008B
#8B0000
#D2691E
#FF4500
#00FFFF
#7FFF00
#9932CC
#2E8B57
#8A2BE2
#8B008B
#B22222
#8B4513
#DC143C
#00CED1
#8B0000
#8B008B
#D2691E
#FF4500
#00FFFF
#7FFF00
#9932CC
#2E8B57
#8A2BE2
#B22222
#8B4513
#DC143C
#00CED1
`
