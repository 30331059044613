import {
  createCategoryGroup,
  createCategorySelector,
  createModel,
  getCategoryGroups,
  getModelData,
  getModels,
  getProjectUsageData,
  updateModel,
} from '@/api'
import { AlertStatus } from '@/components/Common'
import { ModelsSamples } from '@/components/Models/ModelsSamples'
import {
  DEFAULT_PROJECT_PLAN,
  LAYOUT_MODAL_LG_WIDTH,
  ProjectUsageProperties,
  RESERVED_WORDS,
} from '@/configs'
import { LINK_HELP_DEVKEY } from '@/constants'
import { RootState, useAppDispatch } from '@/states'
import {
  setCurrentModel,
  setCurrentProject,
  setModelFormModal,
} from '@/states/actions'
import {
  ComponentInterface,
  ModelInterface,
  ProjectUsagePropertiesInterface,
  SelectorGroupInterface,
} from '@/types'
import {
  CheckOutlined,
  CloseCircleOutlined,
  InteractionOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Drawer,
  Input,
  message,
  Row,
  Tooltip,
  Typography,
} from 'antd'
import axios from 'axios'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'

const initialModelFormValues = {
  name: '',
  devKey: '',
  description: '',
}

export const ModelsFormModal = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { modalsState, projectsState } = useSelector(
    (state: RootState) => ({
      modalsState: state.modals,
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { modelFormModal, modelInfo } = modalsState
  const { currentProject, modelList } = projectsState

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [projectPlan, setProjectPlan] = useState<
    ProjectUsagePropertiesInterface | undefined
  >(ProjectUsageProperties.find((p) => p.price === DEFAULT_PROJECT_PLAN))
  const [importedModelList, setImportedModelList] = useState<ModelInterface[]>(
    []
  )
  const [importedGroupList, setImportedGroupList] = useState<
    SelectorGroupInterface[]
  >([])

  // Effect
  useEffect(() => {
    if (modelFormModal) {
      setTimeout(() => {
        document.getElementById('modelFormName')?.focus()
      })

      if (!modelInfo) {
        formikModelForm.validateForm()
      }
    } else {
      resetForm()
    }
  }, [modelFormModal])

  useEffect(() => {
    if (modelInfo && currentProject) {
      formikModelForm.setFieldValue(
        'name',
        modelInfo.languageMap[currentProject.defaultLang]
      )
      formikModelForm.setFieldValue('devKey', modelInfo.devKey)
      formikModelForm.setFieldValue('description', modelInfo.description)
    }
  }, [modelInfo])

  // Validation
  const validationModelFormSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    devKey: Yup.string()
      .required(t('validation.required'))
      .notOneOf(
        [...RESERVED_WORDS, ...RESERVED_WORDS.map((w) => w.toLowerCase())],
        t('validation.reservedWordsCannotUsedForDevKey')
      ),
  })

  // Formik
  const formikModelForm = useFormik({
    initialValues: initialModelFormValues,
    validationSchema: validationModelFormSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (!currentProject) return

      // 예약어 개발키 확인
      if (RESERVED_WORDS.includes(values.devKey.toUpperCase())) {
        formikModelForm.setErrors({
          devKey: t('validation.reservedWordsCannotUsedForDevKey'),
        })
        document.getElementById('modelFormDevKey')?.focus()
        return false
      }

      // 중복 개발키 확인
      if (
        (!modelInfo && modelList.find((m) => m.devKey === values.devKey)) ||
        (modelInfo &&
          modelList.find(
            (m) => m.devKey === values.devKey && m.devKey !== modelInfo.devKey
          ))
      ) {
        message.warning(t('error.duplicatedDevKey'))
        document.getElementById('modelFormDevKey')?.focus()
        return false
      }

      setStatus(null)
      setLoading(true)

      // 언어별 값 선택
      const languageMap = {}
      currentProject.languageList.forEach((lang) => {
        languageMap[lang] = values.name
      })

      const req = {
        languageMap,
        devKey: values.devKey.replace(/[^A-Z0-9]/gi, ''),
        componentList:
          importedModelList && importedModelList.length
            ? importedModelList[0].componentList
            : ([] as ComponentInterface[]),
        order: modelList.length,
      }

      if (!modelInfo) {
        // 카테고리 생성
        const projectStarterSelectorReqs: any[] = []
        const selectorGroupIds: {
          oldCategoryGroupId: number
          newCategoryGroupId: number
        }[] = []

        // 카테고리 생성
        await importedGroupList.forEach((selectorGroupReq, sIdx) => {
          return projectStarterSelectorReqs.push(
            createCategoryGroup(currentProject.uid, selectorGroupReq).then(
              async (res) => {
                const newCategoryGroupId = res.data
                const categorySelectorReq = {
                  languageMap: selectorGroupReq.languageMap,
                  order: selectorGroupReq.order,
                  selectorList: selectorGroupReq.selectorList,
                }

                selectorGroupIds.push({
                  oldCategoryGroupId: selectorGroupReq.id as number,
                  newCategoryGroupId,
                })

                return await createCategorySelector(
                  currentProject.uid,
                  newCategoryGroupId,
                  categorySelectorReq
                )
              }
            )
          )
        })

        if (projectStarterSelectorReqs.length > 0) {
          await axios.all([...projectStarterSelectorReqs]).then(
            axios.spread(async (...responses) => {
              // 카테고리 연결
              return req.componentList.forEach((component) => {
                const selectorGroupId = selectorGroupIds.find(
                  (s) => s.oldCategoryGroupId === component.selectorGroupId
                )?.newCategoryGroupId

                if (component.type === 'CATEGORY' && selectorGroupId) {
                  component.selectorGroupId = selectorGroupId
                }
              })
            })
          )
        }

        // 모델 생성
        await createModel(currentProject.uid, req)
          .then((res) => {
            getModelData(currentProject?.uid, res.data).then((res) => {
              const newModel = res.data

              // 사용량 갱신
              getProjectUsageData(currentProject?.uid)
                .then((res) => {
                  const updatedProject = currentProject
                  updatedProject.usage = res.data

                  dispatch(setCurrentProject(updatedProject))
                  dispatch(setCurrentModel(newModel))
                  dispatch(setModelFormModal(false))
                  dispatch(getModels(currentProject?.uid))
                  dispatch(getCategoryGroups(currentProject.uid))
                })
                .catch((e) => {
                  message.error(e.response.data.error)
                })
            })
          })
          .catch((e) => {
            setSubmitting(false)
            setStatus(e.response.data.error)
          })
      } else {
        req.order = modelInfo.order as number
        req.componentList = modelInfo.componentList
          ? modelInfo.componentList
          : []

        await updateModel(currentProject?.uid, modelInfo.id, req)
          .then((res) => {
            dispatch(setModelFormModal(false))
            dispatch(getModels(currentProject?.uid, modelInfo.id))
          })
          .catch((e) => {
            setSubmitting(false)
            setStatus(e.response.data.error)
          })
      }

      setLoading(false)
    },
  })

  /**
   * 폼 리셋
   */
  const resetForm = () => {
    formikModelForm.resetForm()
    setLoading(false)
    setImportedModelList([])
    setImportedGroupList([])
  }

  /**
   * 랜덤 개발키 생성
   */
  const generateRandomDevKey = () => {
    formikModelForm.setFieldValue('devKey', uuidv4())
  }

  return (
    <Drawer
      width={LAYOUT_MODAL_LG_WIDTH}
      closeIcon={<CloseCircleOutlined title={t('close')} />}
      open={modelFormModal}
      maskClosable={false}
      onClose={() => dispatch(setModelFormModal(false))}
      title={modelInfo ? t('editModel') : t('addModel')}
      extra={
        <div>
          <Button
            type={'primary'}
            icon={<CheckOutlined />}
            onClick={() => formikModelForm.submitForm()}
            disabled={loading}
            loading={loading}>
            {modelInfo ? t('update') : t('save')}
          </Button>
        </div>
      }
      /* footer={[
        <div key={'footer'} className={'flex justify-between items-center'}>
          <div>
            <Button
              type="primary"
              ghost
              onClick={() => dispatch(setModelFormModal(false))}>
              {t('cancel')}
            </Button>
          </div>
        </div>,
      ]} */
    >
      <>
        <form
          onSubmit={formikModelForm.handleSubmit}
          method="POST"
          className="h-full">
          <AlertStatus
            status={formikModelForm.status}
            onClick={() => formikModelForm.setStatus(null)}></AlertStatus>
          <div className={'space-y-6 h-full flex flex-col'}>
            <div className={'space-y-4 flex-none'}>
              <Row gutter={24}>
                <Col xs={24} md={12} className="mb-6 md:mb-0">
                  <div className="block">
                    <label htmlFor="modelFormName" className="">
                      <div className={'mb-2'}>
                        {t('modelName')} <span className="text-red-500">*</span>
                      </div>
                      <Input
                        id={'modelFormName'}
                        name="name"
                        onChange={formikModelForm.handleChange}
                        value={formikModelForm.values.name}
                      />
                    </label>
                    {formikModelForm.touched.name &&
                    formikModelForm.errors.name ? (
                      <p className="my-1 text-xs text-red-500">
                        {formikModelForm.errors.name}
                      </p>
                    ) : null}
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="space-y-4">
                    <div className="block">
                      <label htmlFor="modelFormDevKey" className="">
                        <div
                          className={'mb-2 flex justify-between items-center'}>
                          <div>
                            {t('devKey')}{' '}
                            <span className="text-red-500">*</span>
                          </div>
                          {!process.env.REACT_APP_PROJECT_ID && (
                            <a
                              href={LINK_HELP_DEVKEY}
                              tabIndex={-1}
                              target="_blank"
                              rel="noreferrer"
                              className="text-xs flex items-center space-x-0.5">
                              <QuestionCircleOutlined />
                              <span>{t('whatsDevKey')}</span>
                            </a>
                          )}
                        </div>
                        <Input.Group compact>
                          <Input
                            id={'modelFormDevKey'}
                            name="devKey"
                            onChange={formikModelForm.handleChange}
                            value={formikModelForm.values.devKey}
                            style={{
                              width: `calc(100% - 32px)`,
                            }}
                          />
                          <Tooltip title={t('generateRandomDevKey')}>
                            <Button
                              icon={<InteractionOutlined />}
                              onClick={() => {
                                generateRandomDevKey()
                              }}
                            />
                          </Tooltip>
                        </Input.Group>
                      </label>
                      {formikModelForm.touched.devKey &&
                      formikModelForm.errors.devKey ? (
                        <p className="my-1 text-xs text-red-500">
                          {formikModelForm.errors.devKey}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {!modelInfo && (
              <>
                <div className={'border-b pb-1.5 flex-none hidden lg:block'}>
                  <Typography.Title
                    level={5}
                    className={'mt-0 mb-0 flex space-x-2 items-end'}>
                    <span>{t('sampleModelComponentsTitle')}</span>
                    <span className="text-xs font-normal">
                      {t('sampleModelDesc')}
                    </span>
                  </Typography.Title>
                </div>
                <div
                  className={
                    'space-y-4 h-full grow overflow-hidden hidden lg:block'
                  }>
                  <div className="h-full">
                    <ModelsSamples
                      projectPlan={
                        projectPlan as ProjectUsagePropertiesInterface
                      }
                      modelList={importedModelList}
                      onImportModel={(modelToBeImported) => {
                        setImportedModelList([modelToBeImported])
                        formikModelForm.setFieldValue(
                          'name',
                          modelToBeImported.languageMap.KO
                        )
                        formikModelForm.setFieldValue(
                          'devKey',
                          modelToBeImported.devKey
                        )
                      }}
                      onImportGroups={setImportedGroupList}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </form>
      </>
    </Drawer>
  )
}
