import React, { useEffect, useMemo } from 'react'
import { SYNC_API_URL, SYNC_USER_COLORS } from '@/configs'
import { getToken } from '@/utils/auth'
import { useLocation, useParams } from 'react-router'
import { RootState, useAppDispatch } from '@/states'
import { setWsClient, setWsClientSync } from '@/states/actions'
import { shallowEqual, useSelector } from 'react-redux'
import { ProjectSyncActionType, UserInterface } from '@/types'
import { SyncMember } from './SyncMember'

export const SyncContents = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  // Params
  const { projectUid, modelId } = useParams()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject, ws, wsSync } = projectsState

  // Effect
  useEffect(() => {
    connectClient()
  }, [modelId])

  useEffect(() => {
    if (wsSync.length > 0) {
      highlightCellList()
    }
  }, [wsSync])

  // Memo
  const syncMembers: UserInterface[] = useMemo(() => {
    return currentProject && currentProject.memberList && wsSync.length
      ? currentProject.memberList.filter(
          (m) =>
            wsSync
              /* .filter((ws) => {
                return (
                  ws.projectUid === projectUid &&
                  Number(ws.modelId) === Number(modelId)
                )
              }) */
              .map((ws) => Number(ws.memberId))
              .indexOf(m.id) >= 0
        )
      : []
  }, [wsSync, currentProject?.memberList])

  /**
   * WebSocket 연결
   */
  const connectClient = () => {
    if (!process.env.REACT_APP_WS_URL) return

    if (ws) {
      dispatch(setWsClient(null))
      dispatch(setWsClientSync([]))
      ws.close()
    }

    let socket = new WebSocket(SYNC_API_URL + '?Access-Token=' + getToken())
    socket.onopen = function (e) {
      socket.send(
        JSON.stringify({
          action: 'SUBSCRIBE',
          type: 'MODEL',
          projectUid,
          modelId: Number(modelId),
        })
      )

      dispatch(setWsClient(socket))
    }

    socket.onmessage = function (event) {
      const res = JSON.parse(event.data)
      const action: ProjectSyncActionType = res.action
      dispatch(setWsClientSync(res.sync, action))
    }

    socket.onclose = function (event) {}

    socket.onerror = function (error) {
      // WS 재실행
      dispatch(setWsClient(null))
      dispatch(setWsClientSync([]))
      socket.close()
      connectClient()
    }
  }

  /**
   * 콘텐츠 한 줄 하이라이트
   */
  const highlightCellList = () => {
    // Reset highlight
    const oldCellList = document.querySelectorAll('.cell-sync')
    oldCellList.forEach((c) => {
      if (c) {
        c.classList.remove('cell-sync')
        c?.querySelector('.cell-component-container ')?.classList.remove(
          'cell-active'
        )
        // @ts-ignore
        c.style.setProperty('--sync-color', 'transparent')
      }
    })

    // Contents Sync
    const contentsList = wsSync.filter(
      (ws) => ws.select === 'CONTENT' && ws.uid
    )

    contentsList.forEach((c) => {
      const cellList = document.getElementById(`cell-list-${c.uid}`)
      const memberIdx: number = syncMembers
        .map((m) => m.id)
        .indexOf(Number(c.memberId))
      const taretColor = SYNC_USER_COLORS.split('\n').filter((c) => c)[
        memberIdx
      ]

      cellList?.classList.add('cell-sync')
      cellList?.style.setProperty('--sync-color', taretColor)
    })

    // Cell Sync
    const cellsList = wsSync.filter((ws) => ws.select === 'CELL' && ws.uid)

    cellsList.forEach((c) => {
      const cellItem = document.getElementById(`cell-item-${c.uid}`)
      const memberIdx: number = syncMembers
        .map((m) => m.id)
        .indexOf(Number(c.memberId))

      const taretColor = SYNC_USER_COLORS.split('\n').filter((c) => c)[
        memberIdx
      ]

      cellItem?.classList.add('cell-sync')
      cellItem
        ?.querySelector('.cell-component-container ')
        ?.classList.add('cell-active')
      cellItem?.style.setProperty('--sync-color', taretColor)
    })
  }

  return (
    <div className="mr-2.5 w-40 flex justify-end items-center overflow-x-auto h-8">
      <ul className="flex items-center space-x-1 w-max mb-0">
        {syncMembers.map((member, mIdx) => (
          <SyncMember member={member} key={mIdx} idx={mIdx} />
        ))}
      </ul>
    </div>
  )
}
