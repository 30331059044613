import { AlertStatus, Logo } from '@/components/Common'
import { searchPassword } from '@/states/actions'
import { AuthPasswordForgetInterface } from '@/types'
import { Button, Input, message } from 'antd'
import { useFormik } from 'formik'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

const initialPasswordForgetValues: AuthPasswordForgetInterface = {
  email: '',
}

const ForgetPassword = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  // State
  const [loading, setLoading] = useState<boolean>(false)

  // Effect

  // Validation
  const validationPasswordForgetSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('validation.required'))
      .email(t('validation.email')),
  })

  // Formik
  const formikPasswordForget = useFormik({
    initialValues: initialPasswordForgetValues,
    validationSchema: validationPasswordForgetSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setStatus(null)

      const publicUrl = process.env.REACT_APP_URL as string
      const domain = new URL(publicUrl).hostname

      const req = {
        email: values.email,
        site: process.env.REACT_APP_PROJECT_ID
          ? '[' + (process.env.REACT_APP_NAME as string) + '] '
          : null,
        domain: process.env.REACT_APP_PROJECT_ID ? domain : null,
      }

      searchPassword(req)
        .then((res) => {
          message.success(t('auth.password.forget.sent'))

          setTimeout(() => {
            navigate('/login')
          }, 1000)
        })
        .catch((e) => {
          // No data
          if (e.response.status === 404) {
            setStatus(t('error.noEmail'))
          } else {
            setStatus(t('error.wrong'))
          }

          setSubmitting(false)
          setLoading(false)
        })
    },
  })

  return (
    <>
      <Helmet>
        <title>
          {t('auth.password.forget.title')} · {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <div className="min-h-screen flex items-center justify-center ">
        <div className={'max-w-md w-full'}>
          <div className="pt-8 pb-5 px-5 lg:px-16 space-y-5">
            <div className="flex justify-center pb-2">
              <Logo customClass="w-32 h-20" isVertical />
            </div>
            <div className={'space-y-1'}>
              <h2 className="text-center text-2xl leading-8 font-bold text-gray-900 mb-0">
                {t('auth.password.forget.title')}
              </h2>
              <p className={'text-center text-gray-500'}>
                {t('auth.password.forget.desc')}
              </p>
            </div>
            {/* Form - 시작 */}
            <form onSubmit={formikPasswordForget.handleSubmit} method="POST">
              <AlertStatus
                status={formikPasswordForget.status}
                onClick={() =>
                  formikPasswordForget.setStatus(null)
                }></AlertStatus>
              <div className="space-y-2">
                <div className="block">
                  <label htmlFor="passwordForgetEmail" className="">
                    <div className={'mb-0.5'}>{t('email')}</div>
                    <Input
                      id={'passwordForgetEmail'}
                      name="email"
                      type={'email'}
                      onChange={formikPasswordForget.handleChange}
                      value={formikPasswordForget.values.email}
                      placeholder={t('validation.email')}
                      allowClear
                    />
                  </label>
                  {formikPasswordForget.touched.email &&
                  formikPasswordForget.errors.email ? (
                    <p className="my-1 text-xs text-red-500">
                      {formikPasswordForget.errors.email}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="mt-4">
                <Button
                  type={'primary'}
                  htmlType={'submit'}
                  block
                  disabled={loading}
                  loading={loading}>
                  {t('auth.password.forget.send')}
                </Button>
              </div>
            </form>
            {/* Form - 끝 */}
          </div>
          <div className={'text-center'}>
            <Link to="/login">
              <span className="text-xs text-gray-500 leading-5 hover:underline cursor-pointer">
                {t('auth.login.title')}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgetPassword
