import { DEFAULT_CONTENTS_PAGE_SIZE } from '@/configs'
import { RootState, useAppDispatch } from '@/states'
import { setMediaFormModal } from '@/states/actions'
import { ConnectedContentsInterface } from '@/types'
import { getSavedContentsSortKey } from '@/utils/helpers'
import { SelectOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'

interface MediaContentsListProps {
  mediaContentsList: ConnectedContentsInterface[]
}

export const ConnectedContents = ({
  mediaContentsList,
}: MediaContentsListProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState, modalsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      modalsState: state.modals,
    }),
    shallowEqual
  )
  const { currentProject, modelList } = projectsState
  const { mediaInfo } = modalsState

  /**
   * 연결된 컨텐츠로 이동
   * @param contentsToBeEdited
   * @param idx
   */
  const goConnectedContentsForm = (contentsToBeEdited, idx) => {
    if (mediaInfo) {
      dispatch(setMediaFormModal(false))

      navigate(
        `/projects/${currentProject?.uid}/${
          contentsToBeEdited.modelId
        }/contents?page=1&size=${DEFAULT_CONTENTS_PAGE_SIZE}&sort=${getSavedContentsSortKey(
          contentsToBeEdited.modelId
        )}&q=&edit=modal&id=${contentsToBeEdited.uid}`
      )
    }
  }

  return currentProject &&
    mediaInfo &&
    mediaContentsList &&
    mediaContentsList.length ? (
    <div className="space-y-3">
      <p className="text-sm">
        {t('connectedContents')} (
        {mediaContentsList ? mediaContentsList.length : 0})
      </p>
      {modelList ? (
        <div className="space-y-3">
          <hr />
          <ul className="space-y-1">
            {mediaContentsList.length ? (
              mediaContentsList.map((contents, idx) => (
                <li key={contents.uid} className="flex justify-between">
                  <div className="flex space-x-2 items-center text-xs">
                    <div className="px-2 bg-gray-200 border border-gray-300 rounded-sm">
                      {
                        modelList.find((m) => m.id && m.id === contents.modelId)
                          ?.languageMap[currentProject.defaultLang]
                      }
                    </div>
                    <div>
                      {contents && contents.languageMap
                        ? contents.languageMap[currentProject.defaultLang]
                        : 'N/A'}
                    </div>
                  </div>
                  <div className="flex-none">
                    <Button
                      type="text"
                      size="small"
                      icon={<SelectOutlined />}
                      onClick={() => goConnectedContentsForm(contents, idx)}
                      title={t('moveToConnectedContents')}></Button>
                  </div>
                </li>
              ))
            ) : (
              <></>
            )}
          </ul>
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  )
}
