import { deleteContents, getProjectUsage, updateContentsOpen } from '@/api'
import { ContentsFavorite } from '@/components/Contents'
import {
  LAYOUT_CHECKBOX_CELL_WIDTH,
  LAYOUT_DEFAULT_CELL_BODY_HEIGHT,
  LAYOUT_DEFAULT_CELL_DATE_WIDTH,
  LAYOUT_DEFAULT_CELL_WIDTH,
  LAYOUT_MIN_CELL_WIDTH,
} from '@/configs'
import { RootState, useAppDispatch } from '@/states'
import {
  deleteContentsList,
  setCheckedContentsList,
  updateContentsItem,
} from '@/states/actions'
import {
  CellInterface,
  ComponentInterface,
  ContentsInterface,
  UserInterface,
} from '@/types'
import {
  ArrowsAltOutlined,
  DeleteFilled,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { Button, Checkbox, Modal, message } from 'antd'
import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { CellItem } from './CellItem'

interface CellListProps {
  contents: ContentsInterface
  onChangeContents?: () => void
}

export const CellList = ({ contents, onChangeContents }: CellListProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // Params
  const [searchParams, setSearchParams] = useSearchParams()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const {
    currentProject,
    currentModel,
    contentsListLoading,
    checkedContentsList,
    contentsPagination,
    contentsSort,
    currentLanguage,
    flattenComponentList,
    wsSync,
  } = projectsState

  // State
  const [busy, setBusy] = useState<boolean>(false)
  const [valid, setValid] = useState<boolean>(true)

  // Effect
  useEffect(() => {
    const requiredComponents = flattenComponentList.filter(
      (fc) => fc.option && fc.option.required
    )

    let isValid = true

    if (requiredComponents.length) {
      requiredComponents.forEach((rc) => {
        const cellToBeRequired: CellInterface | undefined | null =
          contents.cellList
            ? contents.cellList.find(
                (cell) => cell.component && cell.component.id === rc.id
              )
            : null

        if (
          !cellToBeRequired ||
          (cellToBeRequired &&
            cellToBeRequired.component &&
            (((cellToBeRequired.component.type === 'TITLE' ||
              cellToBeRequired.component.type === 'SINGLE_LINE_TEXT' ||
              cellToBeRequired.component.type === 'LONG_LINE_TEXT') &&
              (!cellToBeRequired.languageMap ||
                !cellToBeRequired.languageMap[currentLanguage])) ||
              (cellToBeRequired.component.type !== 'TITLE' &&
                cellToBeRequired.component.type !== 'SINGLE_LINE_TEXT' &&
                cellToBeRequired.component.type !== 'LONG_LINE_TEXT' &&
                cellToBeRequired.component.type !== 'CATEGORY' &&
                cellToBeRequired.component.type !== 'RELATION' &&
                !cellToBeRequired.value) ||
              (cellToBeRequired.component.type === 'CATEGORY' &&
                (!cellToBeRequired.selectorList ||
                  cellToBeRequired.selectorList.length === 0)) ||
              (cellToBeRequired.component.type === 'RELATION' &&
                (!cellToBeRequired.relationList ||
                  cellToBeRequired.relationList.length === 0))))
        ) {
          isValid = false
          return false
        }
      })
    }

    setValid(isValid)
  }, [contents, flattenComponentList, currentLanguage])

  /**
   * 셀 목록
   * @param componentList
   * @param parentComponents
   * @returns
   */
  const getCells = (
    componentList,
    parentComponents: ComponentInterface[] = []
  ) => {
    return componentList ? (
      componentList.map((comp, cIdx) =>
        comp.type !== 'BLOCK' ? (
          <div
            key={comp.id}
            className={`flex border-r border-b border-gray-300 ${
              comp.first
                ? 'cell-list-fixed cell-list-actions sticky z-30 '
                : 'relative'
            }`}
            style={{
              width:
                (comp.option && comp.option.width
                  ? comp.option.width
                  : LAYOUT_DEFAULT_CELL_WIDTH) + 'px',
              height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
              left: comp.first ? LAYOUT_CHECKBOX_CELL_WIDTH + 'px' : 0,
            }}>
            <div className="cell-item">
              <CellItem
                contents={contents}
                component={comp}
                cell={contents?.cellList?.find(
                  (c) => c?.component?.id === comp.id
                )}
              />
            </div>
            {comp.first ? (
              <div className="absolute top-0 right-1">
                <div
                  className="flex items-center space-x-1 h-full"
                  style={{
                    height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
                  }}>
                  <div className="flex items-center">
                    <ContentsFavorite contents={contents} />
                  </div>
                  <Button
                    icon={<ArrowsAltOutlined />}
                    size="small"
                    onClick={() => onContentsOpenModal(contents)}
                    title={t('editContents')}
                    style={{
                      zIndex: 1010,
                    }}
                  />
                  {currentProject?.role !== 'VIEWER' ? (
                    <Button
                      icon={<DeleteFilled />}
                      size="small"
                      onClick={() => onContentsDelete(contents)}
                      title={t('deleteContents')}
                      style={{
                        zIndex: 1010,
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          getCells(comp.childList, [...parentComponents, comp])
        )
      )
    ) : (
      <></>
    )
  }

  /**
   * 모달 수정폼 열기
   * @param contentsToBeEdited
   */
  const onContentsOpenModal = (contentsToBeEdited) => {
    navigate(
      `/projects/${currentProject?.uid}/${currentModel?.id}/contents?page=${
        contentsPagination.page
      }&size=${
        contentsPagination.size
      }&sort=${contentsSort}&q=${searchParams.get('q')}&edit=modal&id=${
        contentsToBeEdited.uid
      }`
    )
  }

  /**
   * 콘텐츠 삭제
   * @param contentsToBeDeleted
   */
  const onContentsDelete = (contentsToBeDeleted) => {
    Modal.confirm({
      centered: true,
      title: t('confirmDeleteContentsTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('confirmDeleteContentsDesc'),
      okText: t('delete'),
      cancelText: t('cancel'),
      onOk() {
        return new Promise((resolve, reject) => {
          deleteContents(
            currentProject?.uid,
            currentModel?.id,
            contentsToBeDeleted.uid
          )
            .then((res) => {
              message.success(t('deleteSuccess'))
              // dispatch(setContentsListReload(true))
              dispatch(deleteContentsList([contentsToBeDeleted.uid]))
              dispatch(getProjectUsage(currentProject?.uid as string))
              resolve(res)
            })
            .catch((e) => {
              message.error(e.response.data.error)
              reject(e)
            })
        }).catch((e) => console.log(e))
      },
      onCancel() {},
    })
  }

  /**
   * 콘텐츠 체크
   * @param e
   */
  const onToggleCheck = (e) => {
    const isAlready = checkedContentsList
      .map((cc) => cc.uid)
      .includes(contents.uid)

    dispatch(
      setCheckedContentsList(
        !isAlready
          ? [...checkedContentsList, contents]
          : checkedContentsList.filter((cc) => cc.uid !== contents.uid)
      )
    )
  }

  /**
   * 공개여부 토글
   * @param contents
   * @returns
   */
  const onToggleOpen = (contents) => {
    if (busy) return false
    setBusy(true)

    const req = {
      open: !contents.open,
      uidList: [contents.uid],
    }

    updateContentsOpen(currentProject?.uid, currentModel?.id, req)
      .then((res) => {
        const updatedContents = contents
        updatedContents.open = !contents.open

        dispatch(updateContentsItem(updatedContents))
      })
      .catch((e) => {
        message.error(e.response.data.error)
      })
      .then(() => {
        setBusy(false)
      })
  }

  // Memo
  const syncContents = useMemo(() => {
    return contents
      ? wsSync.filter((ws) => {
          return ws.select === 'CONTENT' && ws.uid === contents.uid
        })
      : []
  }, [wsSync, contents])

  const syncContentsMembers: UserInterface[] = useMemo(() => {
    return syncContents.length &&
      currentProject &&
      currentProject.memberList &&
      wsSync.length
      ? currentProject.memberList.filter(
          (m) =>
            syncContents.map((ws) => Number(ws.memberId)).indexOf(m.id) >= 0
        )
      : []
  }, [syncContents, currentProject?.memberList])

  return currentProject && currentModel ? (
    <div
      id={`cell-list-${contents.uid}`}
      className={`cell-list hover-trigger flex max-w-max text-xs ${
        valid ? 'cell-valid' : 'cell-warning'
      } ${
        checkedContentsList.map((cc) => cc.uid).includes(contents.uid)
          ? 'cell-list-checked'
          : ''
      }`}>
      {syncContentsMembers.length > 0 && (
        <div
          className="absolute top-0 left-0 w-12 h-8 cell-sync-user text-white flex justify-center items-center"
          style={{
            zIndex: 110,
          }}>
          {syncContentsMembers.length > 0 && (
            <div className="w-full truncate text-xxs px-px text-center">
              {syncContentsMembers[0].name}
            </div>
          )}
        </div>
      )}
      <div className="cell-list-fixed sticky left-0 z-30">
        <div
          className="px-2 flex justify-center items-center bg-gray-200 border-r border-b border-gray-300  z-30 "
          style={{
            width: LAYOUT_CHECKBOX_CELL_WIDTH + 'px',
            height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
          }}
          title={t('select')}>
          <Checkbox
            checked={checkedContentsList
              .map((cc) => cc.uid)
              .includes(contents.uid)}
            onChange={(e) => onToggleCheck(e)}
            disabled={currentProject?.role === 'VIEWER'}
          />
        </div>
      </div>
      {getCells(currentModel.componentList)}
      <div
        className="cell-item flex items-center border-r border-b text-xs border-gray-300 bg-white px-2"
        style={{
          width: LAYOUT_MIN_CELL_WIDTH + 10 + 'px',
          height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT,
        }}>
        <Checkbox
          checked={contents.open}
          onChange={() => onToggleOpen(contents)}
          disabled={currentProject?.role === 'VIEWER'}
        />
      </div>
      <div
        className="cell-item flex border-r border-b text-xs border-gray-300 bg-white px-2 pt-2 relative"
        style={{
          width: LAYOUT_DEFAULT_CELL_DATE_WIDTH + 'px',
          height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT,
        }}>
        {contents.date && contents.date.createdAt
          ? moment(contents.date.createdAt, 'YYYYMMDDHHmmss').format(
              'YYYY-MM-DD HH:mm:ss'
            )
          : '-'}
      </div>
      <div
        className="cell-item flex border-r border-b text-xs border-gray-300 bg-white px-2 pt-2 relative"
        style={{
          width: LAYOUT_DEFAULT_CELL_DATE_WIDTH + 'px',
          height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT,
        }}>
        {contents.date && contents.date.editedAt
          ? moment(contents.date.editedAt, 'YYYYMMDDHHmmss').format(
              'YYYY-MM-DD HH:mm:ss'
            )
          : '-'}
      </div>
    </div>
  ) : (
    <></>
  )
}
