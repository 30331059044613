import React, { ReactElement } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { isValidLogin } from '@/utils/auth'

interface PrivateRouteProps {
  children?: ReactElement // Router.tsx에서 PrivateRoute가 감싸고 있는 Componet Element
  authentication: boolean // true: 로그인 상태에서만 접속 가능, false: 로그아웃 상태에서만 접속 가능
}

export const PrivateRoute = ({
  authentication,
}: PrivateRouteProps): React.ReactElement | null => {
  // 로그인 여부 확인
  const isAuthenticated = isValidLogin()

  if (authentication) {
    // 인증을 안했을 경우 로그인 페이지로, 했을 경우 해당 페이지로
    return !isAuthenticated ? <Navigate to="/login" /> : <Outlet />
  } else {
    // 인증을 안햇을 경우 해당 페이지로 인증을 한 상태일 경우 main페이지로
    return !isAuthenticated ? <Outlet /> : <Navigate to="/" />
  }
}
