import { AppDispatch } from '@/states'
import { ActionTypesAuth } from '@/states/interfaces'
import { axiosInstance, AUTH_URL } from '@/configs'

/**
 * 회원의 즐겨찾기 불러오기
 * @returns
 */
export const getFavorites = () => (dispatch: AppDispatch) => {
  return axiosInstance
    .get(`${AUTH_URL}/members/favorites`)
    .then((res) => {
      dispatch({
        type: ActionTypesAuth.SET_FAVORITES,
        payload: {
          favorites: res.data,
        },
      })
    })
    .catch((e) => {
      dispatch({
        type: ActionTypesAuth.SET_FAVORITES,
        payload: {
          favorites: null,
        },
      })
    })
}

/**
 * 프로젝트 즐겨찾기 추가
 * @param type
 * @param projectUid
 * @returns
 */
export const addFavoriteProject = (type: string, projectUid: string) => {
  return axiosInstance.post(
    `${AUTH_URL}/members/favorites/${type}/${projectUid}`
  )
}

/**
 * 프로젝트 즐겨찾기 제거
 * @param type
 * @param id
 * @returns
 */
export const removeFavorites = (type: string, id: string | number) => {
  return axiosInstance.delete(`${AUTH_URL}/members/favorites/${type}/${id}`)
}

/**
 * 모델 즐겨찾기 추가
 * @param projectUid
 * @param modelId
 * @returns
 */
export const addFavoriteModel = (projectUid: string, modelId: number) => {
  return axiosInstance.post(
    `${AUTH_URL}/members/favorites/projects/${projectUid}/models/${modelId}`
  )
}

/**
 * 모델 즐겨찾기 제거
 * @param projectUid
 * @param modelId
 * @returns
 */
export const removeFavoriteModel = (projectUid: string, modelId: number) => {
  return axiosInstance.delete(
    `${AUTH_URL}/members/favorites/projects/${projectUid}/models/${modelId}`
  )
}

/**
 * 콘텐츠 즐겨찾기 추가
 * @param projectUid
 * @param modelId
 * @param contentsUid
 * @returns
 */
export const addFavoriteContents = (
  projectUid: string,
  modelId: number,
  contentsUid: string
) => {
  return axiosInstance.post(
    `${AUTH_URL}/members/favorites/projects/${projectUid}/models/${modelId}/contents/${contentsUid}`
  )
}

/**
 * 콘텐츠 즐겨찾기 제거
 * @param projectUid
 * @param modelId
 * @param contentsUid
 * @returns
 */
export const removeFavoriteContents = (
  projectUid: string,
  modelId: number,
  contentsUid: string
) => {
  return axiosInstance.delete(
    `${AUTH_URL}/members/favorites/projects/${projectUid}/models/${modelId}/contents/${contentsUid}`
  )
}
