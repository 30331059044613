import { dateFormatReq } from '@/configs'
import { RootState, useAppDispatch } from '@/states'
import {
  CheckOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  RightOutlined,
} from '@ant-design/icons'
import { Button, Col, Drawer, Modal, Row, Select, message } from 'antd'
import { useFormik } from 'formik'
import moment from 'moment-timezone'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'

import {
  createContents,
  deleteContents,
  getContentsItem,
  getProjectUsage,
  updateContents,
} from '@/api'
import { AlertStatus } from '@/components/Common'
import { FormComponent, FormPrevNext } from '@/components/Contents'
import {
  addContentsList,
  deleteContentsList,
  setContentsPreviewFormModal,
  setContentsPreviewReqComponents,
  setContentsReqComponents,
  setCurrentLanguage,
  updateContentsItem,
} from '@/states/actions'
import {
  CellInterface,
  CellValidationInterface,
  ComponentInterface,
  LanguageMap,
  LanguagesAvailable,
  MediaInterface,
  ProjectInterface,
  UserInterface,
} from '@/types'
import { checkCellValidation } from '@/utils/helpers'
import { SyncMember } from '../Sync'

const initialContentsFormValues = {}

export const ContentsFormModal = ({
  open,
  width,
  preview,
}: {
  open: boolean
  width: number
  preview?: boolean
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const [, updateState] = React.useState<{} | undefined>()

  // Params
  const { projectUid, modelId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  // State (Redux)
  const { modalsState, projectsState } = useSelector(
    (state: RootState) => ({
      modalsState: state.modals,
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const {
    contentsInfo,
    contentsPreviewInfo,
    contentsModelId,
    contentsPreviewModelId,
  } = modalsState
  const {
    currentProject,
    reqComponents,
    reqPreviewComponents,
    contentsPagination,
    contentsSort,
    contentsListReload,
    contentsListLoading,
    contentsList,
    modelList,
    currentLanguage,
    ws,
    wsSync,
  } = projectsState

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [flatComponents, setFlatComponents] = useState<ComponentInterface[]>([])
  const [langs, setLangs] = useState<
    { label: string; value: LanguagesAvailable }[]
  >([])

  // Memo
  const reqComponentsArr = useMemo(() => {
    return !preview ? reqComponents : reqPreviewComponents
  }, [preview, reqComponents, reqPreviewComponents])

  const contentsFormInfo = useMemo(() => {
    return !preview ? contentsInfo : contentsPreviewInfo
  }, [preview, contentsInfo, contentsPreviewInfo])

  const selectedModel = useMemo(() => {
    return !preview
      ? modelList.find((m) => m.id === contentsModelId)
      : modelList.find((m) => m.id === contentsPreviewModelId)
  }, [preview, contentsModelId, contentsPreviewModelId])

  // Effect
  useEffect(() => {
    if (currentProject) {
      setLangs(
        currentProject.languageList.map((l) => {
          return { label: t('lang.' + l.toLocaleLowerCase()), value: l }
        })
      )
    }
  }, [currentProject])

  useEffect(() => {
    if (open) {
      if (!contentsInfo) {
        formikContentsForm.validateForm()
      }

      generateReqComponents(true)
    } else {
      resetForm()

      if (ws) {
        onSyncContents('UNSELECT')
      }
    }
  }, [open])

  useEffect(() => {
    if (ws) {
      if (open && contentsInfo) {
        onSyncContents('SELECT', contentsInfo.uid)
      } else {
        // onSyncContents('UNSELECT')
      }
    }
  }, [ws, open, contentsInfo])

  useEffect(() => {
    if (selectedModel) {
      flattenComponents()
    }
  }, [selectedModel])

  useEffect(() => {
    if (flatComponents && flatComponents.length) {
      generateReqComponents()
    }
  }, [flatComponents])

  // Validation
  const validationcontentsFormSchema = Yup.object().shape({})

  // Formik
  const formikContentsForm = useFormik({
    initialValues: initialContentsFormValues,
    validationSchema: validationcontentsFormSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (!selectedModel || !currentProject) return false

      // 필수 입력 확인
      let validationErrorArr: CellValidationInterface[] = []

      reqComponentsArr.forEach((rc) => {
        validationErrorArr = [
          ...validationErrorArr,
          ...checkCellValidation(
            rc,
            currentProject as ProjectInterface,
            contentsFormInfo,
            contentsList
          ),
        ]
      })

      if (validationErrorArr.length) {
        message.warning({
          content: (
            <ul className="text-left list-disc pl-4 mb-0">
              {validationErrorArr.map((error, eIdx) => (
                <li key={eIdx}>
                  {t('error.' + error.errorType, { field: error.name })}
                </li>
              ))}
            </ul>
          ),
        })

        return false
      }

      setLoading(true)

      // Sanitize
      reqComponentsArr.forEach((cell) => {
        if (
          cell.component &&
          (cell.component.type === 'TITLE' ||
            cell.component.type === 'SINGLE_LINE_TEXT' ||
            cell.component.type === 'LONG_LINE_TEXT') &&
          cell.component.option &&
          !cell.component.option.allowHtml
        ) {
          currentProject?.languageList.forEach((lang) => {
            if (cell && cell.languageMap && cell.languageMap[lang]) {
              cell.languageMap[lang] = (cell.languageMap[lang] as string)
                .trim()
                .normalize('NFC')
                .replace(/(<([^>]+)>)/gi, '')
            }
          })
        }

        if (
          cell.component &&
          (cell.component.type === 'PASSWORD' ||
            cell.component.type === 'EMAIL') &&
          cell.value
        ) {
          cell.value = (cell.value as string)
            .trim()
            .normalize('NFC')
            .replace(/(<([^>]+)>)/gi, '')
        }
        if (
          cell.component &&
          (cell.component.type === 'SINGLE_LINE_TEXT_MONO' ||
            cell.component.type === 'LONG_LINE_TEXT_MONO' ||
            cell.component.type === 'RICH_TEXT_MONO') &&
          cell.component.option &&
          !cell.component.option.allowHtml &&
          cell.value
        ) {
          cell.value = (cell.value as string)
            .trim()
            .normalize('NFC')
            .replace(/(<([^>]+)>)/gi, '')
        }

        if (
          cell.component?.type === 'DATE' &&
          cell.value &&
          (typeof cell.value === 'string' || typeof cell.value === 'object')
        ) {
          if (cell.option?.dateFormats === 'year') {
            cell.value = moment(cell.value, 'YYYY').format(dateFormatReq)
          } else if (cell.option?.dateFormats === 'month') {
            cell.value = moment(cell.value, 'YYYY-MM').format(dateFormatReq)
          } else if (cell.option?.dateFormats === 'date') {
            cell.value = moment(cell.value, 'YYYY-MM-DD').format(dateFormatReq)
          } else if (cell.option?.dateFormats === 'time') {
            cell.value = moment(cell.value, 'HH:mm:ss').format(dateFormatReq)
          }
        }
      })

      const req = {
        order: null,
        uid: '',
        cellList: reqComponentsArr,
      }

      if (!contentsFormInfo) {
        createContents(currentProject?.uid, selectedModel?.id, req)
          .then((res) => {
            message.success(t('saveSuccess'))

            getContentsItem(
              currentProject?.uid,
              selectedModel?.id,
              res.data
            ).then((res) => {
              dispatch(addContentsList(res.data))
              dispatch(getProjectUsage(currentProject?.uid as string))
            })

            if (preview) {
              dispatch(setContentsPreviewFormModal(false))
            } else {
              navigate(
                `/projects/${currentProject?.uid}/${
                  selectedModel?.id
                }/contents?page=${contentsPagination.page}&size=${
                  contentsPagination.size
                }&sort=${contentsSort}&q=${searchParams.get('q')}`
              )
            }
          })
          .catch((e) => {
            message.error(e.response.data.error)
            setLoading(false)
          })
      } else {
        if (contentsFormInfo.uid) {
          req.uid = contentsFormInfo.uid
        }

        updateContents(
          currentProject?.uid,
          selectedModel?.id,
          contentsFormInfo.uid,
          req
        )
          .then((res) => {
            getContentsItem(
              currentProject?.uid,
              selectedModel?.id,
              contentsFormInfo.uid
            ).then((res) => {
              message.success(t('saveSuccess'))

              dispatch(updateContentsItem(res.data))

              if (!preview) {
                navigate(
                  `/projects/${currentProject?.uid}/${
                    selectedModel?.id
                  }/contents?page=${contentsPagination.page}&size=${
                    contentsPagination.size
                  }&sort=${contentsSort}&q=${
                    searchParams.get('q') ? searchParams.get('q') : ''
                  }`
                )
              } else {
                dispatch(setContentsPreviewFormModal(false))

                // 연관 콘텐츠 Title 변경
                const updatedTitle = res.data.cellList.find(
                  (cell) => cell.component.type === 'TITLE'
                )
                const titleEles = document.querySelectorAll(
                  `.rel-contentsTitle-${contentsFormInfo.uid}`
                )

                if (updatedTitle && titleEles && titleEles.length) {
                  titleEles.forEach((ele) => {
                    ele.innerHTML = updatedTitle.languageMap[currentLanguage]
                  })
                }
              }
            })
          })
          .catch((e) => {
            message.error(e.response.data.error)
            setLoading(false)
          })
      }
    },
  })

  /**
   * 컴포넌트 depth 제거
   */
  const flattenComponents = () => {
    if (selectedModel) {
      // Component 평탄화
      const flattedComponentsArr: ComponentInterface[] = []
      var flatComponents = (compList) => {
        compList.forEach((comp) => {
          if (comp.childList) {
            flattedComponentsArr.push(comp)
            flatComponents(comp.childList)
          } else flattedComponentsArr.push(comp)
        })
      }

      flatComponents(selectedModel.componentList)
      setFlatComponents(flattedComponentsArr)
    }
  }

  /**
   * 컴포넌트 depth 제거
   */
  const generateReqComponents = (isScroll = false) => {
    // Request Cell 생성
    const cellArr: CellInterface[] = []
    flatComponents
      .filter((comp) => comp.type !== 'BLOCK')
      .forEach((comp) => {
        const oldCell =
          contentsFormInfo &&
          contentsFormInfo?.cellList?.find(
            (cell) => cell?.component?.id === comp.id
          )

        // Language Map
        const languageMap: LanguageMap = {}
        currentProject?.languageList.forEach((lang) => {
          languageMap[lang] =
            oldCell && oldCell.languageMap ? oldCell.languageMap[lang] : ''
        })

        const cellInfo: CellInterface = {
          componentId: comp.id as number,
          component: comp,
          type: comp.type,
          option: comp.option,
          value: oldCell && oldCell.value ? oldCell.value : null,
          selectorList:
            oldCell && oldCell.selectorList && oldCell.selectorList.length
              ? oldCell.selectorList
              : [],
          selectorIdList:
            oldCell && oldCell.selectorList && oldCell.selectorList.length
              ? oldCell.selectorList.map((s) => s.id as number)
              : [],
          mediaIdList:
            oldCell && oldCell.mediaList
              ? oldCell.mediaList.map((m) => m.id)
              : [],
          mediaList: oldCell && oldCell.mediaList ? oldCell.mediaList : [],
          relationUidList:
            oldCell && oldCell.relationList
              ? oldCell.relationList.map((m) => m.uid)
              : [],
          relationList:
            oldCell && oldCell.relationList ? oldCell.relationList : [],
          languageMap: languageMap,
        }

        if (oldCell) {
          cellInfo.uid = oldCell.uid
        }

        cellArr.push(cellInfo)
      })

    if (!preview) {
      dispatch(setContentsReqComponents(cellArr))
    } else {
      dispatch(setContentsPreviewReqComponents(cellArr))
    }

    if (isScroll) {
      setTimeout(() => {
        const modalForm = document.getElementById(
          'contents-modal-form'
        ) as HTMLElement

        if (modalForm) {
          if (searchParams.get('component')) {
            const contentsComponent = document.getElementById(
              `contents-component-${searchParams.get('component')}`
            )

            setTimeout(() => {
              if (contentsComponent) {
                modalForm.scrollTop =
                  contentsComponent.getBoundingClientRect().top -
                  modalForm.getBoundingClientRect().top
              }
            })
          } else {
            modalForm.scrollTop = 0
          }
        }
      })
    }
  }

  /**
   * 폼 리셋
   */
  const resetForm = () => {
    formikContentsForm.resetForm()
    setLoading(false)
    // generateReqComponents()

    if (!preview) {
      dispatch(setContentsReqComponents([]))
    } else {
      dispatch(setContentsPreviewReqComponents([]))
    }
  }

  /**
   * 셀 변경
   * @param componentId
   * @param key
   * @param value
   * @param lang
   */
  const onHandleChangeCell = async (componentId, key, value, lang) => {
    const updatedReqComponents: CellInterface[] = reqComponentsArr
    const updatedReqComponent = updatedReqComponents.find(
      (rc) => rc.componentId === componentId
    )

    if (!reqComponentsArr || !updatedReqComponent) return

    const idx = updatedReqComponents.indexOf(updatedReqComponent)
    if (!updatedReqComponent) return

    if (lang) {
      updatedReqComponent[key][lang] = value
    } else if (typeof updatedReqComponent[key] !== 'undefined') {
      updatedReqComponent[key] = value
    }

    if (
      updatedReqComponent.type === 'DATE' &&
      updatedReqComponent.option?.dateFormats === 'time'
    ) {
      updatedReqComponent[key] = moment(value, 'HH:mm:ss').format(dateFormatReq)
    }

    if (key === 'mediaList') {
      updatedReqComponent.mediaList = value.map((m: MediaInterface) => {
        return {
          id: m.id,
          file: m.file,
          languageMap: m.languageMap,
          mediaType: m.mediaType,
          order: m.order,
          type: m.type,
          value: m.value,
          annotations: m.annotations ? m.annotations : [],
        }
      })
    } else if (key === 'relationList') {
      updatedReqComponent.relationUidList = value.map((m) => m.uid)
    }

    updatedReqComponents[idx] = updatedReqComponent

    if (!preview) {
      await dispatch(setContentsReqComponents(updatedReqComponents))
    } else {
      await dispatch(setContentsPreviewReqComponents(updatedReqComponents))
    }
  }

  /**
   * 모달 닫기
   */
  const onModelFormClose = (isConfirm = false) => {
    if (isConfirm && !confirm(t('confirmClose'))) {
      return false
    }

    if (preview) {
      dispatch(setContentsPreviewFormModal(false))
    } else {
      navigate(
        `/projects/${currentProject?.uid}/${selectedModel?.id}/contents?page=${
          contentsPagination.page
        }&size=${contentsPagination.size}&sort=${contentsSort}&q=${
          searchParams.get('q') ? searchParams.get('q') : ''
        }`
      )
    }
  }

  /**
   * 콘텐츠 삭제
   * @param contentsToBeDeleted
   */
  const onContentsDelete = (contentsToBeDeleted) => {
    Modal.confirm({
      centered: true,
      title: t('confirmDeleteContentsTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('confirmDeleteContentsDesc'),
      okText: t('delete'),
      cancelText: t('cancel'),
      onOk() {
        return new Promise((resolve, reject) => {
          deleteContents(
            currentProject?.uid,
            selectedModel?.id,
            contentsToBeDeleted.uid
          )
            .then((res) => {
              message.success(t('deleteSuccess'))
              dispatch(deleteContentsList([contentsToBeDeleted.uid]))
              dispatch(getProjectUsage(currentProject?.uid as string))
              onModelFormClose()
              resolve(res)
            })
            .catch((e) => {
              message.error(e.response.data.error)
              reject(e)
            })
        }).catch((e) => console.log(e))
      },
      onCancel() {},
    })
  }

  /**
   * Websocket 편집 Sync
   * @param action
   * @param uid
   */
  const onSyncContents = (action: 'SELECT' | 'UNSELECT', uid?: string) => {
    if (ws) {
      // WS 정보 업데이트
      const wsReq = {
        action,
        type: 'MODEL',
        projectUid,
        modelId: Number(modelId),
        select: 'CONTENT',
        uid,
      }

      ws.send(JSON.stringify(wsReq))
    }
  }

  // Memo
  const syncContents = useMemo(() => {
    return contentsInfo
      ? wsSync.filter((ws) => {
          return ws.select === 'CONTENT' && ws.uid === contentsInfo.uid
        })
      : []
  }, [wsSync, contentsInfo])

  const syncContentsMembers: UserInterface[] = useMemo(() => {
    return syncContents.length &&
      currentProject &&
      currentProject.memberList &&
      wsSync.length
      ? currentProject.memberList.filter(
          (m) =>
            syncContents.map((ws) => Number(ws.memberId)).indexOf(m.id) >= 0
        )
      : []
  }, [syncContents, currentProject?.memberList])

  return currentProject && selectedModel ? (
    <>
      {/* 콘텐츠 모달: 시작 */}
      <Drawer
        width={width}
        // centered={true}
        maskClosable={false}
        closeIcon={<CloseCircleOutlined title={t('close')} />}
        open={open}
        bodyStyle={{
          backgroundColor: '#FAFAFA',
        }}
        onClose={() => onModelFormClose(true)}
        title={
          <div className="flex items-center space-x-2">
            <span>{selectedModel.languageMap[currentProject.defaultLang]}</span>
            <span className="flex items-center">
              <RightOutlined className="text-xs"></RightOutlined>
            </span>
            <span>
              {contentsFormInfo ? t('editContents') : t('addContents')}
            </span>
          </div>
        }
        extra={[
          <div key={'footer'} className={'flex justify-between items-center'}>
            <div className="mr-2.5 w-40 flex justify-end items-center overflow-x-auto h-8">
              <ul className="flex items-center space-x-1 w-max mb-0">
                {syncContentsMembers.map((member, mIdx) => (
                  <SyncMember member={member} key={mIdx} idx={mIdx} />
                ))}
              </ul>
            </div>
            <div>
              {contentsFormInfo &&
              !preview &&
              currentProject.role !== 'VIEWER' ? (
                <Button
                  type="text"
                  danger
                  disabled={
                    loading || contentsListReload || contentsListLoading
                  }
                  onClick={() => onContentsDelete(contentsFormInfo)}>
                  {t('delete')}
                </Button>
              ) : (
                <></>
              )}
            </div>
            <div>
              {currentProject.role !== 'VIEWER' ? (
                <Button
                  type={'primary'}
                  icon={<CheckOutlined />}
                  onClick={() => formikContentsForm.submitForm()}
                  disabled={
                    loading || contentsListReload || contentsListLoading
                  }
                  loading={loading}>
                  {t('save')}
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>,
        ]}
        footer={contentsFormInfo && !preview ? <FormPrevNext /> : <></>}>
        {open ? (
          <form
            onSubmit={formikContentsForm.handleSubmit}
            method="POST"
            autoComplete="off">
            <AlertStatus
              status={formikContentsForm.status}
              onClick={() => formikContentsForm.setStatus(null)}></AlertStatus>
            <div className={'space-y-6'}>
              <Row gutter={48}>
                <Col
                  id="contents-modal-form"
                  xs={24}
                  lg={18}
                  className={'overflow-y-auto mb-6 lg:mb-0'}>
                  <div>
                    <Row gutter={24} className="space-y-6">
                      {selectedModel.componentList.map((comp) => (
                        <Col
                          span={
                            comp?.option?.colSpan ? comp.option.colSpan : 24
                          }
                          key={comp.id}>
                          <FormComponent
                            onCellChange={onHandleChangeCell}
                            cell={
                              reqComponentsArr
                                ? reqComponentsArr.find(
                                    (cell) => cell?.componentId === comp.id
                                  )
                                : null
                            }
                            flattenCells={reqComponentsArr}
                            component={comp}
                            preview={preview}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Col>
                <Col xs={24} lg={6} className="">
                  <div className="sticky top-0 space-y-3">
                    {/* 언어 선택: 시작 */}
                    <div className="bg-white rounded shadow p-3 text-sm">
                      <p className="mb-1">{t('languages')}</p>
                      <div className="space-y-2">
                        <Select
                          value={currentLanguage}
                          className="w-full"
                          onChange={(val) =>
                            dispatch(
                              setCurrentLanguage(val as LanguagesAvailable)
                            )
                          }>
                          {langs.map((lang) => (
                            <Select.Option key={lang.value}>
                              {lang.label}
                            </Select.Option>
                          ))}
                        </Select>
                        {contentsFormInfo ? (
                          <div>
                            <p className="mb-1">{t('isPublic')}</p>
                            <p className="mb-0">
                              {contentsFormInfo.open
                                ? t('public')
                                : t('private')}
                            </p>
                          </div>
                        ) : (
                          <></>
                        )}
                        {contentsFormInfo && contentsFormInfo.date ? (
                          <div className="space-y-2">
                            <div>
                              <p className="mb-1">{t('createdDate')}</p>
                              <p className="mb-0">
                                {contentsFormInfo.date?.createdAt
                                  ? moment(
                                      contentsFormInfo.date?.createdAt,
                                      'YYYYMMDDHHmmss'
                                    ).format('YYYY-MM-DD HH:mm')
                                  : moment(
                                      contentsFormInfo.date?.createdAt,
                                      'YYYYMMDDHHmmss'
                                    ).format('YYYY-MM-DD HH:mm')}
                              </p>
                            </div>
                            <div>
                              <p className="mb-1">{t('lastEditedDate')}</p>
                              <p className="mb-0">
                                {contentsFormInfo.date?.editedAt
                                  ? moment(
                                      contentsFormInfo.date?.editedAt,
                                      'YYYYMMDDHHmmss'
                                    ).format('YYYY-MM-DD HH:mm')
                                  : moment(
                                      contentsFormInfo.date?.createdAt,
                                      'YYYYMMDDHHmmss'
                                    ).format('YYYY-MM-DD HH:mm')}
                              </p>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    {/* 언어 선택: 끝 */}
                    {/* 추가 기능 버튼: 시작*/}
                    <div className="space-y-2">
                      <Button block disabled>
                        {t('notes')}
                      </Button>
                    </div>
                    {/* 추가 기능 버튼: 끝 */}
                  </div>
                </Col>
              </Row>
            </div>
          </form>
        ) : (
          <></>
        )}
      </Drawer>
      {/* 콘텐츠 모달: 끝 */}
    </>
  ) : (
    <></>
  )
}
